/* eslint-disable */
// reducers
import {
  TOGGLE_LOADING,
  FETCH_ASSET_SELECT_TYPE_LIST,
  FETCH_CAMPAIGN_LIST,
  FETCH_GREETING_LIST,
  FETCH_LANDINGPAGE_URLS,
  FETCH_CREATE_TEMPLATE_DATA,
  FETCH_SHOWCASE_URLS,
  FETCH_MICROSITE_URLS,
  SET_BREADCRUMB_STATUS,
  SET_SELECTED_ASSET_TYPE,
  SET_BASE_UPLOAD_DATA,
  SET_GLOBAL_FIELDS_DATA,
  SAVE_GLOBAL_FIELDS_DATA,
  FETCH_PARTNERS_LIST,
  FETCH_USERS_LIST,
  SET_SELECTED_ASSET_PUBLISH,
  DIRECT_PUBLISH_ASSET,
  EDIT_ASSET,
  PUBLISH_ASSET,
  FETCH_CONTENT_TYPE_LIST,
  FETCH_KEYWORDS_LIST,
  FETCH_CTA_LIST,
  UNAUTHORIZED,
  SET_SELECTED_PERSONALIZE,
  UNAUTHORIZED_ASSET_UPLOAD,
  SET_SELECTED_CATEGORIES,
  SET_SELECTED_SUBCATEGORIES,
  SET_SELECTED_CAMPAIGNS,
  SET_SELECTED_HASHTAGS,
  SET_SELECTED_GREETINGS,
  SET_SELECTED_CONTENT_TYPE,
  SET_SELECTED_VIDEO_TYPE,
  SET_SELECTED_BUSINESS_TYPE,
  SET_SELECTED_DEFINE_URL,
  SET_CUSTOM_URL,
  FETCH_PERSONALIZE_LIST,
  AUTOEMAIL_DATA,
  SET_AUTOEMAIL_PATH,
  SET_AUTOEMAIL_STATUS,
  CAMPAIGN_TITLE_STATUS
} from "../../constants/ActionTypes";

const initialState = {
  loading: false,
  breadcrumbStatus: 0,
  selectTypeList: [],
  campaignList: [],
  landingpageUrlList: [],
  showcaseUrlList: [],
  landingSecureUrl: null,
  showcaseSecureUrl: null,
  micrositeUrl: null,
  customUrl: null,
  greetingId: null,
  selectedAssetType: {},
  baseUploadData: {},
  globalFieldsData: {},
  globalCategories: [],
  globalSubCategories: [],
  globalCampaigns: [],
  globalHashtags: [],
  selectedCategories: [],
  selectedSubCategories: [],
  selectedCampaigns: [],
  selectedHashtags: [],
  selectedGreetings: null,
  selectedPersonalizes: null,
  selectedContentType: null,
  selectedBusinessType: null,
  selectedVideoType: null,
  selectedDefineUrl: null,
  saveGlobalFieldsData: [],
  autoEmailData: null,
  partnersList: [],
  usersList: [],
  directPublish: false,
  editAsset: false,
  selectedAssetPublish: {},
  publishAsset: {},
  autoEmailPath: 0,
  contentTypeList: [],
  autoemailStatus: null,
  personalizeList: null,
  keywordsList: [],
  ctaList: [],
  autoemail_main_data: null,
  title_status: "",
  unauthorizedAssetUpload: null,
};

const assetUploadReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case TOGGLE_LOADING:
      return { ...state, loading: payload };
    case FETCH_ASSET_SELECT_TYPE_LIST:
      return { ...state, selectTypeList: payload };
    case AUTOEMAIL_DATA:
      return { ...state, autoemail_main_data: payload };
    case SET_AUTOEMAIL_PATH:
      return { ...state, autoEmailPath: payload };
    case FETCH_CAMPAIGN_LIST:
      return { ...state, campaignList: payload };
    case FETCH_GREETING_LIST:
      return { ...state, greetingList: payload };
    case FETCH_CREATE_TEMPLATE_DATA:
      return { ...state, autoEmailData: payload };
    case FETCH_LANDINGPAGE_URLS:
      return { ...state, landingpageUrlList: payload.response, landingSecureUrl: payload.secure_url };
    case FETCH_SHOWCASE_URLS:
      return { ...state, showcaseUrlList: payload.response, showcaseSecureUrl: payload.secure_url };
    case FETCH_MICROSITE_URLS:
      return { ...state, micrositeUrl: payload };
    case SET_AUTOEMAIL_STATUS:
      return { ...state, autoemailStatus: payload };
    case CAMPAIGN_TITLE_STATUS:
      return { ...state, title_status: payload };
    case SET_BREADCRUMB_STATUS:
      return { ...state, breadcrumbStatus: payload };
    case SET_SELECTED_ASSET_TYPE:
      return { ...state, selectedAssetType: payload };
    case SET_BASE_UPLOAD_DATA:
      return { ...state, baseUploadData: payload };
    case SET_GLOBAL_FIELDS_DATA:
      return {
        ...state,
        globalFieldsData: payload.globalFields,
        globalCategories: payload.selectedCategory,
        globalSubCategories: payload.selectedSubCategories,
        globalCampaigns: payload.selectedCampaign,
        globalHashtags: payload.selectedHashtag,
      };
    case SET_SELECTED_CATEGORIES:
      return { ...state, selectedCategories: payload };
    case SET_SELECTED_CAMPAIGNS:
      return { ...state, selectedCampaigns: payload };
    case SET_SELECTED_HASHTAGS:
      return { ...state, selectedHashtags: payload };
    case SET_SELECTED_GREETINGS:
      return { ...state, selectedGreetings: payload };
    case SET_SELECTED_PERSONALIZE:
      return { ...state, selectedPersonalizes: payload };
    case SET_SELECTED_CONTENT_TYPE:
      return { ...state, selectedContentType: payload }; 
    case SET_SELECTED_VIDEO_TYPE:
      return { ...state, selectedVideoType: payload };
    case SET_SELECTED_BUSINESS_TYPE:
      return { ...state, selectedBusinessType: payload };
    case SET_SELECTED_DEFINE_URL:
      return { ...state, selectedDefineUrl: payload };
    case SET_CUSTOM_URL:
      return { ...state, customUrl: payload };
    case SAVE_GLOBAL_FIELDS_DATA:
      return { ...state, saveGlobalFieldsData: payload };
    case FETCH_PARTNERS_LIST:
      return { ...state, partnersList: payload };
    case FETCH_USERS_LIST:
      return { ...state, usersList: payload };
    case SET_SELECTED_ASSET_PUBLISH:
      return { ...state, selectedAssetPublish: payload };
    case DIRECT_PUBLISH_ASSET:
      return { ...state, directPublish: payload };
    case EDIT_ASSET:
      return { ...state, editAsset: payload };
    case PUBLISH_ASSET:
      return { ...state, publishAsset: payload };
    case FETCH_CONTENT_TYPE_LIST:
      return { ...state, contentTypeList: payload };
    case FETCH_PERSONALIZE_LIST:
      return { ...state, personalizeList: payload };
    case FETCH_KEYWORDS_LIST:
      return { ...state, keywordsList: payload };
    case FETCH_CTA_LIST:
      return { ...state, ctaList: payload };
    case UNAUTHORIZED_ASSET_UPLOAD:
      return { ...state, unauthorizedAssetUpload: payload };
      case SET_SELECTED_SUBCATEGORIES:
        return { ...state, selectedSubCategories: payload};
    default:
      return state;
  }
};
export default assetUploadReducer;
