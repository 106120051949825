import React from 'react'
import "./PageNotFound.css"
import { useDispatch, useSelector } from "react-redux";
import logo from "../../assets/pagenotfound/logo_big.png"
import { backToPreviousRoute } from "../../redux/actions/actions";
import { useEffect } from 'react'

export default function PageNotFound() {
    const dispatch = useDispatch();

    useEffect(() => {
      dispatch(backToPreviousRoute(""));
      return () => {
        dispatch(backToPreviousRoute(""));
      }
    }, [])
    
    
    return (
        <div className='main-div-404'>
            <div>
                <div id="wrapper"> 
                    <div class="container container-404">
                        <a href="/home/AssetLibrary" class="logo-link" title="back home"> 
                        <img src={logo} class="logo" alt="SalesPanda Logo"/> </a>   
                        {/* <!-- brick of wall -->             */}
                        <div class="brick"></div>    
                        {/* <!-- end brick of wall -->            <!-- Number -->          */}
                        <div class="number">              
                            <div class="four"></div>             
                            <div class="zero">                 
                                <div class="nail"></div>            
                            </div>             
                            <div class="four"></div>  
                        </div>        
                        <div class="info">    
                            <h1 className='h1-head'>Oops! Did you take a wrong turn?</h1>       
                            <p>The page you are looking for was moved, renamed or might<br/> never have existed.</p> 
                            <a href="/home/AssetLibrary" class="btn">Go Home</a>         
                        </div>      
                    </div>
                .   
                </div>
            </div>
            <footer id="footer">   
                <div class="container container-404">          
                    <div class="worker"></div>  
                <div class="tools"></div>   
                </div> 
            </footer>
        </div>
    )
}
