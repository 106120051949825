import "bootstrap/dist/css/bootstrap.min.css";
import { useState,useEffect } from "react";

export default function Table(props) {
  const [users, setUsers] = useState([]);
  const [rankHolderActive, setRankHolderActive] = useState(false);
  
  useEffect(() => {
    if(props.selfRank?.length > 0 || Object.keys(props.selfRank).length > 0){
      props.categoryData.unshift(props.selfRank);
      setRankHolderActive(true);
    } else {
      setRankHolderActive(false);
    }
    setUsers(props.categoryData);
  }, [])
  
  
  return (
    <div className="App">
        <div className="table borderd">
              <div style={{ lineHeight: "35px" }} className="text-capitalize">
                    {users && users.map((user, index) => {
                            return (
                              <div key={"report-"+index} className={"row report-border-bottom " + ((rankHolderActive === true && index === 0) && "rank-holder-highlight")}>
                                  <div className="report-row-count">
                                      {user.rank}
                                  </div>
                                  <div className="level-styling" style={{width:"12%"}}>
                                      <span className="level-inside">
                                        {user.badge_icon !== "NA" ? <img src={user.badge_icon} alt="badge"/>
                                        : <span>{"NA"}</span>}
                                      </span>
                                  </div>
                                  <div className="report-common report-name">
                                      <span title={user.name}>{user.name && user.name.slice(0, 16)}
                                      {((user.name && user.name.length > 16)) && <span>...</span>}</span>
                                  </div>
                                  <div className="report-common report-points">
                                    <span className="column-text-color">{user.total_points}</span>
                                  </div>
                                  <div className="report-common report-code">
                                    <span className="column-text-color" title={user.advisor_code}>{user.advisor_code}</span>
                                  </div>
                                  <div className="report-common report-branch">
                                    <span className="column-text-color" title={ user.branch }>{user.branch.slice(0, 16)} {(user?.branch?.length > 16) && "..."}</span>
                                  </div>
                              </div>
                            )
                        }
                    )}
              </div>
        </div>
    </div>
  );
}
