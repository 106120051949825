import ReactDOM from "react-dom";
import React, { PureComponent } from "react";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import { Row, Col } from 'react-bootstrap';

class CropImage extends PureComponent {

    constructor(props) {
        super();
        console.log("props", props);
        this.state = {
            src: props.file,
            crop: {
                unit: "px",
                width: 500,
                height: 282,
                aspect: 16 / 9
            },
            baseImage: null
        };
    }
    
    componentDidMount() {
        if (this.props.thumbnailStatus === "microsite") {
            this.setState({
                crop: {
                    unit: "px",
                    width: 353,
                    height: 400,
                    aspect: 4 / 5
                },
            })
        }
    }
    
    onSelectFile = (e) => {
        if (e.target.files && e.target.files.length > 0) {
            const reader = new FileReader();
            reader.addEventListener("load", () =>
                this.setState({ src: reader.result })
            );
            reader.readAsDataURL(e.target.files[0]);
        }
    };

    // If you setState the crop in here you should return false.
    onImageLoaded = (image) => {
        this.imageRef = image;
    };

    onCropComplete = (crop) => {
        this.makeClientCrop(crop);
    };

    onCropChange = (crop, percentCrop) => {
        // You could also use percentCrop:
        // this.setState({ crop: percentCrop });
        this.setState({ crop });
    };

    async makeClientCrop(crop) {
        if (this.imageRef && crop.width && crop.height) {
            const croppedImageUrl = await this.getCroppedImg(
                this.imageRef,
                crop,
                "newFile.jpeg"
            );
            this.setState({ croppedImageUrl });
        }
    }

    getCroppedImg(image, crop, fileName) {
        const canvas = document.createElement("canvas");
        const pixelRatio = window.devicePixelRatio;
        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;
        const ctx = canvas.getContext("2d");

        canvas.width = crop.width * pixelRatio * scaleX;
        canvas.height = crop.height * pixelRatio * scaleY;

        ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
        ctx.imageSmoothingQuality = "high";

        ctx.drawImage(
            image,
            crop.x * scaleX,
            crop.y * scaleY,
            crop.width * scaleX,
            crop.height * scaleY,
            0,
            0,
            crop.width * scaleX,
            crop.height * scaleY
        );

        // return new Promise((resolve, reject) => {
        //   canvas.toBlob(
        //     (blob) => {
        //       if (!blob) {
        //         //reject(new Error('Canvas is empty'));
        //         console.error("Canvas is empty");
        //         return;
        //       }
        //       blob.name = fileName;
        //       console.log("fu", this.imgUrl);
        //       window.URL.revokeObjectURL(this.imgUrl);
        //       this.imgUrl = window.URL.createObjectURL(blob);
        //       resolve(this.imgUrl);
        //     },
        //     "image/jpeg",
        //     1
        //   );
        // });

        const base64Image = canvas.toDataURL("image/jpeg", 1);
        this.setState({ baseImage: base64Image });
        return base64Image;
    }

    submitImage() {
        return this.state.baseImage;
    }

    render() {
        const { crop, croppedImageUrl, src } = this.state;

        return (
            <div className="App">
                {/* <div>
                    <input type="file" accept="image/*" onChange={this.onSelectFile} />
                </div> */}
                {src && (
                    <div className="crop-box">
                        <ReactCrop
                            src={src}
                            crop={crop}
                            ruleOfThirds
                            onImageLoaded={this.onImageLoaded}
                            onComplete={this.onCropComplete}
                            onChange={this.onCropChange}
                            // locked={true}
                        />
                    </div>
                )}


                {croppedImageUrl && (
                    <div className="cropImg">
                        {this.props.thumbnailStatus !== "none" && <span className="cropper-recommend">{this.props.thumbnailStatus !== "microsite" ? "500x282" : "353x400"}</span>}
                        <img src={croppedImageUrl} width={this.props.thumbnailStatus !== "microsite" ? 200 : 112 } height={112} alt="Crop" />
                    </div>
                )}

                {/* <button className="float-start btn submit-btn" type="button" onClick={() => this.props.onImgSubmit(this.state.baseImage)}>Crop &amp; Submit</button> */}
                <button className="float-start btn submit-btn" type="button" onClick={() => {
                    this.props.onImgSubmit(this.state.baseImage);
                    if (this.props.checkPass === "multi") {
                        this.props.nextCropper(src);                    
                    } else if(this.props.checkPass === "single") {
                        this.props.cropperFlag(false);
                    } else {
                        console.log(".");
                    }
                }}>Crop &amp; {this.props.checkPass === "multi" ? "Next" : "Submit"}</button>
            </div>
        );
    }
}

export default CropImage;
