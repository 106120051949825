/* eslint-disable */
import Page from "../../../Page";
import {
  Container,
  Row,
  Col,
  Button,
  Breadcrumb,
  Modal,
} from "react-bootstrap";
import * as Icon from "react-bootstrap-icons";
import { Link } from "react-router-dom";
import Loading from "../../../Loading";
import Bookmark from "../../../Bookmark";
import { useNavigate } from "react-router-dom";
import _ from "lodash";
import { useState } from "react";

//Redux-imports
import { useDispatch, useSelector } from "react-redux";
import {
  fetchContent,
  deleteContent,
  setPublishType,
  setSelectedAssetType,
  publishContent,
  duplicateContent,
  setGlobalFieldsData,
  setEditAsset,
  //fetchCampaignList,
  fetchAssetLibraryHashtagList,
  fetchGreetingList,
  fetchLandingpageUrls,
  fetchShowcaseUrls,
  fetchMicrositeUrls,
  fetchPersonalizeList,
  setAutoEmailAccessPath,
} from "../../../../redux/actions/actions";

import { fetchAssetLibraryVideoTypeList } from "../../../../redux/actions/conversionAction"

import {
  fetchBusinessNeed
} from "../../../../redux/actions/setupActions";

import "./AssetDetails.css";

const AssetDetails = (props) => {
  const dispatch = useDispatch();

  props.pageTypeHandler("Asset Details");

  let navigate = useNavigate();

  const isLoading = useSelector((state) => state.assetLibrary.loading);
  const currAssetDetails = useSelector(
    (state) => state.assetLibrary.currentAssetDetails
  );

  const [popupMsg, setpopupMsg] = useState(null);
  const [popupAction, setpopupAction] = useState(null);

  const [show, setShow] = useState(false);
  const handleClose = () => {
    setShow(false);
  };
  const handleShow = () => setShow(true);

  console.log("currAssetDetails", currAssetDetails);

  return (
    <>
      <div className="sub-header">
        <Row>
          <Col className="header-breadcrumb">
            <Breadcrumb>
              <Breadcrumb.Item
                linkAs={Link}
                linkProps={{ to: "/AssetLibrary" }}
                eventKey={"Asset Details"}
                onClick={() => {
                  dispatch(fetchContent(null));
                }}
              >
                <div>Asset Library</div>
              </Breadcrumb.Item>
              <span>
                <Icon.ChevronRight />
              </span>
              { isLoading ? <div className="shine" style={{ width:'160px', height: '40px'}}></div> : <Breadcrumb.Item active>
                {_.some(currAssetDetails, "content_title") &&
                  currAssetDetails[0]?.content_title}
              </Breadcrumb.Item>}
            </Breadcrumb>
          </Col>
        </Row>
      </div>
      <Page title="Asset Details">
        <Container className="folder-asset-details my-2">
          <Row>
            { isLoading ? 
              <Col md={6} sm={12}>
              <div>
                <Row>
                  <Col>
                    <h1 style={{ height: '30px'}} className="title shine"></h1>
                    <div className="sub-title shine" style={{height: '40px'}}></div>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div className="tags-hashtags">
                      <ul className="tags">
                          <li style={{ height: '30px', width: '80px' }} className="contentType shine"></li>
                          <li style={{height: '30px', width:'100px'}} className="contentType shine"></li>      
                      </ul>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div className="text shine" style={{height: '30px', width:'200px'}}></div>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div className="hr-divider"> <hr /> </div>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div className="published-details">
                      <span className="shine" style={{height: '20px', width:'110px'}}></span>
                      <ul>
                        <li className="shine" style={{height: '30px', width:'160px'}}></li>
                        <li className="shine" style={{height: '30px', width:'160px'}}></li>
                      </ul>
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
            :
            <Col className="asset-detail-left" md={6} sm={12}>
              <div>
                <Row>
                  <Col>
                    <h1 className="title pb-lg-0 pb-2">
                      {_.some(currAssetDetails, "solution_types") && (
                        <>
                          {currAssetDetails[0]?.solution_types.length > 1
                            ? currAssetDetails[0]?.solution_types.map((type) => {
                              return <>{type.it_type + ", "} </>;
                            })
                            : currAssetDetails[0]?.solution_types.map((type) => {
                              return <>{type.it_type}</>;
                            })}
                        </>
                      )}
                      {currAssetDetails && (
                        <span className="asset-card-tag">
                          <Bookmark
                            className="asset-card-tag "
                            authToken={props.authToken}
                            bookmarkStatus={
                              _.has(currAssetDetails[0], "bookmark") &&
                              currAssetDetails[0]?.bookmark
                            }
                            id={
                              _.has(currAssetDetails[0], "content_id") &&
                              currAssetDetails[0]?.content_id
                            }
                            type={
                              _.has(currAssetDetails[0], "content_type") &&
                              currAssetDetails[0]?.type
                            }
                          // bookmarkHandler={bookmarkHandler}
                          />
                        </span>
                      )}
                    </h1>
                    <div className="sub-title">
                      {_.some(currAssetDetails, "content_title") &&
                        currAssetDetails[0]?.content_title}
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div className="tags-hashtags">
                      <ul className="tags">
                        {(currAssetDetails && currAssetDetails.length > 0) &&
                          currAssetDetails[0]?.new === 1 && (
                            <li className="new-tag">NEW</li>
                          )}
                        <li className="contentType">
                          {(currAssetDetails && currAssetDetails.length > 0) &&
                            currAssetDetails[0]?.content_type}
                        </li>
                        {(currAssetDetails && currAssetDetails.length > 0) &&
                          currAssetDetails[0]?.cobrand === 1 && (
                            <li>CO-BRANDED</li>
                          )}
                        {_.some(currAssetDetails, "contenttype_status") && (
                          <li>
                            {(currAssetDetails && currAssetDetails.length > 0) &&
                              currAssetDetails[0]?.contenttype_status}
                          </li>
                        )}
                        {/* {_.some(currAssetDetails, "content_name") && (
                        <li>{currAssetDetails[0].content_name}</li>
                      )} */}
                        {/* <li>
                        {assetContentDetails &&
                          assetContentDetails.length > 0 &&
                          assetContentDetails[0].content_name}
                      </li> */}
                        {_.some(currAssetDetails, "internal_hashtags") &&
                          currAssetDetails[0]?.internal_hashtags.length > 0 && (
                            <li className="vr-divider">
                              <div></div>
                            </li>
                          )}
                        {_.some(currAssetDetails, "internal_hashtags") && (
                          <>
                            {currAssetDetails[0]?.internal_hashtags?.map(
                              (htag) => {
                                return <li className="hash-tags">#{htag?.inter_hashtag}</li>;
                              }
                            )}
                          </>
                        )}
                        {/* <li className="hash-tags">#hashtag</li>
                    <li className="hash-tags">#longerhashtag</li>
                    <li className="hash-tags">#morehashtags</li>
                    <li className="hash-tags">#lasthashtag</li>
                    <li className="hash-tags">#hashtag</li>
                    <li className="hash-tags">#longerhashtag</li> */}
                      </ul>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    {_.some(currAssetDetails, "description") &&
                      <div className="text">
                        {currAssetDetails[0]?.description}
                      </div>
                    }
                    {_.some(currAssetDetails, "long_caption") &&
                      <div className="text">
                        {currAssetDetails[0]?.long_caption}
                      </div>
                    }
                    {_.some(currAssetDetails, "short_caption") &&
                      <div className="text">
                        {currAssetDetails[0]?.short_caption}
                      </div>
                    }
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div className="hr-divider">
                      <hr />
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div className="published-details">
                      <span>
                        {_.some(currAssetDetails, "status") &&
                          currAssetDetails[0]?.status}
                      </span>
                      <ul>
                        <li>
                          Created
                          <span>
                            {_.some(currAssetDetails, "activity_date") &&
                              currAssetDetails[0]?.activity_date}
                          </span>
                        </li>
                        {/* <li>
                        Uploaded
                        <span>
                          {_.some(assetContentDetails, "activity_date") ?
                            assetContentDetails[0].updated_date : ""}
                        </span>
                      </li> */}
                        <li>
                          {_.some(currAssetDetails, "status") &&
                            currAssetDetails[0]?.status === "DRAFT"
                            ? ""
                            : "Published"}
                          <span>
                            {_.some(currAssetDetails, "published_date") &&
                              currAssetDetails[0]?.published_date}
                          </span>
                        </li>
                      </ul>
                    </div>
                  </Col>
                  <Col>
                    {_.some(currAssetDetails, "partner_category") &&
                      currAssetDetails[0]?.partner_category.length > 0 && (
                        (currAssetDetails[0]?.status == "Published") && <div className="access-details">
                          <span>Access</span>
                          <ul>
                            {currAssetDetails[0]?.partner_category?.map(
                              (category) => {
                                const partnerName = category.name.charAt(0);
                                partnerName.toUpperCase();
                                return (
                                  <li title={category.name}>
                                    <div className="circle">{partnerName}</div>
                                  </li>
                                );
                              }
                            )}
                          </ul>
                        </div>
                      )}
                    {_.some(currAssetDetails, "users_category") &&
                      currAssetDetails[0]?.users_category.length > 0 && (
                        (currAssetDetails[0]?.status == "Published") && <div className="access-details">
                          <span>Access</span>
                          <ul>
                            {currAssetDetails[0]?.users_category?.map(
                              (category) => {
                                const userName = category.name.charAt(0);
                                userName.toUpperCase();
                                return (
                                  <li title={category.name}>
                                    <div className="circle">{userName}</div>
                                  </li>
                                );
                              }
                            )}
                          </ul>
                        </div>
                      )}
                    {/* <li>
                      <div className="square">Edu.</div>
                    </li> */}
                  </Col>
                </Row>
              </div>
            </Col>
            }
            <Col md={6} sm={12} className="asset-detail-right">
              <div className="folder-asset-img">
                {isLoading ?
                  <div className="details-image shine"></div>
                  : <img
                  src={
                    _.some(currAssetDetails, "thumb_image") &&
                    currAssetDetails[0]?.thumb_image
                  }
                  alt="asset-img"
                />}
                {(props.userType === "P" || props.userType === "S") && <ul className="folder-asset-img-actions my-2 ">
                  {/*<li>
                    <Button variant="light">
                      <span>
                        <Icon.Clock />
                      </span>
                    </Button>
                  </li> */}
                  {currAssetDetails?.length > 0 && <li className="my-4 m-lg-0 mx-auto">
                      <Button variant="light" onClick={() => {
                        dispatch(setEditAsset(true));
                        dispatch(setPublishType(false));
                        //dispatch(fetchCampaignList(props.authToken));
                        dispatch(fetchAssetLibraryHashtagList(props.authToken));
                        dispatch(fetchGreetingList(props.authToken));
                        dispatch(fetchLandingpageUrls(props.authToken));
                        dispatch(fetchShowcaseUrls(props.authToken));
                        dispatch(fetchMicrositeUrls(props.authToken));
                        dispatch(fetchBusinessNeed(props.authToken));
                        dispatch(fetchPersonalizeList(props.authToken));
                        dispatch(fetchAssetLibraryVideoTypeList(props.authToken));
                        dispatch(
                          setSelectedAssetType({
                            id: currAssetDetails[0]?.content_id,
                            article_type: currAssetDetails[0]?.type,
                            type: currAssetDetails[0]?.type,
                          })
                        );
                        if (currAssetDetails[0]?.type !== "Emailer") {
                          dispatch(setGlobalFieldsData([{ Base: currAssetDetails[0] }]));
                          setTimeout(() => {
                            navigate("/new-asset/edit-asset-details");
                          }, 1000);
                        } else {
                          dispatch(setAutoEmailAccessPath(1));
                          dispatch(setGlobalFieldsData([{ Base: currAssetDetails[0] }]));
                          setTimeout(() => {
                            navigate("/new-asset/upload-email-asset");
                          }, 1000);
                        }
                      }}>
                        <span>
                          <Icon.Pencil />
                        </span>
                      </Button>
                    </li>}
                  <li>
                    <Button
                      variant="light"
                      onClick={() => {
                        setpopupAction("Duplicate");
                        setpopupMsg(
                          "Are you sure that you want to duplicate this Asset?"
                        );
                        handleShow();
                      }}
                    >
                      <span>
                        <Icon.Files />
                      </span>
                    </Button>
                  </li>
                  <li>
                    <Button
                      variant="light"
                      onClick={() => {
                        setpopupAction("Delete");
                        setpopupMsg(
                          "Are you sure that you want to delete this Asset?"
                        );
                        handleShow();
                      }}
                    >
                      <span>
                        <Icon.Trash />
                      </span>
                    </Button>
                  </li>
                  <li>
                    {(currAssetDetails?.length > 0 && currAssetDetails[0] &&  currAssetDetails[0].status &&
                     currAssetDetails[0].status === "Draft") ? (
                      <Button
                        variant="light publish"
                        onClick={() => {
                          dispatch(setEditAsset(false));
                          dispatch(setPublishType(true));
                          dispatch(
                            setSelectedAssetType({
                              id: currAssetDetails[0]?.content_id,
                              article_type: currAssetDetails[0]?.type,
                              type: currAssetDetails[0]?.type,
                            })
                          );
                          dispatch(
                            fetchContent(props.authToken, {
                              [currAssetDetails[0]?.type]: [
                                currAssetDetails[0]?.content_id,
                              ],
                            })
                          );
                          setTimeout(() => {
                            navigate("/new-asset/publish-asset");
                          }, 1000);
                        }}
                      >
                        Publish
                      </Button>
                    ) : (currAssetDetails?.length > 0 && currAssetDetails[0]?.status && currAssetDetails[0]?.status == "Scheduled") ? (
                      <Button
                        variant="light unpublish"
                        
                      >
                        {currAssetDetails[0]?.status}
                      </Button>
                    ) : (
                      <Button
                        variant="light unpublish"
                        onClick={() => {
                          // console.log("currAssetDetails[0]",currAssetDetails[0]);
                          // console.log("[currAssetDetails[0].content_id]",[currAssetDetails[0].content_id]);
                          // console.log("[currAssetDetails[0].type]",[currAssetDetails[0].content_name]);
                        setpopupAction("Unpublish");
                        setpopupMsg(
                          "Are you sure that you want to Unpublish this Asset?"
                        );
                        handleShow();
                        }}
                      >
                        Unpublish
                      </Button>
                    )}
                  </li>
                </ul>}
              </div>
            </Col>
          </Row>
        </Container>
      </Page>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body style={{ textAlign: "center" }}>
          <div className="alert-icon">
            <Icon.ExclamationTriangle />
          </div>
          {/* Are you sure that you want to delete this Asset? */}
          {popupMsg}
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="info"
            onClick={() => {
              if (popupAction === "Delete") {
                dispatch(
                  deleteContent(
                    props.authToken,
                    currAssetDetails[0]?.content_id,
                    currAssetDetails[0]?.type
                  )
                );
                setTimeout(() => {
                  navigate("/AssetLibrary");
                }, 2000);
              } else if (popupAction === "Duplicate") {
                dispatch(
                  duplicateContent(
                    props.authToken,
                    currAssetDetails[0]?.content_id,
                    currAssetDetails[0]?.type
                  )
                );
                setTimeout(() => {
                  navigate("/AssetLibrary");
                }, 2000);
              } else if (popupAction === "Unpublish") {
                dispatch(
                  publishContent(
                    props.authToken,
                    currAssetDetails[0]?.content_id,
                    [currAssetDetails[0]?.type],
                    "0"
                  )
                );
                setTimeout(() => {
                  dispatch(
                    fetchContent(props.authToken, {
                      [currAssetDetails[0]?.type]: [
                        currAssetDetails[0]?.content_id,
                      ],
                    })
                  );
                }, 2000);
              }
              handleClose();
            }}
          >
            Yes
          </Button>
          <Button variant="info" onClick={handleClose}>
            No
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default AssetDetails;
