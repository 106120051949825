/* eslint-disable */
import Page from "../../../Page";
import { Row, Col, Breadcrumb } from "react-bootstrap";
import * as Icon from "react-bootstrap-icons";
import "./NewAssetContainer.css";
import { useState, useEffect } from "react";
import _ from "lodash";
import { useNavigate, Outlet } from "react-router-dom";

//Redux-imports
import { useDispatch, useSelector } from "react-redux";
import {
  setBreadcrumbStatus,
  setSelectedAssetType,
  setBaseUploadData,
  setGlobalFieldsData,
  fetchAssetSelectTypeList,
} from "../../../../redux/actions/actions";

const NewAssetContainer = (props) => {
  const dispatch = useDispatch();

  let navigate = useNavigate();

  const breadcrumbStatus = useSelector(
    (state) => state.assetUpload.breadcrumbStatus
  );

  const selectedAssetPublish = useSelector(
    (state) => state.assetUpload.selectedAssetPublish
  );

  const selectedAssetType = useSelector(
    (state) => state.assetUpload.selectedAssetType
  );

  const baseUploadData = useSelector(
    (state) => state.assetUpload.baseUploadData
  );

  const directPublish = useSelector(
    (state) => state.assetUpload.directPublish
  );

  const editAsset = useSelector(
    (state) => state.assetUpload.editAsset
  );


  const [uploadedData, setUploadData] = useState({});

  useEffect(() => {
    dispatch(fetchAssetSelectTypeList(props.authToken));
  }, [dispatch]);

  const getUploadedData = (data) => {
    setUploadData(data);
  };

  //console.log("editAsset",editAsset);
  //console.log("directPublish",directPublish);

  return (
    <>
      <div className="sub-header">
        <Row>
          <Col className="header-breadcrumb">
            <Breadcrumb>
              <Breadcrumb.Item 
                eventKey={"New Asset"}
                onClick={()=>{
                  dispatch(setBreadcrumbStatus(0));
                  dispatch(setSelectedAssetType({}));
                  dispatch(setBaseUploadData({}));
                  dispatch(setGlobalFieldsData({}));
                  navigate("/AssetLibrary");
                }}
              >
                <div>Asset Library</div>
              </Breadcrumb.Item>
              <span>
                <Icon.ChevronRight />
              </span>
              <>
                <Breadcrumb.Item active>
                  { directPublish ? "Publish" : editAsset ? "Edit" : "New Asset" }
                </Breadcrumb.Item>
              </>
            </Breadcrumb>
          </Col>
        </Row>
      </div>
      <Page title="New Asset">
        {(directPublish || editAsset) ? "" : <Row className="sub-breadcrumb mb-5 custom-row">
          <Col className="p-0">
            <ul>
              <li
                className="link active"
                onClick={() => {
                  dispatch(setBreadcrumbStatus(0));
                  navigate("/new-asset");
                }}
              >
                1. Select Asset Type
              </li>
              <li className={breadcrumbStatus > 0 ? "link active" : "link"}>
                --------------------
              </li>
              <li
                className={breadcrumbStatus > 0 ? "link active" : "link"}
                onClick={() => {
                  if(!_.isEmpty(selectedAssetType)) {
                    navigate("/new-asset/upload-asset");
                    dispatch(setBreadcrumbStatus(1));
                  }
                }}
              >
                2. Upload/Create/Import
              </li>
              <li className={breadcrumbStatus > 1 ? "link active" : "link"}>
                --------------------
              </li>
              <li
                className={breadcrumbStatus > 1 ? "link active" : "link"}
              >
                3. Asset(s) Details
              </li>
              <li className={breadcrumbStatus > 3 ? "link active" : "link"}>
                --------------------
              </li>
              {selectedAssetType.type === "Emailer" && <>
              <li
                className={breadcrumbStatus > 3 ? "link active" : "link"}
              >
                4. Upload
              </li>
              <li className={breadcrumbStatus > 4 ? "link active" : "link"}>
                --------------------
              </li>
              </>}
              <li
                className={(breadcrumbStatus > (selectedAssetType.type === "Emailer" ? 4 : 3)) ? "link active" : "link"}
              >
                {selectedAssetType.type === "Emailer" ? "5" : "4"}. Publish Asset
              </li>
            </ul>
          </Col>
        </Row>}
        <Outlet />
      </Page>
    </>
  );
};

export default NewAssetContainer;
