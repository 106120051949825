import React from 'react'
import { Row, Col, Form, Button, Modal, InputGroup, ToggleButton } from "react-bootstrap";
import * as Icon from "react-bootstrap-icons";
import SelectTable from './SelectTable';
import { useState, useRef, useEffect } from "react";
// import vector from '../../assets/setup-icon/Vector.png';
import graph from '../../assets/setup-icon/graph.png';
import _ from "lodash";
import Loading from "../Loading";
import { DebounceInput } from 'react-debounce-input';
import * as types from "../../constants/ActionTypes";
import Toast from "../shared/Toast";
import { useDispatch, useSelector } from "react-redux";
import Pagination from "../shared/ReactPaginate";
import DragShimmer from './shimmer/DragShimmer';
import { fetchGlobalSearchListing, deleteBulkGlobalSearch, bulkPopularityGlobalSearch, addGlobalSearch, searchInput } from "../../redux/actions/setupActions";
// import { useNavigate } from "react-router-dom";

const SetupGlobalSearch = (props) => {
    // let navigate = useNavigate();
    const authToken = props.authToken;
    const dispatch = useDispatch();
  
    let isLoading = useSelector((state) => state.setupScreen.loading);
    let pageNum = useSelector((state) => state.setupScreen.currPage);
    let search_input = useSelector((state) => state.setupScreen.search_input);
    const search_total_data = useSelector((state) => state.setupScreen.global_search_listing?.total_data);
    const search_un_total_data = useSelector((state) =>  state.setupScreen.global_search_u_listing?.total_data);
    const global_search_listing = useSelector((state) => state.setupScreen.global_search_listing);
    const global_search_u_listing = useSelector((state) => state.setupScreen.global_search_u_listing);
    // const hash_list = useSelector((state) => state.setupScreen.hashtag_list);
  
    const [checked, setChecked] = useState(0);
    const [tagName, setTagName] = useState("");
    const [buttonToggle, setButtonToggle] = useState(false);
    const [searchValue, setSearchValue] = useState("");
    // const [sortValue, setSortValue] = useState("ASC");
    const [isSelectAll, setIsSelectAll] = useState(false);
    const [isExpand, setIsExpand] = useState(false);
    const [contentList, setContentList] = useState({});
    const [deleteData, setDeleteData] = useState("");
    const [show, setShow] = useState(false);
    const [showDelete, setShowDelete] = useState(false);
  
    const handleCloseDelete = () => {
          setDeleteData("");
          setShowDelete(false);
    }
  
    const handleShowPopup = (type) => {
        setShowDelete(true);
        setDeleteData(type)
    }
    
    const unselectData = () => {
      setContentList({});
      setChecked(0);
      setIsSelectAll(false);
    }

    const deleteHandleShow = () => {
        dispatch(deleteBulkGlobalSearch(authToken, contentList, deleteData, pageNum));
        handleRemoveSearch();
        handleCloseDelete();
        setContentList({});
        setChecked(0);
    }
    const handlePopularity = () => {
        dispatch(bulkPopularityGlobalSearch(authToken, contentList, "successfull", "mark_as_popular", pageNum));
        handleRemoveSearch();
        setContentList({});
        setChecked(0);
    }
    const handleApprove = () => {
        dispatch(bulkPopularityGlobalSearch(authToken, contentList, "unsuccessfull", "mark_as_approved", pageNum));
        handleRemoveSearch();
        setContentList({});
        setChecked(0);
    }
    const handleRemoveSearch = (e) => {
        setSearchValue("");
        dispatch(searchInput(""));
        dispatch(fetchGlobalSearchListing(authToken, "successfull" , pageNum, ""));
    }
    const handleRemoveSearchUnsuccess = (e) => {
          setSearchValue("");
          dispatch(searchInput(""));
          dispatch(fetchGlobalSearchListing(authToken, "unsuccessfull" , pageNum, ""));
    }
    const handleUnselectedData = (value) => {
        setIsSelectAll(value)
    }
    const saveSearch = (tagTitle) => {
      if (tagTitle !== "") {
        dispatch(addGlobalSearch(authToken, tagTitle))
        handleClose();
      } else {
        Toast("Please add Search text", "E");
      }
    }
    const handleAddName = (e) => {
        setTagName(e.target.value);
    }
    const selectAllData = (selectFlag) => {
      let all_ids
      if (buttonToggle === false)
      {
        setIsSelectAll(!selectFlag);
        all_ids = global_search_listing.successful.map((item) => {
          return item.id;
        })
        setChecked(global_search_listing?.successful?.length);
      } else {
        setIsSelectAll(!selectFlag);
        all_ids = global_search_u_listing.unsuccessful.map((item) => {
          return item.id;
        })
        setChecked(global_search_u_listing?.unsuccessful?.length);
      }
        setContentList({web: all_ids});
    }
    const handleSearch = (e) => {
      setSearchValue(e.target.value);
      dispatch(searchInput(e.target.value));
      dispatch(fetchGlobalSearchListing(authToken, "successfull", 0, e.target.value));
  }
    const handleSearchUnSuccess = (e) => {
      setSearchValue(e.target.value);
      dispatch(searchInput(e.target.value));
      dispatch(fetchGlobalSearchListing(authToken, "unsuccessfull", 0, e.target.value));
    }
    const toggleButton = (status) => {  
      setSearchValue("");
      dispatch(searchInput(""));
      if (status === true) {
        dispatch(fetchGlobalSearchListing(authToken, "unsuccessfull", 0, ""));  
      } else {
        dispatch(fetchGlobalSearchListing(authToken, "successfull", 0, ""));  
      }
      setButtonToggle(status);
      unselectData();
    }
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const handleCheck = (e, id, type) => {
      if (e === null) {
        setChecked(0);
      } else {
        let _updatedContent = _.clone(contentList);
        let count = checked;
        if (e.target.checked) {
          if (_.has(contentList, [type])) {
            if (!_updatedContent[type].includes(id)) {
              _updatedContent[type].push(id);
            }
          } else {
            _updatedContent[type] = [id];
          }
          setChecked(count + 1);
        } else {
          if (_.has(contentList, [type])) {
            _updatedContent[type] = _updatedContent[type].filter(
              (item) => item !== id
            );
            if (_updatedContent[type].length === 0) {
              _updatedContent = _.omit(_updatedContent, [type]);
            }
          }
          setChecked(count - 1);
        }
        setContentList(_updatedContent);
      }
      console.log(contentList);
    };
  
    useEffect(() => {
      // setSearchValue(search_input);
      // dispatch(fetchGlobalSearchListing(authToken, "successfull", 0, search_input));
      dispatch(fetchGlobalSearchListing(authToken, "successfull", 0, ""));
    }, []);
  
    useEffect(() => {    // onDestroy
        return () => {
          dispatch({ type: types.SEARCH_INPUT, payload: "" });
          // dispatch({ type: types.CURRENT_PAGE, payload: 0 });
        }
    }, [])
    
    return (
      <div className='g-search'>
        <div className="mb-3 toggle-two-button">
            <ul className="nav nav-tabs">
                <li onClick={()=>toggleButton(false)}>
                    <h5 className={buttonToggle === false && "active"}>Successful Search</h5>
                </li>
                <li onClick={()=>toggleButton(true)}>
                    <h5 className={buttonToggle === true && "active"}>Unsuccessful Search</h5>
                </li>
            </ul>
        </div>
        <div>
          {checked > 0 && (
            <Row>
              <Col>
                <div className="selectedAssetActions">
                   <ul>
                      <li>
                        <Button variant="light selected">
                          {checked} Selected
                        </Button>
                      </li>
                        <li>
                          <Button
                            variant="light"
                            onClick={() => {  
                              let call_type
                              call_type = buttonToggle !== true ? "successfull":"unsuccessfull"
                              handleShowPopup(call_type);
                            }}
                          >
                            <span>
                              <Icon.Trash />
                            </span>
                            Delete
                          </Button>
                      </li>
                      { buttonToggle !== true ? <li>
                          <Button variant="light" onClick={handlePopularity}>
                            <span>
                              <img src={ graph } alt="graph" />
                            </span>
                            Mark Popular
                          </Button>
                      </li>
                      : <li>
                        <Button variant="light" onClick={handleApprove}>
                          <span>
                            <Icon.FileEarmarkCheck />
                          </span>
                          Approve
                        </Button>
                      </li>}
                    </ul>
                    <ul className="float-right">
                      <li>
                      <Button
                        variant="light selected"
                        onClick={() => {
                          selectAllData(isSelectAll)
                        }}
                      >
                        Select All
                      </Button>
                      </li>
                      <li>
                        <Button
                          variant="light selected"
                          onClick={unselectData}
                        >
                          Unselect
                        </Button>
                      </li>
                    </ul>
                </div>
              </Col>
            </Row>
          )}
          { buttonToggle === false ?
            <div className='row left-balance'>
              <div className={isExpand !== true ? 'col-6 half-table mb-3' : 'col half-table mb-3'}>
                <span className='sort-text free-style-btn mx-1' onClick={() => { setIsExpand(!isExpand) }}>{isExpand && <span className='sort-text blink_me'>&#x276E;&#x276E;</span>}{!isExpand && <span className='sort-text blink_me'>&#x276F;&#x276F;</span>}</span>
                <div>
                  <div>
                    <InputGroup className="mb-3 search-inside">
                      <DebounceInput value={searchValue} style={{width:"75%"}} className="search-inside-input" placeholder="Popular Searches" minLength={3} debounceTimeout={2000} onChange={handleSearch} />
                      <InputGroup.Text id="basic-addon1">
                        {searchValue && searchValue !== "" ? <Icon.X color="#A8A8A8" onClick={handleRemoveSearch} /> : <Icon.Search color="#A8A8A8" onClick={handleSearch}/>}
                      </InputGroup.Text>
                    </InputGroup>
                    {/* <div className='right-buttons'>
                      <Button style={{margin:"0px 20px"}} onClick={handleShow} className='common-setup-button' variant="primary">+ Add New</Button>
                    </div> */}
                  </div>
                  {isLoading && <DragShimmer subPageName={props.subPageName} tableData="gSearch-left"/>}
                  <div>
                    {global_search_listing?.successful?.length > 0 && isLoading === false && global_search_listing.successful.map((listItem,index) => {
                      return (
                        <Col key={index}>
                            <SelectTable tableData="gSearch-left" authToken={props.authToken} listItem={listItem} handleCheck={handleCheck} handleUnselectedData={handleUnselectedData} isSelectAll={isSelectAll} checked={checked} isExpand={isExpand} />
                        </Col>
                      )
                    })}
                    {global_search_listing?.successful?.length === 0 && (
                      <div className="no-data asset">
                        <span>
                          <Icon.Inbox />
                        </span>
                        <br />
                        No Data
                      </div>
                    )}
                  </div>
                </div>
              </div>
              {isExpand !== true && <div className='col-6 half-table'>
                <div>
                  <p className="gSearch-right-title">Top 10 Searches</p>
                  <div>
                    {isLoading && <DragShimmer subPageName={props.subPageName} tableData="gSearch-right"/>}
                    {global_search_listing?.top_10?.length > 0 && isLoading === false &&  global_search_listing.top_10.map((listItem,index) => {
                      return (
                        <Col key={index}>
                            <SelectTable tableData="gSearch-right" index={index+1} authToken={props.authToken} listItem={listItem} handleCheck={handleCheck} checked={checked} />
                        </Col>
                      )
                    })}
                    {global_search_listing?.top_10?.length === 0 && (
                      <div className="no-data asset">
                        <span>
                          <Icon.Inbox />
                        </span>
                        <br />
                        No Data
                      </div>
                    )}
                  </div>
                </div>
              </div>}
              <div className={isExpand !== true ? '' : 'p-0'}>
                {search_total_data > 20 && <Pagination authToken={props.authToken} total_page={ search_total_data } unselectData={unselectData} page={pageNum} currPage={props.subPageName} />}
                {/* <Pagination authToken={props.authToken} total_page={search_total_data} unselectData={unselectData} currPage={props.subPageName} /> */}
              </div>
            </div>
            :
            <div className='row'>
              <div className='col-12 half-table mb-3'>
                <div>
                   <div>
                    <InputGroup className="mb-3 search-inside">
                      <DebounceInput value={searchValue} style={{width:"75%"}} className="search-inside-input" placeholder="Unsuccessful Searches" minLength={3} debounceTimeout={2000} onChange={handleSearchUnSuccess} />
                      <InputGroup.Text id="basic-addon1">
                        {searchValue && searchValue !== "" ? <Icon.X color="#A8A8A8" onClick={handleRemoveSearchUnsuccess} /> : <Icon.Search color="#A8A8A8" onClick={handleSearchUnSuccess}/>}
                      </InputGroup.Text>
                    </InputGroup>
                  </div>
                  {isLoading && <DragShimmer subPageName={props.subPageName} tableData="gSearch"/>}
                  <div>
                    {global_search_u_listing?.unsuccessful?.length > 0 && isLoading === false && global_search_u_listing.unsuccessful.map((listItem,index) => {
                      return (
                        <Col key={index}>
                            <SelectTable tableData="gSearch" authToken={props.authToken} listItem={listItem} handleCheck={handleCheck} handleUnselectedData={handleUnselectedData} isSelectAll={isSelectAll} checked={checked} />
                        </Col>
                      )
                    })}
                    {global_search_u_listing?.unsuccessful?.length === 0 && (
                      <div className="no-data asset">
                        <span>
                          <Icon.Inbox />
                        </span>
                        <br />
                        No Data
                      </div>
                    )}
                  </div>
                </div>
              </div>
              
              {search_un_total_data > 20 && <Pagination authToken={props.authToken} total_page={search_un_total_data} unselectData={unselectData} page={pageNum} currPage="globalSearchUn" />}
              {/* <Pagination authToken={props.authToken} total_page={ search_un_total_data }  unselectData={unselectData} currPage="globalSearchUn" /> */}
            </div>
          }
        </div>

          {/* Add search popup   */}
          <Modal show={show} onHide={handleClose} size="sm" animation="true" centered>
            <Modal.Header closeButton>
              <Modal.Title className='modal-heading-custom'>Add Search</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form.Control onChange={handleAddName} type="text" placeholder="Search Name" />
              <Button className='common-setup-button w-100 mt-3' variant="primary" onClick={()=>{saveSearch(tagName)}}>
                Save Changes
              </Button>
            </Modal.Body>
          </Modal>

          {/* Delete popup  */}
            <Modal className="delete-popup"  show={showDelete} onHide={handleCloseDelete}>
                <Modal.Header closeButton>
                    <Modal.Title>Delete</Modal.Title>
                </Modal.Header>
                <Modal.Body>Are you sure you want to delete ?</Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={deleteHandleShow}>
                      Delete
                    </Button>
                    <Button variant="secondary" onClick={handleCloseDelete}>
                      Cancel
                    </Button>
                </Modal.Footer>
            </Modal>
      </div>
    )
}

export default SetupGlobalSearch;