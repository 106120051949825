import React from "react";
import * as Icon from "react-bootstrap-icons";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Form, Table, Modal, Button } from "react-bootstrap";
import Toast from "../shared/Toast";
import { editHashtagName,editPartner, updatePartnerCategory } from "../../redux/actions/setupActions";
import { useNavigate } from "react-router-dom";


const CommonSelectTable = (props) => {
    let navigate = useNavigate();
    const dispatch = useDispatch();
    const [show, setShow] = useState(false);
    const [showPartnerCatModal, setShowPartnerCatModal] = useState(false);
    const [hashChecked, setHashChecked] = useState(false);
    const [titleBlock, setTitleBlock] = useState("");
    const [tagName, setTagName] = useState("");
    const [radioBtn, setRadioBtn] = useState("");
    const [categoryName, setCategoryName] = useState("");
    const [partnerCatId, setPartnerCatId] = useState("");

    // const { Language } = radioBtn;
    const temp = "web"
    const categoryArray = props.listItem?.categories?.split(",");
    const editHashtag = (u_id, tagTitle) => {
        if (props.listItem.tag !== tagTitle) {
            dispatch(editHashtagName(props.authToken, { id : u_id , internal_hashtag : tagTitle }))
            handleClose();
        } else {
            Toast("Please update the hashtag name", "E");
        }
    } 

    const handleRadioBtn = e => {
        e.persist();
        // console.log(e.target.value);
    
        setRadioBtn(e.target.value);
      };

      const handleUpdatePartnerCategory = (authToken,categoryName,radioBtn,partnerCatId) => {
        if(categoryName != "" && radioBtn != ""){
            console.log("abhishek",radioBtn)
          dispatch(updatePartnerCategory(authToken, categoryName, radioBtn, partnerCatId))
          setCategoryName("");
          setPartnerCatId("");
          setRadioBtn("");
          setShow(false)
      }else{
        Toast("Please fill all the fields", "E");
      }
    }
    
    const handleEditName = (e) => {
        setTagName(e.target.value);
    }
    const handleClose = () => setShow(false);
    const handlePartnerCatClose = () => setShowPartnerCatModal(false);
    
    const handleShow = () => setShow(true);
    
    const redirection = (value) => {
        if (props.tableData === "partner_list") {
            navigate({
                pathname: '/setup/userManagement/editPartner',
                search: `?id=${value.id}`,
              });
            // navigate(`/setup/userManagement/editPartner/${id}`)
        }else if(props.tableData === "partner_category_list"){
            console.log(value)
            setCategoryName(value.partner_category);
            setRadioBtn(value.language_param)
            setPartnerCatId(value.id)
            setShowPartnerCatModal(true);
        }else {
            console.log("else");
        }
    }
    useEffect(() => {
        if (props.isSelectAll === true) {
            setHashChecked(true);
        }  
    },[props.isSelectAll])
    
    useEffect(() => {
        if (props.checked === 0) {
            setHashChecked(false);
        }
    }, [props.checked]);
    
    useEffect(() => {
        // console.log(props.displayContent);
        if (props.tableData === "hashtag") {
            setTitleBlock(props.listItem && props.listItem.tag ? props.listItem.tag.slice(0, 20) : "" )
        } else {
            setTitleBlock(props.listItem?.name ? props.listItem.name.slice(0, 20) :"")
        }
    }, [])

    // console.log(props.listItem,"listItem")
    
    return (
        <div>
            <Form>
                {/* <div className="hashtag-list-card mb-3"> */}
                <div className={"hashtag-list-card mb-3 " + ((props.checked > 0 && hashChecked) || props.isSelectAll === true ? "selected-card" : "")}>
                    <Table>
                    <tbody>
                        <tr>
                        {props.displayContent?.checkbox?.value === true && <td style={{ width:props.displayContent.checkbox.width, textAlign: "center" }}>
                            <Form.Check
                                type="checkbox"
                                className="check-box"
                                checked={hashChecked}
                                value={props.listItem.id}
                                onChange={(e) => {
                                    setHashChecked(!hashChecked);
                                    props.handleCheck(e, props.listItem.id, temp);
                                    if (props.isSelectAll === true) {
                                        props.handleUnselectedData(false);
                                    }
                                }}
                            />
                        </td> }
                        {props.displayContent?.index?.value === true && <td className="hashtag-name" style={{ width:props.displayContent.index.width, textAlign: "center" }}>
                            {props.index}.
                        </td>}
                        {props.displayContent?.title?.value === true && <td title={props.tableData === "partner_category_list" ? props.listItem.partner_category : props.listItem.name} style={{ width: props.displayContent.title.width }} className={props.tableData !== "gSearch" && props.tableData !== "gSearch-left" ? "hashtag-name" : "hashtag-name w-50"}>
                            {props.tableData === "partner_category_list" ? props.listItem.partner_category?.slice(0, 20) : props.listItem.name?.slice(0, 15)}
                            {(props.listItem?.name?.length > 15 || props.listItem?.partner_category?.length > 20)  && <span>...</span>}
                        </td>}
                        {props.displayContent?.email?.value === true && <td title={props.listItem.email} style={{width:props.displayContent.email.width}} className="mail-container">
                            { props.listItem?.email?.slice(0,20) }{props.listItem?.email?.length > 20 && <span>...</span>}
                        </td>}
                        { props.displayContent?.number?.value === true && <td style={{ width: props.displayContent.number.width }}>
                            <span className="date-style num">
                                {props.listItem.contact}
                            </span>
                        </td>}
                        { props.displayContent?.language?.value === true && <td className="mail-container" style={{ width: props.displayContent.language.width }}>
                            <span className="bordering-date date-style">
                                {props.listItem.language_param}
                            </span>
                        </td> }
                        { props.displayContent?.date?.value === true && <td style={{ width: props.displayContent.date.width }}>
                            <span className="bordering-date date-style">
                            {props.tableData === "partner_category_list" ?  props?.listItem?.date?.slice(0,10) :  props?.listItem?.date?.slice(0,10)}
                            </span>
                        </td> }
                        {props.displayContent?.divider?.value === true && <td className="dot">
                            <Icon.CircleFill color="#C4C4C4" />
                        </td>}
                        {props.displayContent?.time?.value === true && <td style={{ width: props.displayContent.time.width }} className="bordering-date time-style">
                            <div className="list-card-tags">
                                {props.tableData === "partner_category_list" ? props?.listItem?.date?.slice(-10,-5) + props.listItem.date.slice(-2):  props.listItem.date?.slice(-10,-5) + props.listItem.date.slice(-2) }
                            </div>
                        </td>}
                        {props.displayContent?.searchText?.value === true && <td style={{ width: props.displayContent.searchText.width }} className="bordering-date time-style">
                            <div className="list-card-tags">{props.listItem.most_search} searches</div>
                        </td>}
                        {props.displayContent?.uid?.value === true && <td style={{width:props.displayContent.uid.width}} className="bordering-date time-style">
                            <div title={props.listItem?.urn_no} className="list-card-tags num">{props.listItem?.urn_no?.slice(0,10) }{props.listItem?.urn_no?.length > 10 && <span>...</span>}</div>
                        </td>}
                        {props.displayContent?.category?.value === true && <td style={{ width: props.displayContent.category.width }} className="bordering-date time-style">
                            { props.listItem?.categories !== null && <div className="list-card-tags">
                                {categoryArray?.length > 0 && <span title={categoryArray[0]} className="pr-category">{categoryArray[0]?.charAt(0)}{categoryArray[0]?.indexOf(" ") > 0 && categoryArray[0].charAt(categoryArray[0].indexOf(" ")+1)}</span>}
                                {categoryArray?.length > 1 && <span title={categoryArray[1]} className="edu-category">{categoryArray[1]?.charAt(0)}{categoryArray[1]?.indexOf(" ") > 0 && categoryArray[1].charAt(categoryArray[1].indexOf(" ")+1)}</span>}
                                {categoryArray?.length > 2 && <span title={props.listItem.categories} className="add-num-category">+ {categoryArray?.length-2}</span>}
                            </div>}
                                </td>}
                        {props.displayContent?.designation?.value === true && <td style={{width:props.displayContent.designation.width}} className="bordering-date time-style">
                            {props.listItem?.designation_name !== null && <div className="list-card-tags num" title={props.listItem?.designation_name}><span className="designation-partner">{props.listItem?.designation_name?.slice(0,4)}{props.listItem?.designation_name?.length > 4 && <span>...</span>}</span></div>}
                        </td>}
                        {props.displayContent?.edit?.value === true && <td style={{ justifyContent: "end", width: props.displayContent.edit.width }} className="d-flex">
                                <div className="card-footer-action list-card" onClick={()=>{redirection(props.listItem)}}>
                                    <span className="edit-icon">
                                        <Icon.PencilSquare style={{ fontSize: "15px" }} color="#64B6E7" />
                                        {props.tableData === "partner_category_list" && "Edit"}    
                                    </span>
                                </div>
                                {props.tableData !== "partner_category_list" && <div className="card-footer-action list-card" style={{marginLeft:"5px"}} onClick={()=>{props.handleDigicard(props.listItem)}}>
                                    <span className="edit-icon" title="Update digicard">
                                        <Icon.PersonVcardFill style={{fontSize:"20px"}} color="#64B6E7" />
                                    </span>
                                </div>}
                            </td>
                        }
                        {props.isExpand === true &&
                            <td style={{ justifyContent: "end" }} className="d-flex">
                                <div className="card-footer-action list-card">
                                    {props.listItem.popular === 1 && <span className="approve-span">
                                        Popular
                                    </span>}
                                </div>
                            </td>
                        }
                        {props.displayContent?.u_search?.value === true &&
                            <td style={{ justifyContent: "end",style: props.displayContent.u_search.width }} className="d-flex">
                                <div className="card-footer-action list-card">
                                    {props.listItem.approved === 1 && <span className="approve-span">
                                        Approved
                                    </span>}
                                </div>
                            </td>}
                        </tr>
                    </tbody>
                    </Table>
                </div>
            </Form>
            
            {/* Edit popup  */}
            <Modal show={show} onHide={handleClose} size="sm" animation="true" centered>
                <Modal.Header closeButton>
                    <Modal.Title className='modal-heading-custom'>Edit hashtag name</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Control defaultValue={props.listItem.tag} onChange={handleEditName} type="text" placeholder="Hashtag Name" />
                    <Button className='common-setup-button w-100 mt-3' variant="primary" onClick={() => { editHashtag(props.listItem.id,tagName) }}>
                        Save Changes
                    </Button>
                </Modal.Body>
            </Modal>
            {/* reactivate partner category popup */}
          <Modal className='partner-category delete-popup' show={showPartnerCatModal} onHide={handlePartnerCatClose}>
            <Modal.Header closeButton>
              <Modal.Title>Create Partner Category</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form>
              <Form.Label>Language : </Form.Label>
              <Form.Check
                inline
                // label="All"
                // type="radio"
                // onChange={(e) => {console.log(e,"events")}}

                value="All"
                type="radio"
                aria-label="radio 1"
                id="inline-radio-1"
                name="group1"
                label="All"
                onChange={handleRadioBtn}
                checked={radioBtn === "All"}
              />
              <Form.Check
                inline
                value="Language Specific"
                type="radio"
                aria-label="radio 2"
                name="group1"
                label="Language Specific"
                onChange={handleRadioBtn}
                checked={radioBtn === "Language Specific"}
                // label="Language Specific"
                // name="group1"
                // type="radio"
                id="inline-radio-2"
              />
              <Form.Group className="my-3">
                <Form.Control
                  type="email"
                  placeholder="Category Name"
                  value={categoryName}
                  onChange={(e) => setCategoryName(e.target.value)}
                />
              </Form.Group>
              </Form>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handlePartnerCatClose}>
                Close
              </Button>
              <Button variant="primary" onClick={()=>handleUpdatePartnerCategory(props.authToken,categoryName,radioBtn,partnerCatId)}>
                Submit
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
    );
};

export default CommonSelectTable;
