
/* eslint-disable */
import { useRef, useState, useEffect } from "react";
import Loading from "../../../Loading";
import { Row, Col, Form, Card, Button } from "react-bootstrap";
import * as Icon from "react-bootstrap-icons";
import ReactMultiSelectCheckboxes from "react-multiselect-checkboxes";
import _ from "lodash";
import { useNavigate } from "react-router-dom";
import Toast from "../../../shared/Toast";
// import DtPicker from "react-calendar-datetime-picker";
// import "react-calendar-datetime-picker/dist/index.css"
import Multiselect from "multiselect-react-dropdown";
import micrositeVideo from "../../../../assets/asset-upload/video.png";
import micrositeBroucher from "../../../../assets/asset-upload/brochures.png";

import DatePicker from 'react-datepicker';
//import moment from 'moment';

import 'react-datepicker/dist/react-datepicker.css';
import "./NewAssetContainer.css"
//Redux-imports
import { useDispatch, useSelector } from "react-redux";
import {
  fetchAssetLibraryCategoryList,
  fetchCTAList,
  fetchKeywordsList,
  fetchPartnersAccessList,
  fetchUsersAccessList,
  fetchContent,
  publishAsset,
} from "../../../../redux/actions/actions";

import deviceImg from "../../../../assets/asset-upload/device-img.png";
import "react-dual-listbox/lib/react-dual-listbox.css";
import moment from 'moment';
import * as types from '../../../../constants/ActionTypes';

const PublishAsset = (props) => {
  const authToken = props.authToken;

  const [social, setSocial] = useState(false);
  const [sip, setSip] = useState(false);

  const [partners, setPartners] = useState(true);
  const [users, setUsers] = useState(false);
  const [selectedPartners, setSelectedPartners] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);

  const [cobrandingOption, setCobrandingOption] = useState(false);
  const [startFrame, setStartFrame] = useState("");
  const [endFrame, setEndFrame] = useState("");
  const [pushNotificationOption, setPushNotificationOption] = useState(false);
  const [emailNotificationOption, setEmailNotificationOption] = useState(false);
  const company_logo = useSelector((state) => state.headerTopMenus.companyLogo);

  const [whatsApp, setWhatsApp] = useState(false);

  const [mobileApp, setMobileApp] = useState(false);
  const [microsite, setMicrosite] = useState(false);
  const [metaDescYes, setMetaDescYes] = useState(true);
  const [metaDescNo, setMetaDescNo] = useState(false);
  const [editKeywords, setEditKeywords] = useState(false);
  const [directCampaignType, setDirectCampaignType] = useState(false);
  const [indirectCampaignType, setIndirectCampaignType] = useState(true);
  const [platform, setPlatform] = useState([]);
  const [contentType, setContentType] = useState([]);
  const [platformFB, setPlatformFB] = useState(false);
  const [platformTW, setPlatformTW] = useState(false);
  const [platformLN, setPlatformLN] = useState(false);
  const [videoUrl, setVideoUrl] = useState(false);

  const [date, setDate] = useState(null);
  const [contentDate, setContentDate] = useState(null);
  const [expiredDate, setExpiredDate] = useState(null);
  const [defaultKeywords, setDefaultKeywords] = useState([
    "Keyword-1",
    "Keyword-2",
    "Keyword-3",
  ]);

  const [newKeywords, setNewKeywords] = useState([]);
  const [selectedKeywords, setSelectedKeywords] = useState([]);
  const [selectedCTA, setSelectedCTA] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [assetTypeImgUrl, setAssetTypeImgUrl] = useState(null);

  const [selectedAssetPublish, setSelectedAssetPublish] = useState({});
  const [assetChecked, setAssetChecked] = useState(false);

  let d = new Date();
  let dd = {
    year: moment(d).format('YYYY'),
    month: moment(d).format('MM'),
    day: moment(d).format('DD'),
    hour: moment(d).format('HH'),
    minute: moment(d).format('mm')
  }
  const [minDate, setMinDate] = useState(dd);
  const [maxDate, setMaxDate] = useState(dd);

  const [publishData, setPublishData] = useState([]);
  const [activeAsset, setActiveAsset] = useState(0);

  // Add/Remove checked item from list
  const handleCheck = (e, id, type) => {
    console.log("id", id);
    let _updatedContent = _.clone(selectedAssetPublish);
    if (e.target.checked) {
      if (_.isEmpty(selectedAssetPublish)) {
        _updatedContent["data"] = [
          { Base: { content_id: id, content_name: type } },
        ];
      } else {
        _updatedContent["data"] = [
          ..._updatedContent.data,
          { Base: { content_id: id, content_name: type } },
        ];
      }
    } else {
      _updatedContent["data"] = _updatedContent["data"].filter((item) => {
        return item.Base.content_id !== id;
      });
    }
    setSelectedAssetPublish(_updatedContent);
  };

  const msTitleRef = useRef();
  const addKeywordRef = useRef();

  const isLoading = useSelector((state) => state.assetUpload.loading);

  const selectedAssetType = useSelector(
    (state) => state.assetUpload.selectedAssetType
  );

  const assetSelectTypeList = useSelector(
    (state) => state.assetUpload.selectTypeList
  );

  let assetContentList = useSelector(
    (state) => state.assetLibrary.currentAssetDetails
  );

  console.log("assetContentList", assetContentList);

  const uploadedData = useSelector(
    (state) => state.assetUpload.saveGlobalFieldsData
  );

  console.log("uploadedData", uploadedData);

  const keywordsList = useSelector((state) => state.assetUpload.keywordsList);

  const ctaList = useSelector((state) => state.assetUpload.ctaList);

  const categoryList = useSelector((state) => state.assetLibrary.categoryList);
   const directPublish = useSelector(
    (state) => state.assetUpload.directPublish
  );

  const imgUrl = _.find(assetSelectTypeList, (obj) => {
    if (obj.type === selectedAssetType.type) {
      return obj.article_image;
    } else if (selectedAssetType.type === "Document" && obj.type === "Brochure") {
      return obj.article_image;
    } else if (selectedAssetType.type === "Banner" && obj.type === "Banners") {
      return obj.article_image;
    } else if (selectedAssetType.type === "Social" && obj.type === "Social Post") {
      return obj.article_image;
    }
  });

  const dispatch = useDispatch();

  let navigate = useNavigate();

  const partnersList = useSelector((state) => state.assetUpload.partnersList);

  const dummyUserList = useSelector((state) => state.assetUpload.usersList);

  let user, partner;
  if (Object.keys(dummyUserList).length > 0) {
    user = _.flatten(
      Object.values(dummyUserList).map((f, index) => {
        return f.map((ff) => {
          return {
            id: ff.client_id,
            name: ff.full_name,
            cat: Object.keys(dummyUserList)[index],
          };
        });
      })
    );
  }

  if (partnersList.length > 0) {
    partner = partnersList.map(
      ({ id: value, partner_category: label }) => ({
        value,
        label,
      })
    );
  }

  const onSelect = (selectedList, selectedItem) => {
    setSelectedUsers(selectedList);
  };

  const onRemove = (selectedList, removedItem) => {
    setSelectedUsers(selectedList);
  };

  const selectPartnerHandler = (value, event) => {
    if (event.action === "select-option" && event.option.value === "*") {
      setSelectedPartners(partner);
    } else if (
      event.action === "deselect-option" &&
      event.option.value === "*"
    ) {
      setSelectedPartners([]);
    } else if (event.action === "deselect-option") {
      value.filter((o) => o.value !== "*");
      setSelectedPartners(value.map(({ value }) => value));
    } else if (value.length === partner.length - 1) {
      setSelectedPartners(partner);
    } else {
      setSelectedPartners(value.map(({ value }) => value));
    }
  };

  const cobrandingOptionHandler = () => {
    setCobrandingOption(!cobrandingOption);
    if (!cobrandingOption) {
      setStartFrame("");
      setEndFrame("");
    }
  };

  const pushNotificationOptionHandler = () => {
    setPushNotificationOption(!pushNotificationOption);
  };

  const emailNotificationOptionHandler = () => {
    setEmailNotificationOption(!emailNotificationOption);
  };

  props.pageTypeHandler("Global Fields");

  const platformHandler = (e, type) => {
    let _updatedContent = _.clone(platform);
    if (e.target.checked) {
      if (type === "fb" || type === "brochure" || type === "video") {
        _updatedContent.push(1);
      } else if (type === "tw" || type === "microsite") {
        _updatedContent.push(2);
      } else if (type === "in") {
        _updatedContent.push(3);
      }
    } else {
      if (type === "fb" || type === "brochure" || type === "video") {
        _updatedContent = _updatedContent.filter((item) => item !== 1);
      } else if (type === "tw" || type === "microsite") {
        _updatedContent = _updatedContent.filter((item) => item !== 2);
      } else if (type === "in") {
        _updatedContent = _updatedContent.filter((item) => item !== 3);
      }
    }
    setPlatform(_updatedContent);
  };

  const contentTypeHandler = (e, type) => {
    let _updatedContent = _.clone(contentType);
    if (e.target.checked) {
      if (type === "wa" || type === "vc" || type === "br") {
        _updatedContent.push(1);
      }
      if (type === "sc" || type === "ms") {
        _updatedContent.push(2);
        //setPlatform([1]);
      }
      if (type === "sip") {
        _updatedContent.push(3);
      }
      if (type === "s_ms") {
         _updatedContent.push(4);
      }
    } else {
      if (type === "wa" || type === "vc" || type === "br") {
        _updatedContent = _updatedContent.filter((item) => item !== 1);
      }
      if (type === "sc" || type === "ms") {
        _updatedContent = _updatedContent.filter((item) => item !== 2);
        setPlatform([]);
      }
      if (type === "sip") {
        _updatedContent = _updatedContent.filter((item) => item !== 3);
      }
      if (type === "s_ms") {
         _updatedContent = _updatedContent.filter((item) => item !== 4);
      }
    }
    setContentType(_updatedContent);
  };

  console.log("selectedAssetPublish", selectedAssetPublish);
  //console.log("directPublish",directPublish);

  useEffect(() => {

    if (assetContentList) {
      let PublishArray = assetContentList.map((b, index) => {
        return {
          Base: { content_id: b.content_id, content_name: b.content_type }
        }

      });

      setPublishData(PublishArray)
      // console.log("abhishek",abhishek);
    }
  }, [assetContentList]);

  // console.log("publishData", publishData);


  const submitHandler = async (e, path) => {
    e.preventDefault();

    let finalPartnersList = [];
    let finalUsersList = selectedUsers.map((u) => u.id);
    let assetPublish = { data: [{ Base: {} }] }
    // let newSelectedAssetPublish = _.clone(selectedAssetPublish);
    let newSelectedAssetPublish = publishData;


    if (selectedPartners) {
      if ((selectedPartners.some(p => p.label))) {
        // console.log("obj found");
        finalPartnersList = selectedPartners.map((c) => {
          return c.value;
        })
      } else {
        finalPartnersList = selectedPartners;
      }
      // console.log("obj not found");
    }

    // console.log("finalPartnersList",finalPartnersList);
    // console.log("selectedPartners",selectedPartners);

    // if (_.isEmpty(assetContentList) || !(assetContentList.length > 0)) {

    //   Toast("Asset not selected.", "E");

    // } else {



    // }

    if ((!_.isEmpty(assetContentList)) &&
      (assetContentList?.length > 0)) {

      if (selectedAssetType.type === "Social Post" || selectedAssetType.type === "Image" ||
        selectedAssetType.type === "Creative" ||
        selectedAssetType.type === "Social") {
        newSelectedAssetPublish.map((d,index) => {
          if(activeAsset == index){
            return (d.Base = {
              ...d.Base,
              selected_assets: contentType,
              // whatsapp: 0,
              // social: 0,
              platform: contentType.includes(2) ? platform : [],
              category: partners ? finalPartnersList : finalUsersList,
              option: partners ? 1 : 2,
              schedule: date ? 1 : 0,
              schedule_date: date
                ? moment(date).format('YYYY-MM-DD HH:mm:ss')
                : 0, //.add(-1, 'M')
              cobranding: cobrandingOption ? 1 : 0,
              push_notification: pushNotificationOption ? 1 : 0,
              email_notification: emailNotificationOption ? 1 : 0,
              start_date: contentDate !== null ? moment(contentDate).format('YYYY-MM-DD HH:mm:ss') : "", //.add(-1, 'M')
              expired_date: expiredDate !== null ? moment(expiredDate).format('YYYY-MM-DD HH:mm:ss') : "" //.add(-1, 'M')
            });
          }
        });
      } else if (selectedAssetType.type === "Emailer") {
          newSelectedAssetPublish.map((d,index) => {
            if (activeAsset == index) {
              if (indirectCampaignType === true) {
                  return (d.Base = {
                  ...d.Base,
                  content_name: "Emailer",
                  content_id: directPublish != false ? assetContentList[index]?.content_id : (uploadedData?.length > 0 ? uploadedData[0] : selectedAssetType.id),
                  article_id: directPublish != false ? assetContentList[index]?.content_id : (uploadedData?.length > 0 ? uploadedData[0] : selectedAssetType.id),
                  selected_assets: contentType,
                  category: partners ? finalPartnersList : finalUsersList,
                  option: partners ? 1 : 2,
                  camp_type: 1, 
                  schedule: date ? 1 : 0,
                  schedule_date: date
                    ? moment(date).format('YYYY-MM-DD HH:mm:ss')
                    : 0, //.add(-1, 'M')
                  push_notification: pushNotificationOption ? 1 : 0,
                  start_date: contentDate !== null ? moment(contentDate).format('YYYY-MM-DD HH:mm:ss') : "", //.add(-1, 'M')
                  expired_date: expiredDate !== null ? moment(expiredDate).format('YYYY-MM-DD HH:mm:ss') : "", //.add(-1, 'M')
                  button: path
                });
              } else {
                  return (d.Base = {
                    ...d.Base,
                    content_id: directPublish != false ? assetContentList[index]?.content_id : (uploadedData?.length > 0 ? uploadedData[0] : selectedAssetType.id),
                    content_name: "Emailer",
                    article_id: directPublish != false ? assetContentList[index]?.content_id : (uploadedData?.length > 0 ? uploadedData[0] : selectedAssetType.id),
                    selected_assets: contentType,
                    category: [],
                    option: 1,
                    camp_type: 2,
                    schedule: date ? 1 : 0,
                    schedule_date: date
                      ? moment(date).format('YYYY-MM-DD HH:mm:ss')
                      : 0, //.add(-1, 'M')
                    push_notification: pushNotificationOption ? 1 : 0,
                    start_date: "", //.add(-1, 'M')
                    expired_date: "", //.add(-1, 'M')
                    button: path
                  });
                }
              
            }
          });
          } else if (
            selectedAssetType.type === "Brochure" ||
            (assetContentList[0]?.type == "Microsite" && assetContentList[0]?.c_type == "brochure") ||
            selectedAssetType.type === "Infographics" ||
            selectedAssetType.type === "Infographic" ||
            selectedAssetType.type === "Document"
          ) {
          newSelectedAssetPublish.map((d,index) => {
            if(activeAsset == index){
              return (d.Base = {
                ...d.Base,
                content_name: selectedAssetType.article_type,
                article_id: selectedAssetType.id,
                selected_assets: contentType,
                // mobile_app: 0,
                // microsite: 0,
                //seo_page_title: microsite ? msTitleRef.current.value : "",
                //seo_meta_description: microsite && metaDescYes ? 1 : 0,
                //keyword: newKeywords,
                CTA: microsite ? parseInt(selectedCTA) : 0,
                category: partners ? finalPartnersList : finalUsersList,
                option: partners ? 1 : 2,
                schedule: date ? 1 : 0,
                schedule_date: date
                  ? moment(date).format('YYYY-MM-DD HH:mm:ss')
                  : 0, //.add(-1, 'M')
                cobranding: cobrandingOption ? 1 : 0,
                push_notification: pushNotificationOption ? 1 : 0,
                email_notification: emailNotificationOption ? 1 : 0,
                start_date: contentDate !== null ? moment(contentDate).format('YYYY-MM-DD HH:mm:ss') : "", //.add(-1, 'M')
                expired_date: expiredDate !== null ? moment(expiredDate).format('YYYY-MM-DD HH:mm:ss') : "" //.add(-1, 'M')
              });
              }
            });
          } else if (selectedAssetType.type === "Video" || (assetContentList[0]?.type == "Microsite" && assetContentList[0]?.c_type == "video")) {
            newSelectedAssetPublish.map((d,index) => {
              if(activeAsset == index){
              return (d.Base = {
                ...d.Base,
                content_name: selectedAssetType.article_type,
                article_id: selectedAssetType.id,
                selected_assets: contentType,
                // mobile_app: 0,
                // microsite: 0,
                //platform: platform,
                //seo_page_title: microsite ? msTitleRef.current.value : "",
                //seo_meta_description: microsite && metaDescYes ? 1 : 0,
                //keyword: newKeywords,
                CTA: microsite ? parseInt(selectedCTA) : 0,
                category: partners ? finalPartnersList : finalUsersList,
                option: partners ? 1 : 2,
                schedule: date ? 1 : 0,
                schedule_date: date
                  ? moment(date).format('YYYY-MM-DD HH:mm:ss')
                  : 0, //.add(-1, 'M')
                cobranding: cobrandingOption ? 1 : 0,
                cobrand_startframe: cobrandingOption && startFrame === 'start' ? 1 : 0,
                cobrand_endframe: cobrandingOption && startFrame === 'end' ? 1 : 0,
                push_notification: pushNotificationOption ? 1 : 0,
                email_notification: emailNotificationOption ? 1 : 0,
                start_date: contentDate !== null ? moment(contentDate).format('YYYY-MM-DD HH:mm:ss') : "", //.add(-1, 'M')
                expired_date: expiredDate !== null ? moment(expiredDate).format('YYYY-MM-DD HH:mm:ss') : "" //.add(-1, 'M')
              });
            }
            });
          } else if (selectedAssetType.type === "Banners" ||
            selectedAssetType.type === "Banner") {
            newSelectedAssetPublish.map((d,index) => {
              if(activeAsset == index){
                return (d.Base = {
                  ...d.Base,
                  content_name: selectedAssetType.article_type,
                  article_id: selectedAssetType.id,
                  category: partners ? finalPartnersList : finalUsersList,
                  option: partners ? 1 : 2,
                  // schedule: date ? 1 : 0,
                  // schedule_date: date
                  //   ? moment(date).add(-1, 'M').format('YYYY-MM-DD HH:mm:ss')
                  //   : 0,
                  // push_notification: pushNotificationOption ? 1 : 0,
                });
              }

            });
          }


          if (activeAsset !== assetContentList.length - 1) {
            setActiveAsset(activeAsset + 1);
            setContentType([]);
            setCobrandingOption(false);
            setPushNotificationOption(false);
            setEmailNotificationOption(false);
            // setSelectedPartners([]);
            // setSelectedUsers([]);
            setContentDate(new Date());
            setExpiredDate(null);
            setDate(null);
            setPlatformFB(false);
            setPlatformLN(false);
            setPlatformTW(false);
            setMobileApp(false);
            setMicrosite(false);
            setWhatsApp(false);
            setSip(false);
            setSocial(false);
            window.scroll(0, 0);

            // setTimeout(() => {
            //   console.log("in next wala block")
            //   // if (Object.keys(activeAssetData.Base.thumbImg).length <= 0) {
            //   //   activeAssetData.Base.thumbImg = {};
            //   // }
            // }, 2000)
            // console.log(newData ,"i am here in next block")
            // console.log(newSelectedAssetPublish ,"i am here in next block payload")
            // setArrayPayload(newSelectedAssetPublish)
          } else {
            if ((selectedAssetType.type === "Social Post" || selectedAssetType.type === "Image" ||
              selectedAssetType.type === "Creative" ||
              selectedAssetType.type === "Social") &&
              (!(contentType.length > 0))) {
              Toast("Please enter mandatory fields", "E");
            } else if ((selectedAssetType.type === "Brochure" ||
              selectedAssetType.type === "Infographics" ||
              selectedAssetType.type === "Infographic" ||
              selectedAssetType.type === "Video" ||
              selectedAssetType.type === "Document") && (!(contentType.length > 0))) {
              Toast("Either Mobile App or Microsite must be selected.", "E");
            } else {
              dispatch(publishAsset(authToken, { data: newSelectedAssetPublish }));
              setContentType([]);
              setCobrandingOption(false);
              setPushNotificationOption(false);
              setEmailNotificationOption(false);
              setSelectedPartners([]);
              setSelectedUsers([]);
              setContentDate(new Date());
              setExpiredDate(null);
              setDate(null);
              setPlatformFB(false);
              setPlatformLN(false);
              setPlatformTW(false);
              setMobileApp(false);
              setMicrosite(false);
              setWhatsApp(false);
              setSip(false);
              setSocial(false);
              setPublishData([]);
              setTimeout(() => {
                navigate("/AssetLibrary");
              }, 4000);
              // console.log("newSelectedAssetPublish", newSelectedAssetPublish);
            }
          }

          // if ((selectedAssetType.type === "Social Post" || selectedAssetType.type === "Image" ||
          //   selectedAssetType.type === "Creative" ||
          //   selectedAssetType.type === "Social") &&
          //   (!(contentType.length > 0))) {
          //   Toast("Please enter mandatory fields", "E");
          // } else if ((selectedAssetType.type === "Brochure" ||
          //   selectedAssetType.type === "Infographics" ||
          //   selectedAssetType.type === "Infographic" ||
          //   selectedAssetType.type === "Video" ||
          //   selectedAssetType.type === "Document") && (!(contentType.length > 0))) {
          //   Toast("Either Mobile App or Microsite must be selected.", "E");
          // } else {
          //   dispatch(publishAsset(authToken, newSelectedAssetPublish));
          //   setTimeout(() => {
          //     navigate("/AssetLibrary");
          //   }, 4000);
          //   // console.log("newSelectedAssetPublish", newSelectedAssetPublish);
          // }

      }

  };

  // console.log(selectedPartners, "selectedPartner");


  const addKeywordsHandler = () => {
    let newkeys = _.xorBy(defaultKeywords, selectedKeywords);
    setDefaultKeywords(newkeys);
    setNewKeywords(_.concat(newKeywords, selectedKeywords));
    setSelectedKeywords([]);
  };

  const removeKeywordsHandler = () => {
    let newkeys = _.xorBy(newKeywords, selectedKeywords);
    setNewKeywords(newkeys);
    setDefaultKeywords(_.concat(defaultKeywords, selectedKeywords));
    setSelectedKeywords([]);
  };

  const addNewKeywordHandler = () => {
    let newKey = addKeywordRef.current.value;
    setNewKeywords(_.concat(newKeywords, [newKey]));
    addKeywordRef.current.value = "";
  };

  useEffect(() => {
    // setPartners(false);
    // setUsers(false);
    // setCobrandingOption(false);
    // setPushNotificationOption(false);
    // setSelectedPartners([]);
    // setSelectedUsers([]);
    // setContentDate(null);
    // setExpiredDate(null);
    // setDate(null);
    dispatch(fetchPartnersAccessList(authToken));
    dispatch(fetchUsersAccessList(authToken));
    //dispatch(fetchKeywordsList(authToken));
    dispatch(fetchCTAList(authToken));
    // dispatch(fetchAssetLibraryCategoryList(authToken));
    if (directPublish === false) {
      dispatch({ type: types.FETCH_ASSET_CONTENT_DETAILS, payload: [] });
      dispatch(fetchContent(authToken, { [selectedAssetType.type]: uploadedData }));
    }
    if (_.isEmpty(selectedAssetType)) {
      Toast("Unable to publish, please retry..", "E");
      navigate("/AssetLibrary");
    }

    if (directPublish === false && assetContentList && assetContentList.length > 0 && assetContentList[0].status === "Draft" && assetContentList[0].type === "Video") {
      if (assetContentList[0]?.video_mp4 && !(assetContentList[0]?.video_mp4.includes(".mp4"))) {
        setVideoUrl(true);
      } else {
        setVideoUrl(false);
      }
    }

    if (directPublish === true && assetContentList && assetContentList?.length > 0 && assetContentList[0]?.status !== "Draft") {
      if (assetContentList[0]?.partner_category?.length > 0) {
        let seletectPCategory = [];
        assetContentList[0]?.partner_category.map((c) => {
          partnersList?.map(
            ({ id: value, partner_category: label }) => {
              if (c.id == value) {
                seletectPCategory.push({
                  value,
                  label,
                })
              }
            }
          );
        });
        setPartners(true);
        setUsers(false);
        setSelectedUsers([]);

        setSelectedPartners(seletectPCategory);
        // console.log("seletectPCategory", seletectPCategory);
      } else {
        setSelectedPartners([]);
      }

      if (assetContentList[0]?.users_category && assetContentList[0]?.users_category.length > 0) {
        let selectedUCategory = [];
        assetContentList[0]?.users_category.map(item => {
          if (user) {
            user.map(u => {
              if (item.id == u.id) {
                selectedUCategory.push(u);
              }
            })
          }
        })

        setPartners(false);
        setUsers(true);
        setSelectedPartners([]);
        setSelectedUsers(selectedUCategory);
      } else {
        setSelectedUsers([]);
      }

      if (assetContentList[0]?.type == "Microsite" || assetContentList[0]?.type === "Video") {
        if ((assetContentList[0]?.video_mp4) && !(assetContentList[0]?.video_mp4.includes(".mp4"))) {
          setVideoUrl(true);
        } else {
          setVideoUrl(false);
        }
      }

      /* if (assetContentList && assetContentList[0]?.platform) {
        if ((assetContentList[0].platform.includes(1))) {
          setPlatformFB(true);
        } else {
          setPlatformFB(false);
        }

        if ((assetContentList[0].platform.includes(2))) {
          setPlatformTW(true);
        } else {
          setPlatformTW(false);
        }

        if ((assetContentList[0].platform.includes(3))) {
          setPlatformLN(true);
        } else {
          setPlatformLN(false);
        }

        setPlatform(assetContentList && assetContentList[0]?.platform);
      } */

      if (assetContentList && assetContentList[0]?.type === 'Social' || assetContentList[0]?.type == 'Image' || assetContentList[0]?.type === 'Social Post') {
        if (assetContentList && assetContentList[0]?.selected_asset.includes(1)) {
          setWhatsApp(true);
          setContentType(contentType => [...contentType, 1]);
        }

        if (assetContentList && assetContentList[0]?.selected_asset.includes(2)) {
          setSocial(true);
          setContentType(contentType => [...contentType, 2]);
          if (assetContentList && assetContentList[0]?.platform && assetContentList[0].platform.length > 0) {
            if ((assetContentList[0].platform.includes(1))) {
              setPlatformFB(true);
            } else {
              setPlatformFB(false);
            }

            if ((assetContentList[0].platform.includes(2))) {
              setPlatformTW(true);
            } else {
              setPlatformTW(false);
            }

            if ((assetContentList[0].platform.includes(3))) {
              setPlatformLN(true);
            } else {
              setPlatformLN(false);
            }

            let newPlatform = assetContentList[0].platform.map(p => p);
            setPlatform(newPlatform);
          }

        }
      }

      if (assetContentList && assetContentList[0]?.cobrand === 1) {
        setCobrandingOption(true);
      }

      if (assetContentList && assetContentList[0]?.push_notification === 1) {
        setPushNotificationOption(true);
      }

      if (assetContentList && assetContentList[0]?.email_notification === 1) {
        setEmailNotificationOption(true);
      }

      if (assetContentList && assetContentList[0]?.type == 'Social' || assetContentList[0]?.type == 'Image' ||
        assetContentList[0]?.type == 'Social Post' ||
        assetContentList[0]?.type == 'Brochure' ||
        assetContentList[0]?.type == 'Video' ||
        assetContentList[0]?.type == "Microsite" ||
        assetContentList[0]?.type === "Infographics" ||
        assetContentList[0]?.type === "Infographic") {

        if (assetContentList[0]?.scheduled_date && assetContentList[0]?.scheduled_date !== "0000-00-00 00:00:00" && assetContentList[0]?.scheduled_date !== "") {
          /* let y, m, d, h, mi;
          y = moment(assetContentList[0].scheduled_date).format('YYYY');
          m = moment(assetContentList[0].scheduled_date).format('MM');
          d = moment(assetContentList[0].scheduled_date).format('DD');
          if (d[0] == 0) {
            d = d.substring(1);
          }
          h = moment(assetContentList[0].scheduled_date).format('HH');
          mi = moment(assetContentList[0].scheduled_date).format('mm');
          if (mi[0] == 0) {
            mi = mi.substring(1);
          }
          setDate({
            year: y,
            month: m,
            day: d,
            hour: h,
            minute: mi
          }); */
          //setDate(moment(assetContentList[0]?.scheduled_date).format('ddd MM DD YYYY HH:mm:ss a') + ' GMT+0530 (India Standard Time)');
          setDate(new Date(moment(assetContentList[0]?.scheduled_date).format('YYYY-MM-DD hh:mm A')));
        }

        if (assetContentList && assetContentList[0]?.start_date && assetContentList[0]?.expired_date) {
          if (assetContentList[0].start_date !== "0000-00-00 00:00:00" && assetContentList[0].start_date !== "" &&
            assetContentList[0].expired_date !== "0000-00-00 00:00:00" && assetContentList[0].expired_date !== "") {
            /* let yy, dd, mm, hh, mii, yyy, mmm, ddd, hhh, miii;
            yy = moment(assetContentList[0].start_date).format('YYYY');
            mm = moment(assetContentList[0].start_date).format('MM');
            dd = moment(assetContentList[0].start_date).format('DD');
            if (dd[0] == 0) {
              dd = dd.substring(1);
            }
            hh = moment(assetContentList[0].start_date).format('HH');
            if (hh[0] == 0) {
              hh = hh.substring(1);
            }
            mii = moment(assetContentList[0].start_date).format('mm');
            if (mii[0] == 0) {
              mii = mii.substring(1);
            }

            setContentDate({
              year: yy,
              month: mm,
              day: dd,
              hour: hh,
              minute: mii
            }); */
            setContentDate(new Date(moment(assetContentList[0]?.start_date).format('YYYY-MM-DD hh:mm A')));
            // console.log("cd bcjks", setContentDate(moment(assetContentList[0]?.start_date).format('ddd MM DD YYY HH:mm:ss').format() + ' GMT+0530 (India Standard Time)'));

            /*  yyy = moment(assetContentList[0].expired_date).format('YYYY');
             mmm = moment(assetContentList[0].expired_date).format('MM');
             ddd = moment(assetContentList[0].expired_date).format('DD');
             if (ddd[0] == 0) {
               ddd = ddd.substring(1);
             }
             hhh = moment(assetContentList[0].expired_date).format('HH');
             if (hhh[0] == 0) {
               hhh = hhh.substring(1);
             }
             miii = moment(assetContentList[0].expired_date).format('mm');
             if (miii[0] == 0) {
               miii = miii.substring(1);
             }
             setExpiredDate({
               year: yyy,
               month: mmm,
               day: ddd,
               hour: hhh,
               minute: miii
             }); */

            setExpiredDate(new Date(moment(assetContentList[0]?.expired_date).format('YYYY-MM-DD hh:mm A')));
          }
        }
      }

      if (assetContentList && assetContentList[0]?.type == "Brochure" || assetContentList[0]?.type == "Video" || assetContentList[0]?.type == "Microsite" || assetContentList[0]?.type == "Image") {
        if (assetContentList[0]?.selected_asset.includes(1)) {
          setMobileApp(true);
          setContentType(contentType => [...contentType, 1])
        }
        if (assetContentList[0].selected_asset.includes(2)) {
          setMicrosite(true);
          setContentType(contentType => [...contentType, 2]);
        }
      }

      if (assetContentList && (assetContentList[0]?.type == "Video" || assetContentList[0]?.type == "Microsite" || assetContentList[0]?.type == "Image")) {
        if (assetContentList[0]?.cobrand === 1) {
          setCobrandingOption(true);
          if (assetContentList[0]?.cobrand_startframe === 1) {
            setStartFrame('start');
          } else if (assetContentList[0]?.cobrand_endframe === 1) {
            setStartFrame('end');
          }
        }
      }
    } else {
      setContentType([]);
      setCobrandingOption(false);
      setPushNotificationOption(false);
      setEmailNotificationOption(false);
      setSelectedPartners([]);
      setSelectedUsers([]);
      setContentDate(new Date());
      setExpiredDate(null);
      setDate(null);
      setPlatformFB(false);
      setPlatformLN(false);
      setPlatformTW(false);
      setMobileApp(false);
      setMicrosite(false);
      setWhatsApp(false);
      setSocial(false);
    }
  }, [directPublish]);

  //console.log("contentType",contentType);
  //console.log("selectedAssetType" ,selectedAssetType);
  //console.log("assetContentList", assetContentList);
  //console.log("selectedAssetPublish",selectedAssetPublish);
  //console.log("assetTypeImgUrl",assetTypeImgUrl);
  //console.log("assetSelectTypeList",assetSelectTypeList);
  //console.log("uploadedData",uploadedData);
  //console.log("directPublish",directPublish);
  //console.log("videoUrl",videoUrl);
  // console.log("whatsApp>>",whatsApp);
  // console.log("social>>",social);

  const handleDates = (e, val) => {
    // console.log("e", e);
    // console.log("val", val);
    if (e !== "0000-00-00 00:00:00" && e !== undefined && e !== null) {
      if (val == 'cd') {
        if (e.year <= maxDate.year && e.month <= maxDate.month && e.day <= maxDate.day) {
          setContentDate(e);
        } else {
          //document.querySelector('.input-picker--input.custom-input-cdate').innerHTML = "";
          Toast('Please select Present day or Past date', 'E');
          // setTimeout(()=>{
          //   setContentDate(null);
          // },3000);
        }
      } else if (val === 'ed') {
        if (e.year >= maxDate.year && e.month >= maxDate.month && e.day >= maxDate.day) {
          setExpiredDate(e);
        } else {
          //document.querySelector('.input-picker--input.custom-input-edate').innerHTML = "";
          Toast('Please select Present day or Future date', 'E');
          //   setTimeout(()=>{
          //   setExpiredDate(null);
          // },3000)
        }
      }
    }
  }

  return (
    <div className="new-asset publish">
      {isLoading && <Loading />}
      <Row>
        <Col>
          <ul className="selected-asset-list my-auto d-flex pb-4">
            <li className="asset-type-img">
              {assetContentList?.length > 0 && assetContentList[0]?.type !== "Microsite" ?
                <img
                  src={imgUrl ? imgUrl.article_image : ""}
                  alt={selectedAssetType.article_type}
                />
                :
                <img
                  src={assetContentList?.length > 0 && assetContentList[0]?.c_type === "video" ? micrositeVideo : micrositeBroucher}
                  alt={selectedAssetType.article_type}
                />
              }
            </li>
            <li className="web-icon active">
              <span>
                <Icon.Globe />
              </span>
            </li>
            {assetContentList &&
              assetContentList.map((b, index) => {
                return (<li className="asset">
                  {/* {( ((_.isEmpty(directPublish)) || (directPublish.length > 0)) && 
                      (assetContentList && assetContentList.length > 0)) &&  */}
                  {/* <Form.Check
                    type="checkbox"
                    className="check-box"
                    value={b.content_id}
                    onChange={(e) => {
                      setAssetChecked(!assetChecked);
                      handleCheck(
                        e,
                        b.content_id,
                        b.type
                      );
                    }}
                  /> */}
                  {/* } */}
                  <img src={b.thumb_image} alt={b.content_title} />
                  {activeAsset === index && (
                    <span className="" >
                      <Icon.CaretUpFill />
                    </span>
                  )}
                </li>);
                //}             
              })}
          </ul>
        </Col>
      </Row>
      <Card className="">
        <Row className="asset-type g-0">
          <Col className="col-lg-6 col-md-12 col-12">
            <Row className="publish-setup-wrap">
              <Col >
                <h1 className="mb-5">Publish Setup</h1>
                <Form onSubmit={(e)=>{submitHandler(e, "Submit")}}>
                  {(selectedAssetType.type === "Banners" ||
                    selectedAssetType.type === "Banner") ? (
                    ""
                  ) : (
                    <>
                      <Form.Label htmlFor="select-platform">
                        Where to Publish
                      </Form.Label>
                      <hr />
                    </>
                  )}
                  {(selectedAssetType.type === "Emailer") && (
                    <div className="publish-access">
                      <Form.Group className="checkbox-btn ms-0">
                          <Form.Check
                            type="radio"
                            label="Indirect Campaign"
                            id="flexRadioDefault2"
                            name="flexRadioDefault"
                            checked={indirectCampaignType}
                            onChange={(e) => {
                              setDirectCampaignType(!e.target.checked);
                              setIndirectCampaignType(e.target.checked);
                            }}
                          />
                      </Form.Group>
                      <hr />
                        <Form.Group className="checkbox-btn ms-0">
                          <Form.Check
                            type="radio"
                            label="Direct Campaign"
                            id="flexRadioDefault1"
                            name="flexRadioDefault"
                            checked={directCampaignType}
                            onChange={(e) => {
                              setDirectCampaignType(e.target.checked);
                              setIndirectCampaignType(!e.target.checked);
                            }}
                          />
                        </Form.Group>
                        
                        
                      </div>
                    )}
                  {(selectedAssetType.type === "Social Post" || selectedAssetType.type === "Image" ||
                    selectedAssetType.type === "Social") && (
                      <div className="publish-access">
                        <Form.Group className="checkbox-btn ms-0">
                          <Form.Check
                            type="checkbox"
                            label="WhatsApp"
                            name="wh-platform"
                            checked={whatsApp}
                            onChange={(e) => {
                              contentTypeHandler(e, "wa");
                              setWhatsApp(!whatsApp);
                            }}
                          />
                        </Form.Group>
                        <hr />
                        <Form.Group className="checkbox-btn ms-0">
                          <Form.Check
                            type="checkbox"
                            label="Social"
                            name="so-platform"
                            checked={social}
                            onChange={(e) => {
                              contentTypeHandler(e, "sc");
                              setSocial(!social);
                            }}
                          />
                      </Form.Group>
                      {company_logo?.is_sip == "1" &&
                        <>
                          <hr />
                          <Form.Group className="checkbox-btn ms-0">
                            <Form.Check
                              type="checkbox"
                              label="SIP Campaign"
                              name="si-platform"
                              checked={sip}
                              onChange={(e) => {
                                contentTypeHandler(e, "sip");
                                setSip(!sip);
                              }}
                            />
                          </Form.Group>
                        </>
                      }
                      
                      {(( selectedAssetType.type === "Social Post" || selectedAssetType.type === "Image" || selectedAssetType.type === "Social") && company_logo.new_login == "tal") && (
                        <>
                          <hr />
                          <Form.Group className="checkbox-btn ms-0">
                            <Form.Check
                              type="checkbox"
                              label="Microsite"
                              name="microsite-platform"
                              checked={microsite}
                              onChange={(e) => {
                                contentTypeHandler(e, "s_ms");
                                setMicrosite(!microsite);
                              }}
                            />
                          </Form.Group>
                          </>
                      )}
                      </div>
                    )}
                  <div className="publish-media">
                    {(selectedAssetType.type === "Brochure" ||
                      selectedAssetType.type === "Microsite" ||
                      selectedAssetType.type === "Infographics" ||
                      selectedAssetType.type === "Infographic" ||
                      selectedAssetType.type === "Video" ||
                      selectedAssetType.type === "Document") && (
                        <Form.Group className="checkbox-btn ms-0">
                          <Form.Check
                            type="checkbox"
                            label="Mobile App"
                            name="brochure-platform"
                            checked={mobileApp}
                            onChange={(e) => {
                              contentTypeHandler(e, "br");
                              setMobileApp(!mobileApp)
                            }}
                          />
                        </Form.Group>
                      )}

                    {((selectedAssetType.type) === "Brochure" ||
                      selectedAssetType.type === "Microsite" ||
                      (selectedAssetType.type) === "Infographics" ||
                      (selectedAssetType.type) === "Infographic" ||
                      (selectedAssetType.type) === "Document" ||
                      (selectedAssetType.type) === "Video") && (
                        <>
                          <hr />
                          <Form.Group className="checkbox-btn ms-0">
                            <Form.Check
                              type="checkbox"
                              label="Microsite"
                              name="microsite-platform"
                              checked={microsite}
                              onChange={(e) => {
                                contentTypeHandler(e, "ms");
                                setMicrosite(!microsite);
                              }}
                            />
                          </Form.Group>
                          <br />
                          {microsite && (
                            <div className="microsite-options">
                              {/* <Form.Group className="mb-4 mt-3">
                            <Form.Control
                              type="text"
                              ref={msTitleRef}
                              autoComplete="off"
                              //required={microsite}
                              placeholder="SEO Page Title"
                            />
                          </Form.Group>
                          <Form.Label htmlFor="select-seo-meta-desc">
                            SEO Meta Description:
                          </Form.Label>
                          <br />
                          <Form.Label htmlFor="select-platform">
                            Meta Description same as Document Description?
                          </Form.Label>
                          <br />
                          <Form.Group className="mb-4 radio-btn">
                            <Form.Check
                              type="radio"
                              label="Yes"
                              name="seo-meta-desc"
                              value="yes"
                              checked={metaDescYes}
                              onClick={(e) => {
                                setMetaDescYes(true);
                                setMetaDescNo(false);
                              }}
                            />
                          </Form.Group>
                          <Form.Group className="mb-4 radio-btn">
                            <Form.Check
                              type="radio"
                              label="No"
                              name="seo-meta-desc"
                              value="no"
                              checked={metaDescNo}
                              onClick={(e) => {
                                setMetaDescYes(false);
                                setMetaDescNo(true);
                              }}
                            />
                          </Form.Group>
                          <br /> */}
                              {/* <Form.Label htmlFor="select-seo-meta-desc">
                            Keywords
                          </Form.Label>
                          <Form.Group className="mb-4">
                            <Form.Control
                              type="text"
                              autoComplete="off"
                              //required={microsite}
                              value={newKeywords.join(",")}
                            />
                            <div
                              className="edit-keywords"
                              onClick={() => {
                                setEditKeywords(!editKeywords);
                              }}
                            >
                              <Icon.Pencil />
                            </div>
                            {editKeywords && (
                              <div className="keyword-options mt-4">
                                <Row>
                                  <Col>
                                    <Form.Label htmlFor="select-seo-meta-desc">
                                      Available Keywords
                                    </Form.Label>
                                    <Form.Select
                                      multiple
                                      className="mb-3 multi-select-keyword"
                                      aria-label="select-keyword"
                                      onChange={(e) => {
                                        setSelectedKeywords(
                                          [].slice
                                            .call(e.target.selectedOptions)
                                            .map((item) => item.value)
                                        );
                                      }}
                                    >
                                      {keywordsList &&
                                        keywordsList.map((k) => {
                                          return (
                                            <option value={k.keyword}>
                                              {k.keyword}
                                            </option>
                                          );
                                        })}
                                    </Form.Select>
                                  </Col>
                                  <Col className="col-1">
                                    <Form.Label htmlFor="select-seo-meta-desc"></Form.Label>
                                    <Button
                                      variant="info"
                                      className="add mb-3 mt-2"
                                      onClick={addKeywordsHandler}
                                    >
                                      <span>
                                        <Icon.ChevronDoubleRight />
                                      </span>
                                    </Button>
                                    <Button
                                      variant="info"
                                      className="remove mb-4"
                                      onClick={removeKeywordsHandler}
                                    >
                                      <span>
                                        <Icon.ChevronDoubleLeft />
                                      </span>
                                    </Button>
                                  </Col>
                                  <Col>
                                    <Form.Label htmlFor="select-seo-meta-desc">
                                      Selected Keywords
                                    </Form.Label>
                                    <Form.Select
                                      multiple
                                      className="mb-3 multi-select-keyword"
                                      aria-label="select-keyword"
                                      onChange={(e) => {
                                        setSelectedKeywords(
                                          [].slice
                                            .call(e.target.selectedOptions)
                                            .map((item) => item.value)
                                        );
                                      }}
                                    >
                                      {newKeywords.map((k) => {
                                        return <option value={k}>{k}</option>;
                                      })}
                                    </Form.Select>
                                  </Col>
                                </Row>
                                <Form.Label htmlFor="select-seo-meta-desc">
                                  Add New Keyword
                                </Form.Label>
                                <Row>
                                  <Col>
                                    <Form.Select
                                      className="mb-3"
                                      aria-label="select-cta"
                                      onChange={(e) => {
                                        setSelectedCategory(e.target.value);
                                      }}
                                      value={selectedCategory}
                                    >
                                      <option value={null}>
                                        Select Solution
                                      </option>
                                      {categoryList &&
                                        categoryList.map((c) => {
                                          return (
                                            <option value={c.id}>
                                              {c.solution_type}
                                            </option>
                                          );
                                        })}
                                    </Form.Select>
                                  </Col>
                                  <Col>
                                    <Form.Group className="mb-4">
                                      <Form.Control
                                        type="text"
                                        ref={addKeywordRef}
                                        autoComplete="off"
                                        placeholder="Enter New Keyword"
                                      />
                                    </Form.Group>
                                  </Col>
                                  <Col>
                                    <Button
                                      variant="info"
                                      className="add-keyword-btn"
                                      onClick={addNewKeywordHandler}
                                    >
                                      {" "}
                                      Add
                                      <span>
                                        <Icon.Plus />
                                      </span>
                                    </Button>
                                  </Col>
                                </Row>
                              </div>
                            )}
                          </Form.Group> */}
                              <Form.Select
                                className="mb-3 mt-2 smartFolder-multiSelect"
                                aria-label="select-cta"
                                onChange={(e) => {
                                  setSelectedCTA(e.target.value);
                                }}
                                value={selectedCTA}
                              >
                                <option value={null}>Select CTA</option>
                                {ctaList &&
                                  ctaList.map((c) => {
                                    return (
                                      <option value={c.id}>{c.ctaName}</option>
                                    );
                                  })}
                              </Form.Select>
                            </div>
                          )}
                        </>
                      )}
                  </div>
                  {social && selectedAssetType.type != "Emailer" && (
                    <div className="">
                      <br />
                      <Form.Label htmlFor="select-platform">
                        Select Platform
                      </Form.Label>
                      <div className="url-max-size">
                        <Form.Group className="checkbox-btn">
                          <Form.Check
                            type="checkbox"
                            label="Facebook"
                            name="fb-platform"
                            checked={platformFB}
                            onChange={(e) => {
                              platformHandler(e, "fb");
                              setPlatformFB(!platformFB);
                            }}
                          />
                        </Form.Group>
                        <Form.Group className="checkbox-btn">
                          <Form.Check
                            type="checkbox"
                            label="Twitter"
                            name="tw-platform"
                            checked={platformTW}
                            onChange={(e) => {
                              platformHandler(e, "tw");
                              setPlatformTW(!platformTW);
                            }}
                          />
                        </Form.Group>
                        <Form.Group className="checkbox-btn">
                          <Form.Check
                            type="checkbox"
                            label="LinkedIn"
                            name="in-platform"
                            checked={platformLN}
                            onChange={(e) => {
                              platformHandler(e, "in");
                              setPlatformLN(!platformLN);
                            }}
                          />
                        </Form.Group>
                      </div>
                    </div>
                  )}
                  {(selectedAssetType.type === "Banners" || selectedAssetType.type === "Banner") ? "" : <hr />}
                  {indirectCampaignType === true && <>
                  <Form.Label htmlFor="select-platform">Access</Form.Label>
                  <br />

                  <div className="public-access">
                    <Form.Group className="mb-4 radio-btn">
                      <Form.Check
                        type="radio"
                        label="Partners"
                        name="access"
                        checked={partners}
                        onClick={(e) => {
                          setPartners(true);
                          setUsers(false);
                          setSelectedUsers([]);
                        }}
                      />
                    </Form.Group>

                    <Form.Group className="mb-4 radio-btn">
                      <Form.Check
                        type="radio"
                        label="Users"
                        name="access"
                        checked={users}
                        onClick={(e) => {
                          setPartners(false);
                          setUsers(true);
                          setSelectedPartners([]);
                        }}
                      />
                    </Form.Group>
                  </div>
                  {partners && (
                    <div className="smartFolder-multiSelect mb-1 w-100">
                      <div className="select-box-w100 user-drop-select">
                        <ReactMultiSelectCheckboxes
                          options={partner}
                          placeholderButtonLabel={selectedPartners?.length > 0 ? `${selectedPartners.length} selected` : "Select Partners"}
                          defaultValue={selectedPartners}
                          values={selectedPartners}
                          setState={setSelectedPartners}
                          onChange={selectPartnerHandler}
                        />
                      </div>
                    </div>
                  )}

                  {users && (
                    <div className="smartFolder-multiSelect user-drop">
                      <Multiselect
                        options={user}
                        selectedValues={selectedUsers}
                        onSelect={onSelect}
                        onRemove={onRemove}
                        displayValue="name"
                        groupBy="cat"
                        showCheckbox={true}
                        placeholder="Select Users"
                      />
                    </div>
                  )}
                  </>}
                  {(selectedAssetType.type === "Banners" ||
                    selectedAssetType.type === "Banner" || selectedAssetType.type === "Emailer") ? "" : <Row className="mb-3 gap-1 date-picker m-md-0">
                    <Col className="cdate ">
                      {/*   <DtPicker
                        withTime
                        showTimeInput
                        initValue={contentDate}
                        onChange={(e) => handleDates(e, 'cd')}
                        placeholder="Content Date"
                        clearBtn={true}
                        inputClass='custom-input-cdate'
                        autoClose={false}
                      //minDate={minDate}
                      // maxDate={maxDate}
                      // disabledDates={[maxDate]}
                      /> */}

                      <DatePicker
                        selected={contentDate}
                        onChange={(date) => setContentDate(date)}
                        maxDate={new Date()}
                        placeholderText="Content Date"
                        timeInputLabel="Time:"
                        dateFormat="MM/dd/yyyy h:mm aa"
                        showTimeInput
                        shouldCloseOnSelect={false}
                      />

                    </Col>
                    <Col className="edate ">
                      {/* <DtPicker
                        withTime
                        showTimeInput
                        initValue={expiredDate}
                        onChange={(e) => handleDates(e, 'ed')}
                        placeholder="Expired on"
                        clearBtn={true}
                        inputClass='custom-input-edate'
                        autoClose={false}
                      // minDate={minDate}
                      // disabledDates={[minDate]}
                      /> */}


                      <DatePicker
                        selected={expiredDate}
                        onChange={(date) => setExpiredDate(date)}
                        minDate={new Date()}
                        placeholderText="Expired Date"
                        timeInputLabel="Time:"
                        dateFormat="MM/dd/yyyy h:mm aa"
                        showTimeInput
                        shouldCloseOnSelect={false}
                      />

                    </Col>
                  </Row>}


                  {(selectedAssetType.type === "Banner" ||
                    selectedAssetType.type === "Banners") ? "" : (
                    /*  <DtPicker
                       withTime
                       showTimeInput
                       initValue={date}
                       onChange={setDate}
                       placeholder="Schedule"
                       clearBtn={true}
                       autoClose={false}
                     /> */


                    <DatePicker
                      selected={date}
                      onChange={(date) => { setDate(date); setContentDate(date)}}
                      minDate={new Date()}
                      placeholderText="Schedule Date"
                      timeInputLabel="Time:"
                      dateFormat="MM/dd/yyyy h:mm aa"
                      showTimeInput
                      disabled={directCampaignType === true}  
                      shouldCloseOnSelect={false}
                    />

                  )}


                  {((selectedAssetType.type) === "Banners" ||
                    (selectedAssetType.type) === "Banner") ? (
                    ""
                  ) : (
                    <Row>
                      {selectedAssetType.type !== "Emailer" && <Col className="col-lg-4 col-md-4 col-sm-6 col-7">
                        <Form.Group
                          className="mb-3 mt-4"
                          controlId="formSwitchBtn"
                        >
                          <Form.Check
                            type="switch"
                            id="custom-switch"
                            label="Co-Branding"
                            checked={cobrandingOption}
                            onChange={cobrandingOptionHandler}
                            disabled={videoUrl}
                          />
                        </Form.Group>
                      </Col>}
                      <Col className="col-lg-4 col-md-4col-sm-6 col-8">
                        <Form.Group
                          className="mb-3 mt-4"
                          controlId="formSwitchBtn"
                        >
                          <Form.Check
                            type="switch"
                            id="custom-switch"
                            label="Push Notifications"
                            checked={pushNotificationOption}  
                            onChange={pushNotificationOptionHandler}
                            disabled={(selectedAssetType.type !== "Emailer" && ((microsite === true && mobileApp === true) || microsite === false)) || (directCampaignType !== true) ? false : true}
                          />
                        </Form.Group>
                      </Col>
                      {(company_logo?.notification_email !== 0 && selectedAssetType.type !== "Emailer") && <Col className="col-lg-4 col-md-4 col-sm-6 ">
                        <Form.Group
                          className="mb-3 mt-4"
                          controlId="formSwitchBtn"
                        >
                          <Form.Check
                            type="switch"
                            id="custom-switch"
                            label="Email Notifications"
                            checked={emailNotificationOption}
                            onChange={emailNotificationOptionHandler}
                            disabled={(microsite === true && mobileApp === true) || microsite === false ? false : true}
                          />
                        </Form.Group>
                      </Col>}
                    </Row>
                  )}

                  {(selectedAssetType.type) === "Video" &&
                    cobrandingOption && (
                      <Row>
                        <Col>
                          <Form.Group
                            className="mb-3"
                            controlId="formSwitchBtn"
                          >
                            <Form.Check
                              type="radio"
                              id="custom-switch"
                              label="Start frame Cobrand"
                              checked={startFrame === 'start' ? true : false}
                              onChange={() => setStartFrame('start')}
                            />
                          </Form.Group>
                          { !assetContentList[activeAsset]?.p_type?.includes("reel") && <Form.Group
                            className="mb-3"
                            controlId="formSwitchBtn"
                          >
                            <Form.Check
                              type="radio"
                              id="custom-switch"
                              label="End frame Cobrand"
                              checked={startFrame === 'end' ? true : false}
                              onChange={() => setStartFrame('end')}
                            />
                          </Form.Group>}
                        </Col>
                      </Row>
                    )}

                  <Button
                    variant="info"
                    type="submit"
                    className="save-proceed-btn"
                  >{activeAsset !== assetContentList?.length - 1 ? "Next" : "Publish"}
                    <span>
                      <Icon.ChevronRight />
                    </span>
                  </Button>
                  
                  {selectedAssetType.type == "Emailer" && <Button
                    variant="secondary"
                    className="save-proceed-btn mx-3"
                    onClick={(e)=>{submitHandler(e, "Draft")}}
                  >Draft
                    <span>
                      <Icon.ChevronRight />
                    </span>
                  </Button>}
                  
                </Form>
              </Col>
            </Row>
          </Col>
          <Col className="col-lg-6 col-md-12 col-12">
            <div className="device-img mt-5">

              <img className="mobile-img" src={deviceImg} alt="device-img" />
              <div className="publish-mobile-layout">
                <div className="title">{(assetContentList && assetContentList.length > 0) && assetContentList[activeAsset].content_title}</div>
                <div className="desc">{(assetContentList && assetContentList.length > 0) && assetContentList[activeAsset].short_caption}</div>
                <div className="img"><img src={(assetContentList && assetContentList.length > 0) && assetContentList[activeAsset].thumb_image} /></div>
              </div>
            </div>
          </Col>
        </Row>
      </Card>
    </div>
  );
};

export default PublishAsset;
