/* eslint-disable */
import { Col, Row, Stack, Form, Button, Container } from 'react-bootstrap';
import React, { useState, useEffect } from "react";
import * as Icon from "react-bootstrap-icons";
import "../leads/tab-components/Leadtabs.css";
import { useParams } from 'react-router';
import { useDispatch } from 'react-redux';
import { addNote, taskManager, appUserActivity } from '../../../../../redux/actions/crmActions';
import { useNavigate } from "react-router-dom";
import { useSelector } from 'react-redux';
import Toast from '../../../../shared/Toast';

const AddNote = (props) => {
    const [title, setTitle] = useState("");
    const [note, setNote] = useState("");
    const [page, setPage] = useState("Add");
    const { contactid, from, setDefault, authToken } = props;

    const { id, name, noteId } = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const userDetails = useSelector((state) => state.crmLeads.userActivityDetails);
    const stagesList = useSelector((state) => state.crmLeads.stagesList);

    const [validated, setValidated] = useState(false);

    const getChars = (str) => {
        const firstLetters = str.split(' ').slice(0,2).map(word => word[0]).join('');
        return firstLetters;
    }

    useEffect(() => {
        if (name === 'edit') {
            setPage('Edit');
            dispatch(appUserActivity(authToken, id));
            let itemm = userDetails.response.notes.find(item => {
                if (item.id == noteId) {
                    return item;
                }
            });

            //setNoteId(itemm.id);
            setTitle(itemm.title);
            setNote(itemm.task_notes);
        } else if (name === 'add') {
            setPage('Add');
        }
    }, [])

    const handleSubmit = (event) => {
        // const form = event.currentTarget;
        console.log("value", page);

        // if (form.checkValidity() === false) {
        //     event.preventDefault();
        //     event.stopPropagation();
        // }
        event.preventDefault();
        if (page === 'Add') {
            if (title == "") {
                Toast('Please enter title', "E");
            } else {
                if (contactid?.contact_ids?.length > 0) {
                    let associatedIds = [];

                    contactid.contact_ids.map((item) => {
                        associatedIds.push(item.contact_id);
                    });
                    dispatch(addNote(authToken, associatedIds, title, note, stagesList));
                } else if (id && id !== undefined || id === null) {
                    dispatch(addNote(authToken, id, title, note, null));
                    navigate(`/crm/leads/${id}`);
                }
            }
        } else if (page === 'Edit') {
            if (title == "") {
                Toast('Please enter title', "E");
            } else {
                let body = {
                    action: "note_edit",
                    associated_as: "1",
                    id: noteId,
                    notes: note,
                    title: title,
                    task_category: "Notes"
                };

                body["associated_with"] = contactid ? contactid.contact_ids[0].contact_id : id;

                dispatch(taskManager(authToken, body));
                navigate(`/crm/leads/${id}`);
            }
        }
        setNote("");
        setTitle("");
        setPage("");

        if (from == 'bulk') {
            setDefault();
        }
    };

    return (
        <>
            <div className="mt-2 pa-1 offcanvas-body-background"> {/*  offcanvas-body-background */}
                {from !== 'bulk' && (
                    <>
                        <div className="fs-5 fw-bold">{page} Note</div>
                        <hr />
                    </>
                )}
                <Form onSubmit={handleSubmit} className="p-3"> {/* noValidate validated={validated} */}
                    <Form.Group className="mb-3" controlId="title">
                        <Form.Control required type="text" value={title} placeholder="Title" onChange={(e) => setTitle(e.target.value)} />
                        {/* <Form.Control.Feedback type="invalid">
                            Please Enter a Title.
                        </Form.Control.Feedback> */}
                    </Form.Group>
                    <Form.Group controlId="note">
                        <Form.Control as="textarea" rows="3" value={note} name="description" placeholder="Description" onChange={(e) => setNote(e.target.value)} />
                        {/*  <Form.Control.Feedback type="invalid">
                            Please Enter a Description.
                        </Form.Control.Feedback> */}
                    </Form.Group>
                    <Button type="submit" className="d-flex ms-auto submit-btn mt-3">
                        {page == 'Edit' ? 'Update Note' : 'Create Note'}&nbsp;&nbsp;&nbsp;
                        <Icon.ChevronRight
                            style={{
                                fontSize: 25,
                                color: '#fff',
                                padding: 5
                            }} />
                    </Button>
                </Form>
            </div>
        </>
    )
}

export default AddNote;