import React from 'react'
import { Button, InputGroup, Form } from 'react-bootstrap';
import * as Icon from "react-bootstrap-icons";
import vector from '../../assets/setup-icon/Vector.png';
import DragTable from '../shared/DragTable';
import { useNavigate } from "react-router-dom";
import { DebounceInput } from 'react-debounce-input';
import Loading from "../Loading";
import { useDispatch, useSelector } from "react-redux";
import { useState, useRef, useEffect } from "react";
import * as types from "../../constants/ActionTypes";
import Pagination from "../shared/ReactPaginate";
import DragShimmer from './shimmer/DragShimmer';
import { fetchContentListing, fetchLevelsListing, searchInput } from "../../redux/actions/setupActions";

export const SetupLevels = (props) => {
    let navigate = useNavigate();
    const authToken = props.authToken;
    const dispatch = useDispatch();
    let isLoading = useSelector((state) => state.setupScreen.loading);
    // const total_data = useSelector((state) => state.setupScreen.levels_listing?.total_data);
    const levels_listing = useSelector((state) => state.setupScreen.levels_listing);
    let search_input = useSelector((state) => state.setupScreen.search_input);
    let pageNum = useSelector((state) => state.setupScreen.currPage);
    const [sortValue, setSortValue] = useState("ASC");
    // let search_input = useSelector((state) => state.setupScreen.search_input);
    // let pageNum = useSelector((state) => state.setupScreen.currPage);
    const [searchValue, setSearchValue] = useState("");
    const add_edit_screen = () => {
      navigate("/setup/leaderBoard/levels/addBatches"); 
    }
    const handleRemoveSearch = (e) => {
        setSearchValue("");
        dispatch(searchInput(""));
        dispatch(fetchLevelsListing(authToken,""));
    }
    const handleSearch = (e) => {
        setSearchValue(e.target.value);
        dispatch(searchInput(e.target.value));
        dispatch(fetchLevelsListing(authToken,e.target.value));
    }
    useEffect(() => {
        dispatch(fetchLevelsListing(authToken,""));
    }, []);
  
    useEffect(() => {    // onDestroy
        return () => {
          dispatch({ type: types.SEARCH_INPUT, payload: "" });
          // dispatch({ type: types.CURRENT_PAGE, payload: 0 });
        }
    }, [])
    return (
      <div>
        <div>
          <div>
            <InputGroup className="mb-3 search-inside">
              <DebounceInput value={searchValue} className="search-inside-input" placeholder="Search Levels" minLength={3} debounceTimeout={2000} onChange={handleSearch} />
              <InputGroup.Text id="basic-addon1">
                {searchValue && searchValue !== "" ? <Icon.X color="#A8A8A8" onClick={handleRemoveSearch} /> : <Icon.Search color="#A8A8A8" onClick={handleSearch}/>}
              </InputGroup.Text>
            </InputGroup>
            <div className='right-buttons'>
              {/* <span>Sort By <span className='sort-text mx-1' onClick={dateSorting}>Date Added <img className={sortValue === "DESC" ? 'rotate' : ''} src={vector} alt="icon" /></span></span> */}
              <Button style={{marginLeft: '60px'}} onClick={add_edit_screen} className='common-setup-button' variant="primary">+ Batches</Button>
            </div>
          </div>

          <div>
            {isLoading && <DragShimmer subPageName={props.subPageName}/>}
            {levels_listing?.response?.length > 0 && isLoading === false && <DragTable authToken={authToken} page={pageNum} subPageName={props.subPageName} categoryData={levels_listing.response} handleRemoveSearch={ handleRemoveSearch } />}
            {levels_listing?.response?.length === 0 && (
              <div className="no-data asset">
                <span>
                  <Icon.Inbox />
                </span>
                <br />
                No Data
              </div>
            )}
          </div>
        </div>
        {/* {total_data > 20 && <Pagination authToken={props.authToken} total_page={ total_data } page={pageNum} currPage={props.subPageName} />} */}
         {/* <Pagination total_page={ total_data } authToken={props.authToken} currPage={props.subPageName} /> */}
      </div>
    )
}

