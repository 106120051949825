/* eslint-disable */
import { useState, useEffect, useContext } from "react";
import { Nav, Navbar, Container, NavDropdown, Dropdown,Modal,Button } from "react-bootstrap";
import { Link, Navigate, useNavigate } from "react-router-dom";
import * as Icon from "react-bootstrap-icons";
import logo from "../../../assets/logo.png";
import "./Navigation.css";
import Loading from "../../Loading";
import AuthContext from "../../../context/AuthProvider";

//Redux-imports
import { useDispatch, useSelector } from "react-redux";
import { fetchTopMenus, logout, backToPreviousRoute } from "../../../redux/actions/actions";

const Navigation = (props) => {
  const dispatch = useDispatch();
  const ctx = useContext(AuthContext);
  let navigate = useNavigate();
  const authToken = props.authToken;
  const [active, setActive] = useState("default");
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  
  const handleLogout = () => {
    navigate("/");
    dispatch(backToPreviousRoute(""));
    dispatch(logout(authToken));
    ctx.onLogout();
  }
  // const [checkLogoutPopup, setCheckLogoutPopup] = useState(false);

  const topMenus = useSelector((state) => state.headerTopMenus.topMenus);
  const company_logo = useSelector((state) => state.headerTopMenus.companyLogo);

  useEffect(() => {
    dispatch(fetchTopMenus(authToken));
  }, []);

  return (
    <>
      {!topMenus && <Loading />}
      <Navbar expand="lg" className="fixed-header">
        <Container>
          <Navbar.Brand as={Link} to="/" onClick={()=>{dispatch(backToPreviousRoute(""))}}>
            <img src={company_logo?.logo ? company_logo?.logo : logo} alt="Company Logo" />
          </Navbar.Brand>
          <div className="d-flex  align-items-center"> 
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <div>
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav
              className="ms-auto"
              activeKey={active}
              onSelect={(selectedKey) => setActive(selectedKey)}
            >
              {topMenus &&
                topMenus.map((menu) => {
                  return !menu.sub_menu ? (
                    <Nav.Link
                      className={ window.location.pathname.includes(menu.main_menu.name) ? "active-nav": ""}
                      as={Link}
                      to={menu.main_menu.name}
                      eventKey={menu.main_menu.name}
                    >
                      {menu.main_menu.name}
                    </Nav.Link>
                  ) : (
                    <NavDropdown
                      title={menu.main_menu.name}
                      id="basic-nav-dropdown"
                    >
                      {menu.sub_menu.map((submenu) => {
                        return (
                          <NavDropdown.Item
                            as="a"
                            href={`//${submenu.link}`}
                            eventKey={submenu.name}
                          >
                            {submenu.name}
                          </NavDropdown.Item>
                        );
                      })}
                    </NavDropdown>
                  );
                })}
            </Nav>
          </Navbar.Collapse>
          </div>
          <Dropdown className="user-profile">
            <Dropdown.Toggle variant="light" id="dropdown-basic">
              <Icon.PersonCircle />
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <Dropdown.Item
                as="a"
                href={window.location.href.split('/')[0] + "/" + window.location.href.split('/')[1] + "/" + window.location.href.split('/')[2] + "/manager/my-profile.php"}>
                My Profile
              </Dropdown.Item>
              {company_logo?.privacyPolicyPageTitle && company_logo?.privacyPolicyPageTitle !== "" && <Dropdown.Item
                as="a"
                target="blank"  
                href={company_logo?.privacyPolicyPageUrl}>
                {company_logo?.privacyPolicyPageTitle || "Privacy"}
              </Dropdown.Item>}  
              <Dropdown.Item
                onClick={handleShow}
              >
                Log Out
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
          </div>
        </Container>
      </Navbar>
      <Modal className="logout-popup" show={show} onHide={handleClose}>
        <Modal.Header>
          <button class="btn-secondary border-0 p-1 close" type="button" onClick={handleClose} data-dismiss="modal" aria-label="Close">
            <span>×</span>
          </button>
        </Modal.Header>
        <Modal.Body>Are you sure you want to logout?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleLogout}>
            Logout
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Navigation;
