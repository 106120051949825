import React from 'react'
import * as Icon from "react-bootstrap-icons";
import { useDispatch, useSelector } from "react-redux";
import SelectTable from "../campaign/SelectTable"
import { useEffect } from 'react';
import { searchInput } from '../../../redux/actions/setupActions';
import { useState } from 'react';
import { Button, Col, Modal, Row, Table } from 'react-bootstrap';
import { deleteContactGroupData, fetchListIndividualDataListing, setSelectedListIds, setshowEnteries } from '../../../redux/actions/campaignAction';
import Pagination from "./CampaignPagination";
import Loading from '../../Loading';

const ContactList = (props) => {
    const dispatch = useDispatch();
    let show_enteries = useSelector((state) => state.campaign.show_enteries);
    let list_id = useSelector((state) => state.campaign.list_id);
    const authToken = props.authToken;
    const total_data = useSelector((state) => state.campaign?.individual_contact_list?.total_count);
    const individual_contact_list = useSelector((state) => state.campaign?.individual_contact_list?.data);
    const [selectedCrawlUrls, setSelectedCrawlUrls] = useState([]);
    const [selectedOption, setSelectedOption] = useState("20");
  
    let pageNum = useSelector((state) => state.campaign.currPage);
    const isLoading = useSelector((state) => state.campaign.loading);
  

    const unselectData = () => {
      setSelectedCrawlUrls([]);
      dispatch(setSelectedListIds([])); 
    }
  
    const handleUrlCheckboxChange = async (e, obj) => {
      if (selectedCrawlUrls.includes(obj)) {
        setSelectedCrawlUrls(selectedCrawlUrls.filter(item => item !== obj));
      } else {
        setSelectedCrawlUrls([...selectedCrawlUrls, obj]);
      }
      let data = selectedCrawlUrls;
      data.push(obj);
      let all_ids = data.map((item) => {
        return item.id
      })
      await dispatch(setSelectedListIds(all_ids));
    };
  
    const selectAllHashtag = () => {
      setSelectedCrawlUrls(individual_contact_list);
      let all_ids = individual_contact_list.map((item) => {
        return item.id
      })
      dispatch(setSelectedListIds(all_ids));      
    }
  
    useEffect(() => {
      dispatch(fetchListIndividualDataListing(authToken, list_id, props.searchField, pageNum, show_enteries));
    }, []);
    
    const handleOptionChange = async(event) => {
        setSelectedOption(event.target.value);
        await dispatch(setshowEnteries(event.target.value))
        dispatch(fetchListIndividualDataListing(authToken, list_id, props.searchField, pageNum, event.target.value));
    };    
   
    return (
      <div className='list-campaign'>
        {isLoading && <Loading />}
        <div className={"hashtag-list-title-card list-custom mb-0"}>
          {selectedCrawlUrls?.length > 0 && (
            <Row>
              <Col>
                <div className="selectedAssetActions d-flex justify-content-between align-items-center">
                  <ul>
                    <li>
                      <Button variant="light selected">
                        {selectedCrawlUrls?.length} Selected
                      </Button>
                    </li>
                    {/* <li>
                      <Button variant="light">
                        <span>
                          <Icon.Folder />
                        </span>
                        Add to Group
                      </Button>
                    </li> */}
                  </ul>
                    <ul className="">
                      <li>
                      <Button
                        variant="light selected"
                        onClick={() => {
                          selectAllHashtag()
                        }}
                      >
                        Select All
                      </Button>
                      </li>
                      <li>
                        <Button
                          variant="light selected"
                          onClick={unselectData}
                        >
                          Unselect
                        </Button>
                      </li>
                    </ul>
                </div>
              </Col>
            </Row>
          )}
          <Table>
          <tbody>
              <tr>
                <td style={{ width: "5%", textAlign: "center" }}></td>
              {props.tableData === "gSearch-right" && <td className="hashtag-name" style={{ width: "5%", textAlign: "center" }}>
                  {props.index}.
              </td>}
              <td className="hashtag-name-head">
                  <span>Name</span>
                </td>
              <td style={{ width: "25%" }}>
                  <span className="bordering-custom date-style">
                  Email
                  </span>
              </td> 
              <td style={{ width: "25%" }}>
                  <span className="bordering-custom date-style">
                  Phone Number
                  </span>
              </td>
              <td>
                <div className="bordering-custom date-style">
                    Company
                </div>
              </td>
              </tr>
          </tbody>
          </Table>
      </div>
        {individual_contact_list?.length > 0 && individual_contact_list.map((listItem,index) => {
          return (
            <Col key={index}>
                <SelectTable tableData="contact_list" page={pageNum} authToken={props.authToken} handleUrlCheckboxChange={ handleUrlCheckboxChange } selectedCrawlUrls={selectedCrawlUrls} listItem={listItem}/>
            </Col>
          )
        })}
        {individual_contact_list?.length === 0 && (
          <div className="no-data asset">
            <span>
              <Icon.Inbox />
            </span>
            <br />
            No Data
          </div>
        )}
        <div className='d-flex align-items-center justify-content-between'>    
            {total_data > parseInt(show_enteries) && <Pagination className="mt-3" authToken={props.authToken} selectedOption={selectedOption} total_page={ total_data } page={pageNum} currPage={props.subPageName} />}
            <div className="show-entry mb-3">
                <span>Show</span>
                <select
                    value={show_enteries}
                    onChange={handleOptionChange}
                >
                <option value="20">20</option>
                <option value="50">50</option>
                <option value="100">100</option>
                <option value="250">250</option>
                <option value="500">500</option>
                <option value="1000">1000</option>
                </select>
                <span>Entries</span>
            </div>
        </div>
      </div>
    )
}

export default ContactList;