/* eslint-disable */
import { Col, Row, Stack, Form, Button, ButtonGroup, InputGroup, Modal, FormGroup, Overlay, Popover } from 'react-bootstrap';
import React, { useState, useEffect, useRef } from "react";
import * as Icon from "react-bootstrap-icons";
import "./tab-components/Leadtabs.css";
import "bootstrap/dist/css/bootstrap.min.css";
import ToggleButton from 'react-bootstrap/ToggleButton';
import ToggleButtonGroup from 'react-bootstrap/ToggleButtonGroup';
import { useParams } from 'react-router';
import { useDispatch } from 'react-redux';
import { addTask, fetchTaskList, taskManager } from '../../../../../redux/actions/crmActions';
import DatePicker from 'sassy-datepicker';
import moment from 'moment';
import Toast from '../../../../shared/Toast';
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

const AddTask = (props) => {
    const { contactId, from, setDefault, authToken } = props;

    const { id, name, taskId } = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const ref = useRef();
    const taskList = useSelector((state) => state.crmLeads.tasksList);
    const stagesList = useSelector((state) => state.crmLeads.stagesList);


    const [title, setTitle] = useState("");
    const [note, setNote] = useState("");
    const [date, setDate] = useState("");
    const [time, setTime] = useState("");
    const [remindMe, setRemindMe] = useState("");
    const [customDate, setCustomDate] = useState("");
    const [modal, setModal] = useState(false);
    const [page, setPage] = useState("Add");
    const [radio, setRadio] = useState('2');

    const [datePickerVal, setDatePickerVal] = useState(new Date());

    const [dateTime, setDateTime] = useState("");

    const [target, setTarget] = useState(null);

    const [hours, setHour] = useState(null);
    const [minutes, setMinutes] = useState(null);
    const [meridien, setMeridien] = useState("AM");

    let hoursRef = useRef(0);
    let minutesRef = useRef(0);

    const remind = [
        { name: "None", value: "" },
        { name: "10 minutes before", value: "10" },
        { name: "20 minutes before", value: "20" },
        { name: "40 minutes before", value: "40" }
    ];

    const day = [
        { name: "Today", value: '1' },
        { name: "Tomorrow", value: '2' },
        { name: "Custom", value: '3' },
    ];


    useEffect(() => {
        //console.log("ad", datePickerVal);

        if (name === 'edit') {
            setPage('Edit');
            dispatch(fetchTaskList(authToken, id));
            let itemm = taskList.find(item => {
                if (item.id == taskId) {
                    return item;
                }
            });
            //console.log("edit task find", itemm);

            setTitle(itemm.title);
            setNote(itemm.notes);


            setRadio('3');
            if (itemm.due_date !== '0000-00-00 00:00:00' || itemm.due_date !== null || itemm.due_date !== "") {
                let dt = moment(itemm.due_date, 'YYYY-MM-DD HH:mm:ss').format('ddd MMM DD YYYY hh:mm:ss A');
                setCustomDate(moment(itemm.due_date, 'YYYY-MM-DD HH:mm:ss').format('DD-MM-YYYY'));
                setDateTime(moment(itemm.due_date, 'YYYY-MM-DD HH:mm:ss').format('YYYY-MM-DD HH:mm'));
                setHour(moment(dt, 'ddd MMM DD YYYY hh:mm A').format('hh'));
                setMinutes(moment(dt, 'ddd MMM DD YYYY hh:mm A').format('mm'));
                hoursRef.current = +moment(dt, 'ddd MMM DD YYYY hh:mm A').format('hh');
                minutesRef.current = +moment(dt, 'ddd MMM DD YYYY hh:mm A').format('mm');
                setMeridien(moment(dt, 'ddd MMM DD YYYY hh:mm A').format('A'));
                setDatePickerVal(new Date(moment(itemm.due_date, 'YYYY-MM-DD HH:mm:ss').format('MMM DD, YYYY HH:mm:ss')));
            } else {
                let dt = moment(new Date()).format('ddd MMM DD YYYY hh:mm:ss A');
                setCustomDate(moment(new Date()).format('DD-MM-YYYY'));
                setDateTime(moment(new Date()).format('YYYY-MM-DD HH:mm'));
                setHour(moment(dt, 'ddd MMM DD YYYY hh:mm A').format('hh'));
                setMinutes(moment(dt, 'ddd MMM DD YYYY hh:mm A').format('mm'));
                hoursRef.current = +moment(dt, 'ddd MMM DD YYYY hh:mm A').format('hh');
                minutesRef.current = +moment(dt, 'ddd MMM DD YYYY hh:mm A').format('mm');
                setMeridien(moment(dt, 'ddd MMM DD YYYY hh:mm A').format('A'));
                setDatePickerVal(new Date(moment(new Date()).format('MMM DD, YYYY HH:mm:ss')));
            }
            // setDatePickerVal(null);
            //setDatePickerVal('Thu Aug 08 2022 14:13:05 GMT+0530 (India Standard Time)');
            //'Sept 24, 22 13:20:18'
            // console.log("sd", moment(itemm.due_date, 'YYYY-MM-DD HH:mm:ss').format('MMM DD, YYYY HH:mm:ss'));
            let d = moment(itemm.due_date, 'YYYY-MM-DD HH:mm:ss').format('HH:mm');
            let r = moment(itemm.reminder_date, 'YYYY-MM-DD HH:mm:ss').format('HH:mm');
            let diff = moment.utc(moment(d, "HH:mm").diff(moment(r, "HH:mm"))).format("mm");
            setRemindMe(diff);
        } else if (name === 'add') {
            setPage('Add');
            setDatePickerVal(new Date());
        }
    }, [])

    const handleDatee = (newDate) => {
        let y = newDate.getFullYear();
        let m = newDate.getMonth() + 1;
        let d = newDate.getDate();
        let cd = d + '-' + m + '-' + y;
        setCustomDate(cd);
    }

    const handleTimeFormats = (e, val) => {
        if (e && val === 'h') {
            if (e > 12) {
                Toast("Please enter a valid hours", "E");
            } else {
                setHour(e);
                hoursRef.current = e;
                console.log("asa", hours);
            }
        }
        else if (e && val === 's') {
            if (e > 59) {
                Toast("Please enter a valid minutes", "E");
            } else {
                setMinutes(e);
                minutesRef.current = e;
            }
        }
    }


    const handleSubmit = (e) => {
        e.preventDefault();
        //console.log("form data", "id", id, "title",title,  "note",note, "date",date, "remind",remind);
        /* let d = new Date();
        let datee = "";
        if (date === '1') {
            datee = `${d.getDate()}-${d.getMonth()}-${d.getFullYear()}`;
        } else if (date === '2') {
            datee = `${d.getDate() + 1}-${d.getMonth()}-${d.getFullYear()}`;
        } else if (date === '3') {
            if (customDate) {
                datee = `${customDate.day}-${customDate.month}-${customDate.year}`;
            }
        }

        let dd = time !== "" ? handleDate(time) : "";
        let ddd = dd ? (datee + " " + dd.hours + ":" + dd.minutes + " " + dd.meridian) : ""; */

        let tt = time;
        console.log("hmm", time);
        if (meridien == "PM") {
            tt = moment(time, ["h:mm A"]).format("HH:mm A");
        }

        console.log("remindMe", remindMe);
        let r = "";
        if (remindMe == "10") {
            r = moment(tt, 'HH:mm A').subtract(10, 'minutes').format("HH:mm A");
        } else if (remindMe == '20') {
            r = moment(tt, 'HH:mm A').subtract(20, 'minutes').format("HH:mm A");
        } else if (remindMe == "40") {
            r = moment(tt, 'HH:mm A').subtract(40, 'minutes').format("HH:mm A");
        } else {
            r = "";
        }

        console.log("r", r);
        let rr = r !== "" ? handleTime(r) : "";
        let rm = rr !== "" ? (date + " " + moment(rr.hours + ":" + rr.minutes + " " + rr.meridian, 'hh:mm A').format('HH:mm')) : "";
        console.log("rm", rm, moment(rm, 'hh:mm A').format('HH:mm'));

        if (page === 'Add') {
            if (contactId?.contact_ids?.length > 0) {
                let associatedIds = [];

                contactId.contact_ids.map((item) => {
                    associatedIds.push(item.contact_id);
                });
                dispatch(addTask(authToken, associatedIds, title, note, dateTime, rm, stagesList,"lpage"));
            } else if (id && id !== undefined || id === null) {
                dispatch(addTask(authToken, id, title, note, dateTime, rm, null, "dpage"));
                navigate(`/crm/leads/${id}`);
            }
        } else if (page === 'Edit') {
            let body = {
                due_date: dateTime,
                reminder_date: rm,
                action: "add",
                associated_as: "1",
                id: taskId,
                notes: note,
                title: title,
                task_category: "Task"
            };
            body["associated_with"] = contactId ? contactId.contact_ids[0].contact_id : id;
            dispatch(taskManager(authToken, body, "editTask", contactId ? contactId.contact_ids[0].contact_id : id));
            navigate(`/crm/leads/${id}`);
        }

        setTitle(""); setNote(""); setDate(""); setTime(""); setCustomDate(""); setRemindMe("");
        //setHour(""); setMinutes(""); 
        // hours = null;
        // minutes = null;
        setDateTime("");
        setMeridien("AM");
        if (from == 'bulk') {
            setDefault();
        }
    };

    const handleTime = (e) => {
        console.log("e", e);

        var timeSplit = e.split(':'),
            hours,
            minutes,
            meridian;
        hours = timeSplit[0];
        minutes = timeSplit[1];
        if (hours > 12) {
            meridian = 'PM';
            hours -= 12;
        } else if (hours < 12) {
            meridian = 'AM';
            if (hours == 0) {
                hours = 12;
            }
        } else {
            meridian = 'PM';
        }
        console.log("hours", hours, meridian);
        return { hours, minutes, meridian }

    }


    const modalSubmit = (e) => {
        e.preventDefault();
        console.log('ddd', date, customDate);

        if (radio == '') {
            Toast("Please add Date", "E");
        } else if (radio == 3 && customDate == "") {
            Toast("Please add Custom Date", "E");
        } else if (hours == "" && minutes == "") {
            Toast("Please add Hours and Minutes", "E");
        } else {
            let d = new Date();
            let datee = "";
            if (radio === '1') {
                datee = `${d.getFullYear()}-${d.getMonth() + 1}-${d.getDate()}`;
            } else if (radio === '2') {
                datee = `${d.getFullYear()}-${d.getMonth() + 1}-${d.getDate() + 1}`;
            } else if (radio === '3') {
                console.log("", customDate);
                if (customDate !== "") {
                    datee = moment(customDate, 'DD-MM-YYYY').format('YYYY-MM-DD');
                    let d = datee + " " + hours + ":" + minutes + ":" + '00';
                    setDatePickerVal(new Date(moment(d, 'YYYY-MM-DD hh:mm:ss A').format('MMM DD, YYYY HH:mm:ss')))
                }
            } console.log("datee", datee);
            setDate(datee);

            let t = hours + ":" + minutes + " " + meridien;
            setTime(t);

            let dt = datee + " " + moment(t, 'hh:mm A').format('HH:mm');
            setDateTime(dt);
            setModal(false);

        }
    }

    const openModal = () => {
        // console.log(dateTime);
        // if (dateTime !== "") {
        //     //setCustomDate(moment(dateTime, 'YYYY-MM-DD HH:mm').format('YYYY-MM-DD hh:mm'));
        //     setDateTime(moment(dateTime, 'YYYY-MM-DD HH:mm').format('YYYY-MM-DD hh:mm'));
        //     setHour(moment(dateTime, 'YYYY-MM-DD HH:mm').format('hh'));
        //     setMinutes(moment(dateTime, 'YYYY-MM-DD HH:mm').format('mm'));
        //     setMeridien(moment(dateTime, 'YYYY-MM-DD HH:mm').format('A'));
        //     setDatePickerVal(new Date(moment(dateTime, 'YYYY-MM-DD HH:mm').format('MMM DD, YYYY HH:mm:ss')));
        // }

        // console.log("sa", customDate, dateTime);
        setModal(true);
    }

    return (
        <>
            <div className="mt-2 pa-1 offcanvas-body-background"  > {/*  offcanvas-body-background */}

                {from !== 'bulk' && (
                    <>
                        <div className="fs-5 fw-bold">{page} Task</div>
                        <hr />
                    </>
                )}
                <Form onSubmit={handleSubmit} className="p-3">  {/* noValidate validated={validated}  */}
                    <Form.Group className="mb-3" controlId="title">
                        <Form.Control required type="text" value={title} placeholder="Task Title" onChange={(e) => setTitle(e.target.value)} />
                        {/* <Form.Control.Feedback type="invalid">
                            Please Enter a Title.
                        </Form.Control.Feedback> */}
                    </Form.Group>
                    <Form.Group controlId="note">
                        <Form.Control as="textarea" rows="3" name="note" placeholder="Note" value={note} onChange={(e) => setNote(e.target.value)} />
                        {/* <Form.Control.Feedback type="invalid">
                            Please Enter a Description.
                        </Form.Control.Feedback> */}
                    </Form.Group>

                    <Row>
                        <Col>
                            <div ref={ref}>
                                <InputGroup className="mb-3 mt-3">
                                    <InputGroup.Text id="basic-addon1">
                                        <Icon.Calendar4 style={{ color: '#45BCD2', fontSize: 25, padding: 4 }} />
                                    </InputGroup.Text>
                                    <Form.Control
                                        //type='datetime-local'
                                        required
                                        controlId="date"
                                        placeholder="Date and Time"
                                        aria-label="Date and Time"
                                        aria-describedby="basic-addon1"
                                        value={dateTime}
                                        //readOnly
                                        onClick={(event) => {
                                            //openModal()
                                            setModal(!modal);
                                            setTarget(event.target);
                                        }}
                                    />

                                    <Overlay
                                        show={modal}
                                        target={target}
                                        placement="bottom"
                                        container={ref}
                                        className="my-overlay"
                                    >
                                        <Popover id="popover-contained">
                                            <Popover.Body>
                                                <div className='p-3'>
                                                    <Row>
                                                        <ButtonGroup className="mb-2 btnGroup">
                                                            {day.map((datee, idx) => (
                                                                <ToggleButton
                                                                    className={radio === datee.value ? 'active-custom-radio' : 'custom-radio'}
                                                                    key={idx}
                                                                    id={`date-${idx}`}
                                                                    type="radio"
                                                                    name="date"
                                                                    value={datee.value}
                                                                    checked={radio === datee.value}
                                                                    onChange={(e) => {
                                                                        setCustomDate("");
                                                                        setRadio(e.currentTarget.value);
                                                                    }}
                                                                >
                                                                    {datee.name}
                                                                </ToggleButton>
                                                            ))}
                                                        </ButtonGroup>
                                                    </Row>
                                                    {radio == "3" &&
                                                        <Row className='mb-2'>
                                                            <Col sm lg={5} className='customDate'>
                                                                <DatePicker
                                                                    initialDate={datePickerVal}
                                                                    onChange={handleDatee}
                                                                    // minDate={new Date(2021, 10, 16)}
                                                                    className="absolute mt-2"
                                                                    selected={datePickerVal}
                                                                />
                                                            </Col>
                                                        </Row>
                                                    }
                                                    <Row>
                                                        <Col>
                                                            <FormGroup controlId='hour'>
                                                                <Form.Control className="hours" type="number" placeholder='Hours' min={0} max={12} maxLength={2}
                                                                    ref={hoursRef} value={hours} onChange={(e) => handleTimeFormats(e.target.value, 'h')} />
                                                            </FormGroup> {/* value={hours}  */}
                                                        </Col>
                                                        <Col>
                                                            <FormGroup controlId='seconds'>
                                                                <Form.Control className="seconds" type="number" placeholder='Minutes' min={0} max={60} maxLength={2}
                                                                    ref={minutesRef} value={minutes} onChange={(e) => handleTimeFormats(e.target.value, 's')} />
                                                            </FormGroup> {/*  value={minutes}  */}
                                                        </Col>
                                                        <Col>
                                                            <FormGroup controlId='meridien'>
                                                                <Form.Select
                                                                    controlId="meridien"
                                                                    className="meridien-select"
                                                                    as="select"
                                                                    value={meridien}
                                                                    onChange={e => {
                                                                        setMeridien(e.target.value);
                                                                    }}
                                                                >
                                                                    <option value="AM">AM</option>
                                                                    <option value="PM">PM</option>
                                                                </Form.Select>
                                                            </FormGroup>
                                                        </Col>
                                                    </Row>
                                                    <Button type='button' onClick={modalSubmit} className='submit-btn mt-2 d-flex ms-auto'>OK</Button>
                                                </div>
                                            </Popover.Body>
                                        </Popover>
                                    </Overlay>

                                </InputGroup>
                            </div>
                        </Col>
                        <Col>
                            <InputGroup className="mb-3 mt-3">
                                <InputGroup.Text id="basic-addon1">
                                    <Icon.Alarm style={{ color: '#45BCD2', fontSize: 25, padding: 4 }} />
                                </InputGroup.Text>
                                <Form.Select
                                    controlId="remind"
                                    className="reminder-select"
                                    as="select"
                                    aria-placeholder='Reminder'
                                    value={remindMe}
                                    onChange={e => {
                                        setRemindMe(e.target.value);
                                    }}
                                >
                                    {remind.map((r, i) => {
                                        return (
                                            <option key={i} value={r.value}>{r.name}</option>
                                        )
                                    })}
                                </Form.Select>
                            </InputGroup>
                        </Col>
                    </Row>
                    {/*  <Form.Group >
                        <Form.Label>Date</Form.Label>
                         <Row>
                            <ButtonGroup className="mb-2 btnGroup">
                                {day.map((datee, idx) => (
                                    <ToggleButton
                                        className={date === datee.value ? 'active-custom-radio' : 'custom-radio'}
                                        key={idx}
                                        id={`date-${idx}`}
                                        type="radio"
                                        name="date"
                                        value={datee.value}
                                        checked={date === datee.value}
                                        onChange={(e) => {
                                            setCustomDate("");
                                            setDate(e.currentTarget.value)
                                        }}
                                    >
                                        {datee.name}
                                    </ToggleButton>
                                ))}
                            </ButtonGroup>
                        </Row>
                        <Row>
                            {date === "3" &&
                                <Row>
                                    <Col sm lg={5}>
                                        <DtPicker
                                            required
                                            showTimeInput
                                            onChange={(e) => setCustomDate(e)}
                                            placeholder="Select Date"
                                            //clearBtn={true}
                                            className="customDate"
                                        >
                                            <Icon.Alarm />
                                        </DtPicker>

                                    </Col>
                                </Row>
                            }
                        </Row> 
                    </Form.Group>

                     <Form.Group controlId="time" className='mt-2'>
                        <Form.Label>Time</Form.Label>
                        <Form.Control
                            style={{ width: 'auto' }}
                            className="customDate"
                            type="time"
                            name="time"
                            //defaultValue={this.props.selectedValue}
                            placeholder="Add Time"
                            onChange={(e) => setTime(e.target.value)} />
                    </Form.Group> 

                    <Form.Group controlId="remaind" className='mt-2'>
                        <Form.Label>Remaind me</Form.Label>
                        <Row>
                            <ButtonGroup className="mb-2 btnGroup">
                                {remind.map((radio, idx) => (
                                    <ToggleButton
                                        className={remindMe === radio.value ? 'active-custom-radio' : 'custom-radio'}
                                        key={idx}
                                        id={`remind-${idx}`}
                                        type="radio"
                                        name="remind"
                                        value={radio.value}
                                        checked={remindMe === radio.value}
                                        onChange={(e) => setRemindMe(e.currentTarget.value)}
                                    >
                                        {radio.name}
                                    </ToggleButton>
                                ))}
                            </ButtonGroup>
                        </Row>
                    </Form.Group>

                    */}


                    <Button type="submit" className="d-flex ms-auto submit-btn mt-3">
                        {page === 'Edit' ? 'Update Task' : 'Add Task'}&nbsp;&nbsp;&nbsp;
                        <Icon.ChevronRight
                            style={{
                                fontSize: 25,
                                color: '#fff',
                                padding: 5
                            }} />
                    </Button>
                </Form>
            </div>




        </>
    )

}

export default AddTask;