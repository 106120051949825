import React from "react";
import * as Icon from "react-bootstrap-icons";
import { Form, Table} from "react-bootstrap";
const DragShimmer = (props) => {
    return (
        <>
            { (props.subPageName === "category" || props.subPageName === "contentList" || props.subPageName === "levels" || props.subPageName === "businessNeed" || props.subPageName === "productType") && <div>
                {[...Array(20)].map((item,index) => {
                    return (
                        <div key={"table-1-"+index} className="table borderd m-0">
                            <div style={{ lineHeight: "35px" }} className="text-capitalize">
                                <div className="row border-bottom">
                                    {props.subPageName === "category" && <div className="drag-button">
                                        <span className="shimmerEffect" style={{ width: "35px", height: "25px" }}></span>
                                    </div>}
                                    <div className="table-row-count drag-button" style={{ fontSize: "initial" }}>
                                        <span className="shimmerEffect" style={{ width: "20px", height: "25px" }}></span>
                                    </div>
                                    <div className="text-aligning table-name">
                                        <span className="shimmerEffect" style={{ width: "160px", height: "25px" }}></span>
                                    </div>
                                    <div
                                        className={
                                            props.subPageName === "category"
                                                ? "text-aligning"
                                                : "adjust-width text-aligning"
                                        }
                                    >
                                        <span className="shimmerEffect" style={{ width: "100px", height: "25px" }}></span>
                                    </div>
                                    <div className="column-styling" style={{fontSize:"30px"}}>
                                        <span className="shimmerEffect" style={{ width: "50px", height: "25px" }}></span>
                                    </div>
                                    <div className="column-styling" style={{fontSize:"30px"}}>
                                        <span className="shimmerEffect" style={{ width: "25px", height: "25px" }}></span>
                                    </div>
                                    {props.subPageName === "category" &&  <div className="column-styling" style={{fontSize:"30px"}}>
                                        <span className="shimmerEffect" style={{ width: "20px", height: "25px" }}></span>
                                    </div>}
                                </div>
                            </div>
                        </div>)
                })}
            </div>}
            { props.subPageName === "report" && <div>
                {[...Array(20)].map((item,index) => {
                    return (
                        <div key={"report-"+index} className="table borderd m-0">
                            <div style={{ lineHeight: "35px" }} className="text-capitalize">
                                <div className="row border-bottom">
                                    <div className="report-row-count" style={{ fontSize: "initial" }}>
                                        <span className="shimmerEffect" style={{ width: "20px", height: "25px" }}></span>
                                    </div>
                                    <div className="level-styling" style={{ fontSize: "initial" }}>
                                        <span className="shimmerEffect" style={{ width: "20px", height: "25px" }}></span>
                                    </div>
                                    <div className="report-common report-name">
                                        <span className="shimmerEffect" style={{ width: "160px", height: "25px" }}></span>
                                    </div>
                                    <div className="report-common report-points" style={{fontSize:"30px"}}>
                                        <span className="shimmerEffect" style={{ width: "50px", height: "25px" }}></span>
                                    </div>
                                    <div className="report-common report-code" style={{fontSize:"30px"}}>
                                        <span className="shimmerEffect" style={{ width: "50px", height: "25px" }}></span>
                                    </div>
                                    <div className="report-common report-branch">
                                        <span className="shimmerEffect" style={{ width: "100px", height: "25px" }}></span>
                                    </div>
                                </div>
                            </div>
                        </div>)
                })}
            </div>}
            { (props.subPageName === "hashtag" || props.subPageName === "globalSearch") && <div>
                {[...Array(20)].map((item,index) => {
                    return (
                    <div key={"table-3-"+index}>
                        <Form>
                            <div className="hashtag-list-card mb-3" style={{padding:"10px 0px 5px"}}>
                                <Table>
                                <tbody>
                                    <tr>
                                    {props.tableData !== "gSearch-right" && <td style={{ width: "5%", textAlign: "center" }}>
                                        <span className="shimmerEffect" style={{ width: "35px", height: "25px" }}></span>
                                    </td> }
                                    {props.tableData === "gSearch-right" && <td className="hashtag-name" style={{ width: "5%", textAlign: "center" }}>
                                       <span className="shimmerEffect" style={{ width: "35px", height: "25px" }}></span>
                                    </td>}
                                    {props.tableData !== "partner" && 
                                    <td className={props.tableData !== "gSearch" && props.tableData !== "gSearch-left" ? "hashtag-name" : "hashtag-name w-50"}>
                                        <span className="shimmerEffect" style={{ width: "160px", height: "25px" }}></span>
                                    </td>
                                    }
                                    { props.tableData !== "gSearch-left" && props.tableData !== "gSearch-right" && props.tableData !== "gSearch" && <td style={{ width: "12%" }}>
                                        <span className="shimmerEffect" style={{ width: "100px", height: "25px" }}></span>
                                    </td> }
                                    { props.tableData === "partner" &&   <td style={{ width: "30%" }} className={props.tableData !== "gSearch" && props.tableData !== "gSearch-left" ? "hashtag-name" : "hashtag-name w-50"}>
                                        <span className="shimmerEffect" style={{ width: "160px", height: "25px" }}></span>
                                    </td> }
                                    { props.tableData === "partner" && <td style={{ width: "5%" }}>
                                        <span className="shimmerEffect" style={{ width: "80px", height: "25px" }}></span>
                                    </td> }
                                    { props.tableData === "partner" && <td style={{ width: "5%" }}>
                                        <span className="shimmerEffect" style={{ width: "50px", height: "25px" }}></span>
                                    </td> }
                                    {props.tableData !== "gSearch" && props.tableData !== "gSearch-left" && props.tableData !== "partner" && props.tableData !== "gSearch-right" && <td className="dot">
                                        <Icon.CircleFill color="#C4C4C4" />
                                    </td>}
                                    {props.tableData === "hashtag" ? 
                                        (<td className="bordering-date time-style" style={{fontSize:"30px"}}>
                                            <span className="shimmerEffect" style={{ width: "50px", height: "25px" }}></span>
                                        </td>)
                                        :
                                        (<td className={(props.tableData === "gSearch" || props.tableData === "gSearch-left" || props.tableData === "gSearch-right" ? "gsearch-align":"")+" bordering-date time-style"}>
                                           <span className="shimmerEffect" style={{ width: "100px", height: "25px" }}></span>
                                        </td>)
                                    }
                                    {props.tableData !== "gSearch" && props.tableData !== "gSearch-left" && props.tableData !== "gSearch-right" && props.tableData !== "partner" && <td style={{ justifyContent: "end" }} className="d-flex">
                                            <span className="shimmerEffect" style={{ width: "50px", height: "25px" }}></span>
                                        </td>
                                    }
                                    {props.tableData === "gSearch" || props.tableData === "partner" &&
                                        <td style={{ justifyContent: "end" }} className="d-flex">
                                            <div className="card-footer-action list-card">
                                                <span className="shimmerEffect" style={{ width: "50px", height: "25px" }}></span>
                                            </div>
                                        </td>}
                                    </tr>
                                </tbody>
                                </Table>
                            </div>
                        </Form>
                    </div>
                    )
                })}
            </div>}
            { (props.subPageName === "partner" || props.subPageName === "partnerCategory") && <div>
                {[...Array(20)].map((item,index) => {
                    return (
                    <div key={"table-4-"+index}>
                        <Form>
                            <div className="hashtag-list-card mb-3" style={{padding:"10px 0px 5px"}}>
                                <Table>
                                <tbody>
                                    <tr>
                                    <td style={{ width: "5%", textAlign: "center" }}>
                                        <span className="shimmerEffect" style={{ width: "35px", height: "25px" }}></span>
                                    </td>
                                    {props.tableData === "partner_category_list" && <td style={{ width: "22%", textAlign: "left" }} className="hashtag-name">
                                        <span className="shimmerEffect" style={{ width: "160px", height: "25px" }}></span>
                                    </td>}
                                    {props.tableData === "partner_category_list" && <td className="hashtag-name">
                                        <span className="shimmerEffect" style={{ width: "160px", height: "25px" }}></span>
                                    </td>}
                                    {props.tableData === "partner_category_list" && <td style={{ width: "10%", textAlign: "left" }} className="hashtag-name">
                                        <span className="shimmerEffect" style={{ width: "80px", height: "25px" }}></span>
                                    </td>}
                                    { props.tableData === "partner" && <td style={{ width: "20%" }}>
                                        <span className="shimmerEffect" style={{ width: "160px", height: "25px" }}></span>
                                    </td> }
                                    { props.tableData === "partner" &&   <td style={{ width: "20%" }} className="hashtag-name">
                                        <span className="shimmerEffect" style={{ width: "160px", height: "25px" }}></span>
                                    </td> }
                                    { props.tableData === "partner" && <td style={{ width: "12%" }}>
                                        <span className="shimmerEffect" style={{ width: "80px", height: "25px" }}></span>
                                    </td> }
                                    { props.tableData === "partner" && <td style={{ width: "12%" }}>
                                        <span className="shimmerEffect" style={{ width: "50px", height: "25px" }}></span>
                                    </td>}
                                    { props.tableData === "partner" && <td style={{ width: "10%" }}>
                                        <span className="shimmerEffect" style={{ width: "50px", height: "25px" }}></span>
                                    </td>}
                                    { props.tableData === "partner" && <td>
                                        <span className="shimmerEffect" style={{ width: "50px", height: "25px" }}></span>
                                    </td> }
                                    {props.tableData === "partner_category_list" && <td className="dot">
                                        <Icon.CircleFill color="#C4C4C4" />
                                    </td>}
                                    { props.tableData === "partner_category_list" && <td style={{ width: "12%" }}>
                                        <span className="shimmerEffect" style={{ width: "50px", height: "25px" }}></span>
                                    </td>}
                                    {props.tableData === "partner_category_list" && <td style={{ justifyContent: "end" }} className="d-flex">
                                            <span className="shimmerEffect" style={{ width: "50px", height: "25px" }}></span>
                                        </td>
                                    }
                                    { props.tableData === "partner" &&
                                        <td style={{ justifyContent: "end" }} className="d-flex">
                                            <div className="card-footer-action list-card">
                                                <span className="shimmerEffect" style={{ width: "50px", height: "25px" }}></span>
                                            </div>
                                        </td>}
                                    </tr>
                                </tbody>
                                </Table>
                            </div>
                        </Form>
                    </div>
                    )
                })}
            </div>}
        </>
  );
};

export default DragShimmer;
