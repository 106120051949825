/* eslint-disable */
import * as XLSX from "xlsx";


const Utils = (arr,name,type) => {

    let { sheetName, fileName } = getFileName(name);
    console.log("arr", arr);

    var wb = XLSX.utils.book_new();
    var ws = XLSX.utils.json_to_sheet(arr);
    XLSX.utils.book_append_sheet(wb, ws, sheetName);
    
    let c = XLSX.utils.sheet_to_csv(ws);
    var j = XLSX.utils.sheet_to_json(ws);
    var t = XLSX.utils.sheet_to_txt(ws);

    if (type && type == 'json') {
        setDownload(j, type);
    } else if (type && type == 'txt') {
        setDownload(t, type);
    } else if (type && type == 'xlsx') {
        XLSX.writeFile(wb, `${fileName}.xlsx`,);
    } else if (type && type == 'csv') {
        //XLSX.writeFile(wb, `${fileName}.csv`);
        setDownload(c, type, fileName);
    }

        
          
}

const getFileName = (name) => {
    //let timeSpan = new Date().toISOString();
    let sheetName = name || "Export_Template";
    let fileName = `${sheetName}`;
    return {
      sheetName,
      fileName
    };
  };


const setDownload = (data, type, filename) => {
    if (type == 'json') {
        var dataStr = "data:text/json;charset=utf-8," + encodeURIComponent(JSON.stringify(data));
    } else if (type === 'txt') {
        var dataStr = "data:text/txt;charset=utf-8," + encodeURIComponent(data);
    } else {
        var dataStr = "data:text/csv;charset=utf-8," + encodeURIComponent(data);
    }
    var downloadAnchorNode = document.createElement('a');
    downloadAnchorNode.setAttribute("href", dataStr);
    downloadAnchorNode.setAttribute("download", filename+"." + type);
    document.body.appendChild(downloadAnchorNode); // required for firefox
    downloadAnchorNode.click();
    downloadAnchorNode.remove();

}

export default Utils;