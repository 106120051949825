/* eslint-disable */
import { useRef, useState, useEffect, useContext } from "react";
import AuthContext from "../../../context/AuthProvider";
import axios from "axios";
import Page from "../../Page";
import { Container, Row, Col, Form, Button, Nav, Alert } from "react-bootstrap";
import * as Icon from "react-bootstrap-icons";
import logo from "../../../assets/logo.png";
import "./Login.css";
import { useNavigate, Link } from "react-router-dom";

import { useSelector, useDispatch } from "react-redux";
import { fetchResetPasswordDetails, fetchCompanyLogo } from "../../../redux/actions/actions";

let md5 = require("md5");

const Login = (props) => {
  const authCtx = useContext(AuthContext);
  const userRef = useRef();

  const newPassRef = useRef();
  const confirmPassRef = useRef();

  const LOGIN_URL = authCtx.loginUrl;
  const FORGOT_PASSWORD_URL = authCtx.forgotPasswordUrl;
  const GET_OTP_URL = authCtx.getOtpUrl;
  const OTP_LOGIN_URL = authCtx.otpLoginUrl;
  const GENERATE_PASSWORD_URL = authCtx.generatePasswordUrl;

  const resetPassDetails = useSelector(
    (state) => state.headerTopMenus.resetPassDetails
  );

  const company_logo = useSelector((state) => state.headerTopMenus.companyLogo);

  let navigate = useNavigate();
  const dispatch = useDispatch();

  const [buttonLoader, setButtonLoader] = useState(false);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [errMsg, setErrMsg] = useState("");

  const [newPassword, setNewPassword] = useState(null);
  const [confirmPassword, setConfirmPassword] = useState(null);

  const [show, setShow] = useState(false);
  const [authToken, setAuthToken] = useState("");

  const [loginOption, setLoginOption] = useState(1);
  const [resetPassword, setResetPassword] = useState(false);
  const [success, setSuccess] = useState(false);

  const [otpStatus, setOtpStatus] = useState(false);
  const [contact, setContact] = useState(null);
  const [saveUsername, setSaveUsername] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    setErrMsg("");
    setButtonLoader(true);
    if (!resetPassword) {

      if (loginOption === 3) {
        if (otpStatus) {
          try {
            const response = await axios.post(OTP_LOGIN_URL, {
              otp: username,
              username: contact
            });
            if (response?.data?.statusCode === "401") {
              setShow(true);
              setErrMsg(response?.data?.message);
            } else if (response?.data?.statusCode === "1012") {
              setShow(true);
              setErrMsg(
                "Login Failed..! You have reached maximum attempt limit. Please try after 3hrs."
              );
            } else if (response?.data?.statusCode === "1008") {
              setShow(true);
              setErrMsg("Unauthorized");
            } else {
              setShow(false);
              setErrMsg(" ");
              authCtx.onLogin(response?.data);
            }
            setUsername("");
            setButtonLoader(false);
          } catch (err) {
            if (!err?.response) {
              setShow(true);
              setErrMsg("No Server Response");
            } else if (err.response?.status === 400) {
              setShow(true);
              setErrMsg("Invalid OTP");
            } else if (err.response?.status === 401) {
              setShow(true);
              setErrMsg("Unauthorized");
            } else {
              setShow(true);
              setErrMsg("Login Failed");
            }
            setButtonLoader(false);
          }
        } else {
          try {
            const response = await axios.post(GET_OTP_URL, {
              username: username,
            });
            if (response?.data?.statusCode === "200") {
              //console.log("response?.data", response?.data);
              setContact(response?.data.contact);
              setShow(true);
              setSuccess(true);
              setOtpStatus(true);
              setErrMsg(response?.data?.message);
            } else if (response?.data?.statusCode === "403") {
              setShow(true);
              setOtpStatus(true);
              setErrMsg(response?.data?.message);
            }
            setSaveUsername(username);
            setUsername("");
            // setPassword("");
            setButtonLoader(false);
          } catch (err) {
            //console.log("err.response?.status", err.response?.status);
            setButtonLoader(false);
          }
        }
      } else {
        const currTime = new Date();
        let encryptedPass = window.btoa(md5(md5(password)));
        let finalEncrytedPass =
          currTime.getTime() + "//" + window.btoa(encryptedPass);
        try {
          const response = await axios.post(LOGIN_URL, {
            email: username,
            password: finalEncrytedPass,
          });

          if (response?.data?.statusCode === "401") {
            setShow(true);
            setErrMsg(response?.data?.message);
          } else if (response?.data?.statusCode === "1012") {
            setShow(true);
            setErrMsg(
              "Login Failed..! You have reached maximum attempt limit. Please try after 3hrs."
            );
          } else if (response?.data?.statusCode === "1008") {
            setShow(true);
            setErrMsg("Unauthorized");
          } else {
            setShow(false);
            setErrMsg(" ");
            authCtx.onLogin(response?.data);
          }
          setUsername("");
          setPassword("");
          setButtonLoader(false);
        } catch (err) {
          if (!err?.response) {
            setShow(true);
            setErrMsg("No Server Response");
          } else if (err.response?.status === 400) {
            setShow(true);
            setErrMsg("Invalid Username or Password");
          } else if (err.response?.status === 401) {
            setShow(true);
            setErrMsg("Unauthorized");
          } else {
            setShow(true);
            setErrMsg("Login Failed");
          }
          setButtonLoader(false);
        }
      }
    } else {
      try {
        const response = await axios.post(FORGOT_PASSWORD_URL, {
          email: username,
        });
        if (response?.data?.statusCode === "200") {
          //console.log("response?.data", response?.data);
          setShow(true);
          setSuccess(true);
          setErrMsg(response?.data?.message);
        } else if (response?.data?.statusCode === "403") {
          setShow(true);
          setErrMsg(response?.data?.message);
        }
        setUsername("");
        setPassword("");
        setButtonLoader(false);
      } catch (err) {
        setButtonLoader(false);
        //console.log("err.response?.status", err.response?.status);
      }
    }
  };

  const handleResetPassSubmit = async (e) => {
    e.preventDefault();
    setErrMsg("");

    if (newPassword === confirmPassword) {
      try {
        const response = await axios.post(GENERATE_PASSWORD_URL, {
          generatePassword: "Submit",
          password: newPassword,
          re_password: confirmPassword,
          emailID: resetPassDetails.response.email,
        });
        if (response?.data?.statusCode === "200") {
          //console.log("response?.data", response?.data);
          setShow(true);
          setSuccess(true);
          setErrMsg(response?.data?.message);
        } else if (response?.data?.statusCode === "403") {
          setShow(true);
          setErrMsg(response?.data?.message);
        }
      } catch (err) {
        //console.log("err.response?.status", err.response?.status);
      }
    } else {
      setShow(true);
      setErrMsg("Password Mismatch! Please try again.");
    }

  };

  const resendOTPHandler = async () => {
    setShow(false);
    setSuccess(false);
    try {
      const response = await axios.post(GET_OTP_URL, {
        username: saveUsername,
      });
      if (response?.data?.statusCode === "200") {
        //console.log("response?.data", response?.data);
        setContact(response?.data.contact);
        setShow(true);
        setSuccess(true);
        setOtpStatus(true);
        setErrMsg(`New ${response?.data?.message}`);
      } else if (response?.data?.statusCode === "403") {
        setShow(true);
        setOtpStatus(true);
        setErrMsg(response?.data?.message);
      }
    } catch (err) {
      //console.log("err.response?.status", err.response?.status);
    }
  }

  // if(localStorage.getItem("tokenLogin")) {
  //   console.log("localStorage.getItem.SET");
  //   navigate("/token-login");
  // } else {
  //   console.log("localStorage.getItem.NOT-SET");
  // }

  const resetPass = localStorage.getItem("resetPassword");

  useEffect(() => {
    //userRef.current.focus();
    setAuthToken(authCtx.auth);

    // if (localStorage.getItem("resetPassword")) {
    //   newPassRef.current.focus();
    //   //console.log("navigateRESET");
    // } else {
    //   userRef.current.focus();
    // }
  }, [setAuthToken, authCtx.auth, authToken]);


  console.log("logo", company_logo);

  return (
    <>
      {resetPassDetails &&
        resetPassDetails.response.email &&
        resetPassDetails.response.resetkey ? (
        <Page title={props.title || "SalesPanda"}>
          <Row>
            <Col className="col-lg-4 col-md-6 col-sm-8 col-12" style={{ margin: "auto" }}>
              <Row style={{ textAlign: "center" }}>
                <Col>
                  <br />
                  <br />
                  <img src={company_logo?.logo ? company_logo?.logo : logo} alt="Company Logo" className="login-company-logo"/>
                  <br />
                  <br />
                </Col>
              </Row>
              <Row style={{ textAlign: "center" }}>
                <Col>
                  <br />
                  {success ? (
                    <h3 className="title">Login with new password</h3>
                  ) : (
                    <h3 className="title">Generate New Password</h3>
                  )}
                  <br />
                </Col>
              </Row>
              <Container className="login">
                <Row>
                  <Col>
                    {show && (
                      <Alert
                        variant={success ? "success" : "danger"}
                        onClose={() => {
                          setShow(false);
                          setSuccess(false);
                          setNewPassword("");
                          setConfirmPassword("");
                        }}
                        dismissible
                      >
                        <Alert.Heading>
                          <Icon.ExclamationTriangle />
                        </Alert.Heading>
                        <p>{errMsg}</p>
                      </Alert>
                    )}

                    <Form onSubmit={handleResetPassSubmit} >
                      {success ? (
                        <Button
                          variant="info"
                          type="button"
                          onClick={() => {
                            dispatch(fetchResetPasswordDetails(null));
                            localStorage.clear();
                            window.location.reload();
                          }}
                          style={{ width: "100%" }}
                        >
                          Go to Login
                        </Button>
                      ) : (
                        <>
                          <Form.Group className="mb-3 position-relative" controlId="newPassword">
                            <Form.Control
                              type={showPassword != true ? "password":"text"}
                              ref={newPassRef}
                              autoComplete="off"
                              onChange={(e) => setNewPassword(e.target.value)}
                              value={newPassword}
                              required
                              placeholder="Enter New Password"
                            />
                            {showPassword != true ? <Icon.EyeSlash onClick={()=>{setShowPassword(true)}} className="cursor-pointer eye-password"/>:<Icon.EyeFill  onClick={()=>{setShowPassword(false)}} className="cursor-pointer eye-password-active"/>}    
                          </Form.Group>
                          <Form.Group
                            className="mb-3 position-relative"
                            controlId="confirmPassword"
                          >
                            <Form.Control
                              type={showPassword != true ? "password":"text"}
                              ref={confirmPassRef}
                              autoComplete="off"
                              onChange={(e) =>
                                setConfirmPassword(e.target.value)
                              }
                              value={confirmPassword}
                              required
                              placeholder="Confirm New Password"
                            />
                            {showPassword != true ? <Icon.EyeSlash onClick={()=>{setShowPassword(true)}} className="cursor-pointer eye-password"/>:<Icon.EyeFill  onClick={()=>{setShowPassword(false)}} className="cursor-pointer eye-password-active"/>}    
                          </Form.Group>

                          <Nav as="ul" className="mb-4">
                            <Nav.Item as="li">
                              <Nav.Link
                                as={Link}
                                to="/"
                                onClick={() => {
                                  setResetPassword(false);
                                  localStorage.clear();
                                  window.location.reload();
                                }}
                              >
                                Go to Login
                              </Nav.Link>
                            </Nav.Item>
                          </Nav>

                          <Button variant="info" type="submit" style={{ width: "100%" }}>
                            Submit
                          </Button>
                        </>
                      )}
                    </Form>
                  </Col>
                </Row>
              </Container>
            </Col>
          </Row>
        </Page>
      ) : (
        <Page title={props.title}>
          <Row>
            <Col className="col-lg-4 col-md-6 col-sm-8 col-12" style={{ margin: "auto" }}>
              <Row style={{ textAlign: "center" }}>
                <Col>
                  <br />
                  <br />
                  {!resetPassword ? (
                    <img src={company_logo?.logo ? company_logo?.logo : logo} alt="Company Logo" />
                  ) : ""}
                  <br />
                  <br />
                </Col>
              </Row>
              <Row style={{ textAlign: "center" }}>
                <Col>
                  <br />
                  {resetPassword ? (
                    <h3 className="title">Reset Password</h3>
                  ) : (
                    <h3 className="title">Login</h3>
                  )}
                  <br />
                </Col>
              </Row>
              <Container className="login">
                <Row>
                  <Col>
                    {show && (
                      <Alert
                        variant={success ? "success" : "danger"}
                        onClose={() => {
                          setShow(false);
                          setSuccess(false);
                        }}
                        dismissible
                      >
                        <Alert.Heading>
                          <Icon.ExclamationTriangle />
                        </Alert.Heading>
                        <p>{errMsg}</p>
                      </Alert>
                    )}

                    <Form onSubmit={handleSubmit}>
                      {!resetPassword && (
                        <>
                          <Form.Group
                            className="mb-4 radio-btn"
                            controlId="havePassword"
                          >
                            <Form.Check
                              type="radio"
                              label="I have a password"
                              name="loginOption"
                              checked={loginOption === 1 ? true : false}
                              onChange={() => { setLoginOption(1); setOtpStatus(false); setUsername(""); }}
                            />
                          </Form.Group>

                          <Form.Group
                            className="mb-4 radio-btn"
                            controlId="sendOTP"
                          >
                            <Form.Check
                              type="radio"
                              label="Send OTP"
                              name="loginOption"
                              checked={loginOption === 3 ? true : false}
                              onChange={() => { setLoginOption(3); setUsername(""); }}
                            />
                          </Form.Group>
                          {/* <Form.Group className="mb-4 radio-btn" controlId="haveToken">
                  <Form.Check
                    type="radio"
                    label="I have a token"
                    name="loginOption"
                    checked={loginOption === 2 ? true : false}
                    onChange={() => setLoginOption(2)}
                  />
                </Form.Group> */}
                        </>
                      )}

                      {resetPassword && (
                        <div className="mb-3" style={{ textAlign: "left" }}>
                          Enter your registered email-id to reset password.
                        </div>
                      )}

                      {otpStatus ? <Form.Group className="mb-3" controlId="username">
                        <Form.Control
                          type="text"
                          ref={userRef}
                          autoComplete="off"
                          onChange={(e) => setUsername(e.target.value)}
                          value={username}
                          required
                          placeholder={"Enter OTP"}
                        />
                      </Form.Group> : <Form.Group className="mb-3" controlId="username">
                        <Form.Control
                          type="text"
                          ref={userRef}
                          autoComplete="off"
                          onChange={(e) => setUsername(e.target.value)}
                          value={username}
                          required
                          placeholder={
                            loginOption === 1
                              ? "Email address"
                              : loginOption === 2
                                ? "Token"
                                : "Email address or Mobile number"
                          }
                        />
                      </Form.Group>}

                      {loginOption === 1 && !resetPassword && (
                        <Form.Group className="mb-3 position-relative" controlId="password">
                          <Form.Control
                            type={showPassword != true ? "password":"text"}
                            onChange={(e) => setPassword(e.target.value)}
                            value={password}
                            required
                            placeholder="Password"
                          />
                          {showPassword != true ? <Icon.EyeSlash onClick={()=>{setShowPassword(true)}} className="cursor-pointer eye-password"/>:<Icon.EyeFill  onClick={()=>{setShowPassword(false)}} className="cursor-pointer eye-password-active"/>}  
                        </Form.Group>
                      )}

                      {!resetPassword ? (
                        <Nav as="ul" className="mb-4">
                          <Nav.Item as="li">
                            <Nav.Link
                              as={Link}
                              to="/"
                              onClick={() => {
                                if (loginOption === 3) {
                                  resendOTPHandler();
                                } else {
                                  setResetPassword(true);
                                }
                              }}
                            >
                              {loginOption === 1
                                ? "Forgot Password"
                                : loginOption === 2
                                  ? "Get New Token"
                                  : "Resend OTP"}
                            </Nav.Link>
                          </Nav.Item>
                        </Nav>
                      ) : (
                        <Nav as="ul" className="mb-4">
                          <Nav.Item as="li">
                            <Nav.Link
                              as={Link}
                              to="/"
                              onClick={() => {
                                setResetPassword(false);
                              }}
                            >
                              Back to Login
                            </Nav.Link>
                          </Nav.Item>
                        </Nav>
                      )}

                      <Button className="position-relative" variant="info" type="submit" style={{ width: "100%" }}>
                          {buttonLoader ? <div className="login-dual-ring"></div> : 'Submit'}
                      </Button>
                      {company_logo?.privacyPolicyPageUrl !="" && <p className="privacy-policy">On logging in, you agree with our <a href={company_logo?.privacyPolicyPageUrl} target="blank" className="privacy-policy text-primary mt-2 mb-0">Privacy Policy</a></p>}    
                    </Form>
                  </Col>
                </Row>
              </Container>
            </Col>
          </Row>
        </Page>
      )}
    </>
  );
};

export default Login; 
