import react from "react";
import * as Icon from "react-bootstrap-icons";
import "bootstrap/dist/css/bootstrap.min.css";
import { Col, Form, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from "react-redux";
// import { activeCategoryData } from "../../../redux/actions/setupActions";
import { fetchIndirectAnalytics, fetchIndividualAnalytics } from "../../../redux/actions/campaignAction";
import { useState, useEffect } from "react";
import ProgressBar from 'react-bootstrap/ProgressBar';

export default function SubTable(props) {
  const dispatch = useDispatch();
  const analytics_data = useSelector((state) => state.campaign?.analytics_data);
  
  useEffect(() => {
    if (props.path != "indirect") {
      dispatch(fetchIndividualAnalytics(props.authToken, props.subUser))  
    } else {
      dispatch(fetchIndirectAnalytics(props.authToken, props.subUser))  
    }
  }, [])
    
    return (
      <>
        <div className="text-left">
          <Row className="main-div-theme">
            <Col className="analytic-container container-left">
              <Row>
                <Col md={6} className="new-col">
                  <div className="d-flex justify-content-between align-items-center">
                    <div className="inner-analy">
                      <p className="main-heading-font custom-diagonal-first">{analytics_data?.total_overall_req}</p>
                      <p>Total Request</p>
                    </div>
                    <p className="sub-head">{analytics_data?.total_overall_perc}%</p>
                  </div>
                </Col>
                <Col md={6} className="new-col new-col-left-hr">
                  <div className="d-flex justify-content-between align-items-center line-customm-height">
                    <div className="inner-analy">
                      <p className="main-heading-font custom-diagonal-first">{analytics_data?.total_sent_req}</p>
                      <p>Total Sent</p>
                    </div>
                    <p className="sub-head">{analytics_data?.total_sent_perc}%</p>
                  </div>
                </Col>
                <Col md={6} className="new-col">
                  <div className="d-flex justify-content-between align-items-center line-customm-height">
                    <div className="inner-analy">
                      <p className="main-heading-font">{analytics_data?.delivered}</p>
                      <p>Delivered</p>
                    </div>
                    <p className="sub-head  text-warning">{analytics_data?.delivered_perc}%</p>
                  </div>
                </Col>
                <Col md={6} className="new-col new-col-left-hr">
                  <div className="d-flex justify-content-between align-items-center line-customm-height">
                    <div className="inner-analy">
                      <p className="main-heading-font">{analytics_data?.unique_open}</p>
                      <p>Unique Opened</p>
                    </div>
                    <p className="sub-head text-danger">{analytics_data?.opened_perc}%</p>
                  </div>
                </Col>
                <Col md={6} className="new-col">
                  <div className="d-flex justify-content-between align-items-center line-customm-height">
                    <div className="inner-analy">
                      <p className="main-heading-font">{analytics_data?.clicks}</p>
                      <p>Total Clicks</p>
                    </div>
                    <p className="sub-head text-success">{analytics_data?.clicks_perc}%</p>
                  </div>
                </Col>
                <Col md={6} className="new-col new-col-left-hr">
                  <div className="d-flex justify-content-between align-items-center line-customm-height">
                    <div className="inner-analy">
                      <p className="main-heading-font">{analytics_data?.leadscount}</p>
                      <p>Leads</p>
                    </div>
                    <p className="sub-head text-warning">{analytics_data?.leadPercentage}%</p>
                  </div>
                </Col>
              </Row>
            </Col>
            <Col className="analytic-container container-right">
              <div className="right-line-customm-height">
                <div className="d-flex justify-content-between"><p>Leads</p><p>{ analytics_data?.leadPercentage }%</p></div>
                <ProgressBar variant="info" now={analytics_data?.leadPercentage} />
              </div>
              <div className="right-line-customm-height">
                <div className="d-flex justify-content-between"><p>Emails Bounced</p><p>{ analytics_data?.bounced_perc }%</p></div>
                <ProgressBar variant="info" now={analytics_data?.bounced_perc} />
              </div>
              <div className="right-line-customm-height">
                <div className="d-flex justify-content-between"><p>Unique Visits</p><p>{analytics_data?.unique_clicks_perc}%</p></div>
                <ProgressBar variant="info" now={analytics_data?.unique_clicks_perc} />
              </div>
              <div className="right-line-customm-height">
                <div className="d-flex justify-content-between"><p>Opened Emails</p><p>{analytics_data?.opened_perc}%</p></div>
                <ProgressBar variant="info" now={analytics_data?.opened_perc} />
              </div>
              <div className="right-line-customm-height">
                <div className="d-flex justify-content-between"><p>Unopened Emails</p><p>{analytics_data?.unopened_perc}%</p></div>
                <ProgressBar variant="info" now={analytics_data?.unopened_perc} />
              </div>
              <div className="right-line-customm-height">
                <div className="d-flex justify-content-between"><p>Unsubscribed</p><p>{analytics_data?.unsubs_perc}%</p></div>
                <ProgressBar variant="info" now={analytics_data?.unsubs_perc} />
              </div>
            </Col>
          </Row>
        </div>
      </>
    );
}
