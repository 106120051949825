import React from 'react'
import * as Icon from "react-bootstrap-icons";
import { useDispatch, useSelector } from "react-redux";
import SelectTable from "../campaign/SelectTable"
import { useEffect } from 'react';
import { useState } from 'react';
import { Button, Col, Modal, Row, Table } from 'react-bootstrap';
import { addSelectedContactIdsList, deleteContactGroupData, fetchListDataListing, fetchListIndividualDataListing, setshowEnteries } from '../../../redux/actions/campaignAction';
import Pagination from "./CampaignPagination";
import Loading from '../../Loading';

const ListModal= (props) => {
    const dispatch = useDispatch();
    const authToken = props.authToken;
    const total_data = useSelector((state) => state.campaign?.list_data?.total_count);
    const list_data = useSelector((state) => state.campaign?.list_data?.data);
    const individual_contact_list = useSelector((state) => state.campaign?.individual_contact_list?.data);
    const total_data_contact = useSelector((state) => state.campaign?.individual_contact_list?.total_count);
    let show_enteries = useSelector((state) => state.campaign.show_enteries);
    let list_id = useSelector((state) => state.campaign.list_id);
    const [currentList, setCurrentList] = useState(false);
    const [selectedCrawlUrls, setSelectedCrawlUrls] = useState([]);
    const [contentList, setContentList] = useState({});
    const [showDelete, setShowDelete] = useState(false);
    const [selectedOption, setSelectedOption] = useState("20");
    const isLoading = useSelector((state) => state.campaign.loading);
  
    let pageNum = useSelector((state) => state.campaign.currPage);
  
    const handleCloseDelete = () => setShowDelete(false);
  
    const handleShowPopup = () => {
        setShowDelete(true);
    }
  
    const deleteHandleShow = () => {
      let all_ids = selectedCrawlUrls?.map((item) => {
        return item.list_id
      })   
      dispatch(deleteContactGroupData(authToken, all_ids));
      handleCloseDelete();
    }
  
    const selectAllList = () => {
      setSelectedCrawlUrls(list_data);  
    }
    
    const selectAllContact = () => {
      setSelectedCrawlUrls(individual_contact_list);
    }
    
    const unselectData = () => {
      setSelectedCrawlUrls([]);
    }
   
    const handleUrlCheckboxChange = (e, obj) => {
      if (selectedCrawlUrls.includes(obj)) {
        setSelectedCrawlUrls(selectedCrawlUrls.filter(item => item !== obj));
      } else {
        setSelectedCrawlUrls([...selectedCrawlUrls, obj]);
      }
    };
  
    useEffect(() => {
        dispatch(fetchListDataListing(authToken, "", pageNum, props?.sortValue?.title || "", props?.sortValue?.value || ""));
        dispatch(fetchListIndividualDataListing(authToken, list_id, props.searchField, pageNum, show_enteries));
    }, []);
    
    useEffect(() => {
        if (currentList === false) {
            dispatch(fetchListDataListing(authToken, props.searchField, 1, props?.sortValue?.title || "", props?.sortValue?.value || ""));
        } else {
            dispatch(fetchListIndividualDataListing(authToken, list_id, props.searchField, 1, show_enteries));   
        }
    }, [props.searchField]);
    
    const handleOptionChange = async(event) => {
        setSelectedOption(event.target.value);
        await dispatch(setshowEnteries(event.target.value))
        dispatch(fetchListIndividualDataListing(authToken, list_id, props.searchField, pageNum, event.target.value));
    };

    const handleAddToList = () => {
        let all_ids = selectedCrawlUrls?.map((item) => {
            return item.id
        })  
        dispatch(addSelectedContactIdsList(authToken, list_id, all_ids, onSuccessAddingContact))
    }
    
    const handleContactList = () => {
        props.handleModalValue(true);
        setCurrentList(true);
    }
    
    useEffect(() => {
        setCurrentList(props.currentPopupList);
        unselectData()
    }, [props.currentPopupList])
    
  
    const onSuccessAddingContact = () => {
        props.handleModalValue(false)
    }   
   
    return (
        <>
            {isLoading && <Loading />}
            {currentList === false ? <div className='list-campaign'>
            <div className={"hashtag-list-title-card  list-custom mb-0"}>
                {selectedCrawlUrls?.length > 0 && (
                <Row>
                    <Col>
                    <div className="selectedAssetActions d-flex justify-content-between align-items-center">
                        <ul>
                        <li>
                            <Button variant="light selected">
                            {selectedCrawlUrls?.length} Selected
                            </Button>
                        </li>
                            <li>
                            <Button
                                variant="light"
                                onClick={handleShowPopup}
                            >
                                <span>
                                <Icon.Trash />
                                </span>
                                Delete
                            </Button>
                        </li>
                        {/* <li>
                            <Button variant="light">
                            <span>
                                <Icon.Folder />
                            </span>
                            Add to Group
                            </Button>
                        </li> */}
                        </ul>
                        <ul className="">
                            <li>
                            <Button
                            variant="light selected"
                            onClick={() => {
                                selectAllList()
                            }}
                            >
                            Select All
                            </Button>
                            </li>
                            <li>
                            <Button
                                variant="light selected"
                                onClick={unselectData}
                            >
                                Unselect
                            </Button>
                            </li>
                        </ul>
                    </div>
                    </Col>
                </Row>
                )}
                <Table>
                <tbody>
                    <tr>
                    <td style={{ width: "5%", textAlign: "center" }}></td>
                    <td className="hashtag-name-head">
                        <span>Name</span>
                    </td>
                    <td style={{ width: "12%" }}>
                        <span className="bordering-custom date-style">
                        Contacts
                        </span>
                    </td> 
                    {/* <td style={{ width: "12%" }}>
                        <span className="bordering-custom date-style">
                        Valid
                        </span>
                    </td> */}
                    <td>
                        <div className="bordering-custom date-style">
                            Date Created
                        </div>
                    </td>
                    <td style={{ justifyContent: "end" }} className="d-flex">
                        <div className="card-footer-action list-card">
                            Quick Actions
                        </div>
                    </td>
                    </tr>
                </tbody>
                </Table>
            </div>
            {list_data?.length > 0 && list_data.map((listItem,index) => {
                return (
                <Col key={index}>
                    <SelectTable tableData="group_list" page={pageNum} handleContactList={handleContactList} authToken={props.authToken} handleUrlCheckboxChange={ handleUrlCheckboxChange } selectedCrawlUrls={selectedCrawlUrls} listItem={listItem}/>
                </Col>
                )
            })}
            {list_data?.length === 0 && (
                <div className="no-data asset">
                <span>
                    <Icon.Inbox />
                </span>
                <br />
                No Data
                </div>
            )}
            {total_data > 20 && <Pagination className="mt-3" authToken={props.authToken} total_page={ total_data } page={pageNum} currPage={props.subPageName} path="list"/>}
            </div>
            :
            <div className='list-campaign'>
                <div className={"hashtag-list-title-card list-custom mb-0"}>
                {selectedCrawlUrls?.length > 0 && (
                    <Row>
                    <Col>
                        <div className="selectedAssetActions d-flex justify-content-between align-items-center">
                        <ul>
                            <li>
                            <Button variant="light selected">
                                {selectedCrawlUrls?.length} Selected
                            </Button>
                            </li>
                            <li>
                            <Button variant="light" onClick={handleAddToList}>
                                <span>
                                <Icon.Folder />
                                </span>
                                Add to List
                            </Button>
                            </li>
                        </ul>
                            <ul className="">
                            <li>
                            <Button
                                variant="light selected"
                                onClick={() => {
                                selectAllContact()
                                }}
                            >
                                Select All
                            </Button>
                            </li>
                            <li>
                                <Button
                                variant="light selected"
                                onClick={unselectData}
                                >
                                Unselect
                                </Button>
                            </li>
                            </ul>
                        </div>
                    </Col>
                    </Row>
                )}
                <Table>
                <tbody>
                    <tr>
                        <td style={{ width: "5%", textAlign: "center" }}></td>
                    {props.tableData === "gSearch-right" && <td className="hashtag-name" style={{ width: "5%", textAlign: "center" }}>
                        {props.index}.
                    </td>}
                    <td className="hashtag-name-head">
                        <span>Name</span>
                        </td>
                    <td style={{ width: "25%" }}>
                        <span className="bordering-custom date-style">
                        Email
                        </span>
                    </td> 
                    <td style={{ width: "25%" }}>
                        <span className="bordering-custom date-style">
                        Phone Number
                        </span>
                    </td>
                    <td>
                        <div className="bordering-custom date-style">
                            Company
                        </div>
                    </td>
                    </tr>
                </tbody>
                </Table>
            </div>
                {individual_contact_list?.length > 0 && individual_contact_list.map((listItem,index) => {
                return (
                    <Col key={index}>
                        <SelectTable tableData="contact_list" page={pageNum} authToken={props.authToken} handleUrlCheckboxChange={ handleUrlCheckboxChange } selectedCrawlUrls={selectedCrawlUrls} listItem={listItem}/>
                    </Col>
                )
                })}
                {individual_contact_list?.length === 0 && (
                <div className="no-data asset">
                    <span>
                    <Icon.Inbox />
                    </span>
                    <br />
                    No Data
                </div>
                )}
                {total_data_contact > 0 && <div className='d-flex align-items-center justify-content-between'>    
                    {total_data_contact > parseInt(show_enteries) && <Pagination className="mt-3" authToken={props.authToken} selectedOption={selectedOption} total_page={ total_data_contact } page={pageNum} currPage={props.subPageName} path="contact" />}
                    <div className="show-entry mb-3">
                        <span>Show</span>
                        <select
                            value={show_enteries}
                            onChange={handleOptionChange}
                        >
                        <option value="20">20</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                        <option value="250">250</option>
                        <option value="500">500</option>
                        <option value="1000">1000</option>
                        </select>
                        <span>Entries</span>
                    </div>
                </div>}
            </div>}
            {/* Delete popup  */}
            <Modal className="delete-popup"  show={showDelete} onHide={handleCloseDelete}>
                <Modal.Header closeButton>
                    <Modal.Title>Delete</Modal.Title>
                </Modal.Header>
                <Modal.Body>Are you sure you want to delete ?</Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={deleteHandleShow}>
                    Delete
                    </Button>
                    <Button variant="secondary" onClick={handleCloseDelete}>
                    Cancel
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default ListModal;