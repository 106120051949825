/* eslint-disable */
import Page from "../Page";
import { useRef, useState, useEffect, useCallback, useContext } from "react";
import Loading from "../Loading";
import { toast } from "react-toastify";
import Toast from "../shared/Toast";
import { Row, Col, Form, Button, Breadcrumb } from "react-bootstrap";
import * as Icon from "react-bootstrap-icons";
import _ from "lodash";
import CRMList from "./crm/crm-list/CRMList";
import CRMListFilters from "./crm/crm-list/CRMListFilters";
import {
  saveSettings, fetchLeadStagesList, fetchAllLeadTypeDetails, fetchSourceDropdown, exportLeadDetails,
  fetchContactsList, fetchProspectsList, updateLeadsStage, deleteLeads
} from "../../redux/actions/crmActions";
import { useDispatch, useSelector } from "react-redux";
import Contacts from "./crm/crm-list/contacts-prospects/Contacts";
import AddContact from "./crm/crm-list/contacts-prospects/AddContact";
import { useNavigate } from "react-router-dom";
import { logout } from '../../redux/actions/actions';
import * as types from "../../constants/ActionTypes";
import moment from 'moment';

const CRMContainer = (props) => {

  const { authToken, pageName } = props;

  const filterDropdown = useSelector(
    (state) => state.crmLeads.filterDropdown
  );

  const isLoading = useSelector((state) => state.crmLeads.loading);
  const unauthorized = useSelector((state) => state.crmLeads.unAuthorisedCrm);
  const stagesList = useSelector((state) => state.crmLeads.stagesList);

  const allLeadDetails = useSelector((state) => state.crmLeads.allLeadDetails);


  const [checked, setChecked] = useState(0);
  const [leadList, setLeadList] = useState({});
  const [companyName, setCompanyName] = useState(true);
  const [lastEngagement, setLastEngagement] = useState(true);
  const [tasks, setTasks] = useState(true);
  const [settings, setSettings] = useState(false);
  const [check, setCheck] = useState(false);
  const [contactsCP, setContactsCP] = useState([]);

  //Filters page
  const [time, setTime] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [city, setCity] = useState(null);
  const [source, setSource] = useState(null);
  //const [sourceFilterVal, setSourceFilterVal] = useState(null);
  const [product, setProduct] = useState(null);
  const [sort, setSort] = useState(null);
  const [customDate, setCustomDate] = useState(false);
  const [searchField, setSearchField] = useState("");
  const [isSearchActive, setIsSearchActive] = useState(null);

  let start_date = null, end_date = null;

  // Leads List page
  const [leadStages, setLeadStages] = useState({});
  const [columns, setColumns] = useState({});


  const dispatch = useDispatch();
  const navigate = useNavigate();
  props.pageTypeHandler("CRM");

  //console.log("unauthorized", unauthorized);

  // useEffect(() => {
  //   if (unauthorized === 401 || unauthorized === 403) {
  //     dispatch(logout(authToken));
  //     dispatch(setUnauthorized(null));
  //     setTimeout(() => {
  //       //window.location.reload();
  //       localStorage.clear();
  //       navigate('/');
  //     }, 2000);
  //   } else if (unauthorized === 429) {
  //     window.location.reload();
  //   }
  // }, unauthorized);

  const settingsHandler = (type) => {
    setSettings(true);
    if (type === "companyName") {
      setCompanyName(!companyName);
    } else if (type === "lastEngagement") {
      setLastEngagement(!lastEngagement);
    } else if (type === "tasks") {
      setTasks(!tasks);
    }
    //Toast("settings changed successfully", "S");
  };

  const setLeadListFromActions = () => {
    setLeadList({});
    setChecked(0);
  }

  // Add/Remove checked item from list
  const handleCheck = (e, id) => {
    //console.log("eee", e);

    let _updatedContent = _.clone(leadList);
    let count = checked;
    if (e.target.checked) {
      if (_.has(leadList, ["contact_ids"])) {
        if (!_updatedContent["contact_ids"].includes(id)) {
          _updatedContent["contact_ids"].push(id);
        }
      } else {
        _updatedContent["contact_ids"] = [id];
      }
      //setCheck(true);
      setChecked(count + 1);
    } else {
      if (_.has(leadList, ["contact_ids"])) {
        _updatedContent["contact_ids"] = _updatedContent["contact_ids"].filter(
          (item) => item !== id
        );
        if (_updatedContent["contact_ids"].length === 0) {
          _updatedContent = _.omit(_updatedContent, ["contact_ids"]);
        }
      }
      //setCheck(false);
      setChecked(count - 1);
    }
    setLeadList(_updatedContent);
  };

  const searchHandler = (e, searchKey, pageType) => {
    console.log("search", searchKey, pageType);
    e.preventDefault();
    //e.stopPropagation()
    setSearchField(searchKey);

    setIsSearchActive(searchKey);
    if (pageType === 'leads') {
      dispatch({ type: types.CLEAR_ALL_LEAD_TYPE_DETAILS });
      if (startDate && endDate) {
        start_date = moment(startDate).format('YYYY-MM-DD HH:mm:ss');
        end_date = moment(endDate).format('YYYY-MM-DD HH:mm:ss');
      }
      if (stagesList.length > 0) {
        stagesList.map(item => {
          console.log("item lt", item.lead_type);
          dispatch(fetchAllLeadTypeDetails(authToken, item.lead_type, +item.total, source ? source.value : source,
            (source?.value === "SIP Continuity")? "sip" : null,
            sort ? sort.value : sort,
            time,
            start_date,
            end_date, searchKey));
        })
      }
    } else if (pageType === "contacts") {
      dispatch(fetchContactsList(authToken, searchKey, null));
    } else if (pageType === "prospects") {
      dispatch(fetchProspectsList(authToken, searchKey, null));
    }

  }

  const handleChangeCustomDate = () => {
    if (customDate && startDate && endDate) {
      setTime(4);
    }
    document.getElementById('dropdown-autoclose-outside').click();
  }


  const setTimeFilter = (val) => {
    console.log("setTimeFilter val", val);
    setTime(null);
    setCustomDate(false);
    setStartDate(null);
    setEndDate(null);
    if (val === 1) {
      setTime(1);
      document.getElementById('dropdown-autoclose-outside').click();
    } else if (val === 2) {
      setTime(2);
      document.getElementById('dropdown-autoclose-outside').click();
    } else if (val === 3) {
      setTime(3);
      document.getElementById('dropdown-autoclose-outside').click();
    }
    else if (val === 4) {
      setCustomDate(true)
    } else {
      setTime(null);
    }
    //setSettingsVal([1]);
  }

  const clearDropdown = () => {
    setTimeout(() => {
      document.getElementById('dropdown-autoclose-outside').click();
    }, 100);
    setTime(null);
    setStartDate(null);
    setEndDate(null);
    setCustomDate(false);
    //handleChangeDate(null);                             
  }

  const exportDoc = () => {
    let gsearch = null;

    if (startDate && endDate) {
      // start_date = `${startDate.day}-${startDate.month}-${startDate.year}`;
      // end_date = `${endDate.day}-${endDate.month}-${endDate.year}`;
      start_date = moment(startDate).format('YYYY-MM-DD HH:mm:ss');
        end_date = moment(endDate).format('YYYY-MM-DD HH:mm:ss');
    }

    let findStage;
    if (stagesList?.length > 0) {
      findStage = stagesList.filter(stage => {
        if (stage.lead_stage == 'All') {
          return stage.lead_type;
        }
      })
    }
    const leadType = findStage.length > 0 ? findStage[0]?.lead_type : null;
    console.log("leadType export", leadType);
    dispatch(exportLeadDetails(authToken,
      leadType,
      source ? source.value : source,
      (source?.value === "SIP Continuity")? "sip" : null,
      sort ? sort.value : sort,
      time,
      start_date,
      end_date, gsearch))
  }

  const updateLeadStage = (destination, contact_id) => {
   // console.log("update container");
    let obj = {
      source: source ? source.value : source,
      filterSourceType : (source?.value === "SIP Continuity") ? "sip" : null,
      sort: sort ? sort.value : sort,
      time: time,
      start_date: start_date,
      end_date: end_date, gsearch: searchField
    }
    dispatch(updateLeadsStage(authToken, destination, contact_id, stagesList, obj, "1"));
  }

  const deleteMultipleLeads = (arr) => {
    let obj = {
      source: source ? source.value : source,
      filterSourceType: (source?.value === "SIP Continuity")? "sip" : null,
      sort: sort ? sort.value : sort,
      time: time,
      start_date: start_date,
      end_date: end_date, gsearch: searchField
    }
    dispatch(deleteLeads(authToken, arr, stagesList, obj));
    setLeadListFromActions();
  }


  useEffect(() => {
    // dispatch({ type: types.CLEAR_STAGES_LIST });
    // dispatch({ type: types.CLEAR_ALL_LEAD_TYPE_DETAILS });
    setContactsCP([]);
    setLeadListFromActions();
   // console.log("mounted");
    //dispatch(fetchProductDropdown(authToken));
    if (pageName === 'leads') {
      dispatch(fetchSourceDropdown(authToken));
      //dispatch(fetchCityDropdown(authToken));
      dispatch(fetchLeadStagesList(authToken));
    }
    return (() => {
      dispatch({ type: types.CLEAR_STAGES_LIST });
      dispatch({ type: types.CLEAR_ALL_LEAD_TYPE_DETAILS });
    })
  }, [pageName]);


  useEffect(() => {
    var settingsVal = [];
    if (companyName === true) {
      settingsVal.push(2);
    }
    if (lastEngagement === true) {
      settingsVal.push(3);
    }
    if (tasks === true) {
      settingsVal.push(4);
    }
    //console.log("settingsVal", settingsVal);
    if (settings) {
      dispatch(saveSettings(props.authToken, settingsVal));
    }
  }, [companyName, lastEngagement, tasks]);

  useEffect(() => {
    //dispatch(fetchLeadStagesList(props.authToken));   
    //console.log("state filter", filterDropdown);

    if (filterDropdown?.indexOf("2") === -1) {
      setCompanyName(false);
    } else {
      setCompanyName(true);
    }
    if (filterDropdown?.indexOf("3") === -1) {
      setLastEngagement(false);
    } else {
      setLastEngagement(true);
    }
    if (filterDropdown?.indexOf("4") === -1) {
      setTasks(false);
    } else {
      setTasks(true);
    }
    setSettings(false);
  }, [filterDropdown])


  useEffect(() => {
   // console.log("all filters after mounted");
    //console.log("city", city ? city.value : city, "\nsource", source ? source.value : source, "\nsort " , sort? sort.value : sort);  
    let gsearch = searchField;
    
    if (startDate && endDate) {
      // start_date = `${startDate.day}-${startDate.month}-${startDate.year}`;
      // end_date = `${endDate.day}-${endDate.month}-${endDate.year}`;

      start_date = moment(startDate).format('YYYY-MM-DD HH:mm:ss');
        end_date = moment(endDate).format('YYYY-MM-DD HH:mm:ss');
    }
    dispatch({ type: types.CLEAR_ALL_LEAD_TYPE_DETAILS });
    if (stagesList.length > 0) {
      stagesList.map(item => {
        dispatch(
          fetchAllLeadTypeDetails(
            authToken,
            item.lead_type,
            +item.total,
            source ? source.value : source,
            (source?.value === "SIP Continuity")? "sip" : null,
            sort ? sort.value : sort,
            time,
            start_date,
            end_date, gsearch
          )
        );
      })
    }
  }, [authToken, city, source, sort, time, stagesList]);


  //list
  const arrayToObject1 = (arr, key) => {
    console.log("before reduce", moment(new Date()).format('mm:ss'));
    return arr.reduce((obj, item) => {
      if (allLeadDetails.length > 0) {
        allLeadDetails.map(leadDetails => {
          if (leadDetails.leadType == item.lead_type) {
            item['items'] = leadDetails?.data;
            item.total = leadDetails?.totalCount ? +leadDetails?.totalCount : 0;
          }
        });
      } else {
        item['items'] = [];
      }

      obj[item[key]] = item;
      //console.log("obj[item[key]]", obj[item[key]]);
      return obj;
    }, {})
  }

  useEffect(() => {
     console.log("allLeadDetails", allLeadDetails);
    //console.log("stagesList", stagesList);
   // console.log("arrayToObject1", arrayToObject1(stagesList, 'id'));
    //dispatch({ type: types.TOGGLE_LOADING, payload: true });
    setLeadStages({});
    setColumns({});
    console.log("before convert in container", moment(new Date()).format('mm:ss'));
    let stages = arrayToObject1(stagesList, 'order');
    console.log("before stages update", moment(new Date()).format('mm:ss'));
    //console.log("stages", stages);
    /* setTimeout(() => {
      dispatch({ type: types.TOGGLE_LOADING, payload: false });
    }, 5000); */
    setLeadStages(stages);
    setColumns(stages);
  }, [allLeadDetails]); /*  allLeads, engagedLeads, dealLeads, quoteLeads, wonLeads, droppedLeads, newLeads */

  /* useEffect(() => {
    if (stagesList) {
      stagesList.map(item => {
        dispatch(fetchAllLeadTypeDetails(authToken, +item.lead_type, +item.total))
      });
    }
  }, [stagesList]); */



  return (
    <div className="crm-main-container">
      <CRMListFilters
        authToken={props.authToken}
        pagename={pageName}
        handleCheck={handleCheck}
        checked={checked}
        companyName={companyName}
        lastEngagement={lastEngagement}
        tasks={tasks}
        settingsHandler={settingsHandler}
        leadList={leadList}
        setLeadListFromActions={setLeadListFromActions}
        contactsCP={contactsCP}
        setContacts={setContactsCP}
        stagesList={stagesList}
        searchHandler={searchHandler}
        time={time} startDate={startDate} endDate={endDate}
        customDate={customDate} searchField={searchField} isSearchActive={isSearchActive}
        handleChangeCustomDate={handleChangeCustomDate} setTimeFilter={setTimeFilter} clearDropdown={clearDropdown}
        setSearchField={setSearchField} exportDoc={exportDoc} setSort={setSort} setSource={setSource}
        setStartDate={setStartDate} setEndDate={setEndDate} deleteMultipleLeads={deleteMultipleLeads}
      />
      <Page title="CRM">
        {/* {!isLoading && <Loading />} */}
        {isLoading && pageName === 'leads' &&
          <>
          {
            [...Array(3)].map((el, ind) => {
              return(
              <Row className="justify-content-start">
                {
                    [...Array(4)].map(() => {
                      return (
                        <>
                          <Col md={3} style={{paddingLeft: '55px'}}>
                            {ind == 0 &&
                              <div className="crm-shimmer-heading">
                                <h3 className="shine" style={{height:'15px', width: '65px', margin: '0px'}}></h3>
                              </div>
                            }
                            <div className="crm-shimmer">
                              <h3 className="shine" style={{width:"90px", height: '18px'}}></h3>
                                <hr />
                              <h3 className="shine mt-2" style={{width:"100%", height: '18px'}}></h3>
                              <h3 className="shine" style={{width:"180px", height: '12px'}}></h3>
                              <h3 className="shine" style={{width:"170px", height: '10px'}}></h3>
                            </div>
                          </Col>
                        </>
                      )
                    }
                  )
               }
              </Row>)
            })
          }
          </>
        }

        {pageName === 'leads' && allLeadDetails.length > 0 &&
          <CRMList
            // stagesList={stagesList}
            // allLeads={allLeads}
            // newLeads={newLeads}
            // engagedLeads={engagedLeads}
            // dealLeads={dealLeads}
            // quoteLeads={quoteLeads}
            // wonLeads={wonLeads}
            // droppedLeads={droppedLeads}
            authToken={props.authToken}
            handleCheck={handleCheck}
            leadList={leadList}
            companyName={companyName}
            lastEngagement={lastEngagement}
            tasks={tasks}
            setLeadListFromActions={setLeadListFromActions}
            leadStages={leadStages} setLeadStages={setLeadStages} columns={columns}
            setColumns={setColumns} updateLeadStage={updateLeadStage} stagesList={stagesList}
          />
        }

        {(pageName === 'contacts' || pageName === 'prospects') && (
          <Contacts
            authToken={authToken}
            pagename={pageName}
            setContacts={setContactsCP}
          />
        )}
      </Page>
    </div>
  );
};

export default CRMContainer;
