// import "./styles.css";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import * as Icon from "react-bootstrap-icons";
import SubTable from "./SubTable"
import vector from '../../../assets/setup-icon/Vector.png';
import "bootstrap/dist/css/bootstrap.min.css";
import { useDispatch, useSelector } from "react-redux";
import { categoryListSequencing, activeCategoryData, deleteCategory, setCategoryAttribute, setContentAttribute, deleteContent, deleteBusinessNeed, setBatchAttribute, deleteBatch } from "../../../redux/actions/setupActions";
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import RectImage from "../../../assets/RectangleImage.png"
import { Button, Modal, Form} from 'react-bootstrap';
import { deleteDirectListData, deleteIndirectListData, duplicateCampaign, fetchAutoemailData } from "../../../redux/actions/campaignAction";

export default function DragTable(props) {
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const [users, setUsers] = useState(props.categoryData);
  const [deleteData, setDeleteData] = useState({});
  const [isActive, setIsActive] = useState(false);
  const [analyticsFlag, setAnalyticsFlag] = useState(false);
  const [analyticsId, setAnalyticsId] = useState(false);
  const [parentId, setParentId] = useState("");
  const [previewData, setPreviewData] = useState("");
  const category_all = useSelector((state) => state.assetLibrary.categoryList);
  const content_drop = useSelector((state) => state.setupScreen.content_drop.content_type_dropdown);
  const authToken = props.authToken;
  const type = 1;
  const userType = localStorage.getItem("userType")
    ? localStorage.getItem("userType").slice(1, -1)
    : "";
  const [show, setShow] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);

  const handleClose = () => {
    setDeleteData({});
    setDeleteModal(false);
    setShow(false);
  }
  const editPageOpen = (userData) => {
    switch (props.subPageName) {
      case "category":
        edit_category(userData.cat_name, type, userData.id, null, "cat_table", userData.image)
        break;
      case "contentList":
        edit_content(userData.article_type, userData.selected_content_id ,userData.id ,userData.image, userData.description, userData.internal)
        break;
      case "businessNeed":
        props.handleEditShow("inside" ,userData.type ,userData.id);
        break;
      case "levels":
        edit_batch(userData.badge_icon
          , userData.badge_name ,userData.date
          ,userData.id
          , userData.points
          )
        break;
      default:
        break;
    }
  }
  const handleShowPopup = (data) => {
    setShow(true);
    setPreviewData(data)
  }
  
  const handleFirstLetter = (first) => {
    // Getting the first letter of each name
    let name = first.replace(" ","")
    const firstInitial = name?.charAt(0);
    // const lastInitial = second?.charAt(0);
    
    return firstInitial.toUpperCase();
  }
  
  const deleteEvent = () => {
    switch (props.subPageName) {
      case "direct":
        dispatch(deleteDirectListData(authToken, deleteData.mail_id));
        break;
      case "indirect":
        dispatch(deleteIndirectListData(authToken, deleteData.mail_id));
        break;
      default:
        console.log("Table Error")
        break;
    }
    handleClose();
  };
  
  const edit_content = (name, pid, sid, image, desc, internal) => {
    let activeContentType = null;
    activeContentType = content_drop.filter((item) => {
      return item.id === pid
    })
    dispatch(setContentAttribute(name, activeContentType, pid, sid, image, desc, internal));
    navigate("/setup/content/contentList/editContent");
  }
  const edit_batch = (icon, name, date, id, point) => {
    // let activeContentType = null;
    // activeContentType = content_drop.filter((item) => {
    //   return item.id === pid
    // })
    dispatch(setBatchAttribute(icon, name, date, id, point));
    navigate("/setup/leaderBoard/levels/editBatches");
  }
  
  const edit_category = (name, type, pid, sid, page, imageLoaded, s_code) => {
    let activeCategory = null;
    if ((type === 2 && page ==='sub_cat_table') || (type === 1 && page ==='cat_table')) {
      activeCategory = category_all.filter((item) => {
        return item.id === pid
      })
    }
      dispatch(setCategoryAttribute(name, type, activeCategory,pid, sid, imageLoaded,s_code));
      navigate("/setup/content/editCategory");
  }
  
  return (
    <div className="App">
        <div className="table borderd">
              <div
                style={{ lineHeight: "35px" }}
                className="text-capitalize"
              >
                {users && users?.map((user, index) => (
                  <>
                    <div className="row border-bottom">
                      <div className="table-row-count time-date-div">
                        <p>{ user.date?.replace("-"," ") }</p>
                        <p style={{textTransform:"uppercase"}} className="disable-date">{ user.time?.replace("-"," ") }</p>
                      </div>
                      <div className="table-row-count image-div">
                        <img style={{width:"100px"}} src={user.campaign_thumbnail} alt="demo"/>
                      </div>
                      <div className="text-aligning table-name">
                        <span className="title-main" onClick={()=>{handleShowPopup(user.message)}} title={user.camp_name}>{user.camp_name}</span>
                      </div>
                      {props.subPageName != "direct" && <div className="adjust-width text-aligning">
                        <div className="bordering-date time-style">
                            {user?.partner_categories?.length > 0 && <div className="list-card-tags">
                                {user.partner_categories?.map((partner, c_ind)=>{
                                  return (
                                    <>
                                      {c_ind === 0 && <span title={partner} className="pr-category">{handleFirstLetter(partner)}</span>}
                                      {c_ind === 1 && <span title={partner} className="edu-category">{handleFirstLetter(partner)}</span>}
                                    </>
                                  );
                                })}
                                {user?.partner_categories?.length > 2 &&
                                  <span
                                    className="add-num-category"
                                    title={
                                      user?.partner_categories &&
                                      user?.partner_categories?.map(
                                        (category) => category + ", "
                                      )
                                    }
                                  >+ {user?.partner_categories?.length - 2}</span>
                                }
                            </div>}
                        </div>
                      </div>}
                      <div className="adjust-width text-aligning">
                        <div className="bordering-date time-style">
                          {user.list_name?.length > 0 && <div className="list-card-tags">
                            {user.list_name?.map((list, c_ind)=>{
                              return (
                                <>
                                  {c_ind === 0 && <span title={list} style={{background:"#45bcd2"}} className="pr-category">{handleFirstLetter(list)}</span>}
                                  {c_ind === 1 && <span title={list} className="edu-category">{handleFirstLetter(list)}</span>}
                                </>
                              );
                            })}
                            {user?.list_name?.length > 2 &&
                              <span
                                className="add-num-category"
                                title={
                                  user?.list_name &&
                                  user?.list_name?.map(
                                    (list) => list + ", "
                                  )
                                }
                              >+ {user?.list_name?.length - 2}</span>
                            }
                          </div>}
                        </div>
                      </div>
                      <div className="adjust-width text-aligning">
                        <div style={{ width: "20%" }} className="bordering-date time-style">
                          <div className="list-card-tags font-weight-bold">
                              {user.status}
                          </div>
                        </div>
                      </div>
                      {user.status !== "Draft" && <div className="adjust-width date text-aligning">
                        <div className="bordering-date time-style">
                          <div style={{textTransform:"uppercase"}} className="list-card-tags font-weight-bold">
                              {user.schedule_date}
                          </div>
                        </div>
                      </div>}
                      <div className="col d-flex justify-content-end align-items-center">
                        {( user.status !== "Draft" && user.status !== "Scheduled") && <div className="right-bar-icon">
                          <div
                            className="edit-icon"
                            onClick={() => {
                              setAnalyticsFlag(!analyticsFlag);
                              if (analyticsId) {
                                setAnalyticsId(null)
                              } else {
                                setAnalyticsId(user?.mail_id)
                              }
                            }}>
                                <Icon.BarChart color={analyticsId != user.mail_id ?"rgba(33, 33, 33, 0.3)":"#45bcd2"} />
                          </div>
                        </div>}
                        <div className="right-bar-icon">
                        <span className="edit-icon"
                          onClick={() => {
                            dispatch(duplicateCampaign(authToken, user.camp_name, user.mail_id, props.path));
                          }}
                        >
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill={"rgba(33, 33, 33, 0.3)"} class="bi bi-copy" viewBox="0 0 16 16">
                              <path fill-rule="evenodd" d="M4 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2zm2-1a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1zM2 5a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1v-1h1v1a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h1v1z"/>
                            </svg>
                          </span>
                        </div>
                        {user.status !== "Sent" && <div className="right-bar-icon">
                        <span className="edit-icon"
                            onClick={async() => {
                              await dispatch(fetchAutoemailData(authToken, user.mail_id, 0));
                              navigate(`/campaigns/${props.subPageName === "direct" ? "run_campaign":"push_campaign"}`)
                            }}
                          >
                            <Icon.Pencil color={"rgba(33, 33, 33, 0.3)"}/>
                          </span>
                        </div>}
                        {(userType != "C" || user.status != "Sent") && <div className="right-bar-icon">
                          <span className="edit-icon" onClick={() => { setDeleteModal(true); setDeleteData(user) }}>
                            <Icon.Trash color={"rgba(33, 33, 33, 0.3)"}/>
                          </span>
                        </div>}
                      </div>
                      
                      {(analyticsFlag === true && analyticsId === user.mail_id) && <SubTable authToken={authToken} page={props.page} subPageName={props.subPageName} subUser={props.path != "indirect" ? user?.mail_id: user?.autoemailflag} pid={analyticsId} path={props.path} handleShowPopup={handleShowPopup} edit_category={edit_category} />}
                    </div>
                  </>
                ))}
              </div>
        </div>
      
      <Modal
        size="lg"
        show={show}
        onHide={() => handleClose()}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Body><div style={{ zoom: 0.7,margin:"auto"}} dangerouslySetInnerHTML={{__html:previewData}}></div></Modal.Body>
      </Modal>
      
      {/* delete poppup */}
      <Modal className="delete-popup" show={deleteModal} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Delete</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to delete ?</Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={deleteEvent}>
            Delete
          </Button>
          <Button variant="secondary" onClick={handleClose}>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}
