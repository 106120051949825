import React from 'react'
import { Button , Modal, Form } from 'react-bootstrap';
import * as Icon from "react-bootstrap-icons";
import DragTable from '../shared/DragTable';
import { useDispatch, useSelector } from "react-redux";
import { useState, useEffect } from "react";
import DragShimmer from './shimmer/DragShimmer';
import { fetchBusinessNeed, addBusinessNeed, editBusinessNeed } from "../../redux/actions/setupActions";

const SetupBussinessNeed = (props) => {
  const authToken = props.authToken;
  const dispatch = useDispatch();
  let isLoading = useSelector((state) => state.setupScreen.loading);
  const [show, setShow] = useState(false);
  const [popupTitle, setPopupTitle] = useState("");
  const [titleValue, setTitleValue] = useState({});
  const business_need_list = useSelector((state) => state.setupScreen.business_need_list);
  const pageNum = 0;
    
  const editHashtag = () => {
      if (titleValue.path !== "inside") {
            dispatch(addBusinessNeed(props.authToken, titleValue));
      } else {
            dispatch(editBusinessNeed(props.authToken, titleValue))
      }
      handleEditClose();
  } 
    
  const handleEditName = (e) => {
    setTitleValue({ name: e.target.value, path: titleValue.path, id: titleValue.id });
  }
  
  const handleEditClose = () => {
    setShow(false);
    // setPopupTitle("");
    setTitleValue({})
  }
    
  const handleEditShow = (path, name, id) => {
      setPopupTitle(path !== "inside" ? "Create" : "Edit");
      setTitleValue({name:name, path:path, id:id})
      setShow(true);
    }
  
  const add_edit_screen = () => {
    handleEditShow("outside","",0);
  }
  
  useEffect(() => {
    dispatch(fetchBusinessNeed(authToken));
  }, []);
  
  return (
    <div>
      <div>
        <div>
          <div className='right-buttons mb-3 w-100'>
            <Button style={{marginLeft: '60px'}} onClick={add_edit_screen} className='common-setup-button' variant="primary">+ Add Type</Button>
          </div>
        </div>

        <div>
          {isLoading && <DragShimmer subPageName={props.subPageName}/>}
          {business_need_list?.data?.length > 0 && isLoading === false && <DragTable authToken={authToken} subPageName={props.subPageName} page={pageNum} categoryData={business_need_list.data} handleEditShow={ handleEditShow } />}
          {business_need_list?.data?.length === 0 && (
            <div className="no-data asset">
              <span>
                <Icon.Inbox />
              </span>
              <br />
              No Data
            </div>
          )}
        </div>
      </div>
      {/* Edit popup  */}
      <Modal show={show} onHide={handleEditClose} size="sm" animation="true" centered>
        <Modal.Header closeButton>
            <Modal.Title className='modal-heading-custom'>{popupTitle} Business Need</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <Form.Control defaultValue={titleValue.name} onChange={handleEditName} type="text" placeholder="Add Name" />
            <Button className='common-setup-button w-100 mt-3' variant="primary" onClick={editHashtag}>
                Save Changes
            </Button>
        </Modal.Body>
      </Modal>
    </div>
  )
}

export default SetupBussinessNeed;