import React, { useEffect, useState } from 'react'
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import DragTable from './DragTable';
import { fetchIndirectListing } from '../../../redux/actions/campaignAction';
import * as types from "../../../constants/ActionTypes";
import Pagination from "./CampaignPagination";
import * as Icon from "react-bootstrap-icons";
import Loading from '../../Loading';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import 'react-datepicker/dist/react-datepicker.css';
import { Button, Col, Dropdown, Form } from 'react-bootstrap';

const IndirectList = (props) => {
    let navigate = useNavigate();
    const authToken = props.authToken;
    const dispatch = useDispatch();
    const [timeFilter, setTimeFilter] = useState(null);
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    let pageNum = useSelector((state) => state.campaign.currPage);
    const indirect_list_record = useSelector((state) => state.campaign?.indirect_list?.records);
    const total_data = useSelector((state) => state.campaign?.indirect_list?.count);
    const isLoading = useSelector((state) => state.campaign.loading);
    useEffect(() => {
        // document.getElementById('dropdown-autoclose-outside-2').click();  
        dispatch(fetchIndirectListing(authToken, props.searchField, pageNum, null, "", ""));
    }, [])
  
    useEffect(() => {
      if (timeFilter !== 4 && timeFilter !== null) {
        document.getElementById('dropdown-autoclose-outside-2').click();
        dispatch(fetchIndirectListing(authToken, props.searchField, 1, timeFilter, "", ""));
      }  
    }, [timeFilter])
  
    const handleCleanFilter = () => {
      dispatch(fetchIndirectListing(authToken, props.searchField, 1, null, "", ""));
    }
  
    const handleChangeCustomDate = () => {
      let from_time = moment(startDate).format('DD-MM-YYYY')
      let to_time = moment(endDate).format('DD-MM-YYYY')
      dispatch(fetchIndirectListing(authToken, props.searchField, pageNum, timeFilter, from_time, to_time));
      document.getElementById('dropdown-autoclose-outside-2').click();
    }
   
    return (
      <>
      <div className="row campaign-row">
        <div className="col-8 d-flex align-item-center">
          <p className='campaign-title'>Automation</p>
        </div>
          <Col xs={12} md={4} className="more-filters d-flex justify-content-end p-0">
              <Dropdown autoClose="outside">
                <Dropdown.Toggle
                  variant="light"
                  id="dropdown-autoclose-outside-2"
                >
                  {timeFilter === 1
                    ? "Today"
                    : timeFilter === 2
                      ? "One Week"
                      : timeFilter === 3
                        ? "One Month"
                        : timeFilter === 4
                          ? "Custom Date"
                          : "Select Date Range"}
                  {timeFilter != null ? (
                    <span
                      className="clear-time"
                      onClick={() => {
                        setTimeFilter(null); handleCleanFilter();
                      }}
                    >
                      <Icon.X />
                    </span>
                  ):(
                    <span className="icon-time">
                      <Icon.Calendar2Week />
                    </span>
                  )}
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <div className="m-2">
                    <Form.Check
                      type="radio"
                      label="Today"
                      name="chooseTime"
                      checked={timeFilter === 1 ? true : false}
                      onClick={() => { setTimeFilter(1); }}
                    />
                    <Form.Check
                      type="radio"
                      label="One Week"
                      name="chooseTime"
                      checked={timeFilter === 2 ? true : false}
                      onClick={() => setTimeFilter(2)}
                    />
                    <Form.Check
                      type="radio"
                      label="One Month"
                      name="chooseTime"
                      checked={timeFilter === 3 ? true : false}
                      onClick={() => setTimeFilter(3)}
                    />
                    <Form.Check
                      type="radio"
                      label="Custom Date"
                      name="chooseTime"
                      checked={timeFilter === 4 ? true : false}
                      onClick={() => setTimeFilter(4)}
                    />
                  </div>
                  {timeFilter === 4 && (
                    <>
                      <Dropdown.Item>

                        <DatePicker
                          selected={startDate}
                          onChange={setStartDate}
                          placeholderText="Select Start Date"
                          dateFormat="MM/dd/yyyy"
                          shouldCloseOnSelect={true}
                        />
                      </Dropdown.Item>
                      <Dropdown.Item>

                        <DatePicker
                          selected={endDate}
                          onChange={setEndDate}
                          placeholderText="Select End Date"
                          dateFormat="MM/dd/yyyy"
                          shouldCloseOnSelect={true}
                        />

                      </Dropdown.Item>
                      {<Dropdown.Item>
                        <Button variant="info"
                          onClick={handleChangeCustomDate}
                          style={{ width: "100%" }}>
                          Filter By Custom Date
                        </Button>
                      </Dropdown.Item>}
                    </>
                  )}
                </Dropdown.Menu>
              </Dropdown>
          </Col>
      </div>  
      <div className='indirect-list'>
          {isLoading && <Loading />}
            {indirect_list_record?.length > 0 && <>
                <DragTable authToken={authToken} subPageName={props.subPageName} path={"indirect"} categoryData={indirect_list_record}/>
            </>}
            {indirect_list_record?.length === 0 && (
            <div className="no-data asset">
              <span>
                <Icon.Inbox />
              </span>
              <br />
              No Data
            </div>
          )}
            {total_data > 20 && <Pagination authToken={props.authToken} timeFilter={timeFilter} startDate={startDate} endDate={endDate} total_page={ total_data } page={pageNum} currPage={props.subPageName}/>}
        </div>
      </>        
    )
}

export default IndirectList;