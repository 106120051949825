import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Cookies from 'js-cookie';
import Loading from "../../Loading";
import { useDispatch } from "react-redux";
import { backToPreviousRoute } from "../../../redux/actions/actions";
// ..

const LogoutHandler = (props) => {
    const dispatch = useDispatch();
    let navigate = useNavigate();
    props.pageTypeHandler("LogoutHandler");
    useEffect(() => {
        localStorage.clear();
        navigate("/")
        dispatch(backToPreviousRoute(""));
    });
    return (
        <>
         <Loading />
        </>
    )
};

export default LogoutHandler;
