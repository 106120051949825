import React from "react";
import * as Icon from "react-bootstrap-icons";
import SelectTable from "../setup/SelectTable"
import { Form, InputGroup, Button } from "react-bootstrap";
import "./setup.css";

import { useState, useRef, useEffect } from "react";

const CreateGroup = (props) => {
    return (
      <div>
        <span style={{cursor:'pointer'}} onClick={()=>{window.history.back()}}>
          <Icon.CaretLeft color="#64B6E7" />
          Back
        </span>
        <h2>{ props.innerTitle }</h2>
        <Form>
            <Form.Control
                className="add-name my-3 w-50"
                type="text"
                placeholder="Enter hashtag group name"
            />
            <div>
                <InputGroup className="mb-3 search-inside">
                    <Form.Control
                        placeholder="Search Hashtags"
                        aria-label="Username"
                        aria-describedby="basic-addon1"
                    />
                    <InputGroup.Text id="basic-addon1">
                        <Icon.Search/>
                    </InputGroup.Text>
                </InputGroup>
                <div className='right-buttons'>
                    <span>Sort By <a href='#'>Date Added <Icon.CaretDownFill color="#64B6E7" /></a></span>
                </div>
            </div>
            <div>
                <SelectTable tableData="hashtag"/>
                </div>
                <div className="d-flex justify-content-end">
                    <Button variant="primary" className="save-button" style={{width:'auto'}} size="lg">
                        Create Group
                        <Icon.CaretRight color="#ffffff" />
                    </Button>
                </div>
        </Form>
      </div>
    );
};

export default CreateGroup;
