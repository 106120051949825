import React from 'react'
import * as Icon from "react-bootstrap-icons";
import { useState, useRef, useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import Page from "../../Page";
import Multiselect from "multiselect-react-dropdown";
import { Button, InputGroup, Form, Dropdown, Modal, Breadcrumb } from 'react-bootstrap';
import { useNavigate } from "react-router-dom";
import vector from '../../../assets/setup-icon/Vector.png';
import CtaTable from '../../pages/conversion/CtaTable';
import { DebounceInput } from 'react-debounce-input';
// import Loading from "../Loading";
import { useDispatch, useSelector } from "react-redux";
import * as types from "../../../constants/ActionTypes";
import Pagination from "../../shared/ReactPaginate";
import DragShimmer from './../../setup/shimmer/DragShimmer';
import { fetchCtaListing, searchInput, fetchButtonCtaDropdown, addEditButtonCtaData } from "../../../redux/actions/conversionAction";
import "./conversion.css";


const ConversionCtaList = (props) => {
  let navigate = useNavigate();


  let isLoading = useSelector((state) => state.conversionData.loading);
  const dispatch = useDispatch();
  const authToken = props.authToken;
  const total_data = useSelector((state) => state.conversionData.cta_button_list?.total_data);
  const cta_button_list = useSelector((state) => state.conversionData.cta_button_list);
  const cta_button_dropdown = useSelector((state) => state.conversionData.cta_button_dropdown);
  let search_input = useSelector((state) => state.conversionData.search_input);
  let pageNum = useSelector((state) => state.conversionData.currPage);

  const [searchValue, setSearchValue] = useState("");
  const [popupDataCtaName, setPopupDataCtaName] = useState("");
  const [popupDataText, setPopupDataText] = useState("");
  const [popupDataBgColor, setPopupDataBgColor] = useState("");
  const [videoViewData, setVideoViewData] = useState("");
  const [popupDataShadow, setPopupDataShadow] = useState("");
  const [redirectionUrlCheck, setRedirectionUrlCheck] = useState(false);
  const [popupDataTextColor, setPopupDataTextColor] = useState("");
  const [popupDataCtaType, setPopupDataCtaType] = useState(null);
  const [ctaId, setCtaId] = useState(null);
  const [popupDataRedirectUrl, setPopupDataRedirectUrl] = useState("");
  const [popupTitle, setPopupTitle] = useState("Add");
  const [sortValue, setSortValue] = useState("ASC");
  const [show, setShow] = useState(false);
  const [viewShow, setViewShow] = useState(false);


  const viewData = [
    { label: "Enter Your Name" },
    { label: "Enter Your Age" },
    { label: "Enter Your Email" },
    { label: "Enter Your Phone" },
    { label: "Enter Your Company" },
    { label: "Enter Your Profession" },
    { label: "Enter Your City" },
  ]

  props.pageTypeHandler("cta");
  const onSelectCategory = (selectedList, selectedItem) => {
    setPopupDataCtaType(selectedList);
    if (selectedItem.id === 3) {
      setRedirectionUrlCheck(true);
    } else {
      setRedirectionUrlCheck(false);
    }
  };

  const saveButtonCta = () => {
    if (popupTitle === "Add") {
      dispatch(addEditButtonCtaData(authToken, "add_CTA_button", popupDataCtaName, popupDataCtaType?.length !== 0 ? popupDataCtaType[0]?.id.toString() : "", popupDataText, popupDataBgColor, popupDataShadow, popupDataTextColor, popupDataRedirectUrl));
    } else {
      dispatch(addEditButtonCtaData(authToken, "update_CTA_button_details", popupDataCtaName, popupDataCtaType?.length !== 0 ? popupDataCtaType[0]?.id.toString() : "", popupDataText, popupDataBgColor, popupDataShadow, popupDataTextColor, popupDataRedirectUrl, ctaId));
    }
    handleClose();
  }

  const handleViewClose = () => {
    setViewShow(false);
  }

  const handleViewShow = (userData) => {
    console.log(userData);
    setVideoViewData(userData);
    setViewShow(true);
  }

  const handleClose = () => {
    setPopupDataCtaName("");
    setPopupDataText("");
    setPopupDataBgColor("");
    setPopupDataShadow("");
    setPopupDataTextColor("");
    setPopupDataCtaType(null);
    setPopupDataRedirectUrl("");
    setShow(false);
  };
  const handleShow = () => setShow(true);

  const buttonCtaClick = (action) => {
    if (action === "Button") {
      setPopupTitle("Add");
      setPopupDataCtaName("");
      setPopupDataText("");
      setPopupDataBgColor("#000000");
      setPopupDataShadow("#000000");
      setPopupDataTextColor("#ffffff");
      setPopupDataCtaType(null);
      setPopupDataRedirectUrl("");
      handleShow();
    } else {
      navigate("/conversion/videoCta")
    }
  }

  const editCtaData = (data, title) => {
    setCtaId(data.id)
    setPopupDataCtaName(data.ctaName);
    setPopupDataText(data.CTA.buttonLabel);
    setPopupDataBgColor(data.CTA.btn_background_color);
    setPopupDataShadow(data.CTA.buttonColor);
    setPopupDataTextColor(data.CTA.btn_text_color);
    setPopupDataCtaType(data.CTA.ctaType);
    setRedirectionUrlCheck(data.CTA.ctaType[0].id === 3 ? true : false);
    setPopupDataRedirectUrl("");
    setPopupTitle(title);
    handleShow();
  }

  const dateSorting = async (e) => {
    if (sortValue === "ASC") {
      setSortValue("DESC");
    } else {
      setSortValue("ASC");
    }
    await dispatch(fetchCtaListing(authToken, pageNum, search_input, sortValue));
  }

  const handleRemoveSearch = (e) => {
    setSearchValue("");
    dispatch(searchInput(""));
    dispatch(fetchCtaListing(authToken, pageNum, ""));
  }
  const handleSearch = (e) => {
    setSearchValue(e.target.value);
    dispatch(searchInput(e.target.value));
    dispatch(fetchCtaListing(authToken, 0, e.target.value));
  }
  useEffect(() => {
    // setSearchValue(search_input);
    // dispatch(fetchProductTypeListing(authToken, 0, search_input));
    dispatch(fetchCtaListing(authToken, 0, ""));
    dispatch(fetchButtonCtaDropdown(authToken, "cta_type"));
  }, []);

  useEffect(() => { // onDestroy
    return () => {
      dispatch({ type: types.SEARCH_INPUT, payload: "" });
      dispatch({ type: types.CURRENT_PAGE, payload: 0 });
    }
  }, [])



  return (
    <Page title="Conversion CTA">
      <div className='cta-wrap'>
        {/* <h3 className='cta-heading'>CTA</h3> */}
        <div className='subheader'>
          <InputGroup className="mb-3 search-inside">
            <DebounceInput value={searchValue} className="search-inside-input" placeholder="Search CTA" minLength={3} debounceTimeout={2000} onChange={handleSearch} />
            <svg className='search-icon' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" width="1em" height="1em" fill="#A8A8A8"><path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z"></path></svg>
          </InputGroup>
          <div className='right-buttons'>
            {/* <span>Sort By <span className='sort-text mx-1' onClick={dateSorting}>Date Added <img className={sortValue === "DESC" ? 'rotate' : ''} src={vector} alt="icon" /></span></span> */}
            {/* <Button style={{marginLeft: '60px'}} onClick={add_edit_screen} className='common-setup-button' variant="primary">+ Product Type</Button> */}
            <Dropdown>
              <Dropdown.Toggle variant="white" id="dropdown-basic">
                <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24">
                  <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                    <rect x="5" y="5" width="5" height="5" rx="1" fill="#009ef7"></rect>
                    <rect x="14" y="5" width="5" height="5" rx="1" fill="#009ef775" opacity="0.3"></rect>
                    <rect x="5" y="14" width="5" height="5" rx="1" fill="#009ef775" opacity="0.3"></rect>
                    <rect x="14" y="14" width="5" height="5" rx="1" fill="#009ef775" opacity="0.3"></rect>
                  </g>
                </svg>

              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item onClick={() => { buttonCtaClick("Button") }}><Icon.FileEarmarkText className="mr-3" color="#7b7f83" />Add Button CTA</Dropdown.Item>
                <Dropdown.Item onClick={() => { buttonCtaClick("Video") }}><Icon.Gear className="mr-3" color="#7b7f83" />Add Forms</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>
        <Row>
          <Col>
            <div className="top-heading-bar">
              <ul className='top-header-cta'>
                <li style={{ width: "30%" }}>
                  <span>Button Name</span>
                </li>
                <li style={{ width: "25%" }}>
                  <span>Type</span>
                </li>
                <li style={{ width: "30%" }}>
                  <span>Preview</span>
                </li>
                <li style={{ width: "32%" }}>
                  <span>Button Type</span>
                </li>
                <li class="padding-right" style={{ float: "right" }}>
                  <span>Actions</span>
                </li>
              </ul>
            </div>
          </Col>
        </Row>
        <div>
          {isLoading && <DragShimmer subPageName={props.subPageName} />}
          {cta_button_list?.data?.length > 0 && isLoading === false && <CtaTable authToken={authToken} page={pageNum} subPageName={props.subPageName} handleViewShow={handleViewShow} categoryData={cta_button_list.data} editCtaData={editCtaData} />}
          {cta_button_list?.data?.length === 0 && (
            <div className="no-data asset m-0" style={{ top: "420px", left: "50%" }}>
              <span>
                <Icon.Inbox />
              </span>
              <br />
              No Data
            </div>
          )}
        </div>
        {total_data > 20 && <Pagination authToken={props.authToken} total_page={total_data} page={pageNum} currPage={props.subPageName} sortValue={sortValue} />}
      </div>
      {/* modal for button cta */}
      <Modal show={show} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title className='modal-heading-customise'>{popupTitle} Button</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ padding: "10px 18px 0px" }}>
          <Form>
            <Form.Group className="mb-3 form-label-popup" controlId="exampleForm.ControlInput1">
              <div className="div-inside-popup">
                <Form.Label>Button Name :</Form.Label>
                <Form.Control
                  type="text"
                  value={popupDataCtaName}
                  onChange={(e) => {
                    setPopupDataCtaName(e.target.value)
                  }}
                />
              </div>
              <div className="div-inside-popup multi-div-target">
                <Form.Label>Button Type :</Form.Label>
                <Multiselect
                  className="drop-down mb-3"
                  options={cta_button_dropdown}
                  selectedValues={popupDataCtaType ? popupDataCtaType : ""}
                  onSelect={onSelectCategory}
                  singleSelect
                  displayValue="name"
                />
              </div>
              <div className="div-inside-popup">
                <Form.Label>Button Text :</Form.Label>
                <Form.Control
                  type="text"
                  value={popupDataText}
                  onChange={(e) => {
                    setPopupDataText(e.target.value)
                  }}
                />
              </div>
              <div className="div-inside-popup">
                <Form.Label>Button Color :</Form.Label>
                <Form.Control
                  className='w-100'
                  type="color"
                  value={popupDataBgColor}
                  onChange={(e) => {
                    setPopupDataBgColor(e.target.value)
                  }}
                />
                <span>{popupDataBgColor}</span>
              </div>
              <div className="div-inside-popup">
                <Form.Label>Button Shadow :</Form.Label>
                <Form.Control
                  className='w-100'
                  type="color"
                  value={popupDataShadow}
                  onChange={(e) => {
                    setPopupDataShadow(e.target.value)
                  }}
                />
                <span>{popupDataShadow}</span>
              </div>
              <div className="div-inside-popup">
                <Form.Label>Button Text Color :</Form.Label>
                <Form.Control
                  className='w-100'
                  type="color"
                  value={popupDataTextColor}
                  onChange={(e) => {
                    setPopupDataTextColor(e.target.value)
                  }}
                />
                <span>{popupDataTextColor}</span>
              </div>
              {redirectionUrlCheck === true && <div className="div-inside-popup">
                <Form.Label>Redirect URL :</Form.Label>
                <Form.Control
                  type="text"
                  value={popupDataRedirectUrl}
                  onChange={(e) => {
                    setPopupDataRedirectUrl(e.target.value)
                  }}
                />
              </div>}
              {popupDataText !== "" && <div style={{ textAlign: "center" }}>
                <div className='preview-div'>
                  <span className="d-block preview-title">Preview</span>
                  <span
                    style={{
                      fontSize: "20px",
                      background: popupDataBgColor,
                      color: popupDataTextColor,
                      border: "2px solid " + popupDataShadow,
                      padding: "5px 15px"
                    }}
                    title={popupDataText}
                  >
                    {(popupDataText !== "" && popupDataText.slice(0, 25))}
                    {(popupDataText && popupDataText.length > 25) && <span>...</span>}
                  </span>
                </div>
              </div>}
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button className='final-button' onClick={saveButtonCta}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>
      {/* modal for view video form */}
      <Modal className='view-modal ' show={viewShow} onHide={handleViewClose} centered>
        <Modal.Body className='p-4'>
          {videoViewData && videoViewData.map((item) => {
            return (
              <>
                {
                  item.inputType === "text" ?
                    <div className='mb-3'>
                      <span>{item.label}</span>
                      <div>
                        <div class="mt-2 form-control cursor-pointer main-placeholder" style={{ backgroundColor: item.color ? item.color : "#fff" }}>{item.placeholder}</div>
                      </div>
                    </div>
                    :
                    <div className='mb-3'>
                      <span>{item.label}</span>
                      <div className='d-flex'>
                        {item.prefix !== null && <div class="mt-2 me-2 post-fix">{item.prefix}</div>}
                        <div class="mt-2 form-control main-placeholder cursor-pointer" style={{ backgroundColor: item.color ? item.color : "#fff" }}>{item.placeholder}</div>
                        {item.postfix !== null && <div class="mt-2 ms-2 pre-fix">{item.postfix}</div>}
                      </div>
                    </div>
                }
              </>
            )
          })
          }
          <Button className='final-button w-100' onClick={handleViewClose}>
            Submit
          </Button>
        </Modal.Body>
      </Modal>
    </Page>
  )
}

export default ConversionCtaList;