/* eslint-disable */
import React, { useEffect, useState } from "react";
import { Stack, Form, Col, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { appUserActivity, deleteAppUserEmails } from "../../../../../../redux/actions/crmActions";
import "./Leadtabs.css";
import { useParams } from 'react-router';
import moment from "moment";
import * as Icon from "react-bootstrap-icons";
import DeleteModal from "../../../../../shared/DeleteModal";

const Emails = (props) => {
    const { id } = useParams();
    const { authToken } = props;
    const { contactId } = props

    const [deleteId, SetDeleteId] = useState(0);
    const [dltDialog, SetDltDialog] = useState(false);

    const dispatch = useDispatch();

    const userDetails = useSelector((state) => state.crmLeads.userActivityDetails);
    useEffect(() => {
        console.log("idd", id);

        let idd = id !== undefined ? id : contactId.contact_ids[0].contact_id;
       // dispatch(appUserActivity(authToken, idd.toString()));
        return () => {
            // console.log("activity cleanup", props);      
        };
    }, []);

    const getDate = (date) => {
        if (date !== "" && date !== null && date !== "0000-00-00 00:00:00") {
            let d = moment(date).format('DD');
            return d;
        }
    }

    const getMonth = (date) => {
        if (date !== "" && date !== null && date !== "0000-00-00 00:00:00") {
            let m = moment(date).format('MMM');
            return m.toUpperCase();
        }
    }

    const deleteEmail = () => {
        let idd = id !== undefined ? id : contactId.contact_ids[0].contact_id;
        SetDeleteId(0)
        SetDltDialog(false);
        dispatch(deleteAppUserEmails(authToken, [deleteId], idd));
    }

    const hideDialog = () => {
        SetDeleteId(0)
        SetDltDialog(false);
    }

    const openModal = (val) => {
        console.log("dsfs", val);

        SetDeleteId(val.id);
        SetDltDialog(true);
        console.log(deleteId, dltDialog);
    }

    return (
        <div className="offcanvas-body-background">
            {userDetails && (
                <div className="activites">
                    {userDetails.response?.email_list.length > 0 ?
                        userDetails.response?.email_list.map((item, i) => {
                            return (
                                <Row className="m-2 p-4">
                                    <Col sm lg={2} key={i} className="p-1">
                                        <div className="fs-6 fw-bold">{getDate(item.date)} <br /> {getMonth(item.date)}</div>
                                    </Col>
                                    <Col sm lg={10} style={{ backgroundColor: '#fff' }}>
                                        <Row className="p-2 lh-base">
                                            <Col md={10}>
                                                <div className="act-title"><Icon.EnvelopeCheck />&nbsp;<strong>Email Sent</strong>&nbsp;&nbsp;{item.contact_name}</div>
                                            </Col>
                                            <Col md={2}>
                                                <Icon.Trash className="text-color" onClick={(e) => openModal(item)} />
                                            </Col>
                                        </Row>
                                        <Row className="p-2 lh-base">
                                            <div><strong>Subject: &nbsp;&nbsp;</strong>{(item.subject !== "" || item.subject !== null) ? item.subject : "NA"}</div>
                                            <div className="p-1 act-notes-templates ml-2" dangerouslySetInnerHTML={{ __html: item.message !== "" ? `<p><strong>&nbsp;&nbsp;Message:&nbsp;&nbsp;</strong></p><div>&nbsp;&nbsp;${item.message}</div>` : "<p><strong>&nbsp;&nbsp;Message:&nbsp; </strong>NA</p>" }}></div>
                                        </Row>
                                    </Col>
                                </Row>
                            )
                        })
                        :
                        <div className="no-data mt-5">
                            <span>
                                <Icon.Inbox />
                            </span>
                            <br />
                            No Data
                        </div>
                    }
                </div>
            )}

            <DeleteModal show={dltDialog} hide={hideDialog} deleteItem={deleteEmail} />

        </div>
    )
}

export default Emails;