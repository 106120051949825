// New reducers
import {
  TOGGLE_LOADING,
  SEARCH_INPUT,
  UNAUTHORIZED_CONVERSION,
  FETCH_CTA_BUTTON_LIST,
  FETCH_CTA_BUTTON_DROPDOWN,
  FETCH_CTA_ASSET_LIST,
  CURRENT_PAGE,
  FETCH_VIDEO_CTA_FLAG,
  FETCH_SELECTED_DATA
} from '../../constants/ActionTypes'

const initialState = {
  loading: false,
  search_input: "",
  video_cta_flag: false,
  unAuthorisedConversion: null,
  cta_button_list: null,
  cta_asset_list: null,
  cta_button_dropdown: null,
  selected_data:null,
  currPage:0,
}

const conversionReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case TOGGLE_LOADING:
      return { ...state, loading: payload };
    case UNAUTHORIZED_CONVERSION:
      return { ...state, unAuthorisedConversion: payload };
    case SEARCH_INPUT:
      return { ...state, search_input: payload };
    case FETCH_CTA_BUTTON_LIST:
      return { ...state, cta_button_list: payload };
    case FETCH_VIDEO_CTA_FLAG:
      return { ...state, video_cta_flag: payload };
    case FETCH_CTA_BUTTON_DROPDOWN:
      return { ...state, cta_button_dropdown: payload };
    case FETCH_CTA_ASSET_LIST:
      return { ...state, cta_asset_list: payload };
    case FETCH_SELECTED_DATA:
      return { ...state, selected_data: payload };
    case CURRENT_PAGE:
      return { ...state, currPage: payload };
    default:
      return state;
  }
};
export default conversionReducer
