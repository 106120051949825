import React from 'react'
import { useState, useRef, useEffect } from "react";
import * as types from "../../constants/ActionTypes";
import { useDispatch,useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const LeftMenu = (props) => {
    let navigate = useNavigate();
    const dispatch = useDispatch();
    const userType = localStorage.getItem("userType") ? localStorage.getItem("userType").slice(1, -1) : "";
    const business_flag = useSelector((state) => state.setupScreen.business_flag);
    
    const RedirectionControl = (title) => {
        refreshPage();
        switch (title) {
            case "category":
                navigate("/setup/content/");
                break;
            case "contentList":
                navigate("/setup/content/contentList");
                break;
            case "hashtag":
                navigate("/setup/content/hashtag");
                break;
            case "globalSearch":
                navigate("/setup/content/globalSearch");
                break;
            case "businessNeed":
                navigate("/setup/content/businessNeed");
                break;
            case "partner":
                navigate("/setup/userManagement/");
                break;
            case "partnerCategory":
                navigate("/setup/userManagement/partnerCategory");
                break;
            case "leaderBoard":
                navigate("/setup/leaderBoard");
                break;
            case "levels":
                navigate("/setup/leaderBoard/levels");
                break;
            case "report":
                navigate("/setup/leaderBoard/report");
                break;
            default:
                break;
        }
    } 
    
    const refreshPage = () => {
        dispatch({ type: types.CURRENT_PAGE, payload: 0 });
    }
    // const [setupTitle, setSetupTitle] = useState('');
    // props.selectedTask("Category")
    return (
            <>
                {
                    props.pageName === "content" &&
                    <div className='left-menu'>
                        <ul>
                            <li className={props.subPageName === 'category' ? 'active' : ''} onClick={()=>{RedirectionControl("category")}}><span>Category</span></li>
                            {/* <li className={props.subPageName === 'imageLibrary' ? 'active' : '' } onClick={() => { navigate("/setup/content/imageLibrary")}}><span>Image Library</span></li> */}
                            <li className={props.subPageName === 'contentList' ? 'active' : ''} onClick={()=>{RedirectionControl("contentList")}}><span>Content Types</span></li>
                            <li className={props.subPageName === 'hashtag' ? 'active' : ''} onClick={()=>{RedirectionControl("hashtag")}}><span>Hashtags</span></li>
                            {/* <li className={props.subPageName === 'hashtagGroup' || props.subPageName === 'createGroup' || props.subPageName === 'editGroup'? 'active' : '' } onClick={() => { navigate("/setup/content/hashtagGroup")}}><span>Hashtag Groups</span></li> */}
                            <li className={props.subPageName === 'globalSearch' ? 'active' : ''} onClick={()=>{RedirectionControl("globalSearch")}}><span>Global Search</span></li>
                            {/* <li className={props.subPageName === 'productType' ? 'active' : ''} onClick={() => { navigate("/setup/content/productType") }}><span>Product Types</span></li> */}
                            {business_flag === true && <li className={props.subPageName === 'businessNeed' ? 'active' : ''} onClick={() => { navigate("/setup/content/businessNeed") }}><span>Business Need</span></li>}
                        </ul>
                    </div>
                }
                {
                    props.pageName === "userManagement" &&
                    <div className='left-menu'>
                        <ul>
                            <li className={props.subPageName === 'partner' ? 'active' : ''} onClick={()=>{RedirectionControl("partner")}}><span>Partner</span></li>
                            <li className={props.subPageName === 'partnerCategory' ? 'active' : ''} onClick={()=>{RedirectionControl("partnerCategory")}}><span>Partner Category</span></li>
                        </ul>
                    </div>
                }
                {
                    props.pageName === "leaderBoard" &&
                    <div className='left-menu'>
                        <ul>
                            {userType === "P" && 
                                <>
                                    <li className={props.subPageName === 'leaderBoard' ? 'active' : ''} onClick={()=>{RedirectionControl("leaderBoard")}}><span>Leaderboard</span></li>
                                    <li className={props.subPageName === 'levels' ? 'active' : ''} onClick={()=>{RedirectionControl("levels")}}><span>Levels</span></li>
                                </>                            
                            }
                        </ul>
                    </div>
                }
            </>
    )
}

export default LeftMenu;
