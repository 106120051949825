import storeApis from "../../api/storeApi";
import "react-toastify/dist/ReactToastify.min.css";
import * as types from "../../constants/ActionTypes";
import _ from "lodash";
import Toast from "../../components/shared/Toast";

const INDIRECT_LIST_URL = "/framework/api/emailCampaign";
const DIRECT_LIST_URL = "/framework/api/emailCampaign";
const STATS_URL = "/framework/api/statsDirectEmail";
const INDIRECT_STATS_URL = "/framework/api/statsAutoEmail";
const DUPLICATE_LIST_URL = "/framework/api/emailCampaign";
const LIST_DATA_URL = "/framework/api/listDashboard";
const EMAIL_REPLY_LIST = "/framework/api/getVerifiedEmail";

// SET Unauthorization Status
export const setUnauthorizedSetup = () => {
  return (dispatch) => {
    dispatch({ type: "UNAUTHORIZED_SETUP", payload: null });
  };
};

export const seletedTemplateData = (value) => {
    return (dispatch) => {
        dispatch({ type: "CURRENT_TEMPLATE_DATA", payload: value });
    };
};

export const setCurrentPagePag = (value) => {
    return (dispatch) => {
        dispatch({ type: "CAMPAIGN_CURRENT_PAGE", payload: value });
    };
};

export const currentUrlPath = () => {
    return (dispatch) => {
        dispatch({ type: "CURRENT_URL_PATH", payload: null });
    };
};

export const searchInput = (value) => {
    return (dispatch) => {
        dispatch({ type: types.SEARCH_INPUT, payload: value });
    };
};

export const setRunCampaignData = (value) => {
    return (dispatch) => {
        dispatch({ type: types.RUN_CAMPAIGN_DATA, payload: value });
    };
};

export const setshowEnteries = (value) => {
    return (dispatch) => {
        dispatch({ type: types.SET_SHOW_ENTERY, payload: value });
    };
}

export const setContactListId = (value, onSuccessListId) => {
    return (dispatch) => {
        dispatch({ type: types.NEW_LIST_ID, payload: value });
        onSuccessListId(value);
    };
}

export const setSelectedListIds = (value) => {
    return (dispatch) => {
        dispatch({ type: types.SELECTED_LIST_ID, payload: value });
    };
}

// fetch BusinessNeed Listing
export const fetchIndirectListing = (authToken, search, page, filter, from_date, to_date) => {
    let filterData
    if (filter == 1) {
        filterData = "DAY"
    } else if (filter == 2) {
        filterData = "WEEK"
    } else if (filter == 3) {
        filterData = "MONTH"
    } else {
        filterData = null
    }
    return async (dispatch) => {
        dispatch({ type: "CAMPAIGN_TOGGLE_LOADING", payload: true });
        try {
            const response = await storeApis.post(
                INDIRECT_LIST_URL,
                {
                    action: "auto_email_list",
                    search: search,
                    page: page || 1,
                    limit: 20,
                    sort_by: filterData,
                    to_date: filter == 4 ? to_date:"",
                    from_date: filter == 4 ? from_date:"",
                },
                {
                    headers: {
                        Accept: "application/json",
                        Authorization: `Bearer ${authToken}`,
                    },
                }
          );
            if (response.status === 200) {
                if (response.data.statusCode == "403") {
                    if (response.data.message == "Invalid Credentials" || response.data.message == "Unauthenticated" || response.data.message == "Unauthorized") {
                        dispatch({
                            type: "UNAUTHORIZED_SETUP",
                            payload: response.data.statusCode,
                        });
                    } else {
                        Toast(response.data.message, "E");
                    }
                    dispatch({ type: "CAMPAIGN_TOGGLE_LOADING", payload: false });
                } else {
                    await dispatch({ type: types.FETCH_INDIRECT_LIST, payload: null });
                    await dispatch({ type: types.FETCH_INDIRECT_LIST, payload: response.data.response });
                    dispatch({ type: "CAMPAIGN_CURRENT_PAGE", payload: page });
                    dispatch({ type: "CAMPAIGN_TOGGLE_LOADING", payload: false });
                    window.scrollTo(0, 0);
                }
            }
        } catch (err) {
            Toast(err.response.message, 'E');
            dispatch({ type: "CAMPAIGN_TOGGLE_LOADING", payload: false });
            dispatch({ type: "UNAUTHORIZED_SETUP", payload: err.response.status });
        }
    };
};

// fetch BusinessNeed Listing 
export const fetchDirectListing = (authToken, search, page, filter, from_date, to_date) => {
    let filterData
    if (filter == 1) {
        filterData = "DAY"
    } else if (filter == 2) {
        filterData = "WEEK"
    } else if (filter == 3) {
        filterData = "MONTH"
    } else {
        filterData = null
    }
    return async (dispatch) => {
        dispatch({ type: "CAMPAIGN_TOGGLE_LOADING", payload: true });
        try {
            const response = await storeApis.post(
                DIRECT_LIST_URL,
                {
                    action: "run_campaign",
                    search: search,
                    page: page || 1,
                    limit: 20,
                    sort_by: filterData,
                    to_date: filter == 4 ? to_date:"",
                    from_date: filter == 4 ? from_date:"",
                },
                {
                    headers: {
                        Accept: "application/json",
                        Authorization: `Bearer ${authToken}`,
                    },
                }
          );
            if (response.status === 200) {
                if (response.data.statusCode == "403") {
                    if (response.data.message == "Invalid Credentials" || response.data.message == "Unauthenticated" || response.data.message == "Unauthorized") {
                        dispatch({
                            type: "UNAUTHORIZED_SETUP",
                            payload: response.data.statusCode,
                        });
                    } else {
                        Toast(response.data.message, "E");
                    }
                    dispatch({ type: "CAMPAIGN_TOGGLE_LOADING", payload: false });
                } else {
                    await dispatch({ type: types.FETCH_DIRECT_LIST, payload: null });
                    await dispatch({ type: types.FETCH_DIRECT_LIST, payload: response.data.response });
                    dispatch({ type: "CAMPAIGN_CURRENT_PAGE", payload: page });
                    dispatch({ type: "CAMPAIGN_TOGGLE_LOADING", payload: false });
                    window.scrollTo(0, 0);
                }
            }
        } catch (err) {
            Toast(err.response.message, 'E');
            dispatch({ type: "CAMPAIGN_TOGGLE_LOADING", payload: false });
            dispatch({ type: "UNAUTHORIZED_SETUP", payload: err.response.status });
        }
    };
};

// fetch fetchListDataListing 
export const fetchListDataListing = (authToken, search, page, sort_title, sort_value) => {
    return async (dispatch) => {
        dispatch({ type: "CAMPAIGN_TOGGLE_LOADING", payload: true });
        try {
            const response = await storeApis.post(
                LIST_DATA_URL,
                {
                    search: search,
                    page: page || 1,
                    limit: 20,
                    sort_type: sort_value,
                    sort_by: sort_title
                },
                {
                    headers: {
                        Accept: "application/json",
                        Authorization: `Bearer ${authToken}`,
                    },
                }
          );
            if (response.status === 200) {
                if (response.data.statusCode == "403") {
                    if (response.data.message == "Invalid Credentials" || response.data.message == "Unauthenticated" || response.data.message == "Unauthorized") {
                        dispatch({
                            type: "UNAUTHORIZED_SETUP",
                            payload: response.data.statusCode,
                        });
                    } else {
                        Toast(response.data.message, "E");
                    }
                    dispatch({ type: "CAMPAIGN_TOGGLE_LOADING", payload: false });
                } else {
                    await dispatch({ type: types.FETCH_LIST_DATA, payload: null });
                    await dispatch({ type: types.FETCH_LIST_DATA, payload: response.data });
                    dispatch({ type: "CAMPAIGN_CURRENT_PAGE", payload: page });
                    dispatch({ type: "CAMPAIGN_TOGGLE_LOADING", payload: false });
                    window.scrollTo(0, 0);
                }
            }
        } catch (err) {
            Toast(err.response.message, 'E');
            dispatch({ type: "CAMPAIGN_TOGGLE_LOADING", payload: false });
            dispatch({ type: "UNAUTHORIZED_SETUP", payload: err.response.status });
        }
    };
};

// fetch fetchListDataListing 
export const fetchListIndividualDataListing = (authToken, list_id, search, page, limit) => {
    return async (dispatch) => {
        dispatch({ type: "CAMPAIGN_TOGGLE_LOADING", payload: true });
        try {
            const response = await storeApis.post(
                "/framework/api/listDetails",
                {
                    list_id: list_id,
                    search: search,
                    page: page || 1,
                    limit: limit,
                    is_exist: 0
                },
                {
                    headers: {
                        Accept: "application/json",
                        Authorization: `Bearer ${authToken}`,
                    },
                }
          );
            if (response.status === 200) {
                if (response.data.statusCode == "403") {
                    if (response.data.message == "Invalid Credentials" || response.data.message == "Unauthenticated" || response.data.message == "Unauthorized") {
                        dispatch({
                            type: "UNAUTHORIZED_SETUP",
                            payload: response.data.statusCode,
                        });
                    } else {
                        Toast(response.data.message, "E");
                    }
                    dispatch({ type: "CAMPAIGN_TOGGLE_LOADING", payload: false });
                } else {
                    await dispatch({ type: types.FETCH_INDIVIDUAL_LIST_DATA, payload: null });
                    await dispatch({ type: types.FETCH_INDIVIDUAL_LIST_DATA, payload: response.data });
                    dispatch({ type: "CAMPAIGN_CURRENT_PAGE", payload: page });
                    dispatch({ type: "CAMPAIGN_TOGGLE_LOADING", payload: false });
                    window.scrollTo(0, 0);
                }
            }
        } catch (err) {
            Toast(err.response.message, 'E');
            dispatch({ type: "CAMPAIGN_TOGGLE_LOADING", payload: false });
            dispatch({ type: "UNAUTHORIZED_SETUP", payload: err.response.status });
        }
    };
};

// fetch fetchListDataListing 
export const viewListIndividualDataListing = (authToken, list_id, onSuccessViewList) => {
    return async (dispatch) => {
        dispatch({ type: "CAMPAIGN_TOGGLE_LOADING", payload: true });
        try {
            const response = await storeApis.post(
                "/framework/api/listDetails",
                {
                    list_id: list_id,
                    search: "",
                    page: 1,
                    limit: 200,
                    is_exist: 1
                },
                {
                    headers: {
                        Accept: "application/json",
                        Authorization: `Bearer ${authToken}`,
                    },
                }
          );
            if (response.status === 200) {
                if (response.data.statusCode == "403") {
                    if (response.data.message == "Invalid Credentials" || response.data.message == "Unauthenticated" || response.data.message == "Unauthorized") {
                        dispatch({
                            type: "UNAUTHORIZED_SETUP",
                            payload: response.data.statusCode,
                        });
                    } else {
                        Toast(response.data.message, "E");
                    }
                    dispatch({ type: "CAMPAIGN_TOGGLE_LOADING", payload: false });
                } else {
                    onSuccessViewList()
                    await dispatch({ type: types.FETCH_VIEW_LIST_DATA, payload: null });
                    await dispatch({ type: types.FETCH_VIEW_LIST_DATA, payload: response.data.data });
                    // dispatch({ type: "CAMPAIGN_CURRENT_PAGE", payload: page });
                    dispatch({ type: "CAMPAIGN_TOGGLE_LOADING", payload: false });
                    window.scrollTo(0, 0);
                }
            }
        } catch (err) {
            Toast(err.response.message, 'E');
            dispatch({ type: "CAMPAIGN_TOGGLE_LOADING", payload: false });
            dispatch({ type: "UNAUTHORIZED_SETUP", payload: err.response.status });
        }
    };
};

// fetch Individual Analytics Listing 
export const fetchIndividualAnalytics = (authToken, id) => {
    return async (dispatch) => {
        dispatch({ type: "CAMPAIGN_TOGGLE_LOADING", payload: true });
        try {
            const response = await storeApis.post(
                STATS_URL,
                {   
                    camp_id: id
                },
                {
                    headers: {
                        Accept: "application/json",
                        Authorization: `Bearer ${authToken}`,
                    },
                }
          );
            if (response.status === 200) {
                if (response.data.statusCode == "403") {
                    if (response.data.message == "Invalid Credentials" || response.data.message == "Unauthenticated" || response.data.message == "Unauthorized") {
                        dispatch({
                            type: "UNAUTHORIZED_SETUP",
                            payload: response.data.statusCode,
                        });
                    } else {
                        Toast(response.data.message, "E");
                    }
                    dispatch({ type: "CAMPAIGN_TOGGLE_LOADING", payload: false });
                } else {
                    await dispatch({ type: types.FETCH_LIST_ANALYTICS, payload: response.data.data });
                    dispatch({ type: "CAMPAIGN_TOGGLE_LOADING", payload: false });
                    // window.scrollTo(0, 0);
                }
            }
        } catch (err) {
            Toast(err.response.message, 'E');
            dispatch({ type: "CAMPAIGN_TOGGLE_LOADING", payload: false });
            dispatch({ type: "UNAUTHORIZED_SETUP", payload: err.response.status });
        }
    };
};

// fetch Individual Analytics Listing 
export const fetchIndirectAnalytics = (authToken, auto_email_id) => {
    return async (dispatch) => {
        dispatch({ type: "CAMPAIGN_TOGGLE_LOADING", payload: true });
        try {
            const response = await storeApis.post(
                INDIRECT_STATS_URL,
                {   
                    auto_email_flag: auto_email_id
                },
                {
                    headers: {
                        Accept: "application/json",
                        Authorization: `Bearer ${authToken}`,
                    },
                }
          );
            if (response.status === 200) {
                if (response.data.statusCode == "403") {
                    if (response.data.message == "Invalid Credentials" || response.data.message == "Unauthenticated" || response.data.message == "Unauthorized") {
                        dispatch({
                            type: "UNAUTHORIZED_SETUP",
                            payload: response.data.statusCode,
                        });
                    } else {
                        Toast(response.data.message, "E");
                    }
                    dispatch({ type: "CAMPAIGN_TOGGLE_LOADING", payload: false });
                } else {
                    await dispatch({ type: types.FETCH_LIST_ANALYTICS, payload: response.data.data });
                    dispatch({ type: "CAMPAIGN_TOGGLE_LOADING", payload: false });
                    // window.scrollTo(0, 0);
                }
            }
        } catch (err) {
            Toast(err.response.message, 'E');
            dispatch({ type: "CAMPAIGN_TOGGLE_LOADING", payload: false });
            dispatch({ type: "UNAUTHORIZED_SETUP", payload: err.response.status });
        }
    };
};

// fetch duplicate Campaign
export const duplicateCampaign = (authToken, camp_name, camp_id, path) => {
    return async (dispatch) => {
        dispatch({ type: "CAMPAIGN_TOGGLE_LOADING", payload: true });
        try {
            const response = await storeApis.post(
                DUPLICATE_LIST_URL,
                {
                    action: "copy_template",
                    camp_id: camp_id
                },
                {
                    headers: {
                        Accept: "application/json",
                        Authorization: `Bearer ${authToken}`,
                    },
                }
          );
            if (response.status === 200) {
                if (response.data.statusCode == "403") {
                    if (response.data.message == "Invalid Credentials" || response.data.message == "Unauthenticated" || response.data.message == "Unauthorized") {
                        dispatch({
                            type: "UNAUTHORIZED_SETUP",
                            payload: response.data.statusCode,
                        });
                    } else {
                        Toast(response.data.message, "E");
                    }
                    dispatch({ type: "CAMPAIGN_TOGGLE_LOADING", payload: false });
                } else {
                    if (path === "direct") {
                        dispatch(fetchDirectListing(authToken));
                    } else {
                        dispatch(fetchIndirectListing(authToken));
                    }
                    dispatch({ type: "CAMPAIGN_TOGGLE_LOADING", payload: false });
                    // window.scrollTo(0, 0);
                }
            }
        } catch (err) {
            Toast(err.response.message, 'E');
            dispatch({ type: "CAMPAIGN_TOGGLE_LOADING", payload: false });
            dispatch({ type: "UNAUTHORIZED_SETUP", payload: err.response.status });
        }
    };
};

// fetch BusinessNeed Listing 
export const fetchPickTemplateListing = (authToken, search, filter, page, path, user) => {
    return async (dispatch) => {
        dispatch({ type: "CAMPAIGN_TOGGLE_LOADING", payload: true });
        try {
            const response = await storeApis.post(
                "/framework/api/emailCampaign",
                {
                    action: user == "C" ? "select_template" : (path != "run_template" ? "select_template":"pick_template"),
                    filter: filter,
                    search: search,
                    page: page,
                    limit: 20
                },
                {
                    headers: {
                        Accept: "application/json",
                        Authorization: `Bearer ${authToken}`,
                    },
                }
          );
            if (response.status === 200) {
                if (response.data.statusCode == "403") {
                    if (response.data.message == "Invalid Credentials" || response.data.message == "Unauthenticated" || response.data.message == "Unauthorized") {
                        dispatch({
                            type: "UNAUTHORIZED_SETUP",
                            payload: response.data.statusCode,
                        });
                    } else {
                        Toast(response.data.message, "E");
                    }
                    dispatch({ type: "CAMPAIGN_TOGGLE_LOADING", payload: false });
                } else {
                    await dispatch({ type: types.FETCH_PICKUP_LISTING, payload: response.data.response });
                    dispatch({ type: "CAMPAIGN_TOGGLE_LOADING", payload: false });
                    window.scrollTo(0, 0);
                }
            }
        } catch (err) {
            Toast(err.response.message, 'E');
            dispatch({ type: "CAMPAIGN_TOGGLE_LOADING", payload: false });
            dispatch({ type: "UNAUTHORIZED_SETUP", payload: err.response.status });
        }
    };
};

// fetch BusinessNeed Listing 
export const fetchEmailListing = (authToken, type) => {
    return async (dispatch) => {
        dispatch({ type: "CAMPAIGN_TOGGLE_LOADING", payload: true });
        try {
            const response = await storeApis.post(
                EMAIL_REPLY_LIST,
                {
                    email_type: type //from, reply
                },
                {
                    headers: {
                        Accept: "application/json",
                        Authorization: `Bearer ${authToken}`,
                    },
                }
          );
            if (response.status === 200) {
                if (response.data.statusCode == "403") {
                    if (response.data.message == "Invalid Credentials" || response.data.message == "Unauthenticated" || response.data.message == "Unauthorized") {
                        dispatch({
                            type: "UNAUTHORIZED_SETUP",
                            payload: response.data.statusCode,
                        });
                    } else {
                        Toast(response.data.message, "E");
                    }
                    dispatch({ type: "CAMPAIGN_TOGGLE_LOADING", payload: false });
                } else {
                    if (type === "from") {
                        await dispatch({ type: types.FETCH_FROM_EMAIL, payload: response.data.data });
                    } else {
                        await dispatch({ type: types.FETCH_REPLY_EMAIL, payload: response.data.data });
                    }
                    dispatch({ type: "CAMPAIGN_TOGGLE_LOADING", payload: false });
                    window.scrollTo(0, 0);
                }
            }
        } catch (err) {
            Toast(err.response.message, 'E');
            dispatch({ type: "CAMPAIGN_TOGGLE_LOADING", payload: false });
            dispatch({ type: "UNAUTHORIZED_SETUP", payload: err.response.status });
        }
    };
};

// fetch BusinessNeed Listing 
export const fetchAutoemailData = (authToken, camp_id, is_new) => {
    return async (dispatch) => {
        dispatch({ type: "CAMPAIGN_TOGGLE_LOADING", payload: true });
        try {
            const response = await storeApis.post(
                "/framework/api/emailCampaign",
                {
                    action: "edit_campaign",
                    camp_id: camp_id,
                    is_new: is_new
                },
                {
                    headers: {
                        Accept: "application/json",
                        Authorization: `Bearer ${authToken}`,
                    },
                }
          );
            if (response.status === 200) {
                if (response.data.statusCode == "403") {
                    if (response.data.message == "Invalid Credentials" || response.data.message == "Unauthenticated" || response.data.message == "Unauthorized") {
                        dispatch({
                            type: "UNAUTHORIZED_SETUP",
                            payload: response.data.statusCode,
                        });
                    } else {
                        Toast(response.data.message, "E");
                    }
                    dispatch({ type: "CAMPAIGN_TOGGLE_LOADING", payload: false });
                } else {
                    await dispatch({ type: types.INDIVIDUAL_TEMPLATE_DATA, payload: response.data.data });
                    dispatch({ type: "CAMPAIGN_TOGGLE_LOADING", payload: false });
                    // window.scrollTo(0, 0);
                }
            }
        } catch (err) {
            Toast(err.response.message, 'E');
            dispatch({ type: "CAMPAIGN_TOGGLE_LOADING", payload: false });
            dispatch({ type: "UNAUTHORIZED_SETUP", payload: err.response.status });
        }
    };
};

// fetch Individual Analytics Listing 
export const fetchContactListData = (authToken, master) => {
    return async (dispatch) => {
        dispatch({ type: "CAMPAIGN_TOGGLE_LOADING", payload: true });
        try {
            const response = await storeApis.post(
                "/framework/api/listDashboard",
                {
                    search: "",
                    page: "1",
                    limit: "100",
                    is_master: master
                },
                {
                    headers: {
                        Accept: "application/json",
                        Authorization: `Bearer ${authToken}`,
                    },
                }
          );
            if (response.status === 200) {
                if (response.data.statusCode == "403") {
                    if (response.data.message == "Invalid Credentials" || response.data.message == "Unauthenticated" || response.data.message == "Unauthorized") {
                        dispatch({
                            type: "UNAUTHORIZED_SETUP",
                            payload: response.data.statusCode,
                        });
                    } else {
                        Toast(response.data.message, "E");
                    }
                    dispatch({ type: "CAMPAIGN_TOGGLE_LOADING", payload: false });
                } else {
                    await dispatch({ type: types.FETCH_CONTACTS_LIST_CAMPAIGN, payload: response.data.data });
                    
                    let filter_data = response.data?.data?.filter(obj => obj?.valid == 1);
                    await dispatch({ type: types.FILTERED_CONTACTS_LIST, payload: filter_data });
                    dispatch({ type: "CAMPAIGN_TOGGLE_LOADING", payload: false });
                    // window.scrollTo(0, 0);
                }
            }
        } catch (err) {
            Toast(err.response.message, 'E');
            dispatch({ type: "CAMPAIGN_TOGGLE_LOADING", payload: false });
            dispatch({ type: "UNAUTHORIZED_SETUP", payload: err.response.status });
        }
    };
};

// fetch Individual Analytics Listing 
export const fetchContentListData = (authToken, search , callback) => {
    return async (dispatch) => {
        dispatch({ type: "CAMPAIGN_TOGGLE_LOADING", payload: true });
        try {
            const response = await storeApis.post(
                "/framework/api/getContentData",
                {
                    search: search,
                    page: "1",
                    limit: "100"
                },
                {
                    headers: {
                        Accept: "application/json",
                        Authorization: `Bearer ${authToken}`,
                    },
                }
          );
            if (response.status === 200) {
                if (response.data.statusCode == "403") {
                    if (response.data.message == "Invalid Credentials" || response.data.message == "Unauthenticated" || response.data.message == "Unauthorized") {
                        dispatch({
                            type: "UNAUTHORIZED_SETUP",
                            payload: response.data.statusCode,
                        });
                    } else {
                        Toast(response.data.message, "E");
                    }
                    dispatch({ type: "CAMPAIGN_TOGGLE_LOADING", payload: false });
                } else {
                    const fetchedOptions = await response.data.data.map(option => ({
                        value: option.list_id,
                        label: option.contact_list_name
                    }));
                    await dispatch({ type: types.FETCH_CONTENT_LIST_CAMPAIGN, payload: fetchedOptions });
                    // callback(fetchedOptions);
                    dispatch({ type: "CAMPAIGN_TOGGLE_LOADING", payload: false });
                    // window.scrollTo(0, 0);
                }
            }
        } catch (err) {
            // callback([]);
            Toast(err.response.message, 'E');
            dispatch({ type: "CAMPAIGN_TOGGLE_LOADING", payload: false });
            dispatch({ type: "UNAUTHORIZED_SETUP", payload: err.response.status });
        }
    };
};

// Add addBusinessNeed
export const runTestEmailCampaign = (authToken, payload, onSuccess, path) => {
    return async (dispatch) => {
        dispatch({ type: "CAMPAIGN_TOGGLE_LOADING", payload: true });
        try {
            const response = await storeApis.post(
                "/framework/api/emailCampaign",
                payload,
                {
                    headers: {
                        Accept: "application/json",
                        Authorization: `Bearer ${authToken}`,
                    },
                }
          );
            if (response.status === 200) {
                if (response.data.statusCode == "403") {
                    if (response.data.message == "Invalid Credentials" || response.data.message == "Unauthenticated" || response.data.message == "Unauthorized") {
                        dispatch({
                            type: "UNAUTHORIZED_SETUP",
                            payload: response.data.statusCode,
                        });
                    } else {
                        Toast(response.data.message, "E");
                    }
                    dispatch({ type: "CAMPAIGN_TOGGLE_LOADING", payload: false });
                } else {
                    Toast(response.data.message, "S");
                    onSuccess(path);
                    dispatch({ type: "CAMPAIGN_TOGGLE_LOADING", payload: false });
                }
            }
        } catch (err) {
            Toast(err.response.message, 'E');
            dispatch({ type: "CAMPAIGN_TOGGLE_LOADING", payload: false });
            dispatch({ type: "UNAUTHORIZED_SETUP", payload: err.response.status });
        }
    };
};

export const pushEmailCampaign = (authToken, payload, onSuccess) => {
    return async (dispatch) => {
        dispatch({ type: "CAMPAIGN_TOGGLE_LOADING", payload: true });
        try {
            const response = await storeApis.post(
                "/framework/api/emailCampaign",
                payload,
                {
                    headers: {
                        Accept: "application/json",
                        Authorization: `Bearer ${authToken}`,
                    },
                }
          );
            if (response.status === 200) {
                if (response.data.statusCode == "403") {
                    if (response.data.message == "Invalid Credentials" || response.data.message == "Unauthenticated" || response.data.message == "Unauthorized") {
                        dispatch({
                            type: "UNAUTHORIZED_SETUP",
                            payload: response.data.statusCode,
                        });
                    } else {
                        Toast(response.data.message, "E");
                    }
                    dispatch({ type: "CAMPAIGN_TOGGLE_LOADING", payload: false });
                } else {
                    Toast(response.data.message, "S");
                    onSuccess("push");
                    dispatch({ type: "CAMPAIGN_TOGGLE_LOADING", payload: false });
                }
            }
        } catch (err) {
            Toast(err.response.message, 'E');
            dispatch({ type: "CAMPAIGN_TOGGLE_LOADING", payload: false });
            dispatch({ type: "UNAUTHORIZED_SETUP", payload: err.response.status });
        }
    };
};

// Delete BussinessData
export const deleteDirectListData = (authToken, id) => {
    return async (dispatch) => {
        dispatch({ type: "CAMPAIGN_TOGGLE_LOADING", payload: true });
        try {
            const response = await storeApis.post(
                "/framework/api/emailCampaign",
                {
                    action: "delete_template",
                    camp_ids: [id]
                },
                {
                    headers: {
                        Accept: "application/json",
                        Authorization: `Bearer ${authToken}`,
                    },
                }
            );
            if (response.status === 200) {
                if (response.data.statusCode == "403") {
                    if (response.data.message == "Invalid Credentials" || response.data.message == "Unauthenticated" || response.data.message == "Unauthorized") {
                        dispatch({
                            type: "UNAUTHORIZED_SETUP",
                            payload: response.data.statusCode,
                        });
                    } else {
                        Toast(response.data.message, "E");
                    }
                    dispatch({ type: "CAMPAIGN_TOGGLE_LOADING", payload: false });
                } else {
                    dispatch(fetchDirectListing(authToken,"", 1))
                    Toast(response.data.response, "S");
                    dispatch({ type: "CAMPAIGN_TOGGLE_LOADING", payload: false });
                }
            }
        } catch (err) {
            Toast(err.response.message, 'E');
            dispatch({ type: "CAMPAIGN_TOGGLE_LOADING", payload: false });
            dispatch({ type: "UNAUTHORIZED_SETUP", payload: err.response.status });
        }
    };
};

// Delete BussinessData
export const deleteIndirectListData = (authToken, id) => {
    return async (dispatch) => {
        dispatch({ type: "CAMPAIGN_TOGGLE_LOADING", payload: true });
        try {
            const response = await storeApis.post(
                "/framework/api/emailCampaign",
                {
                    action: "delete_template",
                    camp_ids: [id]
                },
                {
                    headers: {
                        Accept: "application/json",
                        Authorization: `Bearer ${authToken}`,
                    },
                }
          );
            if (response.status === 200) {
                if (response.data.statusCode == "403") {
                    if (response.data.message == "Invalid Credentials" || response.data.message == "Unauthenticated" || response.data.message == "Unauthorized") {
                        dispatch({
                            type: "UNAUTHORIZED_SETUP",
                            payload: response.data.statusCode,
                        });
                    } else {
                        Toast(response.data.message, "E");
                    }
                    dispatch({ type: "CAMPAIGN_TOGGLE_LOADING", payload: false });
                } else {
                    dispatch(fetchIndirectListing(authToken,"", 1))
                    Toast(response.data.response, "S");
                    dispatch({ type: "CAMPAIGN_TOGGLE_LOADING", payload: false });
                }
            }
        } catch (err) {
            Toast(err.response.message, 'E');
            dispatch({ type: "CAMPAIGN_TOGGLE_LOADING", payload: false });
            dispatch({ type: "UNAUTHORIZED_SETUP", payload: err.response.status });
        }
    };
};

// Delete deleteContactGroupData
export const deleteContactGroupData = (authToken, id , page) => {
    return async (dispatch) => {
        dispatch({ type: "CAMPAIGN_TOGGLE_LOADING", payload: true });
        try {
            const response = await storeApis.post(
                "/framework/api/deleteContactList",
                {
                    list_id: id
                },
                {
                    headers: {
                        Accept: "application/json",
                        Authorization: `Bearer ${authToken}`,
                    },
                }
          );
            if (response.status === 200) {
                if (response.data.statusCode == "403") {
                    if (response.data.message == "Invalid Credentials" || response.data.message == "Unauthenticated" || response.data.message == "Unauthorized") {
                        dispatch({
                            type: "UNAUTHORIZED_SETUP",
                            payload: response.data.statusCode,
                        });
                    } else {
                        Toast(response.data.message, "E");
                    }
                    dispatch({ type: "CAMPAIGN_TOGGLE_LOADING", payload: false });
                } else {
                    dispatch(fetchListDataListing(authToken, "", page, "", ""));
                    Toast(response.data.response, "S");
                    dispatch({ type: "CAMPAIGN_TOGGLE_LOADING", payload: false });
                }
            }
        } catch (err) {
            Toast(err.response.message, 'E');
            dispatch({ type: "CAMPAIGN_TOGGLE_LOADING", payload: false });
            dispatch({ type: "UNAUTHORIZED_SETUP", payload: err.response.status });
        }
    };
};

// Delete deleteContactListData
export const deleteContactListData = (authToken, id) => {
    return async (dispatch) => {
        dispatch({ type: "CAMPAIGN_TOGGLE_LOADING", payload: true });
        try {
            const response = await storeApis.post(
                "/framework/api/deleteContact",
                {
                    contact_ids: id
                },
                {
                    headers: {
                        Accept: "application/json",
                        Authorization: `Bearer ${authToken}`,
                    },
                }
          );
            if (response.status === 200) {
                if (response.data.statusCode == "403") {
                    if (response.data.message == "Invalid Credentials" || response.data.message == "Unauthenticated" || response.data.message == "Unauthorized") {
                        dispatch({
                            type: "UNAUTHORIZED_SETUP",
                            payload: response.data.statusCode,
                        });
                    } else {
                        Toast(response.data.message, "E");
                    }
                    dispatch({ type: "CAMPAIGN_TOGGLE_LOADING", payload: false });
                } else {
                    Toast(response.data.response, "S");
                    dispatch({ type: "CAMPAIGN_TOGGLE_LOADING", payload: false });
                }
            }
        } catch (err) {
            Toast(err.response.message, 'E');
            dispatch({ type: "CAMPAIGN_TOGGLE_LOADING", payload: false });
            dispatch({ type: "UNAUTHORIZED_SETUP", payload: err.response.status });
        }
    };
};

// add addContactList 
export const addContactList = (authToken, title, onSuccess) => {
    return async (dispatch) => {
        dispatch({ type: "CAMPAIGN_TOGGLE_LOADING", payload: true });
        try {
            const response = await storeApis.post(
                "/framework/api/appCreateContactlist",
                {
                    list_name : title
                },
                {
                    headers: {
                        Accept: "application/json",
                        Authorization: `Bearer ${authToken}`,
                    },
                }
          );
            if (response.status === 200) {
                if (response.data.statusCode == "403") {
                    if (response.data.message == "Invalid Credentials" || response.data.message == "Unauthenticated" || response.data.message == "Unauthorized") {
                        dispatch({
                            type: "UNAUTHORIZED_SETUP",
                            payload: response.data.statusCode,
                        });
                    } else {
                        Toast(response.data.message, "E");
                    }
                    dispatch({ type: "ROUTE_CHANGE_FLAG", payload: false });
                    dispatch({ type: "CAMPAIGN_TOGGLE_LOADING", payload: false });
                } else {
                    onSuccess();
                    dispatch({ type: "NEW_LIST_ID", payload: response.data.response.list_id });
                    // Toast(response.data.message, "S");
                    // window.history.back()
                    dispatch({ type: "CAMPAIGN_TOGGLE_LOADING", payload: false });
                }
            }
        } catch (err) {
            Toast(err.response.message, 'E');
            dispatch({ type: "ROUTE_CHANGE_FLAG", payload: false });
            dispatch({ type: "CAMPAIGN_TOGGLE_LOADING", payload: false });
            dispatch({ type: "UNAUTHORIZED_SETUP", payload: err.response.status });
        }
    };
};

// add addSelectedContactIdsList
export const addSelectedContactIdsList = (authToken, list_id, contact_ids, onSuccessAddingContact) => {
    return async (dispatch) => {
        dispatch({ type: "CAMPAIGN_TOGGLE_LOADING", payload: true });
        try {
            const response = await storeApis.post(
                "/framework/api/addContact",
                {
                    list_id: list_id,
                    contact_ids: contact_ids
                },
                {
                    headers: {
                        Accept: "application/json",
                        Authorization: `Bearer ${authToken}`,
                    },
                }
          );
            if (response.status === 200) {
                if (response.data.statusCode == "403") {
                    if (response.data.message == "Invalid Credentials" || response.data.message == "Unauthenticated" || response.data.message == "Unauthorized") {
                        dispatch({
                            type: "UNAUTHORIZED_SETUP",
                            payload: response.data.statusCode,
                        });
                    } else {
                        Toast(response.data.message, "E");
                    }
                    dispatch({ type: "ROUTE_CHANGE_FLAG", payload: false });
                    dispatch({ type: "CAMPAIGN_TOGGLE_LOADING", payload: false });
                } else {
                    await dispatch(fetchListDataListing(authToken, "", 1, "", ""));
                    onSuccessAddingContact();
                    dispatch({ type: "CAMPAIGN_TOGGLE_LOADING", payload: false });
                }
            }
        } catch (err) {
            Toast(err.response.message, 'E');
            dispatch({ type: "ROUTE_CHANGE_FLAG", payload: false });
            dispatch({ type: "CAMPAIGN_TOGGLE_LOADING", payload: false });
            dispatch({ type: "UNAUTHORIZED_SETUP", payload: err.response.status });
        }
    };
};

// Delete BussinessData
// export const deleteBusinessNeed = (authToken, id) => {
//     return async (dispatch) => {
//         dispatch({ type: "CAMPAIGN_TOGGLE_LOADING", payload: true });
//         try {
//             const response = await storeApis.post(
//                 "BUSINESS_NEED_DELETE",
//                 {
//                    type_id: id
//                 },
//                 {
//                     headers: {
//                         Accept: "application/json",
//                         Authorization: `Bearer ${authToken}`,
//                     },
//                 }
//           );
//             if (response.status === 200) {
//                 if (response.data.statusCode == "403") {
//                     if (response.data.message == "Invalid Credentials" || response.data.message == "Unauthenticated" || response.data.message == "Unauthorized") {
//                         dispatch({
//                             type: "UNAUTHORIZED_SETUP",
//                             payload: response.data.statusCode,
//                         });
//                     } else {
//                         Toast(response.data.message, "E");
//                     }
//                     dispatch({ type: "CAMPAIGN_TOGGLE_LOADING", payload: false });
//                 } else {
//                     // await dispatch({ type: types.FETCH_CATEGORY_LIST, payload: null });
//                     dispatch(fetchBusinessNeed(authToken));
//                     Toast(response.data.response, "S");
//                     dispatch({ type: "CAMPAIGN_TOGGLE_LOADING", payload: false });
//                 }
//             }
//         } catch (err) {
//             Toast(err.response.message, 'E');
//             dispatch({ type: "CAMPAIGN_TOGGLE_LOADING", payload: false });
//             dispatch({ type: "UNAUTHORIZED_SETUP", payload: err.response.status });
//         }
//     };
// };

