import React from "react";
import * as Icon from "react-bootstrap-icons";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Form, Table, Modal, Button } from "react-bootstrap";
import Toast from "../shared/Toast";
import { editHashtagName } from "../../redux/actions/setupActions";
// import { useNavigate } from "react-router-dom";

const SelectTable = (props) => {
//   let navigate = useNavigate();
    const dispatch = useDispatch();
    const [show, setShow] = useState(false);
    const [hashChecked, setHashChecked] = useState(false);
    const [titleBlock, setTitleBlock] = useState("");
    const [tagName, setTagName] = useState("");
    const temp = "web"
    
    const editHashtag = (u_id, tagTitle) => {
        if (props.listItem.tag !== tagTitle) {
            dispatch(editHashtagName(props.authToken, { id : u_id , internal_hashtag : tagTitle }))
            handleClose();
        } else {
            Toast("Please update the hashtag name", "E");
        }
    } 
    
    const handleEditName = (e) => {
        setTagName(e.target.value);
    }
    const handleClose = () => setShow(false);
    
    const handleShow = () => setShow(true);
    
    useEffect(() => {
        if (props.isSelectAll === true) {
            setHashChecked(true);
        }  
    },[props.isSelectAll])
    
    useEffect(() => {
        if (props.checked === 0) {
            setHashChecked(false);
        }
    }, [props.checked]);
    
    useEffect(() => {
        if (props.tableData === "hashtag") {
            setTitleBlock(props.listItem && props.listItem.tag ? props.listItem.tag.slice(0, 20) : "" )
        } else {
            setTitleBlock(props.listItem.search_text && props.listItem.search_text ? props.listItem.search_text.slice(0, 20) :"")
        }
    }, [])
    
    return (
        <div>
            <Form>
                {/* <div className="hashtag-list-card mb-3"> */}
                <div className={"hashtag-list-card mb-3 " + ((props.checked > 0 && hashChecked) || props.isSelectAll === true ? "selected-card" : "")}>
                    <Table>
                    <tbody>
                        <tr>
                        {props.tableData !== "gSearch-right" && <td style={{ width: "5%", textAlign: "center" }}>
                            <Form.Check
                                type="checkbox"
                                className="check-box"
                                checked={hashChecked}
                                value={props.listItem.id}
                                onChange={(e) => {
                                    setHashChecked(!hashChecked);
                                    props.handleCheck(e, props.listItem.id, temp);
                                    if (props.isSelectAll === true) {
                                        props.handleUnselectedData(false);
                                    }
                                }}
                            />
                        </td> }
                        {props.tableData === "gSearch-right" && <td className="hashtag-name" style={{ width: "5%", textAlign: "center" }}>
                            {props.index}.
                        </td>}
                        <td title={props.listItem.tag || props.listItem.search_text} className={props.tableData !== "gSearch" && props.tableData !== "gSearch-left" ?"hashtag-name":"hashtag-name w-50"}>{titleBlock}
                            {(props.listItem?.tag?.length > 20 || props.listItem?.search_text?.length > 20) && <span>...</span>}
                        </td>
                        { props.tableData !== "gSearch-left" && props.tableData !== "gSearch-right" && props.tableData !== "gSearch" && <td style={{ width: "12%" }}>
                            <span className="bordering-date date-style">
                            {props.listItem && props.listItem.date && props.listItem.date.slice(0,10)}
                            </span>
                        </td> }
                        {props.tableData !== "gSearch" && props.tableData !== "gSearch-left" && props.tableData !== "gSearch-right" && <td className="dot">
                            <Icon.CircleFill color="#C4C4C4" />
                        </td>}
                        {props.tableData === "hashtag" ? 
                            (<td className="bordering-date time-style">
                                <div className="list-card-tags">
                                    {props.listItem && props.listItem.date && props.listItem.date.slice(-10,-5) + props.listItem.date.slice(-2) }
                                </div>
                            </td>)
                            :
                            (<td className={(props.tableData === "gSearch" || props.tableData === "gSearch-left" || props.tableData === "gSearch-right" ? "gsearch-align":"")+" bordering-date time-style"}>
                                <div className="list-card-tags">{props.listItem.most_search} searches</div>
                            </td>)
                        }
                        {props.tableData !== "gSearch" && props.tableData !== "gSearch-left" && props.tableData !== "gSearch-right" && <td style={{ justifyContent: "end" }} className="d-flex">
                                <div className="card-footer-action list-card" onClick={handleShow}>
                                    <span className="edit-icon">
                                        <Icon.PencilSquare color="#64B6E7" />
                                        Edit
                                    </span>
                                </div>
                            </td>
                        }
                        {props.isExpand === true &&
                            <td style={{ justifyContent: "end" }} className="d-flex">
                                <div className="card-footer-action list-card">
                                    {props.listItem.popular === 1 && <span className="approve-span">
                                        Popular
                                    </span>}
                                </div>
                            </td>
                        }
                        {props.tableData === "gSearch" &&
                            <td style={{ justifyContent: "end" }} className="d-flex">
                                <div className="card-footer-action list-card">
                                    {props.listItem.approved === 1 && <span className="approve-span">
                                        Approved
                                    </span>}
                                </div>
                            </td>}
                        </tr>
                    </tbody>
                    </Table>
                </div>
            </Form>
            
            {/* Edit popup  */}
            <Modal show={show} onHide={handleClose} size="sm" animation="true" centered>
                <Modal.Header closeButton>
                    <Modal.Title className='modal-heading-custom'>Edit hashtag name</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Control defaultValue={props.listItem.tag} onChange={handleEditName} type="text" placeholder="Hashtag Name" />
                    <Button className='common-setup-button w-100 mt-3' variant="primary" onClick={() => { editHashtag(props.listItem.id,tagName) }}>
                        Save Changes
                    </Button>
                </Modal.Body>
            </Modal>
        </div>
    );
};

export default SelectTable;
