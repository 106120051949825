/* eslint-disable */
import { Card, Button, Dropdown, Form, Modal } from "react-bootstrap";
import "./AssetCard.css";
import * as Icon from "react-bootstrap-icons";
// import { Link } from "react-router-dom";
import React from "react";
import Bookmark from "../../../Bookmark";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

//Redux-imports
import { useDispatch, useSelector } from "react-redux";
import {
  fetchContent,
  deleteContent,
  duplicateContent,
  fetchAssetLibraryContentList,
  //fetchFolderContentList,
  globalFilterAssetLibraryContentList,
  downloadFile,
  setSelectedAssetType,
  setPublishType,
} from "../../../../redux/actions/actions";

const AssetCard = (props) => {
  const dispatch = useDispatch();

  let navigate = useNavigate();

  const isLoading = useSelector((state) => state.assetLibrary.loading);

  const [assetChecked, setAssetChecked] = useState(false);
  const [baseUrlAsset, setBaseUrlAsset] = useState("");
  let partnerCategory = [];
  let userCategory = [];
  const imgUrl = props.content.thumb_image;

  if (props.content.partner_category) {
    partnerCategory = props.content.partner_category.slice(0, 2);
  }
  if (props.content.users_category) {
    userCategory = props.content.users_category.slice(0, 2);
  }

  const globalSearchKey = useSelector((state) => state.assetLibrary.globalSearchKey);
  const qFilterKey = useSelector((state) => state.assetLibrary.qFilterKey);
  const cFilterKey = useSelector((state) => state.assetLibrary.cFilterKey);
  const pFilterKey = useSelector((state) => state.assetLibrary.pFilterKey);
  const sFilterKey = useSelector((state) => state.assetLibrary.sFilterKey);
  const svFilterKey = useSelector((state) => state.assetLibrary.svFilterKey);
  const talic_active = useSelector((state) => state.assetLibrary.talic_active);
  const currFolderId = useSelector((state) => state.assetLibrary.folderId);
  const currFolderType = useSelector((state) => state.assetLibrary.folderType);
  const currFolderName = useSelector((state) => state.assetLibrary.folderName);

  const [popupMsg, setpopupMsg] = useState(null);
  const [popupAction, setpopupAction] = useState(null);

  const [show, setShow] = useState(false);
  const [loadImage, setLoadImage] = useState(false);
  const handleClose = () => {
    setShow(false);
  };
  const handleShow = () => setShow(true);
  const handleDownload = (url) => {
    dispatch(downloadFile(props.authToken,url))
  }
  
  useEffect(() => {
    let baseUrl
    let tempUrl = `${window.location.href}`;
    if (tempUrl.search("localhost") > 0) {
      baseUrl = "https://app.spdevmfp.com/";
      setBaseUrlAsset(baseUrl);
    } else {
      baseUrl = window.location.href;
      baseUrl = baseUrl.split('/')[0] + "/" + baseUrl.split('/')[1] + "/" + baseUrl.split('/')[2] + "/";
      setBaseUrlAsset(baseUrl);
    }
  }, [])
  
  
  useEffect(() => {
    if (props.checked === 0) {
      setAssetChecked(false);
    }
  }, [props.checked]);

  return (
    <>
      {
        isLoading ? 
          <Card style={{ borderRadius: '10px',borderColor: '#ececec' }}>
            {/* <Card.Img variant="top"
                src={imgUrl}
                onLoad={() => {
                    setLoadImage(true)
                }}
              /> */}
              <div style={{borderTopLeftRadius: '10px', borderTopRightRadius: '10px', border:'none', height: '150px'}} className="card-img-top-new shine"></div>
              <Card.Body>
                <div className="card-body-wrapper">
                  <div className="card-tags">
                    <div style={{width:"50px",height:"20px", backgroundColor: '#fff'}}></div>
                    <div style={{width:"100px",height:"20px", backgroundColor: '#fff'}}></div>
                  </div>
                  <Card.Title>
                    <div className="solution_types shine" style={{fontSize:"10px"}}></div>
                  </Card.Title>
                  <Card.Text className="shine" style={{height: "25px"}}></Card.Text>
                  <Card.Title style={{height: "12px"}} className="my-4 shine"></Card.Title>
                  {/* {props.content.activity_date && props.userType === "C" && (
                    <Card.Title>
                      {"Published"}
                      <span>{props.content.activity_date}</span>
                    </Card.Title>
                  )} */}


                </div>
                <div className="hr-divider" style={{margin:"0px 0px",opacity: '0.1'}}>
                  <hr />
                </div>
                <div className="card-footer-action">
                  <Button className="shine" size="sm" variant="secondary" style={{width:"78px", height: "30px", border: "none"}}></Button>
                </div>
              </Card.Body>
          </Card>
          :
          <Card
            className={
              "asset-card " +
              (props.assetChecked > 0 || assetChecked ? "selected-card" : "")
            }
          >
            <Form>
              <Form.Check
                type="checkbox"
                className="check-box"
                checked={assetChecked}
                value={props.content.content_id}
                onChange={(e) => {
                  setAssetChecked(!assetChecked);
                  props.handleCheck(
                    e,
                    props.content.content_id,
                    props.content.type
                  );
                }}
              />
              {/* <div className="x-count">x2</div> */}

              <Card.Img variant="top"
                src={imgUrl}
                onLoad={() => {
                    setLoadImage(true)
                }}
              />
              <Card.Body>
                <div className="card-body-wrapper">
                  <div className="card-tags">
                    {(props.content.new === 1) && <div className="new-card-tag">NEW</div>}
                    <div>{props.content.content_type}</div>
                    {(props.content.cobrand === 1) && <div>CO-BRANDED</div>}
                    {/* {props.content.contenttype_status && (
                      <div>{props.content.contenttype_status}</div>
                    )} */}
                  </div>
                  <Card.Title>
                    <div
                      className="solution_types"
                      title={
                        props.content.solution_types.length > 1 &&
                        props.content.solution_types.map((type) => {
                          return " " + type.it_type;
                        })
                      }
                    >
                      {props.content.solution_types.length > 1
                        ? props.content.solution_types.map((type) => {
                          return <>{type.it_type + ", "}</>;
                        })
                        : props.content.solution_types.map((type) => {
                          return <>{type.it_type}</>;
                        })}
                    </div>
                    {talic_active == "tal" && props.content?.sub_soultion_types?.length > 0 && props.content?.sub_soultion_types?.map((sub, s_ind)=>{
                      return (
                        <span className="font-change">{ sub?.subcat_title }</span>
                      );
                    })}
                    <Bookmark
                      authToken={props.authToken}
                      bookmarkStatus={props.content.bookmark}
                      id={props.content.content_id}
                      type={props.content.type}
                    />
                    {/* <img className="asset-card-tag" src={tagImgUrl} onClick={()=>alert('test')}/>  */}
                  </Card.Title>
                  <a
                    href={() => false}
                    onClick={() => {
                      dispatch(
                        fetchContent(props.authToken, {
                          [props.content.type]: [props.content.content_id],
                        })
                      );
                      navigate("/asset-details");
                    }}
                    title={props.content.content_title}
                  >
                    <Card.Text>{props.content.content_title}</Card.Text>
                  </a>
                  {props.content.activity_date && (props.userType === "P" || props.userType === "S") && (
                    <Card.Title>
                      {props.content.expired == 1 ? "Expired" : props.content.status}
                      <span>{props.content.status === "Scheduled" ? props.content.scheduled_date : props.content.activity_date}</span>
                    </Card.Title>
                  )}
                  {props.content.activity_date && props.userType === "C" && (
                    <Card.Title>
                      {"Published"}
                      <span>{props.content.activity_date}</span>
                    </Card.Title>
                  )}


                </div>
                {(props.userType !== "N") && <div className="hr-divider">
                  <hr />
                </div>}
                {(props.userType === "P" || props.userType === "S") ? (
                  <div className="card-footer-action">
                    {props.content.status &&
                      ((props.content.status === "Published" || props.content.status === "Scheduled") ? (
                        <Button size="sm" variant="secondary" disabled>
                          {props.content.status}
                        </Button>
                      ) : (
                        <Button
                          size="sm"
                          variant="outline-info"
                          onClick={() => {
                            // props.publishAssetContent(
                            //   props.content.content_id,
                            //   props.content.type
                            // );

                            dispatch(setPublishType(true));
                            dispatch(
                              setSelectedAssetType({
                                id: props.content.content_id,
                                article_type: props.content.type,
                                type: props.content.type,
                              })
                            );
                            dispatch(
                              fetchContent(props.authToken, {
                                [props.content.type]: [props.content.content_id],
                              })
                            );
                            setTimeout(() => {
                              navigate("/new-asset/publish-asset");
                            }, 1000);
                          }}
                        >
                          Publish
                        </Button>
                      ))}

                    {(props.content.status === "Published") && <>
                      {partnerCategory &&
                        partnerCategory.map((category) => {
                          //const partnerName = category.split(" ");
                          //const initials = partnerName.shift().charAt(0) + partnerName.pop().charAt(0);
                          //initials.toUpperCase(); 
                          const partnerName = category.name.charAt(0);
                          return (
                            <>
                              <div className="circle" title={category.name}>
                                {partnerName}
                              </div>
                              {/* <div className="square">Edu.</div> */}
                            </>
                          );
                        })}

                      {partnerCategory.length > 1 && (
                        <div
                          className="count"
                          title={
                            props.content.partner_category &&
                            props.content.partner_category.map(
                              (category) => " " + category.name
                            )
                          }
                        >
                          {props.content.partner_category.length - 2 > 0
                            ? `+${props.content.partner_category.length - 2}`
                            : ""}
                        </div>
                      )}

                      {userCategory &&
                        userCategory.map((category) => {
                          //const partnerName = category.split(" ");
                          //const initials = partnerName.shift().charAt(0) + partnerName.pop().charAt(0);
                          //initials.toUpperCase();
                          const userName = category.name.charAt(0);
                          return (
                            <>
                              <div className="circle" title={category.name}>
                                {userName}
                              </div>
                              {/* <div className="square">Edu.</div> */}
                            </>
                          );
                        })}

                      {userCategory.length > 1 && (
                        <div
                          className="count"
                          title={
                            props.content.users_category &&
                            props.content.users_category.map(
                              (category) => " " + category.name
                            )
                          }
                        >
                          {props.content.users_category.length - 2 > 0
                            ? `+${props.content.users_category.length - 2}`
                            : ""}
                        </div>
                      )}
                    </>}

                    <Dropdown className="action-btn">
                      <Dropdown.Toggle variant="light" id="dropdown-basic">
                        <Icon.ThreeDotsVertical />
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        <Dropdown.Item
                          onClick={() => {
                            setpopupAction("Duplicate");
                            setpopupMsg(
                              "Are you sure that you want to duplicate this Asset?"
                            );
                            handleShow();
                          }}
                        >
                          Duplicate
                        </Dropdown.Item>
                        <Dropdown.Item
                          onClick={() => {
                            setpopupAction("Delete");
                            setpopupMsg(
                              "Are you sure that you want to delete this Asset?"
                            );
                            handleShow();
                          }}
                        >
                          Delete
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                ) : props.userType === "N" ? "" : (
                    <div className="card-footer-action">
                      <a href={`${baseUrlAsset}framework/api/file?url=${props.content.link}`} target="blank"><Icon.Download className="download-icon-asset"/></a>
                      <div
                        className="share-btn"
                        onClick={() => props.shareHandleShow(props.content.content_id, props.content.content_type)}
                      >
                        Share
                        <span>
                          <Icon.ChevronRight />
                        </span>
                      </div>
                  </div>
                )}
              </Card.Body>
            </Form>
            <Modal show={show} onHide={handleClose} >
              <Modal.Header closeButton></Modal.Header>
              <Modal.Body style={{ "textAlign": "center" }}>
                <div className="alert-icon">
                  <Icon.ExclamationTriangle />
                </div>
                {/* Are you sure that you want to delete this Asset? */}
                {popupMsg}
              </Modal.Body>
              <Modal.Footer>
                <Button
                  variant="info"
                  onClick={() => {
                    if (popupAction === "Delete") {
                      dispatch(
                        deleteContent(
                          props.authToken,
                          props.content.content_id,
                          props.content.type
                        )
                      );
                      props.handleCheck(null);
                    } else if (popupAction === "Duplicate") {
                      dispatch(
                        duplicateContent(
                          props.authToken,
                          props.content.content_id,
                          props.content.type
                        )
                      );
                    }
                    setTimeout(() => {
                      if (currFolderId) {
                        dispatch(globalFilterAssetLibraryContentList(props.authToken, globalSearchKey, pFilterKey, qFilterKey, cFilterKey, sFilterKey, svFilterKey, currFolderId, currFolderType, currFolderName));
                      } else {
                        //dispatch(fetchAssetLibraryContentList(props.authToken));
                        dispatch(globalFilterAssetLibraryContentList(props.authToken, globalSearchKey, pFilterKey, qFilterKey, cFilterKey, sFilterKey, svFilterKey));
                      }
                    }, 2000);
                    handleClose();
                  }}
                >
                  Yes
                </Button>
                <Button
                  variant="info"
                  onClick={handleClose}
                >
                  No
                </Button>
              </Modal.Footer>
            </Modal>
          </Card>
      }
    </>
  );
};

export default AssetCard;
