/* eslint-disable */
import Loading from "../../../Loading";
import { Row, Col, Card, Button, Modal } from "react-bootstrap";
import * as Icon from "react-bootstrap-icons";
import { useState, useEffect } from "react";
import _ from "lodash";
import CardViewContainer from "../asset-list/CardViewContainer";
import { useNavigate } from "react-router-dom";

//Redux-imports
import { useDispatch, useSelector } from "react-redux";
import {
  //SetLoaderStatus,
  fetchContent,
  setBreadcrumbStatus,
  setSelectedAssetPublish,
} from "../../../../redux/actions/actions";

const UploadedData = (props) => {
  const authToken = props.authToken;

  const isLoading = useSelector((state) => state.assetUpload.loading);

  const selectedAssetType = useSelector(
    (state) => state.assetUpload.selectedAssetType
  );

  const uploadedData = useSelector(
    (state) => state.assetUpload.saveGlobalFieldsData
  );

  const assetContentList = [useSelector((state) => state.assetLibrary.currentAssetDetails)];

  //console.log("selectedAssetType", selectedAssetType);
  //console.log("uploadedData>>", uploadedData);

  const dispatch = useDispatch();

  const [checked, setChecked] = useState(0);
  const [contentList, setContentList] = useState({});
  //const [uploadedDataIDs, setUploadDataIDs] = useState(null);

  const [show, setShow] = useState(false);
  const handleClose = () => {
    setShow(false);
  };
  const handleShow = () => setShow(true);

  // const getIDs = ()=> {
  //   dispatch(fetchContent(authToken, { [selectedAssetType.article_type]: uploadedData }));
  // }

  // if (uploadedData && !uploadedData.length === 0) {
  //   //setUploadDataIDs(uploadedData.length);
  //   getIDs();
  // }

  // if (uploadedData) {
  //   setUploadDataIDs(uploadedData.length); 
  //   getIDs();
  // }

  let navigate = useNavigate();

  useEffect(() => {
    //getIDs()
    //console.log("uploadedDataUSE");
    dispatch(fetchContent(authToken, { [selectedAssetType.type]: uploadedData }));
   // dispatch(fetchContent(authToken, { Social: uploadedData  }))
  }, []);


  // Add/Remove checked item from list
  const handleCheck = (e, id, type) => {
    let _updatedContent = _.clone(contentList);
    let count = checked;
    if (e.target.checked) {
      // if (_.has(contentList, [type])) {
      //   if (!_updatedContent[type].includes(id)) {
      //     _updatedContent[type].push(id);
      //   }
      // } else {
      if (_.isEmpty(contentList)) {
        _updatedContent["data"] = [
          { Base: { content_id: id, content_name: type } },
        ];
      } else {
        _updatedContent["data"] = [
          ..._updatedContent.data,
          { Base: { content_id: id, content_name: type } },
        ];
      }
      // }
      setChecked(count + 1);
    } else {
      //if (_.has(contentList, [type])) {
      _updatedContent["data"] = _updatedContent["data"].filter((item) => {
        return item.Base.content_id !== id;
      });
      // if (_updatedContent[type].length === 0) {
      //   _updatedContent = _.omit(_updatedContent, [type]);
      // }
      //}
      setChecked(count - 1);
    }
    setContentList(_updatedContent);
  };

  // setTimeout(() => {
  //   dispatch(SetLoaderStatus());
  // }, 4000);

  props.pageTypeHandler("Global Fields");

  //console.log("AseetsList", assetContentList);
  //console.log("contentList", contentList);

  return (
    <>
      {isLoading && <Loading />}
      <Row>
        <Col>
          <ul className="selected-asset-list my-2">
            <li className="asset-type-img" >
              <img
                src={selectedAssetType.article_image}
                alt={selectedAssetType.article_type}
              />
            </li>
          </ul>
        </Col>
      </Row>
      <Card className="global-fields-card">
        <Row className="asset-type mb-5">
          <Col className="col-6">
            <h1>
              {(uploadedData) && <>{uploadedData.length} Assets <br /> Uploaded</>}
            </h1>
          </Col>
          <Col className="col-6">
            <Button
              variant="info"
              type="submit"
              className="publish-now-btn"
              onClick={() => {
                dispatch(setSelectedAssetPublish(contentList));
                dispatch(setBreadcrumbStatus(5));
                setTimeout(() => {
                  navigate("/new-asset/publish-asset");
                }, 1000);
                //handleShow();
              }}
            >
              <i>{checked} Selected</i> Publish Now
              <span>
                <Icon.ChevronRight />
              </span>
            </Button>
          </Col>
        </Row>
        <Row className="asset-type">
          <Col className="col-12">
            {(assetContentList.length > 0 ) && <CardViewContainer
              handleCheck={handleCheck}
              assetChecked={checked}
              contentList={assetContentList}
              userType="N"
            />}
          </Col>
        </Row>
      </Card>
      {/* <Modal show={show} onHide={handleClose} >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>Are you sure that you want to proceed?</Modal.Body>
        <Modal.Footer>
          <Button
            variant="info"
            onClick={() => {
              handleClose();
              dispatch(setBreadcrumbStatus(5));
            }}
          >
            Yes
          </Button>
        </Modal.Footer>
      </Modal> */}
    </>
  );
};

export default UploadedData;
