const Loading = () => {
  return (
    <>
      <div className="lds-dual-ring"></div>
      <div className="overlay-bg"></div>
    </>
  );
};

export default Loading;
