import storeApis from "../../api/storeApi";
import "react-toastify/dist/ReactToastify.min.css";
import * as types from "../../constants/ActionTypes";
import _ from "lodash";
import Toast from "../../components/shared/Toast";

const CTA_LIST_URL = "/framework/api/CTAbutton";
const CTA_lIST = "/framework/api/fetchvideocta";

// SET Unauthorization Status
export const setUnauthorizedConversion = () => {
  return (dispatch) => {
    dispatch({ type: "UNAUTHORIZED_CONVERSION", payload: null });
  };
};

export const searchInput = (value) => {
    return (dispatch) => {
        dispatch({ type: types.SEARCH_INPUT, payload: value });
    };
};

export const fetchAssetLibraryVideoTypeList = (authToken) => {
    return async (dispatch) => {
        dispatch({ type: "TOGGLE_LOADING", payload: true });
        try {
            const response = await storeApis.post(
                CTA_lIST,
                null,
                {
                    headers: {
                        Accept: "application/json",
                        Authorization: `Bearer ${authToken}`,
                    },
                }
          );
            if (response.status === 200) {
                if (response.data.statusCode == "403") {
                    if (response.data.message == "Invalid Credentials" || response.data.message == "Unauthenticated" || response.data.message == "Unauthorized") {
                        dispatch({
                            type: "UNAUTHORIZED_CONVERSION",
                            payload: response.data.statusCode,
                        });
                    } else {
                        Toast(response.data.message, "E");
                    }
                    dispatch({ type: "TOGGLE_LOADING", payload: false });
                } else {
                    dispatch({ type: types.FETCH_CTA_ASSET_LIST, payload: response.data.response });
                    dispatch({ type: types.FETCH_VIDEO_CTA_FLAG, payload: response.data.video_configuration });
                    dispatch({ type: "TOGGLE_LOADING", payload: false });
                    window.scrollTo(0, 0)
                }
            }
        } catch (err) {
            Toast(err.response.message, 'E');
            dispatch({ type: "TOGGLE_LOADING", payload: false });
            dispatch({ type: "UNAUTHORIZED_CONVERSION", payload: err.response.status });
        }
    };
};

export const fetchCtaListing = (authToken, startlimit, searchValue, sortValue) => {
    return async (dispatch) => {
        dispatch({ type: "TOGGLE_LOADING", payload: true });
        try {
            const response = await storeApis.post(
                CTA_LIST_URL,
                {
                    startlimit: startlimit ? startlimit : 0,
                    endlimit: 20,
                    search: searchValue ? searchValue : "",
                    sort: sortValue ? sortValue : "",
                    action: "list"
                },
                {
                    headers: {
                        Accept: "application/json",
                        Authorization: `Bearer ${authToken}`,
                    },
                }
          );
            if (response.status === 200) {
                if (response.data.statusCode == "403") {
                    if (response.data.message == "Invalid Credentials" || response.data.message == "Unauthenticated" || response.data.message == "Unauthorized") {
                        dispatch({
                            type: "UNAUTHORIZED_CONVERSION",
                            payload: response.data.statusCode,
                        });
                    } else {
                        Toast(response.data.message, "E");
                    }
                    dispatch({ type: "TOGGLE_LOADING", payload: false });
                } else {
                    await dispatch({ type: types.FETCH_CTA_BUTTON_LIST, payload: null });
                    dispatch({ type: types.FETCH_CTA_BUTTON_LIST, payload: response.data.response });
                    dispatch({ type: "CURRENT_PAGE", payload: startlimit });
                    dispatch({ type: "TOGGLE_LOADING", payload: false });
                    window.scrollTo(0, 0)
                }
            }
        } catch (err) {
            Toast(err.response.message, 'E');
            dispatch({ type: "TOGGLE_LOADING", payload: false });
            dispatch({ type: "UNAUTHORIZED_CONVERSION", payload: err.response.status });
        }
    };
};

// button cta popup dropdown
export const fetchButtonCtaDropdown = (authToken, action) => {
    return async (dispatch) => {
        dispatch({ type: "TOGGLE_LOADING", payload: true });
        try {
            const response = await storeApis.post(
                CTA_LIST_URL,
                {
                    action: action
                },
                {
                    headers: {
                        Accept: "application/json",
                        Authorization: `Bearer ${authToken}`,
                    },
                }
          );
            if (response.status === 200) {
                if (response.data.statusCode == "403") {
                    if (response.data.message == "Invalid Credentials" || response.data.message == "Unauthenticated" || response.data.message == "Unauthorized") {
                        dispatch({
                            type: "UNAUTHORIZED_CONVERSION",
                            payload: response.data.statusCode,
                        });
                    } else {
                        Toast(response.data.message, "E");
                    }
                    dispatch({ type: "TOGGLE_LOADING", payload: false });
                } else {
                    await dispatch({ type: types.FETCH_CTA_BUTTON_DROPDOWN, payload: response.data.response });
                    dispatch({ type: "TOGGLE_LOADING", payload: false });
                    window.scrollTo(0, 0)
                }
            }
        } catch (err) {
            Toast(err.response.message, 'E');
            dispatch({ type: "TOGGLE_LOADING", payload: false });
            dispatch({ type: "UNAUTHORIZED_CONVERSION", payload: err.response.status });
        }
    };
};

// ADD BUTTON CTA
export const addEditButtonCtaData = (authToken, action, name, ctaTypeId, butttonText, bgColor, border, color, url, ctaId) => {
    return async (dispatch) => {
        dispatch({ type: "TOGGLE_LOADING", payload: true });
        try {
            const response = await storeApis.post(
                CTA_LIST_URL,
               {
                    action: action,
                    id: ctaId ? ctaId : "",
                    ctaName: name,
                    ctaType: ctaTypeId,
                    ctaButtontext: butttonText,
                    buttonColor: bgColor,
                    buttonShadow: border,
                    buttonText: color,
                    sta_script: url,
                },
                {
                    headers: {
                        Accept: "application/json",
                        Authorization: `Bearer ${authToken}`,
                    },
                }
          );
            if (response.status === 200) {
                if (response.data.statusCode == "403") {
                    if (response.data.message == "Invalid Credentials" || response.data.message == "Unauthenticated" || response.data.message == "Unauthorized") {
                        dispatch({
                            type: "UNAUTHORIZED_CONVERSION",
                            payload: response.data.statusCode,
                        });
                    } else {
                        Toast(response.data.message, "E");
                    }
                    dispatch({ type: "TOGGLE_LOADING", payload: false });
                } else {
                    await dispatch({ type: types.FETCH_CTA_BUTTON_LIST, payload: null });
                    await dispatch(fetchCtaListing(authToken, 0, "", "DESC"));
                    Toast(response.data.message, "S");
                    // window.history.back()
                    dispatch({ type: "TOGGLE_LOADING", payload: false });
                }
            }
        } catch (err) {
            Toast(err.response.message, 'E');
            dispatch({ type: "TOGGLE_LOADING", payload: false });
            dispatch({ type: "UNAUTHORIZED_CONVERSION", payload: err.response.status });
        }
    };
};

// Delete ProductType
export const deleteCtaData = (authToken, cat_id, page) => {
    return async (dispatch) => {
        dispatch({ type: "TOGGLE_LOADING", payload: true });
        try {
            const response = await storeApis.post(
                CTA_LIST_URL,
                {
                    action: "delete",
                    id: cat_id
                },
                {
                    headers: {
                        Accept: "application/json",
                        Authorization: `Bearer ${authToken}`,
                    },
                }
          );
            if (response.status === 200) {
                if (response.data.statusCode == "403") {
                    if (response.data.message == "Invalid Credentials" || response.data.message == "Unauthenticated" || response.data.message == "Unauthorized") {
                        dispatch({
                            type: "UNAUTHORIZED_CONVERSION",
                            payload: response.data.statusCode,
                        });
                    } else {
                        Toast(response.data.message, "E");
                    }
                    dispatch({ type: "TOGGLE_LOADING", payload: false });
                } else {
                    await dispatch({ type: types.FETCH_CTA_BUTTON_LIST, payload: null });
                    await dispatch(fetchCtaListing(authToken,page));
                    Toast(response.data.response, "S");
                    dispatch({ type: "TOGGLE_LOADING", payload: false });
                }
            }
        } catch (err) {
            Toast(err.response.message, 'E');
            dispatch({ type: "TOGGLE_LOADING", payload: false });
            dispatch({ type: "UNAUTHORIZED_CONVERSION", payload: err.response.status });
        }
    };
};



/////Video CTA///////////////////

export const addEditVideoCtaData = (authToken, action, name, time, selectedData,ctaId) => {
    return async (dispatch) => {
        dispatch({ type: "TOGGLE_LOADING", payload: true });
        try {
            const response = await storeApis.post(
                CTA_LIST_URL,
               {
                    action: action,
                    id: ctaId ? ctaId : "",
                    video_name: name,
                    video_time: time,
                    input_form:selectedData && selectedData,
                    
                },
                {
                    headers: {
                        Accept: "application/json",
                        Authorization: `Bearer ${authToken}`,
                    },
                }
          );
            if (response.status === 200) {
                if (response.data.statusCode == "403") {
                    if (response.data.message == "Invalid Credentials" || response.data.message == "Unauthenticated" || response.data.message == "Unauthorized") {
                        dispatch({
                            type: "UNAUTHORIZED_CONVERSION",
                            payload: response.data.statusCode,
                        });
                    } else {
                        Toast(response.data.message, "E");
                    }
                    dispatch({ type: "TOGGLE_LOADING", payload: false });
                } else {
                    await dispatch({ type: types.FETCH_CTA_BUTTON_LIST, payload: null });
                    await dispatch(fetchCtaListing(authToken, 0, "", "DESC"));
                    Toast(response.data.message, "S");
                    window.history.back()
                    dispatch({ type: "TOGGLE_LOADING", payload: false });
                }
            }
        } catch (err) {
            Toast(err.response.message, 'E');
            dispatch({ type: "TOGGLE_LOADING", payload: false });
            dispatch({ type: "UNAUTHORIZED_CONVERSION", payload: err.response.status });
        }
    };
};

//Fetch all the selected items
export const fetchSelectedDataCta = (authToken, action,id) => {
    return async (dispatch) => {
        dispatch({ type: "TOGGLE_LOADING", payload: true });
        try {
            const response = await storeApis.post(
                CTA_LIST_URL,
                {
                    action: action,
                    id: id && id,
                },
                {
                    headers: {
                        Accept: "application/json",
                        Authorization: `Bearer ${authToken}`,
                    },
                }
          );
            if (response.status === 200) {
                if (response.data.statusCode == "403") {
                    if (response.data.message == "Invalid Credentials" || response.data.message == "Unauthenticated" || response.data.message == "Unauthorized") {
                        dispatch({
                            type: "UNAUTHORIZED_CONVERSION",
                            payload: response.data.statusCode,
                        });
                    } else {
                        Toast(response.data.message, "E");
                    }
                    dispatch({ type: "TOGGLE_LOADING", payload: false });
                } else {
                    await dispatch({ type: types.FETCH_SELECTED_DATA, payload: response.data.response });
                    dispatch({ type: "TOGGLE_LOADING", payload: false });
                    window.scrollTo(0, 0)
                }
            }
        } catch (err) {
            Toast(err.response.message, 'E');
            dispatch({ type: "TOGGLE_LOADING", payload: false });
            dispatch({ type: "UNAUTHORIZED_CONVERSION", payload: err.response.status });
        }
    };
};


//Fetch all the selected items








/////Video CTA///////////////