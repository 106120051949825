/* eslint-disable */
import Page from "../Page";
import FolderList from "./asset-library/asset-list/FolderList";
import AllAssetsList from "./asset-library/asset-list/AllAssetsList";
import { useRef, useState, useEffect } from "react";
import Loading from "../Loading";
import { toast } from "react-toastify";
import { Row, Col, Form, Button, Breadcrumb, Modal } from "react-bootstrap";
import * as Icon from "react-bootstrap-icons";
import { fetchAssetLibraryProductList, setUnauthorized } from "../../redux/actions/actions";
import DeleteModal from "../shared/DeleteModal";
import Cookies from 'js-cookie';
import "./AssetslibraryContainer.css"

//Redux-imports
import { useDispatch, useSelector } from "react-redux";
import {
  searchAssetLibraryContentList,
  fetchAssetLibraryContentList,
  fetchAssetLibraryFolderList,
  fetchAssetLibraryCategoryList,
  fetchAssetLibraryQuickFilters,
  setAssetLibraryQuickFilter,
  setAssetLibraryCategoryFilter,
  setAssetLibraryStatusFilter,
  setAssetLibrarySortByFilter,
  setAssetBookmark,
  setAssetLibraryContentListPagination,
  fetchAssetFolderDropdownList,
  addAssetToFolder,
  fetchFolderContentList,
  globalFilterAssetLibraryContentList,
  editFolderName,
  // SetLoaderStatus,
  publishContent,
  deleteContent,
  deleteBulkContent,
  setUnauthStatus,
  setUnauthorizedAssetLibrary,
  logout,
  deleteAssetFolder
} from "../../redux/actions/actions";
import { useNavigate } from "react-router-dom";

const AssetLibraryContainer = (props) => {

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const authToken = props.authToken;
  const [editFolder, setEditFolder] = useState(false);
  const [currentFolder, setCurrentFolder] = useState(null);

  const [modal, setModal] = useState(false);

  const folderNameRef = useRef("");

  const isLoading = useSelector((state) => state.assetLibrary.loading);

  const globalSearchKey = useSelector((state) => state.assetLibrary.globalSearchKey);
  const qFilterKey = useSelector((state) => state.assetLibrary.qFilterKey);
  const cFilterKey = useSelector((state) => state.assetLibrary.cFilterKey);
  const pFilterKey = useSelector((state) => state.assetLibrary.pFilterKey);
  const sFilterKey = useSelector((state) => state.assetLibrary.sFilterKey);
  const svFilterKey = useSelector((state) => state.assetLibrary.svFilterKey);

  const currFolderId = useSelector((state) => state.assetLibrary.folderId);
  const currFolderType = useSelector((state) => state.assetLibrary.folderType);
  const currFolderName = useSelector((state) => state.assetLibrary.folderName);
  const currentAsset = useSelector((state) => state.assetLibrary.currentAsset);

  const unauthorized = useSelector((state) => state.assetLibrary.unauthorizedAssetLibrary);
  //console.log("unauthorized", unauthorized);

  // useEffect(() => {
  //   if (unauthorized === 401 || unauthorized === 403) {
  //     dispatch(logout(authToken));
  //     dispatch(setUnauthorized(null));
  //     setTimeout(() => {
  //       //window.location.reload();
  //       localStorage.clear();
  //       navigate('/');
  //     }, 2000);
  //   } else if (unauthorized === 429) {
  //     window.location.reload();
  //   }
  // }, unauthorized);

  useEffect(() => { 
      dispatch(globalFilterAssetLibraryContentList(authToken, globalSearchKey, pFilterKey, qFilterKey, cFilterKey, sFilterKey, svFilterKey, currFolderId, currFolderType, currFolderName));
      dispatch(fetchAssetLibraryCategoryList(authToken));
      dispatch(fetchAssetLibraryProductList(authToken));
      dispatch(fetchAssetLibraryQuickFilters(authToken));
      dispatch(fetchAssetFolderDropdownList(authToken)); 
  }, []);

  const folderEditHandler = (name) => {
    setEditFolder(!editFolder);
    setCurrentFolder(currFolderName);
  };

  const updateFolderName = async (e) => {
    e.preventDefault();
    dispatch(
      editFolderName(
        authToken,
        currFolderId,
        currFolderType,
        folderNameRef.current.value
      )
    );
    dispatch(
      // fetchFolderContentList(
      //   authToken,
      //   currFolderId,
      //   currFolderType,
      //   folderNameRef.current.value
      // )
      globalFilterAssetLibraryContentList(props.authToken, globalSearchKey, pFilterKey, qFilterKey, cFilterKey, sFilterKey, svFilterKey, currFolderId, currFolderType, folderNameRef.current.value)
    );
    setEditFolder(!editFolder);
  };

  const publishAssetContent = async (id, type, status) => {
    dispatch(publishContent(authToken, id, type, status));
    dispatch(fetchAssetLibraryContentList(authToken));
    notify("Content published successfully!", "success");
  };

  const notify = (msg, status) => {
    if (status === "success") {
      toast.success(msg, {
        position: "bottom-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
      toast.error(msg, {
        position: "bottom-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  const deleteFolder = () => {
    dispatch(deleteAssetFolder(authToken, currFolderId));
    setModal(false);
    setTimeout(() => {
      dispatch(globalFilterAssetLibraryContentList(authToken));
    }, 1000);
  }

  const handleEdit = () => {
    navigate('/edit-folder');
  }

  props.pageTypeHandler("Asset Library");

  return (
    <>
      {currFolderName ? (
        <div className="sub-header">
          <Row>
            <Col className="header-breadcrumb">
              <Breadcrumb>
                <Breadcrumb.Item
                  onClick={() => {
                    setCurrentFolder(null);
                    //dispatch(fetchFolderContentList(null));
                    dispatch(globalFilterAssetLibraryContentList(authToken, null,null,null,null,null,null,null,null));
                    //dispatch(fetchAssetLibraryContentList(authToken));
                  }}
                >
                  <div>Asset Library</div>
                </Breadcrumb.Item>
                <span>
                  <Icon.ChevronRight />
                </span>
                {!editFolder && (
                  <>
                    <Breadcrumb.Item active>{currFolderName}</Breadcrumb.Item>
                    <span className="edit-folder" onClick={folderEditHandler}>
                      <Icon.Pencil />
                    </span>
                  </>
                )}

                {editFolder && (
                  <Form onSubmit={updateFolderName}>
                    <Form.Group
                      className="mb-3 editFolderInput"
                      controlId="formName"
                    >
                      <Form.Control
                        type="text"
                        value={currentFolder}
                        ref={folderNameRef}
                        required
                        onChange={(e) => {
                          setCurrentFolder(e.target.value);
                        }}
                      />
                    </Form.Group>
                    <span className="edit-folder" onClick={folderEditHandler}>
                      <Icon.XLg />
                    </span>
                    {(currentFolder !== currFolderName && currentFolder !== "") && <Button
                      className="editFolderBtn"
                      variant="info"
                      type="submit"
                    >
                      <span>
                        <Icon.CheckCircleFill />
                      </span>
                    </Button>}
                  </Form>
                )}

                <div className="ms-auto justify-content-between">
                  <span className="fw-bold text-primary show-pointer" onClick={() => handleEdit()}>Edit Folder</span>
                  <span className="fw-bold text-danger show-pointer" onClick={() => setModal(true)}>Delete Folder</span>
                </div>
              </Breadcrumb>
            </Col>
          </Row>
        </div>
      ) : (
        ""
      )}
      <Page title="Asset Library">
        {!currFolderName ? (
          <FolderList
            authToken={authToken}
            setCurrentFolder={setCurrentFolder}
          />
        ) : (
          ""
        )}
        <AllAssetsList
          authToken={authToken}
          userType={props.userType}
          listTitle="All Assets"
          publishAssetContent={publishAssetContent}
        />
      </Page>


      {/* <DeleteModal show={modal} hide={() => setModal(false)} deleteItem={deleteFolder} /> */}

      <Modal show={modal} onHide={() => setModal(false)}>
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body style={{ textAlign: "center" }}>
          <div className="alert-icon">
            <Icon.ExclamationTriangle />
          </div>
          Are you sure, you want to delete this folder?
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="info"
            onClick={deleteFolder}
          >
            Yes
          </Button>
          <Button variant="info" onClick={() => setModal(false)}>
            No
          </Button>
        </Modal.Footer>
      </Modal>


    </>
  );
};

export default AssetLibraryContainer;
