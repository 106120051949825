import { Modal, Button } from "react-bootstrap";

const DeleteModal = (props) => {

    return (
        <Modal
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            backdrop="static"
            keyboard={false}
            show={props.show}
            onHide={props.hide}
        >
            <Modal.Header closeButton style={{borderBottom:'none'}}>
                <div className="fw-bold fs-5">Confirm Delete</div>
            </Modal.Header>
            <Modal.Body>
                <div className="fs-5">Are you sure want to delete?</div>
            </Modal.Body>
            <Modal.Footer style={{borderTop:'none'}}>
                <Button onClick={props.deleteItem}>Ok</Button>
            </Modal.Footer>
        </Modal>

    )
}

export default DeleteModal;