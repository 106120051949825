import React from "react";
import * as Icon from "react-bootstrap-icons";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Form, Table, Modal, Button } from "react-bootstrap";
import { deleteContactGroupData, deleteContactListData, viewListIndividualDataListing, setContactListId, setCurrentPagePag, fetchListIndividualDataListing, setshowEnteries } from "../../../redux/actions/campaignAction";
import { useNavigate } from "react-router-dom";

const SelectTable = (props) => {
    let navigate = useNavigate();
    const dispatch = useDispatch();
    const [show, setShow] = useState(false);
    const [deleteModal, setDeleteModal] = useState(false);
    const [deleteData, setDeleteData] = useState(false);
    const view_list_data = useSelector((state) => state.campaign.view_list_data);

    const handleClose = () => setDeleteModal(false);
    
    const deleteEvent = () => {
        dispatch(deleteContactGroupData(props.authToken, [deleteData.list_id]));
        handleClose();
    };
    
    const onSuccessViewList = () => {
        setShow(true);
    }
    
    const onSuccessListId = (id) => {
        if (!window.location?.pathname?.includes("list")) {
            dispatch(setshowEnteries("20"));
            dispatch(fetchListIndividualDataListing(props.authToken, id, "", 1, 20));
            props.handleContactList(true);
        } else {
            navigate("/campaigns/contactlist");
        }
    }
    
    const handleEdit = (id) => {
        dispatch(setContactListId(id, onSuccessListId))
    }
    
    return (
        <div>
            <Form>
                {/* <div className="hashtag-list-card mb-3"> */}
                <div className={"hashtag-list-card campaign my-3 " + ( props.selectedCrawlUrls.includes(props.listItem) ? "selected-card" : "")}>
                    <Table>
                    <tbody>
                        <tr>
                        <td style={{ width: "5%", textAlign: "center" }}>
                            <Form.Check
                                type="checkbox"
                                className="check-box"
                                checked={props.selectedCrawlUrls.includes(props.listItem)}
                                value={props.listItem.id}
                                onChange={(e) => {
                                    props.handleUrlCheckboxChange(e, props.listItem)
                                }}
                            />
                        </td>
                       {props.tableData === "group_list" && <>        
                            <td title={props.listItem.contact_list_name} className="hashtag-name">{props.listItem?.contact_list_name.slice(0, 20)}
                                {(props.listItem?.contact_list_name?.length > 20 || props.listItem?.contact_list_name?.length > 20) && <span>...</span>}
                            </td>
                            <td style={{ width: "12%" }}>
                                <span style={{border:"none"}} onClick={()=>{dispatch(viewListIndividualDataListing(props.authToken, props.listItem.list_id, onSuccessViewList))}} className="cursor-pointer bordering-date date-style">
                                {props.listItem.total}
                                </span>
                            </td>
                            {/* <td style={{ width: "12%" }}>
                                <span style={{border:"none"}} className="bordering-date date-style">
                                {props.listItem.inactive}
                                </span>
                            </td> */}
                            <td style={{ width: "20%" }}>
                                <span style={{border:"none"}} className="bordering-date date-style">
                                {props.listItem && props.listItem.list_create_time}
                                </span>
                            </td>
                        </>} 
                        {props.tableData === "contact_list" && <> 
                            <td title={props.listItem.first_name + props.listItem.last_name} className="hashtag-name">{(props.listItem.first_name + " " + props.listItem.last_name)?.slice(0, 20) || "Unknown"}
                                {((props.listItem.first_name + " " + props.listItem.last_name).length > 20 || (props.listItem.first_name + " " + props.listItem.last_name).length > 20) && <span>...</span>}
                            </td>                                    
                            <td style={{ width: "25%" }}>
                                <span style={{border:"none"}} className="bordering-date date-style">
                                {props.listItem.email_id.slice(0,30)} {(props.listItem?.email_id?.length > 30 || props.listItem?.email_id?.length > 30) && <span>...</span>}
                                </span>
                            </td>
                            <td style={{ width: "25%" }}>
                            <span style={{ border: "none" }} className="bordering-date date-style">
                                {props.listItem.mobile.slice(0,15)} {(props.listItem?.mobile?.length > 15 || props.listItem?.mobile?.length > 15) && <span>...</span>}
                                </span>
                            </td><td style={{ width: "20%" }}>
                                <span style={{border:"none"}} className="bordering-date date-style">
                                {props.listItem.company_name.slice(0,20)} {(props.listItem?.company_name?.length > 20 || props.listItem?.company_name?.length > 20) && <span>...</span>}
                                </span>
                            </td>
                        </>}                                 
                        <td style={{ justifyContent: "end", gap:"15px" }} className="d-flex">
                            {props.tableData === "group_list" && <>
                            <div className="card-footer-action list-card" onClick={()=>{handleEdit(props.listItem.list_id)}}>
                                <span className="edit-icon">
                                    <Icon.Pencil color="#64B6E7" />
                                </span>
                            </div>
                                <div className="card-footer-action list-card" onClick={() => { setDeleteModal(true); setDeleteData(props.listItem)}}>
                                <span className="edit-icon">
                                    <Icon.Trash color="#64B6E7" />
                                </span>
                            </div>
                            </>}
                        </td>
                        </tr>
                    </tbody>
                    </Table>
                </div>
            </Form>
            
            {/* delete poppup */}
            <Modal className="delete-popup" show={deleteModal} onHide={handleClose}>
                <Modal.Header closeButton>
                <Modal.Title>Delete</Modal.Title>
                </Modal.Header>
                <Modal.Body>Are you sure you want to delete ?</Modal.Body>
                <Modal.Footer>
                <Button variant="primary" onClick={deleteEvent}>
                    Delete
                </Button>
                <Button variant="secondary" onClick={handleClose}>
                    Cancel
                </Button>
                </Modal.Footer>
            </Modal>
            <Modal
              size="xl"
              show={show}
              onHide={() => setShow(false)}
              aria-labelledby="example-modal-sizes-title-lg"
              centered  
            >
              <Modal.Header className="header-color" closeButton>
                <Modal.Title id="example-modal-sizes-title-lg" className="w-100 view-header d-flex justify-content-between">
                    <span>Contact View</span>
                </Modal.Title>
              </Modal.Header>
              <Modal.Body className="list-popup listing">
                <div>
                    <table class="table">
                    <thead>
                        <tr>
                        {/* <th scope="col">#</th> */}
                        <th scope="col">Name</th>
                        <th scope="col">Email</th>
                        <th scope="col">Phone Number</th>
                        <th scope="col">Company</th>
                        </tr>
                    </thead>
                    <tbody>
                        {view_list_data?.length > 0 && view_list_data?.map((view, v_ind) => {
                            return (
                                <tr>
                                    <th scope="row">{ (view.first_name + view.last_name).slice(0,20) || "Unknown" }{((view.first_name + view.last_name)?.length > 15) && <span>...</span>}</th>
                                    <td>{ view.email_id.slice(0,20) }{(view.email_id?.length > 15) && <span>...</span>}</td>
                                    <td>{ view.mobile.slice(0,30) }{(view.mobile?.length > 15) && <span>...</span>}</td>
                                    <td>{ view.company_name.slice(0,20) }{(view.company_name?.length > 15) && <span>...</span>}</td>
                                </tr>
                            );
                        })}
                    </tbody>
                    </table>
                </div>
              </Modal.Body>
            </Modal>
        </div>
    );
};

export default SelectTable;
