import React from 'react'
import { Row, Col, Form, Button, Modal, InputGroup } from "react-bootstrap";
import * as Icon from "react-bootstrap-icons";
import CommonSelectTable from './CommonSelectTable';
import { useState, useEffect } from "react";
import vector from '../../assets/setup-icon/Vector.png';
import _ from "lodash";
import { DebounceInput } from 'react-debounce-input';
import * as types from "../../constants/ActionTypes";
import { useDispatch, useSelector } from "react-redux";
import Toast from "../shared/Toast";
import Pagination from "../shared/ReactPaginate";
import DragShimmer from './shimmer/DragShimmer';
import { searchInput, fetchParentListing, fetchDigicardData, reactivateAccount, saveDigicard, deletePartner } from "../../redux/actions/setupActions";
import { useNavigate } from 'react-router-dom';


const SetupParent = (props) => {
    let navigate = useNavigate();
    const dispatch = useDispatch();
    // const isLoading = useSelector((state) => state.setupScreen.loading);
    let isLoading = useSelector((state) => state.setupScreen.loading);
    let search_input = useSelector((state) => state.setupScreen.search_input);
    let pageNum = useSelector((state) => state.setupScreen.currPage);
    const authToken = props.authToken;
    const [openDigiCard, setOpenDigiCard] = useState(false);
    const [digicardData, setDigicardData] = useState(null);

    const total_data = useSelector((state) => state.setupScreen.parent_list?.total_data);
    const parent_list = useSelector((state) => state.setupScreen.parent_list);
    const [checked, setChecked] = useState(0);
    const [digiFirstName, setDigiFirstName] = useState('');
    const [digiLastName, setDigiLastName] = useState('');
    const [digiEmail, setDigiEmail] = useState('');
    const [digiPhone, setDigiPhone] = useState('');
    const [digiAboutMe, setDigiAboutMe] = useState('');
    const [digiMicrositeUrl, setDigiMicrositeUrl] = useState('');
    const [tableContent, setTableContent] = useState({});
    const [reactivateInput, setReactivateInput] = useState("");
    const [isSelectAll, setIsSelectAll] = useState(false);
    const [searchValue, setSearchValue] = useState("");
    const [sortValue, setSortValue] = useState("ASC");
    const [contentList, setContentList] = useState({});
    const [showDelete, setShowDelete] = useState(false);
  
    const handleCloseDelete = () => setShowDelete(false);

    const handleActivate = () => {

      if(reactivateInput === ""){
        Toast("Please Enter Email Address/Phone Number", "E");
      }else{
      dispatch(reactivateAccount(authToken, reactivateInput));
      setReactivateInput("");
      setShow(false);
      // dispatch(fetchParentListing(authToken, 0, search_input));
    }};

    const handleAdd = () => {
            navigate(`/setup/userManagement/addPartner`)
          };
  
    const handleShowPopup = () => {
        setShowDelete(true);
    }
    
    const handleCloseDigi = () => setOpenDigiCard(false);
  
    const deleteHandleShow = () => {
      dispatch(deletePartner(authToken, contentList,pageNum));
      handleCloseDelete();
      setContentList({});
      setChecked(0);
    }
    
    const handleDigicard = async (id) => {
        setDigicardData(id);  
        await dispatch(fetchDigicardData(props.authToken,id,onSuccess));
    }
    
    const onSuccess = (value) => {
        setDigiFirstName(value.first_name)
        setDigiLastName(value.last_name)
        setDigiEmail(value.email)
        setDigiPhone(value.contact_no)
        setDigiAboutMe(value.about_us)
        setDigiMicrositeUrl(value.microsite_url)
        setOpenDigiCard(true);
    }

    const handleUnselectedData = (value) => {
      setIsSelectAll(value)
    }
  
    const selectAllHashtag = (selectFlag) => {
      setIsSelectAll(!selectFlag);
      let all_ids = parent_list.data.map((item) => {
        return item.id;
      })
      setChecked(parent_list?.data?.length);
      setContentList({web: all_ids});
    }
    const handleRemoveSearch = (e) => {
        setSearchValue("");
        dispatch(searchInput(""));
        dispatch(fetchParentListing(authToken, pageNum, ""));
    }
    const dateSorting = (e) => {
      if (sortValue === "ASC") {
        setSortValue("DESC");
      } else {
        setSortValue("ASC");
      }  
      dispatch(fetchParentListing(authToken, pageNum, search_input, sortValue));
    }
    const handleSearch = (e) => {
      setSearchValue(e.target.value);
      dispatch(searchInput(e.target.value));
      dispatch(fetchParentListing(authToken, 0, e.target.value));
    }
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
  
    const handleSaveDigicard = () => {
      let payload =  {
        id : digicardData.id,
        first_name : digiFirstName,
        last_name : digiLastName,
        contact_no: digiPhone,
        email: digiEmail,
        about_us: digiAboutMe,
        microsite_url: digiMicrositeUrl
      }  
      dispatch(saveDigicard(authToken, payload));
      setOpenDigiCard(false);
    }
  
    const handleCheck = (e, id, type) => {
      if (e === null) {
        setChecked(0);
      } else {
        let _updatedContent = _.clone(contentList);
        let count = checked;
        if (e.target.checked) {
          if (_.has(contentList, [type])) {
            if (!_updatedContent[type].includes(id)) {
              _updatedContent[type].push(id);
            }
          } else {
            _updatedContent[type] = [id];
          }
          setChecked(count + 1);
        } else {
          if (_.has(contentList, [type])) {
            _updatedContent[type] = _updatedContent[type].filter(
              (item) => item !== id
            );
            if (_updatedContent[type].length === 0) {
              _updatedContent = _.omit(_updatedContent, [type]);
            }
          }
          setChecked(count - 1);
        }
        setContentList(_updatedContent);
      }
    };
    // console.log("current",contentList);
  
    useEffect(() => {
        let tableCont={
            checkbox: { value: true, width: "5%" },
            // index: { value: false, width: "5%" },
            email: { value: true, width: "20%" },
            number: { value: true, width: "12%" },
            category: { value: true, width: "10%" },
            uid: { value: true, width: "12%" },
            title: { value: true, width: "20%" },
            // date: { value: false, width: "10%" },
            // divider: { value: false, width: "10%" },
            designation: { value: true, width: "10%" },
            time: { value: false, width: "10%" },
            // searchText: { value: false, width: "10%" },
            edit: { value: true, width: "90%" },
            // u_search: { value: true, width: "10%" },
        }
        setTableContent(tableCont);
        // setSearchValue(search_input);
        dispatch(fetchParentListing(authToken, pageNum, ""));
    }, []);
  
    useEffect(() => {    // onDestroy
        return () => {
          dispatch({ type: types.SEARCH_INPUT, payload: "" });
          // dispatch({ type: types.CURRENT_PAGE, payload: 0 });
        }
    }, [])
    
    return (
      <div>
        <div>
          <div>
            <InputGroup className="mb-3 search-inside" style={{width:"40%"}}>
              <DebounceInput value={searchValue} className="search-inside-input" placeholder="Search Partner" minLength={3} debounceTimeout={2000} onChange={handleSearch} />
              <InputGroup.Text id="basic-addon1">
                {searchValue && searchValue !== "" ? <Icon.X color="#A8A8A8" onClick={handleRemoveSearch} /> : <Icon.Search color="#A8A8A8" onClick={handleSearch}/>}
              </InputGroup.Text>
            </InputGroup>
            <div className='right-buttons' style={{width:"60%"}}>
              <span>Sort By <span className='sort-text mx-1' onClick={dateSorting}>Date Added <img className={sortValue === "DESC" ? 'rotate' : ''} src={vector} alt="icon" /></span></span>
              <Button  onClick={handleShow} className='common-setup-button' variant="primary">Reactivate Account</Button>
              <Button style={{marginLeft: '5px'}} onClick={handleAdd} className='common-setup-button' variant="primary">+ Partner</Button>
            </div>
          </div>
          
          {checked > 0 && (
            <Row>
              <Col>
                <div className="selectedAssetActions">
                  <ul>
                    <li>
                      <Button variant="light selected">
                        {checked} Selected
                      </Button>
                    </li>
                      <li>
                        <Button
                          variant="light"
                          onClick={handleShowPopup}
                        >
                          <span>
                            <Icon.Trash />
                          </span>
                          Delete
                        </Button>
                    </li>
                  </ul>
                    <ul className="float-right">
                      <li>
                      <Button
                        variant="light selected"
                        onClick={() => {
                          selectAllHashtag(isSelectAll)
                        }}
                      >
                        Select All
                      </Button>
                      </li>
                      <li>
                        <Button
                          variant="light selected"
                          onClick={() => {
                            setContentList({});
                            setChecked(0);
                            setIsSelectAll(false);
                          }}
                        >
                          Unselect
                        </Button>
                      </li>
                    </ul>
                </div>
              </Col>
            </Row>
          )}
          
          <Row>
              <Col>
                <div className="top-heading-bar">
                  <ul>
                    <li style={{width: "20%"}}>
                      <span>Name</span>
                    </li>
                    <li style={{width: "20.5%"}}>
                      <span>Email</span>
                    </li>
                    <li style={{width: "12%"}}>
                      <span>Number</span>
                    </li>
                    <li style={{width: "11%"}}>
                      <span>UID</span>
                    </li>
                    <li style={{width: "10%"}}>
                      <span>Category</span>
                    </li>
                    <li style={{width: "10%"}}>
                      <span>Level</span>
                    </li>
                    <li style={{float: "right",paddingRight:"10px"}}>
                      <span>Actions</span>
                    </li>
                  </ul>
                </div>
              </Col>
            </Row>
                
          <div>
            {isLoading && <DragShimmer subPageName={props.subPageName} tableData="partner"/>}
            { isLoading === false && parent_list?.data.map((listItem,index) => {
              return (
                <Col key={index}>
                      <CommonSelectTable tableData="partner_list" authToken={props.authToken} displayContent={tableContent} listItem={listItem} handleCheck={handleCheck} handleUnselectedData={handleUnselectedData} handleDigicard={handleDigicard} isSelectAll={isSelectAll} checked={checked} />
                </Col>
              )
            })}
            {parent_list?.data?.length === 0 && (
              <div className="no-data asset">
                <span>
                  <Icon.Inbox />
                </span>
                <br />
                No Data
              </div>
            )}
          </div>
          
          {parent_list?.total_data > 20 && <Pagination authToken={props.authToken} total_page={ total_data } page={pageNum} currPage={props.subPageName} />}
          
          {/* reactivate partner popup */}
          <Modal className='reactive-partner delete-popup' show={show} onHide={handleClose}>
            <Modal.Header closeButton>
              <Modal.Title>Activate User Account</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form>
                <Form.Group className="mb-3">
                  <Form.Control
                    type="email" 
                    placeholder="Email address / Mobile Number"
                    autoFocus
                    value={reactivateInput}
                    onChange={(e) => {setReactivateInput(e.target.value)}}
                  />
                </Form.Group>
              </Form>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose}>
                Close
              </Button>
              <Button variant="primary" onClick={handleActivate}>
                Activate
              </Button>
            </Modal.Footer>
          </Modal>
          
          {/* Delete popup  */}
            <Modal className="delete-popup"  show={showDelete} onHide={handleCloseDelete}>
                <Modal.Header closeButton>
                    <Modal.Title>Delete</Modal.Title>
                </Modal.Header>
                <Modal.Body>Are you sure you want to delete this partner ? This will affect the reports associated with that hierarchy.</Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={deleteHandleShow}>
                      Delete
                    </Button>
                    <Button variant="secondary" onClick={handleCloseDelete}>
                      Cancel
                    </Button>
                </Modal.Footer>
            </Modal>
          
          <Modal show={openDigiCard} onHide={handleCloseDigi}>
              <Modal.Header closeButton>
              <Modal.Title className='digicard_form_title'>Update Digicard</Modal.Title>
              </Modal.Header>
              <Modal.Body>
              <Form>
                  <Form.Group
                      className="mb-1 container"
                      controlId="exampleForm.ControlInput1"
                  >
                      <div className="row">
                          <div className="col-6">
                          <Form.Label>First Name</Form.Label>
                          <Form.Control type="text"
                            onChange={(e) => {
                              setDigiFirstName(e.target.value)
                            }}
                            value={digiFirstName}
                            autoFocus
                          />
                        </div>
                        <div className="col-6">
                          <Form.Label>Last Name</Form.Label>
                          <Form.Control
                            type="text"
                            onChange={(e) => {
                              setDigiLastName(e.target.value);
                            }}
                            value={digiLastName}
                          />
                        </div>
                      </div>
                  </Form.Group>
                  <Form.Group className="mb-1 container" controlId="exampleForm.ControlInput1">
                  <Form.Label>Email</Form.Label>
                  <Form.Control
                    type="email"
                    onChange={(e) => {
                      setDigiEmail(e.target.value);
                    }}
                    value={digiEmail}
                  />
                  </Form.Group>
                  <Form.Group className="mb-1 container" controlId="exampleForm.ControlInput1">
                  <Form.Label>Phone</Form.Label>
                  <Form.Control
                    type="phone"
                    onChange={(e) => {
                      setDigiPhone(e.target.value);
                    }}
                    value={digiPhone}
                  />
                  </Form.Group>
                  <Form.Group className="mb-1 container" controlId="exampleForm.ControlInput1">
                  <Form.Label>About Me</Form.Label>
                  <Form.Control
                    type="text"
                    onChange={(e) => {
                      setDigiAboutMe(e.target.value);
                    }}
                    value={digiAboutMe}
                  />
                  </Form.Group>
                  <Form.Group className="mb-1 container" controlId="exampleForm.ControlInput1">
                  <Form.Label>Microsite URL</Form.Label>
                  <Form.Control
                    type="text"
                    onChange={(e) => {
                      setDigiMicrositeUrl(e.target.value);
                    }}
                    value={digiMicrositeUrl} />
                  </Form.Group>
              </Form>
              </Modal.Body>
              <Modal.Footer>
              <Button variant="secondary" onClick={handleCloseDigi}>
                  Close
              </Button>
              <Button className='digicard_form_button' onClick={handleSaveDigicard}>
                  Save Changes
              </Button>
              </Modal.Footer>
          </Modal>  
        </div>
      </div>
    )
}

export default SetupParent;