import React from 'react'
import * as Icon from "react-bootstrap-icons";
import { useState, useRef, useEffect } from "react";
import { Button, InputGroup, Form } from 'react-bootstrap';
import { useNavigate } from "react-router-dom";
import vector from '../../assets/setup-icon/Vector.png';
import Table from '../shared/Table';
import { DebounceInput } from 'react-debounce-input';
import Loading from "../Loading";
import { useDispatch, useSelector } from "react-redux";
import * as types from "../../constants/ActionTypes";
import Pagination from "../shared/PaginationNew";
import DragShimmer from './shimmer/DragShimmer';
import { fetchProductTypeListing, searchInput } from "../../redux/actions/setupActions";


const SetupProduct = (props) => {
    let navigate = useNavigate();
    let isLoading = useSelector((state) => state.setupScreen.loading);
    const dispatch = useDispatch();
    const authToken = props.authToken;
    const total_data = useSelector((state) => state.setupScreen.product_type_list?.total_data);
    const product_type_list = useSelector((state) => state.setupScreen.product_type_list);
    let search_input = useSelector((state) => state.setupScreen.search_input);
    let pageNum = useSelector((state) => state.setupScreen.currPage);
    const [searchValue, setSearchValue] = useState("");
    const [sortValue, setSortValue] = useState("ASC");
    const add_edit_screen = () => {
      navigate("/setup/content/addProductType"); 
    }
  
    const dateSorting = async (e) => {
      if (sortValue === "ASC") {
        setSortValue("DESC");
      } else {
        setSortValue("ASC");
      }  
      await dispatch(fetchProductTypeListing(authToken, pageNum, search_input, sortValue));
    }
  
    const handleRemoveSearch = (e) => {
        setSearchValue("");
        dispatch(searchInput(""));
        dispatch(fetchProductTypeListing(authToken, pageNum, ""));
    }
    const handleSearch = (e) => {
        setSearchValue(e.target.value);
        dispatch(searchInput(e.target.value));
        dispatch(fetchProductTypeListing(authToken, 0, e.target.value));
    }
    useEffect(() => {
      // setSearchValue(search_input);
      // dispatch(fetchProductTypeListing(authToken, 0, search_input));
      dispatch(fetchProductTypeListing(authToken, 0, ""));
    }, []);
  
    useEffect(() => { // onDestroy
        return () => {
          dispatch({ type: types.SEARCH_INPUT, payload: "" });
          dispatch({ type: types.CURRENT_PAGE, payload: 0 });
        }
    }, [])
  
    return (
      <div>
        <div>
          <InputGroup className="mb-3 search-inside">
              <DebounceInput value={searchValue} className="search-inside-input" placeholder="Search Product Types" minLength={3} debounceTimeout={2000} onChange={handleSearch} />
              <InputGroup.Text id="basic-addon1">
                {searchValue && searchValue !== "" ? <Icon.X color="#A8A8A8" onClick={handleRemoveSearch} /> : <Icon.Search color="#A8A8A8" onClick={handleSearch}/>}
              </InputGroup.Text>
            </InputGroup>
          <div className='right-buttons'>
            <span>Sort By <span className='sort-text mx-1' onClick={dateSorting}>Date Added <img className={sortValue === "DESC" ? 'rotate' : ''} src={vector} alt="icon" /></span></span>
            <Button style={{marginLeft: '60px'}} onClick={add_edit_screen} className='common-setup-button' variant="primary">+ Product Type</Button>
          </div>
        </div>

        <div>
          {isLoading && <DragShimmer subPageName={props.subPageName}/>}
          {product_type_list?.producttypes?.length > 0 && isLoading === false && <Table authToken={authToken} page={pageNum} subPageName={props.subPageName} categoryData={product_type_list.producttypes} />}
          {product_type_list?.producttypes?.length === 0 && (
              <div className="no-data asset">
                <span>
                  <Icon.Inbox />
                </span>
                <br />
                No Data
              </div>
            )}
        </div>
        <Pagination authToken={props.authToken} total_page={ total_data } currPage={props.subPageName} />
      </div>
    )
}

export default SetupProduct;