import React, { useEffect, useRef, useState } from "react";
import {
  Row,
  Col,
  Form,
  Card,
  Button,
  Modal,
  FormGroup,
} from "react-bootstrap";
import Select from 'react-select';
import {
  fetchAssetSelectTypeList,
  fetchAssetLibraryCategoryList,
  fetchAssetLibrarySubCategoryList,
  fetchAssetLibraryHashtagList,
  fetchAssetLibraryLeadGenerationList,
  saveAutoEmailData,
  fetchGreetingList,
  fetchLandingpageUrls,
  fetchShowcaseUrls,
  fetchMicrositeUrls,
  fetchCreateTemplateData,
  autoEmailMainData,
  setBreadcrumbStatus,
  fetchContentTypeList,
  fetchPersonalizeList,
  addNewHashTag
} from "../../../../redux/actions/actions";
import * as Icon from "react-bootstrap-icons";
import { useSelector } from "react-redux";
import Multiselect from "multiselect-react-dropdown";
import "./emailasset.css";
import DemoTemp from "../../../../assets/asset-upload/demo-temp.png";
import { Editor } from '@tinymce/tinymce-react';
import CropImage from "./CropImage";
import Toast from "../../../shared/Toast";
import { useNavigate } from "react-router-dom";
import _ from "lodash";
import { useDispatch } from "react-redux";

function PickTemplate(props) {
  const authToken = props.authToken;
  const dispatch = useDispatch();
  let navigate = useNavigate();
  const editorRef = useRef(null);
  const hiddenFileInput = useRef(null);
  const [file, setFile] = useState(null);
  const [fileName, setFileName] = useState("");
  const [imagefile, setImagefile] = useState(null);
  const [templateName, setTemplateName] = useState("");
  const [templateDescription, setTemplateDescription] = useState("");
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedFundName, setSelectedFundName] = useState(false);
  const [tagName, setTagName] = useState("");
  const [htmlContent, setHtmlContent] = useState("");
  const [emailBody, setEmailBody] = useState(null);
  const [autoEmailContentId, setAutoEmailContentId] = useState(null);
  const [tabData, setTabData] = useState("all");
  const [selectedCtaType, setSelectedCtaType] = useState("none");
  const [selectedHashtag, setSelectedHashtag] = useState([]);
  const [selectedGreeting, setSelectedGreeting] = useState(null);
  const [selectedShowcasePage, setSelectedShowcasePage] = useState(null);
  const [selectedLandingPage, setSelectedLandingPage] = useState(null);
  const [currentSipChoice, setCurrentSipChoice] = useState("none");
  const [tagModal, setTagModal] = useState(false);
  const selectedAssetType = useSelector(
    (state) => state.assetUpload.selectedAssetType
  );

  const showcaseUrlList = useSelector(
    (state) => state.assetUpload.showcaseUrlList
  );
  
  const autoemailStatus = useSelector(
    (state) => state.assetUpload.autoemailStatus
  );
  
  const autoemail_main_data = useSelector(
    (state) => state.assetUpload.autoemail_main_data
  );
  
  const currentAssetDetails = useSelector(
    (state) => state.assetLibrary?.currentAssetDetails?.length > 0 ? state.assetLibrary?.currentAssetDetails[0] : null
  );
  
  const landingpageUrlList = useSelector(
    (state) => state.assetUpload.landingpageUrlList
  );
  
  const greetingList = useSelector((state) => state.assetUpload.greetingList);
  
  const assetLibraryCategoryList = useSelector(
    (state) => state.assetLibrary.categoryList
  );
  
  const assetLibrarySubCategoryList = useSelector(
    (state) => state.assetLibrary.subcategoryList
  );
  
  const assetLibraryHashtagList = useSelector(
    (state) => state.assetLibrary.hashtagList
  );
  
  const sipDropdown = useSelector((state) => state.assetUpload?.autoEmailData?.response?.options);
  
  
  // const log = () => {
  //   if (editorRef.current) {
  //     console.log(editorRef.current.getContent());
  //   }
  // };
  
  const getOptionLabel = (option) => option.case_study_title;
  // const getHashtagOptionLabel = (option) => option.tag_name;
  const getLandingOptionLabel = (option) => option.publish_page_name;
  
  useEffect(() => {
    dispatch(fetchAssetSelectTypeList(authToken));
    dispatch(fetchAssetLibraryCategoryList(authToken));
    dispatch(fetchAssetLibrarySubCategoryList(authToken));
    dispatch(fetchAssetLibraryLeadGenerationList(authToken));
    dispatch(fetchAssetLibraryHashtagList(authToken));
    // dispatch(fetchAssetLibraryVideoTypeList(authToken));
    //dispatch(fetchCampaignList(authToken));
    dispatch(fetchGreetingList(authToken));
    dispatch(fetchLandingpageUrls(authToken));
    dispatch(fetchShowcaseUrls(authToken));
    dispatch(fetchMicrositeUrls(authToken));
    dispatch(fetchContentTypeList(authToken));
    dispatch(fetchCreateTemplateData(authToken));
    dispatch(fetchPersonalizeList(authToken));
    if (_.isEmpty(selectedAssetType)) {
      Toast("Uploaded content lost, please re-upload..", "E");
      navigate("/new-asset");
    }
  }, [authToken, dispatch]);
  
  useEffect(() => {
    if (currentAssetDetails) {
      setAutoEmailContentId(autoemail_main_data?.id);
      setTemplateName(autoemail_main_data?.temp_name || "");
      setTemplateDescription(autoemail_main_data?.temp_desc || "") ;
      setHtmlContent(autoemail_main_data?.email_body || "");
      const filteredHashtagObjects = assetLibraryHashtagList?.filter(obj => autoemail_main_data?.hashtag.includes(obj.id));
      const filteredCategoryObjects = assetLibraryCategoryList.filter(obj => autoemail_main_data?.category.includes(obj.id));
      
      setSelectedHashtag(filteredHashtagObjects);
      setSelectedCategory(filteredCategoryObjects);
      
      setImagefile(autoemail_main_data?.thumb || null);

      const filteredLandingPage = landingpageUrlList.find(obj => obj.publish_page_id === autoemail_main_data?.landing_page);
      const filteredShowcasePage = showcaseUrlList.find(obj => obj.id === autoemail_main_data?.showcase_page);
      // const filteredSipChoice = sipDropdown.find(obj => obj.id === autoemail_main_data?.campaign_type);
      // const filteredFundName = assetLibrarySubCategoryList.find(obj => obj.id === autoemail_main_data?.fund_name);
      setSelectedGreeting(autoemail_main_data?.greeting)
      setSelectedFundName(autoemail_main_data?.fund_name);
      setCurrentSipChoice(autoemail_main_data?.campaign_type || "none");
      setSelectedLandingPage(filteredLandingPage);
      setSelectedShowcasePage(filteredShowcasePage);
      setSelectedCtaType(autoemail_main_data?.cta_type || "none");
      setEmailBody(autoemail_main_data?.email_body || "");
    }
  }, [autoemail_main_data])
  
  useEffect(() => {
    let emailData = {
      thumb: currentAssetDetails?.thumb_image,
      id:currentAssetDetails?.content_id || null,
      temp_name: currentAssetDetails?.content_title,
      temp_desc: currentAssetDetails?.description,
      greeting: currentAssetDetails?.greeting_id,
      category: currentAssetDetails?.solution_types.map(obj=>obj?.id),
      hashtag: currentAssetDetails?.internal_hashtags.map(obj=>obj?.id),
      campaign_type: currentAssetDetails?.is_option,
      fund_name: currentAssetDetails?.sub_category,
      cta_type: currentAssetDetails?.url_option,
      landing_page: currentAssetDetails?.url_option === "L" ? currentAssetDetails?.publish_page_id:"",
      showcase_page: currentAssetDetails?.url_option === "S" ? currentAssetDetails?.publish_page_id:"",
      email_body: currentAssetDetails?.message
    }
    
    dispatch(autoEmailMainData(emailData))
  }, [currentAssetDetails])
  
  useEffect(() => {
    if (editorRef.current) {
      setEmailBody(editorRef.current.getContent());
    }
  }, [editorRef.current])
  
  const handleClick = event => {
    hiddenFileInput.current.click();
  };
  
  const onSelectLandingPage = (selectedList) => {
    setSelectedLandingPage(selectedList);
  };
  
  const onSelectShowcasePage = (selectedList) => {
    setSelectedShowcasePage(selectedList);
  };

  const onSelectHashtag = (selected) => {
    setSelectedHashtag(selected);
  };
  
  const onRemoveHashtag = (selectedList) => {
    setSelectedHashtag(selectedList);
  };
  
  const onSelectCategory = (selected) => {
    setSelectedCategory(selected);
  };
  
  const onRemoveCategory = (selectedList) => {
    setSelectedCategory(selectedList);
  };
  
  const addHashTag = () => {
    if (tagName !== "") {
      dispatch(addNewHashTag(authToken, tagName));
      setTagName("");
      setTagModal(false);
    } else {
      Toast("Please enter tag name", "E");
    }
  }
  
  const handleAssetDetailData = () => {
    let emailData = {
      thumb: imagefile,
      id:autoEmailContentId || null,
      temp_name: templateName,
      temp_desc: templateDescription,
      greeting: selectedGreeting,
      category: selectedCategory.map(obj=>obj.id),
      hashtag: selectedHashtag.map(obj=>obj.id),
      campaign_type: currentSipChoice,
      fund_name: selectedFundName,
      cta_type: selectedCtaType,
      landing_page: selectedLandingPage?.publish_page_id,
      showcase_page: selectedShowcasePage?.id,
      email_body: emailBody
    }
    
    dispatch(autoEmailMainData(emailData))
    console.log(emailData);
    dispatch(setBreadcrumbStatus(4));
    setTabData("email");
  }
  
  const handleSubmit = () => {
    let emailData = {
      thumb: imagefile,
      id:autoEmailContentId || null,
      temp_name: templateName,
      temp_desc: templateDescription,
      greeting: selectedGreeting,
      category: selectedCategory.map(obj=>obj?.id?.toString()),
      hashtag: selectedHashtag.map(obj=>obj?.id?.toString()),
      campaign_type: currentSipChoice,
      fund_name: selectedFundName,
      cta_type: selectedCtaType,
      landing_page: selectedLandingPage?.publish_page_id,
      showcase_page: selectedShowcasePage?.id,
      email_body: emailBody
    }
    
    let payload = {
      action: autoEmailContentId ? "update_template":"store_template",
      html_template: autoemailStatus,
      id:autoEmailContentId || null,
      emailcamp_img: imagefile,
      temp_name: templateName,
      temp_description: templateDescription,
      multi_cateSolutions: selectedCategory.map(obj=>obj?.id?.toString()),
      is_option: currentSipChoice,
      sub_cat: selectedFundName,
      greeting_name: selectedGreeting,
      url_option: selectedCtaType,
      L_value: selectedCtaType === "L" ? selectedLandingPage?.publish_page_id : "",
      S_value: selectedCtaType === "S" ? selectedShowcasePage?.id : "",
      editor: emailBody,
      intrltags: selectedHashtag.map(obj=>obj?.id?.toString()),
      mailcontent: emailBody
  }
    
    dispatch(autoEmailMainData(emailData));
    dispatch(saveAutoEmailData(authToken, payload));
  }
  
  const customStyles = {
  option: (provided, state) => ({
    ...provided,
      backgroundColor: state.isSelected ? '#FFF' : '#FFF', // Set the background color to white for selected and unselected options
      color: state.isSelected ? '#333' : '#333', // Set the text color
      '&:hover': {
        backgroundColor: '#F0F0F0', // Set the background color on hover
      },
    }),
  };
  
  const handleImage = (e) => {
    if (e) {
      setFile(null);
      setFileName("");
      var fr = new FileReader;
      fr.onload = function () {
        let img = document.createElement('img');
        img.id = 'imgId';
        img.src = e.target.files[0];
        img.onload = function () {
          const imgWidth = img.naturalWidth;
          const imgHeight = img.naturalHeight;
          //console.log("imgWidth", imgWidth, "imgHeight", imgHeight);
          if (imgWidth !== 500 && imgHeight !== 282) {
            setFile(fr.result);
            setFileName(e.target.files[0].name);
            Toast("Image dimesion is not matching.., crop your image & submit", "E");
            return;
          } else {
            setFileName(e.target.files[0].name);
            submitedImage(fr.result, "direct", e.target.files[0].name);
          }
        };
        img.src = fr.result;
      };

      fr.readAsDataURL(e.target.files[0]);
    }
  }
  
  const submitedImage = (e, path, name) => {
    // if (activeAssetData) {
      let blob = new Blob();
      const fileee = new File([e], path === "direct" ? name : fileName, { type: "image/jpeg" });
      // activeAssetData.Base.thumbImg = {
      let img = {
        base64: e,
        file: {
          name: fileee.name,
          size: fileee.size,
          type: fileee.type,
        },
      };
      setImagefile(img);
      setFile(null); setFileName("");
    // }
  }
  
  return (
    <div className="auto-email">
      <div className="new-asset">
        <Card className="mx-2 border border-0">
        <Row className="pick-container">  
            <Col className="pick-template-heading col-lg-12">
                <span>Pick a Template</span>
                <span><Icon.Magic /></span>
            </Col>
            <Col className={`pick-fields-card col-md-12`}>
              <nav class="d-flex gap-3 justify-content-left mb-3">
                <span onClick={() => { setTabData("all")}} class={`text-dark cursor-pointer fw-medium py-1 px-1 ${tabData === "all" && "active-nav"}`}>
                  All
                </span>
                <span onClick={() => { setTabData("email")}} class={`text-dark cursor-pointer fw-medium py-1 px-1 ${tabData === "email" && "active-nav"}`}>
                  Text only
                </span>
                <span onClick={() => { setTabData("html")}}  class={`text-dark cursor-pointer fw-medium py-1 px-1 ${tabData === "html" && "active-nav"}`}>
                  High Fidelity
                </span>
              </nav>
                <div className="pick-template-row row px-3 gap-5">
                    {[...Array(24)].map(() => {
                        return (
                            <div className="card p-0 col-md-2">
                                <img class="card-img-top" src={DemoTemp} alt="Card_cap"/>
                                <div class="card-body">
                                    <p class="card-text">Template Name</p>
                                </div>
                            </div>
                        );
                    })}
                </div>        
                <Button
                  variant="info"
                  type="submit"
                  className="save-proceed-btn btn-pos mt-lg-0"
                  onClick={handleSubmit}
                >
                  Save & Proceed
                  <span>
                    <Icon.ChevronRight />
                  </span>
                </Button>
            </Col>
          </Row>
        </Card>
      </div>
    </div>
  );
}

export default PickTemplate;
