/* eslint-disable */
import AssetListCard from "./AssetListCard";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { Row, Col, Form, Table } from "react-bootstrap";
import { micrositeListSequencing } from "../../../../redux/actions/actions";
//Redux-imports
import { useSelector, useDispatch } from "react-redux";
import { useState } from "react";

const ListView = (props) => {
  const dispatch = useDispatch();
  const contentList = useSelector((state) => state.assetLibrary.contentList);
  const isLoading = useSelector((state) => state.assetLibrary.loading);
  const handleDragEnd = (e) => {
    if (!e.destination) return;
    dispatch(micrositeListSequencing(props.authToken, e.draggableId , e.destination.index + 1, props.pageCount));
  };
  
  return (
    <>
      {isLoading !== true ?
        <DragDropContext onDragEnd={handleDragEnd}>
        <Row className="g-0 mx-lg-0 mx-2">
          <Droppable droppableId="droppable-1">
            {(provider) => (
              <div
                ref={provider.innerRef}
                {...provider.droppableProps}
              >
                {contentList && contentList.map((content, index) => {
                  return (
                    <Draggable
                      key={content.content_id.toString()}
                      draggableId={content.content_id.toString()}
                      index={index}
                    >
                      {(provider) => (
                        <Col className="mb-4 col-md-12" key={index}>
                          <AssetListCard
                            provider={provider}
                            authToken={props.authToken}
                            userType={props.userType}
                            checked={props.assetChecked}
                            handleCheck={props.handleCheck}
                            reorderList={props.reorderList}
                            content={content}
                            shareHandleShow={props.shareHandleShow}
                          />
                        </Col>
                      )}
                    </Draggable>
                  );
                })}
                {provider.placeholder}
              </div>
            )}
          </Droppable>
        </Row>
        </DragDropContext>
        :
        <>
        {[...Array(20)].map(() => {
          return (
          <div>
              <Form>
                  <div className="hashtag-list-card mb-3" style={{padding:"10px 0px 5px"}}>
                      <Table>
                      <tbody>
                          <tr>
                          <td style={{ width: "2%", textAlign: "center" }}>
                              <span className="shimmerEffect" style={{ width: "87px", height: "50px" }}></span>
                          </td>
                          <td style={{ width: "20%" }}>
                              <span className="shimmerEffect" style={{ width: "150px", height: "25px" }}></span>
                          </td>
                          <td style={{ width: "12%" }}>
                              <span className="shimmerEffect" style={{ width: "100px", height: "25px" }}></span>
                          </td>
                          <td style={{ width: "12%" }}>
                              <span className="shimmerEffect" style={{ width: "100px", height: "25px" }}></span>
                          </td>
                          <td className="hashtag-name" style={{ width: "5%", textAlign: "center" }}>
                              <span className="shimmerEffect" style={{ width: "35px", height: "25px" }}></span>
                          </td>
                          <td style={{ width: "12%" }}>
                              <span className="shimmerEffect" style={{ width: "100px", height: "25px" }}></span>
                          </td>
                          </tr>
                      </tbody>
                      </Table>
                  </div>
              </Form>
          </div>
          )
        })}
        </>
      }
    </>
  );
};

export default ListView;
