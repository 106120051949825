import React, { useEffect, useRef, useState } from "react";
import {
  Row,
  Col,
  Form,
  Card,
  Button,
  Modal,
  FormGroup,
  Dropdown,
} from "react-bootstrap";
import Select from 'react-select';
import {
  fetchAssetSelectTypeList,
  fetchAssetLibraryCategoryList,
  fetchAssetLibrarySubCategoryList,
  fetchAssetLibraryHashtagList,
  fetchAssetLibraryLeadGenerationList,
  saveAutoEmailData,
  fetchGreetingList,
  fetchLandingpageUrls,
  fetchShowcaseUrls,
  fetchMicrositeUrls,
  fetchCreateTemplateData,
  autoEmailMainData,
  setBreadcrumbStatus,
  fetchContentTypeList,
  fetchPersonalizeList,
  checkCampaignTitle,
  addNewHashTag,
  setTitleStatus
} from "../../../../redux/actions/actions";
import * as Icon from "react-bootstrap-icons";
import * as types from "../../../../constants/ActionTypes"
import { useSelector } from "react-redux";
import Multiselect from "multiselect-react-dropdown";
import "./emailasset.css";
import MImage from "../../../../assets/asset-upload/email.png";
import { Editor } from '@tinymce/tinymce-react';
import CropImage from "./CropImage";
import Toast from "../../../shared/Toast";
import { useNavigate } from "react-router-dom";
import _ from "lodash";
import { useDispatch } from "react-redux";
import Loading from "../../../Loading";

function EmailAssetDetail(props) {
  const authToken = props.authToken;
  const dispatch = useDispatch();
  let navigate = useNavigate();
  const [buttonPos, setButtonPos] = useState({ left: -1000, top: 0, show: false });
  const inputRef = useRef(null);
  const editorRef = useRef(null);
  const hiddenFileInput = useRef(null);
  const [file, setFile] = useState(null);
  const [fileName, setFileName] = useState("");
  const [imagefile, setImagefile] = useState(null);
  const [templateName, setTemplateName] = useState("");
  const [templateDescription, setTemplateDescription] = useState("");
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedFundName, setSelectedFundName] = useState(false);
  const [tagName, setTagName] = useState("");
  const [htmlContent, setHtmlContent] = useState("");
  const [emailBody, setEmailBody] = useState(null);
  const [autoEmailContentId, setAutoEmailContentId] = useState(null);
  const [tabData, setTabData] = useState("details");
  const [selectedCtaType, setSelectedCtaType] = useState("none");
  const [selectedHashtag, setSelectedHashtag] = useState([]);
  const [selectedGreeting, setSelectedGreeting] = useState(null);
  const [selectedShowcasePage, setSelectedShowcasePage] = useState(null);
  const [selectedLandingPage, setSelectedLandingPage] = useState(null);
  const [currentSipChoice, setCurrentSipChoice] = useState("none");
  const [tagModal, setTagModal] = useState(false);
  
  const personalize_variable = useSelector((state) => state.assetUpload.autoEmailData?.response?.personalize_var);
  
  const selectedAssetType = useSelector(
    (state) => state.assetUpload.selectedAssetType
  );
  
  const autoEmailPath = useSelector(
    (state) => state.assetUpload.autoEmailPath
  );

  const showcaseUrlList = useSelector(
    (state) => state.assetUpload.showcaseUrlList
  );
  
  const autoemailStatus = useSelector(
    (state) => state.assetUpload.autoemailStatus
  );
  
  const autoemail_main_data = useSelector(
    (state) => state.assetUpload.autoemail_main_data
  );
  
  const title_status = useSelector(
    (state) => state.assetUpload.title_status
  );
  
  const currentAssetDetails = useSelector(
    (state) => state.assetLibrary?.currentAssetDetails?.length > 0 ? state.assetLibrary?.currentAssetDetails[0] : null
  );
  
  const landingpageUrlList = useSelector(
    (state) => state.assetUpload.landingpageUrlList
  );
  
  const greetingList = useSelector((state) => state.assetUpload.greetingList);
  
  const assetLibraryCategoryList = useSelector(
    (state) => state.assetLibrary.categoryList
  );
  
  const assetLibrarySubCategoryList = useSelector(
    (state) => state.assetLibrary.subcategoryList
  );
  
  const isLoading = useSelector((state) => state.assetUpload.loading);
  
  const assetLibraryHashtagList = useSelector(
    (state) => state.assetLibrary.hashtagList
  );
  
  const sipDropdown = useSelector((state) => state.assetUpload?.autoEmailData?.response?.options);
  
  const uploadedData = useSelector(
    (state) => state.assetUpload.saveGlobalFieldsData
  );
  
  // const log = () => {
  //   if (editorRef.current) {
  //     console.log(editorRef.current.getContent());
  //   }
  // };
  
  const getOptionLabel = (option) => option.case_study_title;
  // const getHashtagOptionLabel = (option) => option.tag_name;
  const getLandingOptionLabel = (option) => option.publish_page_name;
  
  useEffect(() => {
    dispatch(fetchAssetSelectTypeList(authToken));
    dispatch(fetchAssetLibraryCategoryList(authToken));
    dispatch(fetchAssetLibrarySubCategoryList(authToken));
    dispatch(fetchAssetLibraryLeadGenerationList(authToken));
    dispatch(fetchAssetLibraryHashtagList(authToken));
    dispatch(fetchGreetingList(authToken));
    dispatch(fetchLandingpageUrls(authToken));
    dispatch(fetchShowcaseUrls(authToken));
    dispatch(fetchMicrositeUrls(authToken));
    dispatch(fetchContentTypeList(authToken));
    dispatch(fetchCreateTemplateData(authToken));
    dispatch(fetchPersonalizeList(authToken));
    dispatch({
      type: types.CAMPAIGN_TITLE_STATUS,
      payload: "",
    });
    if (_.isEmpty(selectedAssetType)) {
      Toast("Uploaded content lost, please re-upload..", "E");
      navigate("/new-asset");
    }
  }, [authToken, dispatch]);
  
  useEffect(() => {
    if (currentAssetDetails) {
      setAutoEmailContentId(autoemail_main_data?.id);
      setTemplateName(autoemail_main_data?.temp_name || "");
      setTemplateDescription(autoemail_main_data?.temp_desc || "") ;
      setHtmlContent(autoemail_main_data?.email_body || "");
      const filteredHashtagObjects = assetLibraryHashtagList?.filter(obj => autoemail_main_data?.hashtag?.includes(obj.id));
      const filteredCategoryObjects = assetLibraryCategoryList.filter(obj => autoemail_main_data?.category?.includes(obj.id));
      
      setSelectedHashtag(filteredHashtagObjects);
      setSelectedCategory(filteredCategoryObjects);
      
      setImagefile(autoemail_main_data?.thumb || null);

      const filteredLandingPage = landingpageUrlList.find(obj => obj.publish_page_id === autoemail_main_data?.landing_page);
      const filteredShowcasePage = showcaseUrlList.find(obj => obj.id === autoemail_main_data?.showcase_page);
      // const filteredSipChoice = sipDropdown.find(obj => obj.id === autoemail_main_data?.campaign_type);
      // const filteredFundName = assetLibrarySubCategoryList.find(obj => obj.id === autoemail_main_data?.fund_name);
      setSelectedGreeting(autoemail_main_data?.greeting)
      setSelectedFundName(autoemail_main_data?.fund_name);
      setCurrentSipChoice(autoemail_main_data?.campaign_type || "none");
      setSelectedLandingPage(filteredLandingPage);
      setSelectedShowcasePage(filteredShowcasePage);
      setSelectedCtaType(autoemail_main_data?.cta_type || "none");
      setEmailBody(autoemail_main_data?.email_body || "");
    }
  }, [autoemail_main_data])
  
  useEffect(() => {
    let emailData = {
      thumb: currentAssetDetails?.thumb_image,
      id:currentAssetDetails?.content_id || null,
      temp_name: currentAssetDetails?.content_title,
      temp_desc: currentAssetDetails?.description,
      greeting: currentAssetDetails?.greeting_id,
      category: currentAssetDetails?.solution_types.map(obj=>obj?.id),
      hashtag: currentAssetDetails?.internal_hashtags.map(obj=>obj?.id),
      campaign_type: currentAssetDetails?.is_option,
      fund_name: currentAssetDetails?.sub_category,
      cta_type: currentAssetDetails?.url_option,
      landing_page: currentAssetDetails?.url_option === "L" ? currentAssetDetails?.publish_page_id:"",
      showcase_page: currentAssetDetails?.url_option === "S" ? currentAssetDetails?.publish_page_id:"",
      email_body: currentAssetDetails?.message
    }
    
    dispatch(autoEmailMainData(emailData))
  }, [currentAssetDetails])
  
  useEffect(() => {
    if (editorRef.current) {
      setEmailBody(editorRef.current.getContent());
    }
  }, [editorRef.current])
  
  const handleClick = event => {
    hiddenFileInput.current.click();
  };
  
  useEffect(() => {
    if (uploadedData && uploadedData.length > 0 && autoEmailPath != 1) {
      navigate("/new-asset/publish-asset");
    }
  }, [uploadedData]);
  
  const onSelectLandingPage = (selectedList) => {
    setSelectedLandingPage(selectedList);
  };
  
  const onSelectShowcasePage = (selectedList) => {
    setSelectedShowcasePage(selectedList);
  };

  const onSelectHashtag = (selected) => {
    setSelectedHashtag(selected);
  };
  
  const onRemoveHashtag = (selectedList) => {
    setSelectedHashtag(selectedList);
  };
  
  const onSelectCategory = (selected) => {
    setSelectedCategory(selected);
  };
  
  const onRemoveCategory = (selectedList) => {
    setSelectedCategory(selectedList);
  };
  
  const addHashTag = () => {
    if (tagName !== "") {
      dispatch(addNewHashTag(authToken, tagName));
      setTagName("");
      setTagModal(false);
    } else {
      Toast("Please enter tag name", "E");
    }
  }
  
  const handleAssetDetailData = () => {
    if (!imagefile) {
      Toast("Thumbnail image is required", "E");
      return;
    } else if (templateName === "") {
      Toast("Please fill the title","E");
      return;
    } else if (title_status == "cross") {
      Toast("Template title already exist.","E");
      return;
    }
    window.scrollTo(0, 0);
    let emailData = {
      thumb: imagefile,
      id:autoEmailContentId || null,
      temp_name: templateName,
      temp_desc: templateDescription || "",
      greeting: selectedGreeting,
      category: selectedCategory?.map(obj=>obj.id) || [],
      hashtag: selectedHashtag?.map(obj=>obj.id) || [],
      campaign_type: currentSipChoice || "none",
      fund_name: selectedFundName || false,
      cta_type: selectedCtaType,
      landing_page: selectedLandingPage?.publish_page_id,
      showcase_page: selectedShowcasePage?.id,
      email_body: emailBody
    }
    
    dispatch(autoEmailMainData(emailData))
    console.log(emailData);
    dispatch(setBreadcrumbStatus(4));
    setTabData("email");
  }
  
  const handleSubmit = () => {
    if (templateDescription === "") {
      Toast("Please add email subject", "E");
      return;
    } else if (emailBody === "") {
      Toast(`Please add ${autoemailStatus != 1 ? "Text" : "HTML"} template`,"E");
      return;
    }
    let emailData = {
      thumb: imagefile,
      id:autoEmailContentId || null,
      temp_name: templateName,
      temp_desc: templateDescription,
      greeting: selectedGreeting,
      category: selectedCategory?.map(obj=>obj?.id?.toString()) || [],
      hashtag: selectedHashtag?.map(obj=>obj?.id?.toString()) || [],
      campaign_type: currentSipChoice,
      fund_name: selectedFundName,
      cta_type: selectedCtaType,
      landing_page: selectedLandingPage?.publish_page_id,
      showcase_page: selectedShowcasePage?.id,
      email_body: emailBody || htmlContent
    }
    
    let payload = {
      action: autoEmailContentId ? "update_template":"store_template",
      html_template: autoemailStatus,
      id:autoEmailContentId || null,
      emailcamp_img: imagefile,
      temp_name: templateName,
      temp_description: templateDescription,
      multi_cateSolutions: selectedCategory?.map(obj=>obj?.id?.toString()) || [],
      is_option: currentSipChoice,
      sub_cat: selectedFundName,
      greeting_name: selectedGreeting,
      url_option: selectedCtaType,
      L_value: selectedCtaType === "L" ? selectedLandingPage?.publish_page_id : "",
      S_value: selectedCtaType === "S" ? selectedShowcasePage?.id : "",
      editor: emailBody || htmlContent,
      intrltags: selectedHashtag?.map(obj=>obj?.id?.toString()) || [],
      mailcontent: emailBody || htmlContent
    }
    
    dispatch(autoEmailMainData(emailData));
    
    // setTimeout(() => {
      dispatch({ type: "TOGGLE_LOADING", payload: true });
      dispatch(setBreadcrumbStatus(5));
      dispatch(saveAutoEmailData(authToken, payload));
      dispatch(setTitleStatus(""));
    
    // }, 4000);
    
    if (autoEmailPath == 1) {
      navigate("/AssetLibrary");
    }
    
  }
  
  const customStyles = {
  option: (provided, state) => ({
    ...provided,
      backgroundColor: state.isSelected ? '#FFF' : '#FFF', // Set the background color to white for selected and unselected options
      color: state.isSelected ? '#333' : '#333', // Set the text color
      '&:hover': {
        backgroundColor: '#F0F0F0', // Set the background color on hover
      },
    }),
  };
  
  const handleImage = (e) => {
    if (e) {
      setFile(null);
      setFileName("");
      var fr = new FileReader;
      fr.onload = function () {
        let img = document.createElement('img');
        img.id = 'imgId';
        img.src = e.target.files[0];
        img.onload = function () {
          const imgWidth = img.naturalWidth;
          const imgHeight = img.naturalHeight;
          //console.log("imgWidth", imgWidth, "imgHeight", imgHeight);
          if (imgWidth !== 500 && imgHeight !== 282) {
            setFile(fr.result);
            setFileName(e.target.files[0].name);
            Toast("Image dimesion is not matching.., crop your image & submit", "E");
            return;
          } else {
            setFileName(e.target.files[0].name);
            submitedImage(fr.result, "direct", e.target.files[0].name);
          }
        };
        img.src = fr.result;
      };

      fr.readAsDataURL(e.target.files[0]);
    }
  }
  
  const submitedImage = (e, path, name) => {
    // if (activeAssetData) {
      let blob = new Blob();
      const fileee = new File([e], path === "direct" ? name : fileName, { type: "image/jpeg" });
      // activeAssetData.Base.thumbImg = {
      let img = {
        base64: e,
        file: {
          name: fileee.name,
          size: fileee.size,
          type: fileee.type,
        },
      };
      setImagefile(img);
      setFile(null); setFileName("");
    // }
  }
  
  const updateButtonPosition = () => {
    const editor = editorRef?.current;
    if (editor) {
      const contentAreaContainer = editor?.getContentAreaContainer();
      const editorIframe = contentAreaContainer?.querySelector('iframe');
      const iframeWindow = editorIframe?.contentWindow;
      const iframeDocument = iframeWindow?.document;
      const selection = iframeDocument?.getSelection();

      if (selection?.rangeCount > 0) {
        const cursorPosition = selection?.getRangeAt(0).getBoundingClientRect();
        const iframeRect = editorIframe?.getBoundingClientRect();
        setButtonPos({
          left: (cursorPosition.left || -1000)  + (tabData !== "email" ? 55 : 10),
          top: cursorPosition.top + (tabData !== "email" ? 143 : 45),
          show: true,
        });
      }
    }
  };

  useEffect(() => {
    const handleIframeLoad = () => {
      const editorInstance = editorRef?.current;
      if (editorInstance) {
        const iframe = editorInstance?.getContentAreaContainer().querySelector('iframe');
        const iframeWindow = iframe?.contentWindow;

        iframeWindow.addEventListener('keyup', updateButtonPosition);
        iframeWindow.addEventListener('mouseup', updateButtonPosition);

        return () => {
          iframeWindow.removeEventListener('keyup', updateButtonPosition);
          iframeWindow.removeEventListener('mouseup', updateButtonPosition);
        };
      }
    };

    const editorInstance = editorRef.current;
    if (editorInstance) {
      const contentAreaContainer = editorInstance?.getContentAreaContainer();
      const iframe = contentAreaContainer?.querySelector('iframe');

      if (iframe) {
        iframe.addEventListener('load', handleIframeLoad);
      }

      return () => {
        if (iframe) {
          iframe.removeEventListener('load', handleIframeLoad);
        }
      };
    }
  }, [editorRef.current]);
  
  const insertTextAtCursor = (value) => {
    const editor = editorRef.current;
    if (editor) {
      editor.execCommand('mceInsertContent', false, value);      
      let b_pos = buttonPos;
      b_pos.show = false;      
      setButtonPos(b_pos)
    }
  };
  
  return (
    <div className="auto-email">
      <div className="new-asset">
        {isLoading && <Loading />}
        <Row className="custom-row">
          <Col>
            <ul className="selected-asset-list brochure">
              <li className="asset-type-img">
                <img
                  src={MImage}
                  alt={selectedAssetType.article_type}
                />
              </li>
            </ul>
          </Col>
        </Row>
        <Card className="mx-2 border border-0">
          <Row className="email-container w-100 g-0 p-0">
            <Col className={`email-fields-card ${tabData != "upload" ? "col-lg-6":"col-lg-12"} col-md-12`}>
              <nav class="d-flex gap-3 justify-content-left mb-3">
                <span onClick={() => { setTabData("details"); dispatch(setBreadcrumbStatus(3));}} class={`text-dark cursor-pointer fw-medium py-1 px-1 ${tabData === "details" && "active-nav"}`}>
                  Details
                </span>
                <span class={`text-dark cursor-pointer fw-medium py-1 px-1 ${tabData === "email" && "active-nav"}`}>
                  {autoemailStatus == 1 ? "Upload":"Email"}
                </span>
                {autoemailStatus == 1 && <span class={`text-dark cursor-pointer fw-medium py-1 px-1 ${tabData === "upload" && "active-nav"}`}>
                  Email
                </span>}
              </nav>
              {tabData === "details" && <>

              {(selectedAssetType.type !== "Brochure" && selectedAssetType.type !== "Video") && <>
                    {/* {imagefile !== null && (activeAssetData.Base && !_.isEmpty(activeAssetData.Base.thumbImg)) */}
                    {imagefile !== null
                       ? (
                        <div className="thumbImg mb-3 position-relative">
                          <img
                            src={imagefile?.base64 || imagefile
                              // || activeAssetData.Base.thumbImg.base64
                            }
                            alt="thumbImg"
                            />
                          <Icon.X
                            onClick={() => {
                              setImagefile(null);
                              // activeAssetData.Base.thumbImg = {};
                            }}
                            className="cursor-pointer close-icon-asset"
                          />
                        </div>
                      ) : (
                        <div className="mb-3">
                          <Button onClick={handleClick} className="btn submit-btn m-lg-0 mb-2 mt-5">
                            Upload Thumbnail
                          </Button>
                          <input type="file"
                            ref={hiddenFileInput}
                            style={{ display: 'none' }}
                            accept="image/*"
                            onChange={(e) => handleImage(e)}
                            />
                          <div className="position-relative">
                            {(selectedAssetType.type === "Brochure" ||
                              selectedAssetType.type === "Infographics" ||
                              selectedAssetType.type === "Infographic") && <small className="brochureThumbInfo">Note: If you don't upload a thumbnail, the first page of the PDF will be auto-fetched as a thumbnail.</small>}
                            <small className="text-danger small-text">Accepted image size - Width: 500px and Height: 282px</small>
                          </div>
                          {file !== null &&
                            <div style={{ width: 600, height: 400 }} className="mt-2">
                              <CropImage file={file} onImgSubmit={(e) => submitedImage(e, "crop", "")} /> {/* setSubmitImage */}
                            </div>
                          }
                        </div>
                      )}
                    </>
                  }
                
              <Form.Group className="mb-3 position-relative smartFolder-multiSelect">
                <Form.Control
                  className="d-flex input-fix-height align-items-center gap-3 border rounded bg-white "
                  type="text"
                  autoComplete="off"
                  required
                  placeholder="Title *"
                  value={templateName} 
                  onBlur={(e) => {
                    if (e.target.value) {
                    dispatch(checkCampaignTitle(authToken, templateName, autoEmailContentId))                    
                    }
                  }}
                  onChange={(e) => {
                    setTemplateName(e.target.value)
                    if (e.target.value == "") {
                      dispatch({
                        type: types.CAMPAIGN_TITLE_STATUS,
                        payload: "",
                      });
                    }
                  }}
                />
                  {title_status !== "" ? (title_status !== "cross" ? <Icon.Check2Circle className="text-success icon-position-status" /> : <Icon.XCircle title="Template title already exist." onClick={() => { dispatch(setTitleStatus("")); setTemplateName("")}} className="cursor-pointer text-danger icon-position-status"/>):""}  
              </Form.Group>

              {/* <Form.Group className="mb-3 smartFolder-multiSelect">
                <Form.Control
                  className="d-flex input-fix-height align-items-center gap-3 border rounded bg-white "
                  type="text"
                  autoComplete="off"
                  required
                  placeholder="Description"
                  value={templateDescription}  
                  onChange={(e) => {
                    setTemplateDescription(e.target.value)
                  }}
                />
              </Form.Group> */}

              <div className="w-100 mb-3 hash-email">
                  <div className="input-form-column w-100">
                  <Multiselect
                    className="input-form w-100"
                    options={assetLibraryCategoryList}
                    avoidHighlightFirstOption={true}
                    selectedValues={selectedCategory}
                    onSelect={onSelectCategory}
                    onRemove={onRemoveCategory}
                    displayValue="solution_type"
                    showCheckbox={true}
                    placeholder="Select Category"
                  />
                </div>
              </div>
                
              <div className="w-100 mb-3 hash-email">
                <div className="input-form-column">
                  <Multiselect
                    className="input-form w-100"
                    options={assetLibraryHashtagList}
                    avoidHighlightFirstOption={true}
                    selectedValues={selectedHashtag}
                    onSelect={onSelectHashtag}
                    onRemove={onRemoveHashtag}
                    displayValue="tag_name"
                    showCheckbox={true}
                    placeholder="Select Hashtags"
                  />
                  <div className="max-width-100">
                    <span
                      className="mt-1 text-decoration-none primary-color-text show-pointer"
                      onClick={() => {
                        setTagModal(true);
                      }}
                    >
                      Add New Hashtag?
                    </span>
                  </div>
                </div>
              </div>  

              <div className="smartFolder-multiSelect mb-3">
                  <div className="input-form-column w-100">
                    <Form.Select
                      className="d-flex align-items-center input-fix-height gap-3 border rounded bg-white "
                      aria-label="select-greetings"
                      style={{ color: "#6e6b6b" }}
                      onChange={(e) => {
                        setSelectedGreeting(e.target.value);
                      }}
                      value={selectedGreeting}
                    >
                      <option value="">Select Greeting</option>
                      {greetingList?.length > 0 && greetingList?.map((greet,g_ind)=>{
                      return (
                        <option key={greet.greetingID} value={greet.greetingID}> {greet.greeting_name} </option>
                      )
                    })}
                    </Form.Select>
                  </div>
                </div>
              {sipDropdown?.length > 0 && <>
                <div className="smartFolder-multiSelect mb-3">
                  <div className="input-form-column w-100">
                    <Form.Select
                      className="d-flex align-items-center input-fix-height gap-3 border rounded bg-white "
                      aria-label="select-greetings"
                      style={{ color: "#6e6b6b" }}
                        onChange={(e) => {
                          setCurrentSipChoice(e.target.value);
                        }}
                        value={currentSipChoice}
                    >
                      <option value="none"> Select SIP Campaign type </option>
                      {sipDropdown?.map((sip,s_ind)=>{
                        return (
                          <option key={sip.id} value={sip.id}> {sip.option_name} </option>
                        )
                      })}
                      <option value={"none"}> {"None"} </option>
                    </Form.Select>
                  </div>
                </div>

                {(
                  <div className="smartFolder-multiSelect mb-3">
                    <div className="input-form-column w-100">
                      <Form.Select
                        className="d-flex align-items-center input-fix-height gap-3 border rounded bg-white "
                        aria-label="select-greetings"
                        style={{ color: "#6e6b6b" }}
                        onChange={(e) => {
                          setSelectedFundName(e.target.value);
                        }}
                        value={selectedFundName}
                      >
                        <option
                          value=""
                          onClick={() => {
                            console.log("sf");
                          }}
                        >
                          Select Fund Name
                        </option>
                        {assetLibrarySubCategoryList.map((sub_cat,sc_ind)=>{
                        return (
                          <option key={sub_cat.id} value={sub_cat.id}> {sub_cat.it_subcat} </option>
                        )
                      })}
                      </Form.Select>
                    </div>
                  </div>
                )}
              </>}  
              <div className="smartFolder-multiSelect mb-3">
                <div className="input-form-column w-100">
                  <Form.Select
                    className="d-flex align-items-center input-fix-height gap-3 border rounded bg-white "
                    aria-label="select-greetings"
                    style={{ color: "#6e6b6b" }}
                      onChange={(e) => {
                        setSelectedCtaType(e.target.value);
                      }}
                      value={selectedCtaType}
                  >
                    <option value="none">Select CTA Type</option>
                    <option value="L"> Landing Pages </option>
                    <option value="S"> Showcase Pages </option>
                  </Form.Select>
                </div>
              </div>
                
              {selectedCtaType === "L" && <div className="w-100 mb-3 hash-email">
                <div className="input-form-column">
                  <Select styles={customStyles} placeholder="Select Landing Page" options={landingpageUrlList} isSearchable value={selectedLandingPage} onChange={onSelectLandingPage} getOptionLabel={getLandingOptionLabel} />    
                </div>
              </div>}

              {selectedCtaType === "S" && <div className="w-100 mb-3 hash-email">
                  <div className="input-form-column">
                    <Select styles={customStyles} placeholder="Select Showcase Page" options={showcaseUrlList} isSearchable value={selectedShowcasePage} onChange={onSelectShowcasePage} getOptionLabel={getOptionLabel} />
                  </div>
                </div>} 
                <Button
                  variant="info"
                  type="submit"
                  className="save-proceed-btn mt-lg-0 mt-3"
                  // disabled={submitBtn}
                  onClick={()=>{handleAssetDetailData()}}
                >
                  Save & Next
                  <span>
                    <Icon.ChevronRight />
                  </span>
                </Button>              
              </>}
              {tabData === "email" && <>
                
                <Form.Group className="mb-3 smartFolder-multiSelect">
                  <Form.Control
                    className="d-flex input-fix-height align-items-center gap-3 border rounded bg-white "
                    type="text"
                    value={templateDescription}  
                    onChange={(e) => {
                      setTemplateDescription(e.target.value)
                    }}
                    autoComplete="off"
                    required
                    placeholder="Subject"
                  />
                </Form.Group>
                
                {autoemailStatus != 1 ? 
                <div className="position-relative">
                <Editor
                  className="d-flex input-fix-height align-items-center gap-3 border rounded bg-white form-control"
                  apiKey='dlxobbzpbqb3bqp8b9bkjwwcaru35qqdwpihxqk9o4nklx3d'
                  onInit={(_evt, editor) => editorRef.current = editor}
                  onChange={(e) => { setEmailBody(e.level.content) }}
                  init={{
                    plugins: 'anchor autolink charmap codesample emoticons image link lists media searchreplace table visualblocks wordcount linkchecker code',
                    toolbar: 'undo redo | bold italic underline strikethrough | link code table | blocks fontfamily fontsize | align lineheight | numlist bullist indent outdent | emoticons charmap | removeformat',
                    tinycomments_mode: 'embedded',
                    menubar: 'false',
                    placeholder: "Enter email body",
                    selector: 'textarea',
                    height: autoemailStatus != 1 ? 500 : 1000,
                    statusbar: false,
                    branding: false,
                    tinycomments_author: 'Author name',
                    
                  }}
                  initialValue={htmlContent}
                  onKeyUp={updateButtonPosition}
                  onMouseUp={updateButtonPosition}
                />
                {buttonPos.show && (
                  <Dropdown 
                    style={{
                      position: 'absolute',
                      left: buttonPos.left,
                      top: buttonPos.top,
                    }}
                    className="editor-button"
                  >
                    <Dropdown.Toggle variant="success" className="d-flex px-2 movable-add-button" id="dropdown-basic">
                      <Icon.Plus/>
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                        {personalize_variable.map((item,index) => {
                          return (
                            <Dropdown.Item onClick={()=>{insertTextAtCursor(item.value)}}>{ item.key }</Dropdown.Item>
                          );
                        })}
                    </Dropdown.Menu>
                  </Dropdown>    
                )}
                </div>
                  :
                <>
                  <Form.Group className="d-flex align-items-center gap-3 smartFolder-multiSelect rounded bg-white" controlId="formTextarea">
                    <Form.Control
                        as="textarea"
                        rows={25}
                        className="resize-none"
                        placeholder="Upload HTML"
                        // ref={descRef}
                        value={htmlContent}
                        onChange={(e) => {setHtmlContent(e.target.value)} }
                      // onChange={(e) => {
                      //   setActiveAssetDesc(e.target.value);
                      //   activeAssetData.Base.description = e.target.value;
                      // }}
                    />
                  </Form.Group>
                </>}
                {/* <button onClick={log}>Log editor content</button> */}
                <Button
                  variant="info"
                  type="submit"
                  className="save-proceed-btn btn-pos mt-lg-0"
                  onClick={() => {
                    if (autoemailStatus != 1) {
                      handleSubmit();
                    } else {
                      if (templateDescription === "") {
                        Toast("Please add email subject", "E");
                        return;
                      } else if (htmlContent === "") {
                        Toast(`Please add ${autoemailStatus != 1 ? "Text" : "HTML"} template`,"E");
                        return;
                      }
                      setTabData("upload")
                    }
                    window.scroll(0, 0);
                  }}
                >
                  Save & {autoemailStatus != 1 ? "Proceed":"Next"}
                  <span>
                    <Icon.ChevronRight />
                  </span>
                </Button>
              </>}
              {tabData === "upload" && <>
                <div className="positon-relative">
                <Editor
                  className="d-flex input-fix-height align-items-center gap-3 border rounded bg-white form-control"
                  apiKey='dlxobbzpbqb3bqp8b9bkjwwcaru35qqdwpihxqk9o4nklx3d'
                  onInit={(_evt, editor) => editorRef.current = editor}
                  onChange={(e) => { setEmailBody(e.level.content) }}
                  init={{
                    plugins: 'anchor autolink charmap codesample emoticons image link lists media searchreplace table visualblocks wordcount linkchecker code',
                    toolbar: 'undo redo | blocks fontfamily fontsize | bold italic underline strikethrough | link code table | align lineheight | numlist bullist indent outdent | emoticons charmap | removeformat',
                    tinycomments_mode: 'embedded',
                    menubar: 'false',
                    placeholder: "Enter email body",
                    selector: 'textarea',
                    height: 1000,
                    statusbar: false,
                    branding: false,
                    tinycomments_author: 'Author name',
                    
                  }}
                  initialValue={htmlContent}
                  onKeyUp={updateButtonPosition}
                  onMouseUp={updateButtonPosition}
                />
                {buttonPos.show && (
                  <Dropdown 
                    style={{
                      position: 'absolute',
                      left: buttonPos.left,
                      top: buttonPos.top,
                    }}
                    className="editor-button"
                  >
                    <Dropdown.Toggle variant="success" className="d-flex px-2 movable-add-button" id="dropdown-basic">
                      <Icon.Plus/>
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                        {personalize_variable.map((item,index) => {
                          return (
                            <Dropdown.Item onClick={()=>{insertTextAtCursor(item.value)}}>{ item.key }</Dropdown.Item>
                          );
                        })}
                    </Dropdown.Menu>
                  </Dropdown>    
                )}
                </div>
                <Button
                  variant="info"
                  type="submit"
                  className="save-proceed-btn btn-pos mt-lg-0"
                  onClick={handleSubmit}
                >
                  Save & Proceed
                  <span>
                    <Icon.ChevronRight />
                  </span>
                </Button>
              </>}
            </Col>
            {(tabData != "upload")&& <Col className="email-fields-card preview-mode bg-text-white col-lg-6 col-md-12">
              <p className="text-disabled font-inter font-size-16 font-weight-400 lh-24 custom-font-features">
                {autoemailStatus == 1 && (htmlContent == "") ? "No Preview Available":"Preview"}
              </p>
              <>
                {templateDescription !== "" && <p className="my-3 subject-preview">{templateDescription}</p>}
                <div
                  className="d-flex custom-style flex-column align-items-start p-4 gap-3 rounded bg-white"
                  style={{ width: "529px" }}
                >
                  {autoemailStatus != 1 && <p className="d-flex gap-2">
                    From <b>SenderName</b>
                  </p>}
                  <div dangerouslySetInnerHTML={{ __html: emailBody || htmlContent }} style={{ zoom: autoemailStatus == 1 ? 0.6 : 1,margin:autoemailStatus == 1 ? "auto":"inherit"}} className="d-flex flex-column justify-content-end align-items-stretch">
                  </div>
                </div>
              </>
            </Col>}
          </Row>
        </Card>
      </div>
      <Modal show={tagModal} onHide={() => { setTagModal(false);  setTagName("");}}>
        <Modal.Header closeButton>
          {" "}
          {/* style={{ border: "none" }} */}
          <div className="fw-bold h5">Add New Hashtag</div>
        </Modal.Header>
        <Modal.Body>
          <FormGroup>
            <Form.Label>Hashtag name</Form.Label>
            <Form.Control
              type="text"
              autoComplete="off"
              name="tag-input"
              required
              placeholder="Hashtag Name"
              value={tagName}
              onChange={(e) => setTagName(e.target.value)}
            />
          </FormGroup>
        </Modal.Body>
        <Modal.Footer>
          {" "}
          {/* style={{ border: "none" }} */}
          <Button
            variant="info"
            onClick={() => addHashTag()}
          >
            Add
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default EmailAssetDetail;
