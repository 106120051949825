import React from 'react'
import { Row, Col, Form, Button, Modal, InputGroup } from "react-bootstrap";
import * as Icon from "react-bootstrap-icons";
import SelectTable from './SelectTable';
import { useState, useRef, useEffect } from "react";
import vector from '../../assets/setup-icon/Vector.png';
import _ from "lodash";
import Loading from "../Loading";
import { DebounceInput } from 'react-debounce-input';
import * as types from "../../constants/ActionTypes";
import { useDispatch, useSelector } from "react-redux";
import Toast from "../shared/Toast";
import Pagination from "../shared/ReactPaginate";
import DragShimmer from './shimmer/DragShimmer';
import { fetchHashtagListing, deleteBulkListing, addHashtag, searchInput } from "../../redux/actions/setupActions";
// import { useNavigate } from "react-router-dom";

const SetupHashtag = (props) => {
    // let navigate = useNavigate();
    const dispatch = useDispatch();
    // const isLoading = useSelector((state) => state.setupScreen.loading);
    let isLoading = useSelector((state) => state.setupScreen.loading);
    let search_input = useSelector((state) => state.setupScreen.search_input);
    let pageNum = useSelector((state) => state.setupScreen.currPage);
    const authToken = props.authToken;
    const total_data = useSelector((state) => state.setupScreen.hashtag_list?.total_data);
    const hash_list = useSelector((state) => state.setupScreen.hashtag_list);
    const [checked, setChecked] = useState(0);
    const [tagName, setTagName] = useState("");
    const [isSelectAll, setIsSelectAll] = useState(false);
    const [searchValue, setSearchValue] = useState("");
    const [sortValue, setSortValue] = useState("ASC");
    const [contentList, setContentList] = useState({});
    const [showDelete, setShowDelete] = useState(false);
  
    const handleCloseDelete = () => setShowDelete(false);
  
    const handleShowPopup = () => {
        setShowDelete(true);
    }

  const unselectData = () => {
      setContentList({});
      setChecked(0);
      setIsSelectAll(false);
    }
  
    const deleteHandleShow = () => {
      dispatch(deleteBulkListing(authToken, contentList,pageNum));
      handleCloseDelete();
      handleRemoveSearch();
      setContentList({});
      setChecked(0);
    }

    const handleUnselectedData = (value) => {
      setIsSelectAll(value)
    }
  
    const selectAllHashtag = (selectFlag) => {
      setIsSelectAll(!selectFlag);
      let all_ids = hash_list.taglist.map((item) => {
        return item.id;
      })
      setChecked(hash_list?.taglist?.length);
      setContentList({ web: all_ids });
      console.log(contentList);
    }
    const saveHashtag = (tagTitle) => {
      if (tagTitle !== "") {
        dispatch(addHashtag(authToken, tagTitle))
        handleClose();
      } else {
        Toast("Please add hashtag name", "E");
      }
    }
    const handleAddName = (e) => {
      setTagName(e.target.value);
    }
    const handleRemoveSearch = (e) => {
        setSearchValue("");
        dispatch(searchInput(""));
        dispatch(fetchHashtagListing(authToken, pageNum, ""));
    }
    const dateSorting = (e) => {
      if (sortValue === "ASC") {
        setSortValue("DESC");
      } else {
        setSortValue("ASC");
      }  
      dispatch(fetchHashtagListing(authToken, pageNum, search_input, sortValue));
    }
    const handleSearch = (e) => {
      setSearchValue(e.target.value);
      dispatch(searchInput(e.target.value));
      dispatch(fetchHashtagListing(authToken, 0, e.target.value));
    }
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
  
    const handleCheck = (e, id, type) => {
      if (e === null) {
        setChecked(0);
      } else {
        let _updatedContent = _.clone(contentList);
        let count = checked;
        if (e.target.checked) {
          if (_.has(contentList, [type])) {
            if (!_updatedContent[type].includes(id)) {
              _updatedContent[type].push(id);
            }
          } else {
            _updatedContent[type] = [id];
          }
          setChecked(count + 1);
        } else {
          if (_.has(contentList, [type])) {
            _updatedContent[type] = _updatedContent[type].filter(
              (item) => item !== id
            );
            if (_updatedContent[type].length === 0) {
              _updatedContent = _.omit(_updatedContent, [type]);
            }
          }
          setChecked(count - 1);
        }
        setContentList(_updatedContent);
      }
      console.log("current",contentList);
    };
  
    useEffect(() => {
      // setSearchValue(search_input);
      // dispatch(fetchHashtagListing(authToken, 0, search_input));
      dispatch(fetchHashtagListing(authToken, 0, ""));
    }, []);
  
    useEffect(() => {    // onDestroy
        return () => {
          dispatch({ type: types.SEARCH_INPUT, payload: "" });
          dispatch({ type: types.CURRENT_PAGE, payload: 0 });
        }
    }, [])
    
    return (
      <div>
        <div>
          <div>
            <InputGroup className="mb-3 search-inside">
              <DebounceInput value={searchValue} className="search-inside-input" placeholder="Search Hashtags" minLength={3} debounceTimeout={2000} onChange={handleSearch} />
              <InputGroup.Text id="basic-addon1">
                {searchValue && searchValue !== "" ? <Icon.X color="#A8A8A8" onClick={handleRemoveSearch} /> : <Icon.Search color="#A8A8A8" onClick={handleSearch}/>}
              </InputGroup.Text>
            </InputGroup>
            <div className='right-buttons'>
              <span>Sort By <span className='sort-text mx-1' onClick={dateSorting}>Date Added <img className={sortValue === "DESC" ? 'rotate' : ''} src={vector} alt="icon" /></span></span>
              <Button style={{marginLeft: '60px'}} onClick={handleShow} className='common-setup-button' variant="primary">+ Hashtag</Button>
            </div>
          </div>
          
          {checked > 0 && (
            <Row>
              <Col>
                <div className="selectedAssetActions">
                  <ul>
                    <li>
                      <Button variant="light selected">
                        {checked} Selected
                      </Button>
                    </li>
                      <li>
                        <Button
                          variant="light"
                          onClick={handleShowPopup}
                        >
                          <span>
                            <Icon.Trash />
                          </span>
                          Delete
                        </Button>
                    </li>
                    {/* <li>
                      <Button variant="light">
                        <span>
                          <Icon.Folder />
                        </span>
                        Add to Group
                      </Button>
                    </li> */}
                  </ul>
                    <ul className="float-right">
                      <li>
                      <Button
                        variant="light selected"
                        onClick={() => {
                          selectAllHashtag(isSelectAll)
                        }}
                      >
                        Select All
                      </Button>
                      </li>
                      <li>
                        <Button
                          variant="light selected"
                          onClick={unselectData}
                        >
                          Unselect
                        </Button>
                      </li>
                    </ul>
                </div>
              </Col>
            </Row>
          )}
          
          <div>
            {isLoading && <DragShimmer subPageName={props.subPageName} tableData="hashtag"/>}
            {hash_list?.taglist?.length > 0 && isLoading === false && hash_list.taglist.map((listItem,index) => {
              return (
                <Col key={index}>
                    <SelectTable tableData="hashtag" page={pageNum} authToken={props.authToken} listItem={listItem} handleCheck={handleCheck} handleUnselectedData={handleUnselectedData} isSelectAll={isSelectAll} checked={checked} />
                </Col>
              )
            })}
            {hash_list?.taglist.length === 0 && (
              <div className="no-data asset">
                <span>
                  <Icon.Inbox />
                </span>
                <br />
                No Data
              </div>
            )}
          </div>
          
          {total_data > 20 && <Pagination authToken={props.authToken} total_page={ total_data } unselectData={unselectData} page={pageNum} currPage={props.subPageName} />}
          {/* <Pagination authToken={props.authToken} total_page={ total_data } unselectData={unselectData} currPage={props.subPageName} /> */}
          
          {/* add hashtag popup */}
          <Modal show={show} onHide={handleClose} size="sm" animation="true" centered>
            <Modal.Header closeButton>
              <Modal.Title className='modal-heading-custom'>Create Hashtag</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form.Control onChange={handleAddName} type="text" placeholder="Hashtag Name" />
              <Button className='common-setup-button w-100 mt-3' variant="primary" onClick={()=>{saveHashtag(tagName)}}>
                Save Changes
              </Button>
            </Modal.Body>
          </Modal>
          
          {/* Delete popup  */}
            <Modal className="delete-popup"  show={showDelete} onHide={handleCloseDelete}>
                <Modal.Header closeButton>
                    <Modal.Title>Delete</Modal.Title>
                </Modal.Header>
                <Modal.Body>Are you sure you want to delete ?</Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={deleteHandleShow}>
                      Delete
                    </Button>
                    <Button variant="secondary" onClick={handleCloseDelete}>
                      Cancel
                    </Button>
                </Modal.Footer>
            </Modal>
          
        </div>
      </div>
    )
}

export default SetupHashtag;