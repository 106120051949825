import React, { useState, useEffect } from "react";
import { Button, Form } from "react-bootstrap";
import Page from "../../Page";
import * as Icon from "react-bootstrap-icons";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { useLocation } from "react-router";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import * as types from "../../../constants/ActionTypes";
import {
  addEditVideoCtaData,
  fetchButtonCtaDropdown,
  fetchSelectedDataCta,
} from "../../../redux/actions/conversionAction";
import Loading from "../../Loading";

const VideoCta = (props) => {
  const [dropdownState, setDropdownState] = useState(false);
  const [selectedValues, setSelectedValues] = useState([]);
  const [flag, setFlag] = useState(false);
  const [blockValues, setBlockValues] = useState("");
  const [blockValuesIndex, setBlockValuesIndex] = useState(null);
  const [nameEditFlag, setNameEditFlag] = useState(false);
  const [videoCtaName, setVideoCtaName] = useState("Title");
  const [videoCtaTime, setVideoCtaTime] = useState("");

  const dispatch = useDispatch();

  const useQuery = () => {
    return new URLSearchParams(useLocation().search);
  };
  let query = useQuery();

  console.log(query.get("id"), "query");

  useEffect(() => {
    dispatch(fetchButtonCtaDropdown(props.authToken, "input_type"));
    if (query.get("id") !== null) {
      console.log("Abhishek");
      dispatch(
        fetchSelectedDataCta(props.authToken, "fetch_CTA_form", query.get("id"))
      );
    }
  }, []);

  const cta_button_dropdown = useSelector(
    (state) => state.conversionData.cta_button_dropdown
  );
  const selected_data = useSelector(
    (state) => state.conversionData.selected_data
  );
  const loading = useSelector((state) => state?.setupScreen.loading);

  //Setting selected data at the time of edit
  useEffect(() => {
    if (selected_data) {
      setSelectedValues(selected_data.input_form);
      setVideoCtaName(selected_data.ctaName);
      setVideoCtaTime(selected_data.video_time);
    }
  }, [selected_data]);

  useEffect(() => {
    // onDestroy
    return () => {
      dispatch({ type: types.FETCH_SELECTED_DATA, payload: null });
    };
  }, []);

  console.log("selecteddata", selected_data);

  ////DropDown
  const handleDropdownClick = () => {
    setDropdownState(!dropdownState);
  };
  // Setting values data are selected in dropdown
  const handleSetDropdownValue = (value) => {
    const arr = [...selectedValues];
    const newArr = arr.filter((item) => {
      return item.attributeName === value.attributeName;
    });
    if (newArr?.length > 0) {
    } else {
      arr.push(value);
    }
    setSelectedValues(arr);
    setDropdownState(!dropdownState);
  };

  //Handling dynamic input field for all the selected values
  const handleInputChange = (e, index, check) => {
    console.log(e, "Value check");

    if (check != "checked") {
      const { name, value } = e.target;
      setSelectedValues((prevFormData) => {
        const updatedFormData = [...prevFormData];
        updatedFormData[index] = {
          ...updatedFormData[index],
          [name]: value,
        };
        return updatedFormData;
      });
    } else {
      const { name, checked } = e.target;
      setSelectedValues((prevFormData) => {
        const updatedFormData = [...prevFormData];
        updatedFormData[index] = {
          ...updatedFormData[index],
          [name]: checked,
        };
        return updatedFormData;
      });
    }
  };
  // Drag and drop functionality
  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }

    const newData = Array.from(selectedValues);
    const [removed] = newData.splice(result.source.index, 1);
    newData.splice(result.destination.index, 0, removed);

    setSelectedValues(newData);
  };
  //Form Close
  const handleClose = () => {
    setBlockValues("");
    setBlockValuesIndex(null);
    setFlag(false);
  };
  //Form Delete
  const handleDelete = (index) => {
    const randomArr = [...selectedValues];
    randomArr.splice(index, 1);
    setSelectedValues(randomArr);
    setFlag(false);
  };

  // Handling Save event
  const handleSave = () => {
    selectedValues.forEach((object, index) => {
      object.order = index;
    });
    //In the case of add
    if (query.get("id") !== null) {
      dispatch(
        addEditVideoCtaData(
          props.authToken,
          "update_CTA_form",
          videoCtaName,
          videoCtaTime,
          selectedValues,
          query.get("id")
        )
      );
    } else {
      //in the case of edit
      dispatch(
        addEditVideoCtaData(
          props.authToken,
          "add_CTA_form",
          videoCtaName,
          videoCtaTime,
          selectedValues
        )
      );
    }
  };

  return (
    <>
      <Page title="video CTA">
        {loading && <Loading />}
        <div className="container">
        <div className="videoCtaContainer mb-5 videocta-wrap">
          <div className="nameBlock row  align-items-center h-25v">
            {!nameEditFlag && (
              <div className="col-lg-6 col-md-5  col-sm-5 col-12  d-flex">
                <div className="name">{videoCtaName}</div>
                <span
                  className="ms-2 my-auto "
                  onClick={() => setNameEditFlag(true)}
                >
                  <Icon.Pencil color="#a1a5b7" />
                </span>
              </div>
            )}
            {nameEditFlag && (
              <div className="col-lg-6   my-auto d-flex">
                <Form.Control
                  value={videoCtaName}
                  className="mx-2 w-25"
                  type="text"
                  onBlur={() => setNameEditFlag(false)}
                  // placeholder="Phone *"
                  onChange={(e) => {
                    console.log(e.value);
                    setVideoCtaName(e.target.value);
                  }}
                />
                {/* <span
                  className="ms-2 my-auto"
                  onClick={() => setNameEditFlag(false)}
                >
                  <Icon.CheckSquare color="#a1a5b7" />
                </span> */}
                {/* <span className="ms-2 my-auto" >
                    <Icon.XLg color="#a1a5b7" />
                </span> */}
              </div>
            )}
            <div className="col-lg-6  col-md-7 col-sm-7 col-12 my-auto d-flex ">
              <div className="ms-auto d-flex justify-content-start end-content justify-content-sm-end">
                <span>Show First CTA after</span>
                <Form.Control
                  value={videoCtaTime}
                  className="mx-2 inputTime"
                  type="number"
                  // placeholder="Phone *"
                  onChange={(e) => {
                    setVideoCtaTime(e.target.value);
                  }}
                />
                <span>Sec</span>
              </div>
            </div>
          </div>
          <div className="lowerSection container mt-4 mx-auto w-100 h-50v">
            <div className="row gx-5">
              <div className="firstBlock col-lg-2 blockBorder col-sm-5 col-12 ">
                <div className="blockFontSize" onClick={handleDropdownClick}>
                  Input
                  <span className="ms-1">
                    <Icon.ChevronDown color="#a1a5b7" />
                  </span>
                </div>
                <div
                  className={`dropdown-items ${
                    dropdownState ? "isVisible" : "isHidden"
                  }`}
                >
                  {cta_button_dropdown?.length > 0 &&
                    cta_button_dropdown.map((item, index) => {
                      return (
                        <div className="dropdown-item">
                          <div
                            className="dropdown__link"
                            onClick={() => handleSetDropdownValue(item)}
                          >
                            {item.dropName}
                          </div>
                        </div>
                      );
                    })}
                </div>
              </div>

              <div  className="secondBlock    col-lg-5 blockBorder col-sm-7 col-12"
                style={{ maxHeight: "500px", overflow: "auto" }}
              >
                <div
                  className={`${
                    selectedValues?.length > 0 && "mb-4"
                  } blockFontSize`}
                >
                  Input Form
                </div>

                <DragDropContext onDragEnd={onDragEnd}>
                  <Droppable droppableId="selectedValues">
                    {(provided) => (
                      <div
                        className="selectedValues"
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                      >
                        {selectedValues?.length > 0 &&
                          selectedValues.map((item, index) => {
                            return (
                              <Draggable
                                key={item.attributeName}
                                draggableId={item.attributeName}
                                index={index}
                              >
                                {(provided) => (
                                  <div
                                    className="mb-2"
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                  >
                                    <label
                                      for="exampleFormControlInput1"
                                      className="form-label cursor-pointer"
                                    >
                                      {item.label}
                                    </label>
                                    <div className="d-flex my-auto">
                                      {item.prefix?.length > 0 && (
                                        <div className="my-auto me-2">
                                          {item.prefix}
                                        </div>
                                      )}
                                      <div
                                        className={`form-control cursor-pointer ${
                                          !item.placeholder?.length > 0
                                            ? "paddingDiv"
                                            : "paddingDivAfter"
                                        }`}
                                        style={{
                                          background:
                                            item.color?.length > 0
                                              ? item.color
                                              : "",
                                        }}
                                        onClick={(e) => {
                                          console.log("Abhishek");
                                          setFlag(true);
                                          setBlockValues(item);
                                          setBlockValuesIndex(index);
                                        }}
                                      >
                                        {item.placeholder}
                                      </div>
                                      {item.postfix?.length > 0 && (
                                        <div className="my-auto ms-2">
                                          {item.postfix}
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                )}
                              </Draggable>
                            );
                          })}
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                </DragDropContext>
              </div>
              {flag && (
                <div className="thirdBlock col-lg-5 col-sm-12 col-12"
                  style={{ maxHeight: "400px", overflow: "auto" }}
                >
                  <div className="blockFontSize mb-4 d-flex">
                    <div>
                      {blockValuesIndex + 1}
                      {". "}
                      {/* {blockValues.inputType == "text" ? ". Text" : ". Number"} */}
                      {blockValues.dropName}
                    </div>
                    <div className="ms-auto d-flex">
                      <div onClick={() => handleDelete(blockValuesIndex)}>
                        <span>
                          <Icon.Trash  color="#a1a5b7" />
                        </span>
                      </div>
                      <div
                        className="ms-2 cursor-pointer"
                        onClick={handleClose}
                      >
                        <span>
                          <Icon.XLg color="#a1a5b7" />
                        </span>
                      </div>
                    </div>
                  </div>
                  {blockValues.inputType == "text" ? (
                    <div className="textContainer">
                      <div className="mb-2">
                        <label
                          for="exampleFormControlInput1"
                          class="form-label"
                        >
                          Label
                        </label>
                        <input
                          type="text"
                          class="form-control"
                          value={selectedValues[blockValuesIndex]["label"]}
                          name="label"
                          onChange={(e) =>
                            handleInputChange(e, blockValuesIndex)
                          }
                        />
                      </div>
                      <div className="mb-2">
                        <label
                          for="exampleFormControlInput1"
                          class="form-label"
                        >
                          Placeholder
                        </label>
                        <input
                          type="text"
                          class="form-control"
                          value={
                            selectedValues[blockValuesIndex]["placeholder"]
                          }
                          name="placeholder"
                          onChange={(e) =>
                            handleInputChange(e, blockValuesIndex)
                          }
                        />
                      </div>
                      <div className="mb-2">
                        <label
                          for="exampleFormControlInput1"
                          class="form-label"
                        >
                          Color
                        </label>
                        <div class="position-relative">
                          <input
                            type="color"
                            id="exampleForm.ControlInput1"
                            class="w-100 form-control form-control-color"
                            value={selectedValues[blockValuesIndex]["color"]}
                            name="color"
                            onChange={(e) =>
                                handleInputChange(e, blockValuesIndex)
                              }

                          />
                          <span className="position-absolute colorInput">
                            {selectedValues[blockValuesIndex]["color"]}
                          </span>
                        </div>
                      </div>
                      <div className="mb-2">
                        <Form.Check
                          className="me-3"
                          label="Required"
                          name="required"
                          checked={selectedValues[blockValuesIndex]["required"]}
                          onChange={(e) =>
                            handleInputChange(e, blockValuesIndex, "checked")
                          }
                        />
                      </div>
                    </div>
                  ) : (
                    <div className="numberContainer">
                      <div className="mb-2">
                        <label
                          for="exampleFormControlInput1"
                          class="form-label"
                        >
                          Label
                        </label>
                        <input
                          type="text"
                          class="form-control"
                          name="label"
                          value={selectedValues[blockValuesIndex]["label"]}
                          onChange={(e) =>
                            handleInputChange(e, blockValuesIndex)
                          }
                        />
                      </div>
                      <div className="mb-2">
                        <label
                          for="exampleFormControlInput1"
                          class="form-label"
                        >
                          Placeholder
                        </label>
                        <input
                          type="text"
                          class="form-control"
                          name="placeholder"
                          value={
                            selectedValues[blockValuesIndex]["placeholder"]
                          }
                          onChange={(e) =>
                            handleInputChange(e, blockValuesIndex)
                          }
                        />
                      </div>
                      <div className="mb-2">
                        <label
                          for="exampleFormControlInput1"
                          class="form-label"
                        >
                          Min
                        </label>
                        <input
                          type="number"
                          class="form-control"
                          name="min"
                          value={selectedValues[blockValuesIndex]["min"]}
                          onChange={(e) =>
                            handleInputChange(e, blockValuesIndex)
                          }
                        />
                      </div>
                      <div className="mb-2">
                        <label
                          for="exampleFormControlInput1"
                          class="form-label"
                        >
                          Max
                        </label>
                        <input
                          type="number"
                          class="form-control"
                          name="max"
                          value={selectedValues[blockValuesIndex]["max"]}
                          onChange={(e) =>
                            handleInputChange(e, blockValuesIndex)
                          }
                        />
                      </div>
                      <div className="mb-2">
                        <label
                          for="exampleFormControlInput1"
                          class="form-label"
                        >
                          Prefix
                        </label>
                        <input
                          type="text"
                          class="form-control"
                          name="prefix"
                          value={selectedValues[blockValuesIndex]["prefix"]}
                          onChange={(e) =>
                            handleInputChange(e, blockValuesIndex)
                          }
                        />
                      </div>
                      <div className="mb-2">
                        <label
                          for="exampleFormControlInput1"
                          class="form-label"
                        >
                          Postfix
                        </label>
                        <input
                          type="text"
                          class="form-control"
                          name="postfix"
                          value={selectedValues[blockValuesIndex]["postfix"]}
                          onChange={(e) =>
                            handleInputChange(e, blockValuesIndex)
                          }
                        />
                      </div>
                      <div className="mb-2">
                        <label
                          for="exampleFormControlInput1"
                          class="form-label"
                        >
                          Color
                        </label>
                        <div class="position-relative">
                          <input
                            type="color"
                            id="exampleForm.ControlInput1"
                            class="w-60 form-control form-control-color"
                            value={selectedValues[blockValuesIndex]["color"]}
                            name="color"
                            onChange={(e) =>
                                handleInputChange(e, blockValuesIndex)
                              }

                          />
                          <span className="position-absolute colorInput">
                            {selectedValues[blockValuesIndex]["color"]}
                          </span>
                        </div>
                        {/* <input
                          type="color"
                          class="form-control"
                          name="color"
                          value={selectedValues[blockValuesIndex]["color"]}
                          onChange={(e) =>
                            handleInputChange(e, blockValuesIndex)
                          }
                        /> */}
                      </div>
                      <div className="mb-2 d-flex">
                        <Form.Check
                          className="me-3"
                          label="Required"
                          name="required"
                          checked={selectedValues[blockValuesIndex]["required"]}
                          onChange={(e) =>
                            handleInputChange(e, blockValuesIndex, "checked")
                          }
                        />
                        <Form.Check
                          label="Otp"
                          name="otp"
                          checked={selectedValues[blockValuesIndex]["otp"]}
                          onChange={(e) =>
                            handleInputChange(e, blockValuesIndex, "checked")
                          }
                        />
                      </div>
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
          <div className="row mt-auto fixed-button h-25v">
            <div className="col-lg-3 col-md-12 col-sm-12 mb-2 ms-auto ">
              <Button
                variant="primary"
                className="save-button mt-3"
                size="sm"
                onClick={handleSave}
              >
                Save Changes
              </Button>
            </div>
          </div>
        </div>
        </div>
      </Page>
    </>
  );
};

export default VideoCta;
