// reducers
import { LOGOUT, FETCH_TOP_MENU, FETCH_RESET_PASSWORD_DETAILS, FETCH_COMPANY_LOGO, FETCH_TOPMENU_COMPANY_LOGO } from "../../constants/ActionTypes";

const initialState = {
  phpCookies: null,
  logout: null,
  topMenus: [],
  resetPassDetails: null,
  companyLogo: null,
  topMenuLogo: null
};

const headerReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case LOGOUT:
      return { ...state, logout: payload };
    case FETCH_RESET_PASSWORD_DETAILS:
      return { ...state, resetPassDetails: payload };
    case FETCH_TOP_MENU:
      return { ...state, topMenus: payload };
    case FETCH_COMPANY_LOGO:
      return { ...state, companyLogo: payload };
    case FETCH_TOPMENU_COMPANY_LOGO:
      return { ...state, topMenuLogo: payload };
    default:
      return state;
  }
};
export default headerReducer;
