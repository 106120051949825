/* eslint-disable */
import AssetCard from "./AssetCard";
import { Row, Col } from "react-bootstrap";
import * as Icon from "react-bootstrap-icons";

const CardViewContainer = (props) => {

  //console.log("props.contentList",props.contentList);

  return (
    <>
      <Row className="custom-padding">
        {props.contentList.length === 0 && (
          <div className="no-data asset">
            <span>
              <Icon.Inbox />
            </span>
            <br />
            No Data
          </div>
        )}
        {props.contentList &&
          props.contentList.map((content, index) => {
            return (
              <Col className="mb-4 col-lg-3 col-md-6 col-sm-6 col-12" key={index}>
                <AssetCard
                  authToken={props.authToken}
                  userType={props.userType}
                  checked={props.assetChecked}
                  handleCheck={props.handleCheck}
                  content={content}
                  publishAssetContent={props.publishAssetContent}
                  shareHandleShow={props.shareHandleShow}
                  shareAsset={props.shareAsset}
                />
              </Col>
            );
          })}
      </Row>
    </>
  );
};

export default CardViewContainer;
