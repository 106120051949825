import React from 'react'
import { useState, useRef, useEffect } from "react";

const SetupImageLibrary = (props) => {
    return (
      <div>
        Image Library Screen
      </div>
    )
}

export default SetupImageLibrary;