/* eslint-disable */
import { Row, Col, Form, InputGroup, Button, Modal, Tab, Tabs, FormGroup, FormCheck } from "react-bootstrap";
import React, { useState, useEffect } from "react";
import * as Icon from "react-bootstrap-icons";
import "./Addleads.css";
import { useParams } from 'react-router';
import { useDispatch } from 'react-redux';
import { fetchEmailTemplates, sendMailOrTestmail, appUserActivity } from '../../../../../redux/actions/crmActions';
import { useSelector } from "react-redux";
import Editor from "../../../../shared/editor";
import Toast from "../../../../shared/Toast";
import { useNavigate } from "react-router-dom";
import DatePicker from 'react-datepicker';
import moment from 'moment';
import 'react-datepicker/dist/react-datepicker.css';
import Multiselect from "multiselect-react-dropdown";


const AddMail = (props) => {

    const { contactId, from, authToken, setDefault } = props;

    const [modal, setModal] = useState(false);
    const [subject, setSubject] = useState("");
    const [radio, setRadio] = useState('mail');
    const [content, setContent] = useState([]);
    const [link, setLink] = useState("");
    const [testMails, setTestMails] = useState("");
    const [checkMail, setCheckMail] = useState(false);
    const [emailBody, setEmailBody] = useState("");
    const [campId, setCampId] = useState("");
    const [initialEmailBody, setInitialEmailBody] = useState('');
    const [scheduledDate, setScheduledDate] = useState(null);
    const [selectedContentList, setSelectedContentList] = useState([]);

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { id } = useParams();
    const templates = useSelector((state) => state.crmLeads.mailTemplates);
    const userDetails = useSelector((state) => state.crmLeads.userActivityDetails);
    const stagesList = useSelector((state) => state.crmLeads.stagesList);

    let contents = [];
    if (templates?.content_library && templates?.content_library.length > 0) {
        contents = templates?.content_library.map(
            ({ filename: value, template_name: label }) => ({
                value,
                label,
            })
        );
    }


    useEffect(() => {
        if (id !== undefined && id !== null && id !== "") {
            dispatch(appUserActivity(authToken, id.toString()));
        }

        dispatch(fetchEmailTemplates(authToken));
        if (initialEmailBody == '') {
            setInitialEmailBody(`<strong>Dear -firstName-</strong>`);
        }
    }, [])

    const selectedTemplate = (item) => {
        setSubject(item.subject);
        setInitialEmailBody(item.template);
        setCampId(item.mail_id);
        setModal(false);
    }


    const selectContentHandler = (value, event) => {
        if (event === "select-option" && event === "*") {
            setSelectedContentList(contents);
        } else if (
            event === "deselect-option" &&
            event === "*"
        ) {
            setSelectedContentList([]);
        } else if (event.action === "deselect-option") {
            value.filter((o) => o.value !== "*");
            setSelectedContentList(value.map(({ value }) => value));
        } else if (value.length === contents.length - 1) {
            setSelectedContentList(contents);
        } else {
            setSelectedContentList(value.map(({ value }) => value));
        }
    };

    const onSelectContent = (selectedList, selectedItem) => {
        selectContentHandler(selectedList, "select-option");
        setSelectedContentList(selectedList);
    };

    const onRemoveContent = (selectedList, removedItem) => {
        selectContentHandler(selectedList, "deselect-option");
        setSelectedContentList(selectedList);
    };


    const handleSubmit = (val) => {
        let sm = checkMail === false ? 0 : 1;
        //console.log("sdate", scheduledDate);
        if (subject == "") {
            Toast("Please Select a Template", "E");
        } else if (sm == 1 && scheduledDate == null) {
            Toast("Please Add Scheduled Date", "E");
        } else {
            let sdate = scheduledDate !== null ? moment(scheduledDate).format('YYYY-MM-DD HH:mm:ss') : "";
            let contentsList = [];
            if (selectedContentList.length > 0) {
                selectedContentList.map(c => {
                    contentsList.push(c.value);
                })
            }
            let testMailIds = [];
            if (testMails !== "") {
                testMailIds = testMails.split(",");
            }
            if (contactId?.contact_ids?.length > 0) {
                let listt = "";
                contactId.contact_ids.map((item) => {
                    if (listt !== "") {
                        listt += "," + item.email_id;
                    } else {
                        listt = item.email_id;
                    }
                });
                dispatch(sendMailOrTestmail(authToken, listt, subject, emailBody, radio, contentsList, link, testMailIds, sm, sdate, val, campId, stagesList));
            } else if (id && id !== undefined && id !== null && userDetails && userDetails.response?.about) {
                dispatch(sendMailOrTestmail(authToken, userDetails.response.about.email, subject, emailBody, radio, contentsList, link, testMailIds, sm, sdate, val, campId, null));
                navigate(`/crm/leads/${id}`);
            }

            setSubject("");
            setEmailBody(""); setRadio('mail'); setSelectedContentList([]); setLink("");
            setTestMails(""); setCheckMail(false); setCampId("");
            setInitialEmailBody(`<strong>Dear -firstName-</strong>`);

            if (from == 'bulk') {
                setDefault();
            }
            // } else {

        }
    }

    return (
        <>
            <div className="mt-2 pa-1 offcanvas-body-background"> {/*  offcanvas-body-background */}
                {from !== 'bulk' && (
                    <>
                        <div className="fs-5 fw-bold">Send Email</div>
                        <hr />
                    </>
                )}
                <Form className="p-3"> {/* onSubmit={handleSubmit} noValidate validated={validated} */}
                    <InputGroup className="mb-3">
                        <Form.Control
                            placeholder="Email Subject"
                            aria-label="Email Subject"
                            aria-describedby="basic-addon2"
                            value={subject}
                            onChange={(e) => setSubject(e.target.value)}
                            readOnly
                            required
                        />
                        <Button type="button" className="submit-btn" id="button-addon2" onClick={(e) => setModal(true)}>
                            Select Template
                        </Button>
                    </InputGroup>

                    <div className="mb-3" style={{ backgroundColor: '#fff' }}>
                        <Editor initialContent={initialEmailBody} setEmailBody={setEmailBody} />
                    </div>

                    <FormGroup controlId="radio" className="mb-3">
                        <Row>
                            <Col md={3}>Add Content</Col>
                            <Col md={9}>
                                <Form.Check
                                    inline
                                    label="Attach Content"
                                    name="group1"
                                    type="radio"
                                    id="inline-radio-1"
                                    defaultChecked={radio == 'upload' ? true : false}
                                    onChange={() => setRadio('upload')}
                                />
                                <Form.Check
                                    inline
                                    label="Mail Only"
                                    name="group1"
                                    type="radio"
                                    defaultChecked={radio == 'mail' ? true : false}
                                    id="inline-radio-2"
                                    onChange={() => setRadio('mail')}
                                /></Col>
                        </Row>
                    </FormGroup>

                    {/* {radio === 'upload' && templates?.content_library.length > 0 && ( */}


                    {/* 
                            <Form.Group as={Col} controlId="my_multiselect_field">
                                <Form.Label>My multiselect</Form.Label>
                                <Form.Control as="select" multiple value={field} onChange={e => setField([].slice.call(e.target.selectedOptions).map(item => item.value))}>
                                    <option value="field1">Field 1</option>
                                    <option value="field2">Field 2</option>
                                    <option value="field3">Field 3</option>
                                </Form.Control>
                            </Form.Group>
 */}

                    {radio == 'upload' && (
                        <>
                            {/* <Form.Select
                                controlId="content"
                                className="meridien-select mb-3"
                                as="select"
                                multiple
                                value={content}
                                onChange={e => setContent([].slice.call(e.target.selectedOptions).map(item => item.value))}
                            >
                                <option value="">Select Content From Library</option>
                                {templates?.content_library?.length > 0 && templates.content_library.map((item, i) => {
                                    return (
                                        <option key={i} value={item.filename}>{item.template_name}</option>
                                    )
                                })}
                            </Form.Select> */}


                            <div className="smartFolder-multiSelect mb-3">
                                <Form.Label className="mt-2">
                                    Contents
                                </Form.Label>
                                <Multiselect
                                    options={contents}
                                    allowSelectAll={true}
                                    selectedValues={selectedContentList}
                                    onSelect={onSelectContent}
                                    onRemove={onRemoveContent}
                                    displayValue="label"
                                    showCheckbox={true}
                                    avoidHighlightFirstOption={true}
                                    placeholder="Select Contents"
                                    required
                                />
                            </div>


                            <Form.Select
                                controlId="link"
                                className="meridien-select mb-3"
                                as="select"
                                value={link}
                                onChange={e => {
                                    setLink(e.target.value);
                                }}
                            >
                                <option value="">Select link From Library</option>
                                {templates?.content_library?.length > 0 && templates.content_library.map((item, i) => {
                                    return (
                                        <option key={i} value={item.filename}>{item.template_name}</option>
                                    )
                                })}
                            </Form.Select>
                        </>
                    )}

                    <Row className="mb-3">
                        <Form.Label className="fw-bold">Send Test Mail</Form.Label>
                        <Col md={9}>
                            <Form.Control type="email" placeholder="Enter mail id seperate multiple test ids using comma(,)" name="testmail" onChange={(e) => { setTestMails(e.target.value) }} />
                        </Col>
                        <Col md={3}>
                            <Button variant="primary" type="button" className="submit-btn" onClick={(e) => handleSubmit('sendTestMail')}>Send Test Mail</Button>
                        </Col>
                    </Row>

                    <Row>
                        <Col sm md={4}>
                            <FormCheck type="checkbox" inline className="mb-3"
                                label="Schedule Mail"
                                name="checkboxmail"
                                id="inline-checkbox-1"
                                onChange={(e) => setCheckMail(e.target.checked)}>
                            </FormCheck>
                        </Col>
                        {checkMail == true && (
                            <Col sm md={7}>
                                <DatePicker
                                    selected={scheduledDate}
                                    onChange={setScheduledDate}
                                    placeholderText="Select Schedule Date Time"
                                    shouldCloseOnSelect={false}
                                    timeInputLabel="Time:"
                                    dateFormat="MM/dd/yyyy h:mm aa"
                                    minDate={new Date()}
                                    showTimeInput
                                />
                            </Col>
                        )}
                    </Row>

                    <div className="d-flex justify-content-around">
                        {/* <Col> */}
                        {checkMail == true ?
                            <Button variant="primary" type="button" className="submit-btn mt-3" onClick={() => handleSubmit('schedule_mail')}>
                                Schedule Mail
                            </Button> :
                            <Button variant="primary" type="button" className="submit-btn mt-3" onClick={() => handleSubmit('send_now')}>
                                Send Now
                            </Button>
                        }
                        {/* </Col>
                        <Col> */}
                        <Button variant="primary" type="button" className="submit-btn mt-3" onClick={() => handleSubmit('save_as_draft')}>
                            Save as Draft
                        </Button>
                        {/*  </Col> */}
                    </div>


                </Form>




                <Modal
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    backdrop="static"
                    keyboard={false}
                    show={modal}
                    onHide={(e) => setModal(false)}
                >
                    <Modal.Header closeButton>
                        <div className="fs-5">Select Template</div>
                    </Modal.Header>
                    <Modal.Body>
                        <Tabs
                            defaultActiveKey="html"
                            id="uncontrolled-tab-example"
                            className="mb-2">
                            <Tab eventKey="html" title="HTML Template">
                                <div className="template-body">
                                    {templates && templates.html_template.length > 0 && (
                                        <>
                                            {templates.html_template.map((temp, i) => {
                                                return (
                                                    <div key={i} className="border border-1 m-1 p-2" onClick={(e) => selectedTemplate(temp)}>
                                                        <div>{temp.subject}</div>
                                                        <div dangerouslySetInnerHTML={{ __html: temp.template }}></div>
                                                    </div>
                                                )
                                            })}
                                        </>
                                    )}
                                </div>
                            </Tab>
                            <Tab eventKey="text" title="Text Template">
                                <div className="template-body">
                                    {templates && templates.text_template.length > 0 && (
                                        <>
                                            {templates.text_template.map((temp1, i) => {
                                                return (
                                                    <div key={i} className="border border-1 m-1 p-2" onClick={(e) => selectedTemplate(temp1)}>
                                                        <div >{temp1.subject}</div>
                                                        <div dangerouslySetInnerHTML={{ __html: temp1.template }}></div>
                                                    </div>
                                                )
                                            })}
                                        </>
                                    )}
                                </div>
                            </Tab>
                        </Tabs>
                    </Modal.Body>
                </Modal>



            </div>
        </>
    )

}

export default AddMail; 