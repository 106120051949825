// import "./styles.css";
// import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import * as Icon from "react-bootstrap-icons";
// import SubTable from "./SubTable"
// import vector from '../../assets/setup-icon/Vector.png';
import "bootstrap/dist/css/bootstrap.min.css";
import { useDispatch, useSelector } from "react-redux";
import { deleteCategory, setProductAttribute, deleteProductTypeData } from "../../../redux/actions/setupActions";
import { deleteCtaData } from "../../../redux/actions/conversionAction";
import { useNavigate } from "react-router-dom";
import { Button, Modal } from 'react-bootstrap';
import { useState, useEffect } from "react";

export default function CtaTable(props) {
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const [users, setUsers] = useState(props.categoryData);
  // const [parentId, setParentId] = useState("");
  const authToken = props.authToken;

  const [show, setShow] = useState(false);
  const [deleteData, setDeleteData] = useState(null);
  const handleClose = () => {
    setDeleteData(null);
    setShow(false);
  }
  const handleShowPopup = (id) => {
    setShow(true);
    setDeleteData(id);
  }

  // const toggleSubTable = (id) => {
  //   if (parentId !== id) {
  //     setParentId(id);
  //   } else {
  //     setParentId("");
  //   }
  // };

  const deleteEvent = () => {
    dispatch(deleteCtaData(authToken, deleteData, props.page));
    handleClose();
  };

  return (
    <div className="App">
      <div className="table table-responsive borderd" style={{overflowX:"unset"}}>
        <div style={{ lineHeight: "35px" }} className="text-capitalize">
          {users && users.map((user, index) => {
            return (
              <>
                <div key={"table-row-" + index} className="row border-bottom">
                  <div className="table-row-count drag-button">
                    {props.page + index + 1 + "."}
                  </div>
                  <div className="text-aligning table-name" style={{ width: "22%" }}>
                    <span title={user.ctaName}>{(user.ctaName && user.ctaName.slice(0, 20))}
                      {((user.ctaName && user.ctaName.length > 20)) && <span>...</span>}</span>
                  </div>
                  <div className="text-aligning table-name" style={{ width: "18%" }}>
                    <span title={user.type}>{(user.type && user.type.slice(0, 20))}
                      {((user.type && user.type.length > 20)) && <span>...</span>}</span>
                  </div>
                  <div className="text-aligning table-name" style={{ width: "22%" }}>
                    {(user?.type === "CTA" && user.CTA.buttonLabel !== "") &&
                      (<span
                        style={{
                          background: user.CTA.btn_background_color,
                          color: user.CTA.btn_text_color,
                          border: "2px solid " + user.CTA.buttonColor,
                          padding: "6px 8px"
                        }}
                        title={user.CTA.buttonLabel}
                      >
                        {(user.CTA.buttonLabel !== "" && user.CTA.buttonLabel.slice(0, 15))}
                        {((user.CTA.buttonLabel && user.CTA.buttonLabel.length > 15)) && <span>...</span>}
                      </span>)
                    }
                    {user?.type === "Video CTA" &&
                      (<span className="view-button" onClick={() => { props.handleViewShow(user.VideoCTA) }}><Icon.Eye style={{ fontSize: "16px" }} className="mr-3" color="#a1a5b7" /></span>)
                    }
                  </div>
                  <div className="text-aligning table-name" style={{ width: "20%" }}>
                    <span title={user.name}>{(user.name && user.name.slice(0, 20))}
                      {((user.name && user.name.length > 20)) && <span>...</span>}</span>
                  </div>

                  <div className="column-styling flex-content">
                    <span className="edit-icon" onClick={() => {
                      if (user?.type === "CTA") {
                        props.editCtaData(user, "Edit");
                      } else {
                        navigate({
                          pathname: '/conversion/videoCta',
                          search: `?id=${user.id}`,
                        });
                      }

                    }}>
                      <Icon.PencilSquare className="mr-3" color="#a1a5b7" />

                    </span>
                    <span className="edit-icon" onClick={() => { handleShowPopup(user.id) }}>
                      <Icon.TrashFill color="rgba(33, 33, 33, 0.3)" />
                    </span>
                  </div>
                  {/* <div className="column-styling">
                                            <span className="edit-icon" onClick={() => { handleShowPopup(user.id) }}>
                                                <Icon.TrashFill color="rgba(33, 33, 33, 0.3)" />
                                            </span>
                                        </div> */}

                </div>
              </>
            )
          }
          )}
        </div>
      </div>
      <Modal className="delete-popup" show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Delete</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to delete ?</Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={deleteEvent}>
            Delete
          </Button>
          <Button variant="secondary" onClick={handleClose}>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}
