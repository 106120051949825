import React from "react";
import Tags from "../shared/Tags";
import vector from '../../assets/setup-icon/Vector.png';
import FileInputComponent from "react-file-input-previews-base64";
import Multiselect from "multiselect-react-dropdown";
import { Form, Button } from "react-bootstrap";
import "./setup.css";
// import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Toast from "../shared/Toast";
import * as types from "../../constants/ActionTypes";
import { fetchAssetLibraryCategoryList } from "../../redux/actions/actions";
import { addCategoryData, editCategoryData, editContentData, addProductTypeData, addContentData, addBatch } from "../../redux/actions/setupActions";

import { useState, useEffect } from "react";

const SingleScreen = (props) => {
    // let navigate = useNavigate();
    const dispatch = useDispatch();
    const authToken = props.authToken;

    const batchValues = useSelector((state) => state.setupScreen.batch_values);
    
    // const image_url = useSelector((state) => state.setupScreen.curr_image);
   

    console.log(batchValues,"batchValues")


    const [levelName, setLevelName] = useState(batchValues?.name ? batchValues.name : ""); 
    const [points, setPoints] = useState(batchValues?.point ? batchValues.point : ""); 
    const [uploadedData, setUploadedData] = useState({});
    
    

    const [renderForm, setRenderForm] = useState(false); 
    // const [navigationCheck, setNavigationCheck] = useState(false); 
    const [schemaCode, setSchemaCode] = useState(""); 
    const [isInternal, setIsInternal] = useState(false); 
    const [dropdownData, setDropdownData] = useState({});
    // const [parentValue, setParentValue] = useState(null);
    const [placeHolder, setplaceHolder] = useState({});
    const [categoryName, setCategoryName] = useState("");
    const [contentDesc, setContentDesc] = useState("");
    const [selectValues, setSelectValues] = useState(null);
    // const [selectedSubCatValues, setselectedSubCatValues] = useState(null);
    const onSelectCategory = (selectedList, selectedItem) => {
      setSelectValues(selectedItem.id)
    }; 
    const setCategoryNameInput = (e) => {
      setCategoryName(e.target.value);
  }
    const setCategorySchemaCode = (e) => {
      setSchemaCode(e.target.value);
    }
    const setCategoryDescriptionInput = (e) => {
      setContentDesc(e.target.value);
    }
    const onRemoveCategory = (selectedList, removedItem) => {
      setSelectValues(null);
    };
    const handleChange=(e)=>{
      setIsInternal(!isInternal)
      dispatch({ type: types.SET_ATTR_TYPE, payload: isInternal === false ? 1 : 0 });
    }

    const saveData = async (data) => {  
        let imgObject = null;
        if (data && Object.keys(data).length !== 0) {
          imgObject = {
            base64: data.base64,
            file: {
                  name: data.name,
                  size: data.size,
                  type: data.type
              }          
          }
      }
      switch (props.subPageName) {
        
        case "addBatches":
           
              if (imgObject !== null && levelName !== "" && points !== "") {
                await dispatch(addBatch(authToken,levelName, imgObject, points));
              } else {
                Toast("All fields are mandatory", "E");
              }
          break;
        case "editBatches":
             if ( levelName !== "" && points !== "") {
                await dispatch(addBatch(authToken,levelName, imgObject, points,batchValues.id));
              } else {
                Toast("All fields are mandatory", "E");
              }
          break;
        default:
          console.log('End');
          break;
      }
      setRenderForm(true);
    }
    // useEffect(() => {
    //   setNavigationCheck(is_navigate)
    //   console.log("update",navigationCheck)
    // }, [is_navigate])
    
    // useEffect(() => {
    //   // setNavigationCheck(is_navigate)
    //   console.log("user",special_user);
    //   if (props.subPageName === "addContent") {
    //     setplaceHolder({ name: "Content Name", type: "Select Content Type" });
    //     setDropdownData({data:content_drop, display:"content_type"});
    //   } else if (props.subPageName === "editContent") {
    //       setplaceHolder({ name: "Content Name", type: "Select Content Type" });
    //       setCategoryName(name_attr);
    //       setContentDesc(desc_attr);
    //       setIsInternal(type_attr === 0 ? false : true);
    //       setDropdownData({data:content_drop, display:"content_type"});
    //   } else {
    //       setplaceHolder({ name: "Name", type: "Select Type" });
    //       setDropdownData({data:content_drop, display:"content_type"});
    //   }
    //   dispatch(fetchAssetLibraryCategoryList(authToken));
    //   setRenderForm(true);
    //   console.log("special_user",special_user);
    //   console.log("props.subPageName",props.subPageName);
    //   console.log("selected_value",selected_value);
    //   console.log("selectValues", selectValues);
    //   console.log("type_attr", type_attr);
    // }, [])
  
    useEffect(() => {    // onDestroy
        return () => {
          dispatch({ type: types.BATCH_VALUES, payload: {} });
        }
    }, [])

    return (
      <div className="single-screen">
        <span onClick={()=>{window.history.back()}}>
          <img className="rotate-left" src={vector} alt="icon" />
          Back
        </span>
        <h2>{ props.innerTitle }</h2>
        <Form>
            <Form.Control
                className="add-name my-3"
                value={levelName}
                type="text"
                placeholder="Level Name"
                onChange={(e)=>{setLevelName(e.target.value)}}
            />
            <Form.Control
                className="add-name my-3"
                value={points}
                type="number"
                placeholder="Points"
                onChange={(e)=>{setPoints(e.target.value)}}
            />
           <div className="button-image py-2 mb-3">
              <span>Icon</span>
                <div>
                  { props.subPageName !== "addBatches" && batchValues?.icon !== "" && Object.keys(uploadedData).length === 0 && <img className="main-img" src={batchValues?.icon} alt="preview"/>}
                  <FileInputComponent
                    labelText=""
                    multiple={false}
                    callbackFunction={(file_arr) => {
                      setUploadedData(file_arr);
                      console.log(file_arr);
                    }}
                    accept="image/*"
                  />
                </div>
                <span className="mt-5 text-secondary fw-light">Upload image having dimension 50 X 50</span>
            </div>
            <Button variant="primary" className="save-button" size="lg" onClick={()=>{saveData(uploadedData)}}>
                Save Changes
            </Button>
        </Form>
      </div>
    );
};

export default SingleScreen;
