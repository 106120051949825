/* eslint-disable */
import { useRef, useState, useEffect, useContext } from "react";
import CryptoJS from "crypto-js";
import AuthContext from "../../../context/AuthProvider";
import axios from "axios";
import Page from "../../Page";
import { Container, Row, Col, Form, Button, Alert } from "react-bootstrap";
import * as Icon from "react-bootstrap-icons";
import logo from "../../../assets/logoTata.png";
import "./Login.css";
import { useSelector } from "react-redux";

let md5 = require("md5");

const Login = (props) => {
  const [buttonLoader, setButtonLoader] = useState(false);
  const authCtx = useContext(AuthContext);
  const userRef = useRef();
  const LOGIN_URL = authCtx.loginUrl;
  const company_logo = useSelector((state) => state.headerTopMenus.companyLogo);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [errMsg, setErrMsg] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [show, setShow] = useState(false);
  const [authToken, setAuthToken] = useState("");
  const [success, setSuccess] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setButtonLoader(true);
    setErrMsg("");
    let finalEncrytedPass = passwordEncryptionCipher(password);
    try {
      const response = await axios.post(LOGIN_URL, {
        user_id: username,
        password: finalEncrytedPass,
      });

      if (response?.data?.statusCode === "401") {
        setShow(true);
        setErrMsg(response?.data?.message);
      } else if (response?.data?.statusCode === "1012") {
        setShow(true);
        setErrMsg(
          "Login Failed..! You have reached maximum attempt limit. Please try after 3hrs."
        );
      } else if (response?.data?.statusCode === "1008") {
        setShow(true);
        setErrMsg("Unauthorized");
      } else {
        setShow(false);
        setErrMsg(" ");
        authCtx.onLogin(response?.data);
      }
      setButtonLoader(false);
      setUsername("");
      setPassword("");
    } catch (err) {
      if (!err?.response) {
        setShow(true);
        setErrMsg("No Server Response");
      } else if (err.response?.status === 400) {
        setShow(true);
        setErrMsg("Invalid User ID or Password");
      } else if (err.response?.status === 401) {
        setShow(true);
        setErrMsg("Unauthorized");
      } else {
        setShow(true);
        setErrMsg("Login Failed");
      }
      setButtonLoader(false);
    }
  };
  
  const passwordEncryptionCipher = (inputPassword) => {
    const string = inputPassword;
    const secretKey = CryptoJS.enc.Utf8.parse("Xp2s5v8y/B?E(H+KbPeShVmYq3t6w9z$");
    const initializationVector = CryptoJS.enc.Utf8.parse("RandomInitVector");

    // Encryption
    const cipherNode = CryptoJS.AES.encrypt(string, secretKey, {
      iv: initializationVector,
      mode: CryptoJS.mode.CBC
    });
    const encryptedString = CryptoJS.enc.Base64.stringify(cipherNode.ciphertext);
    return encryptedString;
  };

  useEffect(() => {
    setAuthToken(authCtx.auth);
  }, [setAuthToken, authCtx.auth, authToken]);

  return (
    <>
      <Page title={props.title}>
        <Row>
          <Col className="col-lg-4 col-md-6 col-sm-8 col-11 col" style={{ margin: "auto" }}>
            <Row style={{ textAlign: "center" }}>
              <Col>
                <br />
                <br />
                <img
                  src={company_logo?.logo ? company_logo?.logo : logo}
                  alt="Company Logo"
                  className="login-company-logo"
                />
                <br />
                <br />
              </Col>
            </Row>
            <Row style={{ textAlign: "center" }}>
              <Col>
                <br />
                <h3 className="title">Login</h3>
                <br />
              </Col>
            </Row>
            <Container className="login">
              <Row>
                <Col>
                  {show && (
                    <Alert
                      variant={success ? "success" : "danger"}
                      onClose={() => {
                        setShow(false);
                        setSuccess(false);
                      }}
                      dismissible
                    >
                      <Alert.Heading>
                        <Icon.ExclamationTriangle />
                      </Alert.Heading>
                      <p>{errMsg}</p>
                    </Alert>
                  )}

                  <Form onSubmit={handleSubmit}>
                    <Form.Group className="mb-3" controlId="username">
                      <Form.Control
                        type="text"
                        ref={userRef}
                        autoComplete="off"
                        onChange={(e) => setUsername(e.target.value)}
                        value={username}
                        required
                        placeholder="User ID"
                      />
                    </Form.Group>

                    <Form.Group className="mb-3 position-relative" controlId="password">
                      <Form.Control
                        type={showPassword != true ? "password":"text"}
                        onChange={(e) => setPassword(e.target.value)}
                        value={password}
                        required
                        placeholder="Password"
                      />
                      {showPassword != true ? <Icon.EyeSlash onClick={()=>{setShowPassword(true)}} className="cursor-pointer eye-password"/>:<Icon.EyeFill  onClick={()=>{setShowPassword(false)}} className="cursor-pointer eye-password-active"/>}    
                    </Form.Group>
                    <span className="mb-4 text-left nav-link talic-forgot" onClick={()=>{window.location.href='https://lp.tataaia.com/ResetP/'}}>Forgot Password</span>
                    {/* <Button
                      variant="info"
                      type="submit"
                      style={{ width: "100%" }} 
                    >
                      Submit
                    </Button> */}
                    <Button className="position-relative" variant="info" type="submit" style={{ width: "100%" }}>
                          {buttonLoader ? <div className="login-dual-ring"></div> : 'Submit'}
                      </Button>
                  </Form>
                </Col>
              </Row>
            </Container>
          </Col>
        </Row>
      </Page>
    </>
  );
};

export default Login;
