import React from 'react'
import * as Icon from "react-bootstrap-icons";
import { useDispatch, useSelector } from "react-redux";
// import DragTable from './DragTable';
import SelectTable from "../campaign/SelectTable"
import { useEffect } from 'react';
import { searchInput } from '../../../redux/actions/setupActions';
import { useState } from 'react';
import { Button, Col, Modal, Row, Table } from 'react-bootstrap';
import { deleteContactGroupData, fetchListDataListing } from '../../../redux/actions/campaignAction';
import Pagination from "./CampaignPagination";
import Loading from '../../Loading';

const List = (props) => {
    const dispatch = useDispatch();
    const authToken = props.authToken;
    const total_data = useSelector((state) => state.campaign?.list_data?.total_count);
    const list_data = useSelector((state) => state.campaign?.list_data?.data);
    const [selectedCrawlUrls, setSelectedCrawlUrls] = useState([]);
    const [showDelete, setShowDelete] = useState(false);
    const isLoading = useSelector((state) => state.campaign.loading);
  
    let pageNum = useSelector((state) => state.campaign.currPage);
  
    const handleCloseDelete = () => setShowDelete(false);
  
    const handleShowPopup = () => {
        setShowDelete(true);
    }

    const unselectData = () => {
      setSelectedCrawlUrls([]);
    }
   
    const handleUrlCheckboxChange = (e, obj) => {
      if (selectedCrawlUrls.includes(obj)) {
        setSelectedCrawlUrls(selectedCrawlUrls.filter(item => item !== obj));
      } else {
        setSelectedCrawlUrls([...selectedCrawlUrls, obj]);
      }
    };
  
    const deleteHandleShow = () => {
      let all_ids = selectedCrawlUrls?.map((item) => {
        return item.list_id
      })  
      dispatch(deleteContactGroupData(authToken, all_ids));
      handleCloseDelete();
      unselectData();
    }
  
    const selectAllHashtag = () => {
      setSelectedCrawlUrls(list_data);  
    }
  
    useEffect(() => {
      dispatch(fetchListDataListing(authToken, props.searchField, pageNum, props?.sortValue?.title || "", props?.sortValue?.value || ""));
    }, []);
   
    return (
      <div className='list-campaign'>
        {isLoading && <Loading />}
        <div className={"hashtag-list-title-card  list-custom mb-0"}>
          {selectedCrawlUrls?.length > 0 && (
            <Row>
              <Col>
                <div className="selectedAssetActions d-flex justify-content-between align-items-center">
                  <ul>
                    <li>
                      <Button variant="light selected">
                        {selectedCrawlUrls.length} Selected
                      </Button>
                    </li>
                      <li>
                        <Button
                          variant="light"
                          onClick={handleShowPopup}
                        >
                          <span>
                            <Icon.Trash />
                          </span>
                          Delete
                        </Button>
                    </li>
                    {/* <li>
                      <Button variant="light">
                        <span>
                          <Icon.Folder />
                        </span>
                        Add to Group
                      </Button>
                    </li> */}
                  </ul>
                    <ul className="">
                      <li>
                      <Button
                        variant="light selected"
                        onClick={() => {
                          selectAllHashtag()
                        }}
                      >
                        Select All
                      </Button>
                      </li>
                      <li>
                        <Button
                          variant="light selected"
                          onClick={unselectData}
                        >
                          Unselect
                        </Button>
                      </li>
                    </ul>
                </div>
              </Col>
            </Row>
          )}
          <Table>
          <tbody>
              <tr>
                <td style={{ width: "5%", textAlign: "center" }}></td>
              {props.tableData === "gSearch-right" && <td className="hashtag-name" style={{ width: "5%", textAlign: "center" }}>
                  {props.index}.
              </td>}
              <td className="hashtag-name-head">
                  <span>Name</span>
                </td>
              <td style={{ width: "12%" }}>
                  <span className="bordering-custom date-style">
                    Contacts
                  </span>
              </td> 
              {/* <td style={{ width: "12%" }}>
                  <span className="bordering-custom date-style">
                  Valid
                  </span>
              </td> */}
              <td>
                <div className="bordering-custom date-style">
                    Date Created
                </div>
              </td>
              <td style={{ justifyContent: "end" }} className="d-flex">
                  <div className="card-footer-action list-card">
                      Quick Actions
                  </div>
              </td>
            </tr>
          </tbody>
          </Table>
      </div>
        {list_data?.length > 0 && list_data.map((listItem,index) => {
          return (
            <Col key={index}>
              <SelectTable tableData="group_list" page={pageNum} authToken={props.authToken} handleUrlCheckboxChange={ handleUrlCheckboxChange } selectedCrawlUrls={selectedCrawlUrls} listItem={listItem} />
            </Col>
          )
        })}
        {list_data?.length === 0 && (
          <div className="no-data asset">
            <span>
              <Icon.Inbox />
            </span>
            <br />
            No Data
          </div>
        )}
        {total_data > 20 && <Pagination className="mt-3" authToken={props.authToken} total_page={ total_data } page={pageNum} currPage={props.subPageName} />}

        {/* Delete popup  */}
            <Modal className="delete-popup"  show={showDelete} onHide={handleCloseDelete}>
                <Modal.Header closeButton>
                    <Modal.Title>Delete</Modal.Title>
                </Modal.Header>
                <Modal.Body>Are you sure you want to delete ?</Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={deleteHandleShow}>
                      Delete
                    </Button>
                    <Button variant="secondary" onClick={handleCloseDelete}>
                      Cancel
                    </Button>
                </Modal.Footer>
            </Modal>
      </div>
    )
}

export default List;