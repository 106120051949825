import { useState, useEffect } from "react";
import Pagination from 'react-responsive-pagination';

//Redux-imports
import { useDispatch, useSelector } from "react-redux";
import {
  fetchHashtagListing,
  fetchCategoryListing,
  fetchContentListing,
  fetchGlobalSearchListing,
  fetchProductTypeListing,
  fetchParentListing,
  fetchPartnerCategoryListing
} from "../../redux/actions/setupActions";

import { fetchCtaListing } from "../../redux/actions/conversionAction"

const PaginationNew = (props) => {
  const dispatch = useDispatch();
  const [currentPage, setCurrentPage] = useState(0);
  const totalData = Math.ceil(props.total_page/20);

  const search_input = useSelector((state) => state.setupScreen.search_input);
  const sort_value = useSelector((state) => state.setupScreen.sort_value);

  const resetPage = useSelector((state) => state.assetLibrary.resetPage);

  function handlePageChange(page) {
      setCurrentPage(page);
      let currOffset = (page - 1) * 20;
      switch(props.currPage) {
      case 'category':
        dispatch(fetchCategoryListing(props.authToken, currOffset, search_input));
        break;
      case 'contentList':
        dispatch(fetchContentListing(props.authToken, currOffset, search_input, sort_value));
        break;
      case 'hashtag':
        props.unselectData();
        dispatch(fetchHashtagListing(props.authToken, currOffset, search_input, sort_value));
        break;
      case 'globalSearch':
        props.unselectData();
        dispatch(fetchGlobalSearchListing(props.authToken, "successfull", currOffset, search_input));
        break;
      case 'globalSearchUn':
        props.unselectData();
        dispatch(fetchGlobalSearchListing(props.authToken, "unsuccessfull", currOffset, search_input));  
        break;
      case 'productType':
        dispatch(fetchProductTypeListing(props.authToken, currOffset, search_input, sort_value));
        break;
      case 'partner':
        dispatch(fetchParentListing(props.authToken, currOffset, search_input, sort_value));
        break;
      case 'partnerCategory':
        dispatch(fetchPartnerCategoryListing(props.authToken, currOffset, search_input, sort_value));
          break;
      case 'cta':
        dispatch(fetchCtaListing(props.authToken, currOffset, search_input, sort_value));
        break;
      default:
        break;
    }
  }

    useEffect(() => {
      setCurrentPage(Math.ceil(props.page/20)+1);
  }, [props.page]);
    
  useEffect(() => {
    console.log("PageReset", resetPage);
  }, [resetPage]);

  return (
    <div className="w-50 full-width">
        <Pagination
            className="pagination justify-content-start"  
            total={totalData}
            current={currentPage}
            onPageChange={(page) => handlePageChange(page)}
        />
    </div>
  );
};

export default PaginationNew;
