/* eslint-disable */
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Activity from './Activity';
import Notes from './Notes';
import Calls from './Calls';
import Emails from './Emails';
import Tasks from './Tasks';
import Meetings from './Meetings';
import './Leadtabs.css';
import { Col, Row, Stack } from 'react-bootstrap';
import React, { useState, useEffect } from "react";
import AddTask from '../AddTask';
import { useNavigate, useParams } from 'react-router';
import { appUserActivity , fetchTaskList} from '../../../../../../redux/actions/crmActions';
import { useDispatch } from "react-redux";

const LeadTabs = (props) => {
    //console.log("leadTabs", props.contact);
    const { contact, from, authToken } = props;
    const [key, setKey] = useState('activity');

    const { id } = useParams();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const getChars = (str) => {
        const firstLetters = str.split(' ').slice(0,2).map(word => word[0]).join('');
        return firstLetters;
    }

    const handleRoute = () => {
        navigate(`/crm/leads/${contact.contact_ids[0].contact_id}/`);
    }


    useEffect(() => {
        if (contact && contact?.contact_ids[0]?.contact_id !== undefined && contact?.contact_ids[0]?.contact_id !== null && contact?.contact_ids[0]?.contact_id !== "") {
            console.log("contact leadtab", contact?.contact_ids[0]?.contact_id);
            dispatch(appUserActivity(authToken, contact?.contact_ids[0]?.contact_id));
            dispatch(fetchTaskList(authToken,  contact?.contact_ids[0]?.contact_id));
        }
    }, []);

    return (
        <>
            {/* {from === 'bulk' && contact && (
                <Stack direction="horizontal" gap={3} className='lead-tab-header'>
                    {contact.contact_ids.map((item, index) => {
                        return (
                            <Stack direction="horizontal" gap={3} key={index}>
                                <div className='circle'>{getChars(item.user_name)}</div>
                                <div className="mb-1">
                                    <div className='fs-4 fw-bold'>{item.user_name}</div>
                                    <div className='fs-6 fw-normal'>{item.email_id}</div>
                                </div>
                            </Stack>
                        )
                    }
                    )}
                    <div className='text-color mb-4 pe-auto' onClick={handleRoute}>Expand</div>
                </Stack>
            )} */}

            {/* <div className="main-container"> */}
            <Tabs
                activeKey={key}
                transition={false}
                id="controlled-tab-example"
                className="mb-3 justify-content-around m-3"
                onSelect={key => setKey(key)}
            >
                <hr />
                <Tab eventKey="activity" title="Activity">
                    <Activity contactId={contact} authToken={authToken} />
                </Tab>
                <Tab eventKey="notes" title="Notes">
                    <Notes contactId={contact} authToken={authToken} />
                </Tab>
                <Tab eventKey="emails" title="Emails">
                    <Emails contactId={contact} authToken={authToken} />
                </Tab>
                {/*  <Tab eventKey="calls" title="Calls">
                    <Calls contactId={contact} />
                </Tab> */}
                <Tab eventKey="tasks" title="Tasks">
                    {/*  <Tasks contactId={contact} /> */}
                    <Tasks contactId={contact} authToken={authToken} />
                </Tab>
                {/*  <Tab eventKey="meetings" title="Meetings">
                    <Meetings contactId={contact} />
                </Tab> */}
            </Tabs>
            {/* </div> */}
        </>
    )

};


export default LeadTabs;