// New reducers
import {
  FETCH_DIRECT_LIST,
  FETCH_INDIRECT_LIST,
  FETCH_LIST_ANALYTICS,
  FETCH_LIST_DATA,
  FETCH_PICKUP_LISTING,
  CURRENT_TEMPLATE_DATA,
  FETCH_FROM_EMAIL,
  FETCH_REPLY_EMAIL,
  INDIVIDUAL_TEMPLATE_DATA,
  FETCH_CONTACTS_LIST_CAMPAIGN,
  FETCH_CONTENT_LIST_CAMPAIGN,
  RUN_CAMPAIGN_DATA,
  CAMPAIGN_SEARCH_INPUT,
  CAMPAIGN_CURRENT_PAGE,
  FETCH_INDIVIDUAL_LIST_DATA,
  SET_SHOW_ENTERY,
  NEW_LIST_ID,
  FETCH_VIEW_LIST_DATA,
  SELECTED_LIST_ID,
  CAMPAIGN_TOGGLE_LOADING,
  FILTERED_CONTACTS_LIST
} from '../../constants/ActionTypes'

const initialState = {
  loading: false,
  direct_list: null,
  indirect_list: null,
  analytics_data: null,
  list_data: null,
  pick_up_listing: null,
  current_template: null,
  individual_template: null,
  individual_contact_list: null,
  from_email: null,
  reply_email: null,
  contact_list: null,
  filtered_contact_list: null,
  content_list: null,
  run_campaign_data: null,
  currPage: 1,
  show_enteries: 20,
  search_input: "",
  list_id: null,
  view_list_data: null,
  select_list_ids:[],
}

const campaignReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case CAMPAIGN_TOGGLE_LOADING:
      return { ...state, loading: payload };
    case FETCH_DIRECT_LIST:
      return { ...state, direct_list: payload }
    case FETCH_PICKUP_LISTING:
      return { ...state, pick_up_listing: payload }
    case FETCH_INDIRECT_LIST:
      return { ...state, indirect_list: payload }
    case FETCH_LIST_ANALYTICS:
      return { ...state, analytics_data: payload }
    case FETCH_LIST_DATA:
      return { ...state, list_data: payload }
    case FETCH_VIEW_LIST_DATA:
      return { ...state, view_list_data: payload }
    case FETCH_FROM_EMAIL:
      return { ...state, from_email: payload }
    case FETCH_REPLY_EMAIL:
      return { ...state, reply_email: payload }
    case CURRENT_TEMPLATE_DATA:
      return { ...state, current_template: payload }
    case INDIVIDUAL_TEMPLATE_DATA:
      return { ...state, individual_template: payload }
    case FETCH_CONTACTS_LIST_CAMPAIGN:
      return { ...state, contact_list: payload }
    case FILTERED_CONTACTS_LIST:
      return { ...state, filtered_contact_list: payload }
    case FETCH_CONTENT_LIST_CAMPAIGN:
      return { ...state, content_list: payload }
    case RUN_CAMPAIGN_DATA:
      return { ...state, run_campaign_data: payload }
    case CAMPAIGN_SEARCH_INPUT:
      return { ...state, search_input: payload };
    case CAMPAIGN_CURRENT_PAGE:
      return { ...state, currPage: payload };
    case FETCH_INDIVIDUAL_LIST_DATA:
      return { ...state, individual_contact_list: payload };
    case SET_SHOW_ENTERY:
      return { ...state, show_enteries: payload };
    case NEW_LIST_ID:
      return { ...state, list_id: payload };
    case SELECTED_LIST_ID:
      return { ...state, select_list_ids: payload };
    default:
      return state
  }
}
export default campaignReducer