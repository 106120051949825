/* eslint-disable */
import {
  Container,
  Row,
  Col,
  Form,
  Button,
  ButtonGroup,
  Dropdown, Stack,
  Modal,
  Card,
  Breadcrumb,
  FormGroup
} from "react-bootstrap";
import * as Icon from "react-bootstrap-icons";
import _, { lte } from "lodash";
import DemoTemp from "../../../assets/asset-upload/demo_temp.png";
import React, { useState, useEffect, useCallback, useRef } from "react";
import * as types from "../../../constants/ActionTypes";
import Select from "react-select";
import ReactMultiSelectCheckboxes from "react-multiselect-checkboxes";
// import DtPicker from "react-calendar-datetime-picker";
// import "react-calendar-datetime-picker/dist/index.css";

// import DatePicker from 'react-datepicker';
// import moment from 'moment';

import 'react-datepicker/dist/react-datepicker.css';


// import Offcanvas from 'react-bootstrap/Offcanvas';
// import AddNote from '../crm-list/leads/AddNote';
// import AddMail from '../crm-list/leads/AddMail';
// import LeadTabs from '../crm-list/leads/tab-components/LeadTabs'
// import "./CRMList.css";
// import DeleteModal from "../../../shared/DeleteModal";

//Redux-imports
import { useDispatch, useSelector } from "react-redux";
// import {
//   fetchFilteredLeadDetails,
//   fetchCityDropdown,
//   fetchProductDropdown,
//   fetchSourceDropdown,
//   fetchAllLeadDetails,
//   fetchNewLeadDetails,
//   fetchEngagedLeadDetails,
//   fetchDealLeadDetails,
//   fetchQuoteLeadDetails,
//   fetchWonLeadDetails,
//   fetchDroppedLeadDetails,
//   deleteLeads,
//   exportLeadDetails,
//   fetchContactsList,
//   fetchProspectsList,
//   fetchContactDetails,
//   moveContactsProspectsLeads,
//   fetchAllLeadTypeDetails
// } from "../../../../redux/actions/crmActions";
// import AddTask from "./leads/AddTask";
import { useNavigate } from "react-router";
import { DebounceInput } from "react-debounce-input";
import { addContactList, addSelectedContactIdsList, fetchPickTemplateListing } from "../../../redux/actions/campaignAction";
import { Link } from "react-router-dom";
// import * as types from "../../../../constants/ActionTypes";


const CampaignListFilters = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { pagename, searchField, setSearchField, deleteMultipleLeads } = props;

  const authToken = props.authToken;
  let list_id = useSelector((state) => state.campaign.list_id);
  let select_list_ids = useSelector((state) => state.campaign.select_list_ids);
  const searchRef = useRef();

  const [listModal, setListModal] = useState(false);
  const [listName, setListName] = useState("");

  const userType = localStorage.getItem("userType")
    ? localStorage.getItem("userType").slice(1, -1)
    : "";
  
  useEffect(() => {
    dispatch(fetchPickTemplateListing(authToken, "", "all", ""))
  }, [])

  const searchSubmitHandler = (e, searchKey, pageType) => {
    searchRef.current.value = searchKey;
    props.searchHandler(e, searchKey, pageType);
  }


  const viewComponent = (e) => {
    // setContacts([]);
    if (e !== "" && e.target.value === 'direct') {
      navigate('/campaigns');
    } else if (e !== "" && e.target.value === 'indirect') {
      navigate('/campaigns/indirect');
    } else if (e !== "" && e.target.value === 'list') {
      navigate('/campaigns/list');
    }
    setSearchField("");
    dispatch({ type: types.CAMPAIGN_SEARCH_INPUT, payload: "" });
    dispatch({ type: types.CAMPAIGN_CURRENT_PAGE, payload: 1 });
  }
  
  const breadName = (path) => {
    let num = path.lastIndexOf("campaigns");
    let page = path.slice(num + 10);
    return page.replace("_", " ");
  }

  const addList = () => {
    console.log("add");
  }
  
  const onSuccess = () => {
    navigate("/campaigns/contactlist");
    setListModal(false);
  }
  
  const handleAddToList = () => {
    dispatch(addSelectedContactIdsList(authToken, list_id, select_list_ids, onSuccessAddingContact))
  }
  
  const onSuccessAddingContact = () => {
    navigate("/campaigns/list")
  }
  
  // useEffect(() => {
  //   if (contactsCP?.length == 1) {
  //     if (contactsCP[0].type == "Lead") {
  //       setIsLead(true);
  //     } else if (contactsCP[0].type == "Prospect") {
  //       setIsProspect(true);
  //     } else if (contactsCP[0].type == "Contact") {
  //       setIsContact(true);
  //     }
  //   } else {
  //     setIsLead(false);
  //     setIsProspect(false);
  //     setIsContact(false);
  //   }
  // }, [contactsCP]);


  return (
    <>
      <div className="background-color-add">
        <div className="body-container container campaign-head">
          {props.displayType == 1 ? <>
            <Col className="col-8 crm-col">
              {/* <Form> */}
              <Row>
                { pagename !== "contact_list" && <Col className="col-2">
                  <Form.Select aria-label="select-type" onChange={viewComponent}
                    value={pagename}
                  >
                    <option value="direct">Direct</option>
                    {(userType == "P" || userType == "S") && <option value="indirect">Indirect</option>}
                    <option value="list">List</option>
                    {/* <option value="accounts">Accounts</option> */}
                  </Form.Select>
                </Col>}
                {/* <Col className="col-2">
                    <Form.Select aria-label="select-type">
                      <option>Pipeline Name</option>
                    </Form.Select>
                  </Col> */}
                  <Col className="col-5 position-relative">
                    <DebounceInput
                      value={searchField}
                      className="search-field form-control"
                      placeholder={`Search ${pagename !== "contact_list" ? pagename:"contacts"}`}
                      minLength={3}
                      debounceTimeout={1500}
                      onChange={(e) => {
                        setSearchField(e.target.value);
                        dispatch({ type: types.CAMPAIGN_SEARCH_INPUT, payload: e.target.value });
                      }}
                    />
                    { true ? (
                        <div className="search-icon">
                          <Icon.Search
                            onClick={(e) => {
                              searchSubmitHandler(e, searchRef.current.value, pagename);
                            }}
                          />
                        </div>
                      ) : (
                        <div className="search-icon">
                          <Icon.X
                            onClick={(e) => {
                              searchSubmitHandler(e, null, pagename);
                            }}
                          />
                        </div>
                      )}
                </Col>
              </Row>
              {/* </Form> */}
            </Col>
            <Col>
              {/* {(pagename === "leads" && (userType === "P" || userType === "S")) && ( */}
                <div className="head-btn">
                  {pagename === "direct" && <Button
                    variant="info"
                    className="add-new-asset-btn add-new-asset-btn-camapign"
                    onClick={() => {
                      navigate("/campaigns/run_template");
                    }}
                  >
                    Run Campaign
                  </Button>}
                  {pagename === "indirect" && <Button
                    variant="info"
                    className="add-new-asset-btn add-new-asset-btn-camapign"
                    onClick={() => {
                      navigate("/campaigns/push_template");
                    }}
                  >
                    Push Campaign
                  </Button>}
                {pagename !== "list" && pagename !== "contact_list" ?
                    <>
                    {userType != "C" && <Button
                      variant="info"
                      className="add-new-asset-btn me-2"
                      onClick={() => {
                        navigate("/new-asset");
                      }}
                    >
                      <span className="crm-header-btn-icon">
                        <Icon.PlusLg />
                      </span>
                      Create Template
                    </Button>}
                    </>
                    :
                    (pagename !== "contact_list" ? <Button
                      variant="info"
                      className="add-new-asset-btn me-2"
                      onClick={()=>setListModal(true)}
                    >
                      <span className="crm-header-btn-icon">
                        <Icon.PlusLg />
                      </span>
                      New List
                    </Button>
                    : 
                    <Button
                      variant="info"
                      className="add-new-asset-btn me-2"
                      onClick={()=>handleAddToList()}
                    >
                      <span className="crm-header-btn-icon">
                        <Icon.PlusLg />
                      </span>
                      Add to List
                    </Button>
                    )
                  }
                </div>
              {/* )} */}
            </Col>
          </> 
          :
          <Row>
            <Col className="header-breadcrumb">
              <Breadcrumb>
                <Breadcrumb.Item
                  linkAs={Link}
                  linkProps={{ to: (window.location.pathname.includes("run_template") || window.location.pathname.includes("run_campaign")) ? "/campaigns":"/campaigns/indirect" }}
                  // eventKey={"Asset Details"}
                >
                  <div>{window.location.pathname.includes("run_template") || window.location.pathname.includes("run_campaign")  ? "Direct Campaign":"Indirect Campaign"}</div>
                </Breadcrumb.Item>
                <span>
                  <Icon.ChevronRight />
                </span>
                { false ? <div className="shine" style={{ width:'160px', height: '40px'}}></div> : <Breadcrumb.Item className="capitalize-text" active>
                  {(window.location.pathname.includes("run_template") || window.location.pathname.includes("push_template")) ? "Pick Template":breadName(window.location.pathname)}
                </Breadcrumb.Item>}
              </Breadcrumb>
            </Col>
          </Row>}
        </div>
      </div>
      <Modal show={listModal} onHide={() => { setListModal(false);}}>
        <Modal.Header closeButton>
          {" "}
          {/* style={{ border: "none" }} */}
          <div className="fw-bold h5">Create New List</div>
        </Modal.Header>
        <Modal.Body>
          <FormGroup>
            <Form.Control
              type="text"
              autoComplete="off"
              name="tag-input"
              required
              placeholder="List Name"
              value={listName}
              onChange={(e) => setListName(e.target.value)}
            />
          </FormGroup>
        </Modal.Body>
        <Modal.Footer>
          {" "}
          {/* style={{ border: "none" }} */}
          <Button
            variant="info"
            onClick={() => {dispatch(addContactList(authToken,listName, onSuccess))}}
          >
            Add
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default CampaignListFilters;
