/* eslint-disable */
import { useEffect } from "react";
import Loading from "../../../Loading";
import { Row, Col, Card } from "react-bootstrap";
import * as Icon from "react-bootstrap-icons";
import { useNavigate } from "react-router-dom";
import * as types from '../../../../constants/ActionTypes';
import "./NewAssetContainer.css"
//Redux-imports
import { useDispatch, useSelector } from "react-redux";
import {
  fetchAssetSelectTypeList,
  setBreadcrumbStatus,
  setSelectedAssetType,
  saveGlobalFieldsData,
  setPublishType,
  setEditAsset,
} from "../../../../redux/actions/actions";

const SelectAssetType = (props) => {
  const authToken = props.authToken;

  const isLoading = useSelector((state) => state.assetUpload.loading);
  const assetSelectTypeList = useSelector(
    (state) => state.assetUpload.selectTypeList
  );

  const uploadedData = useSelector(
    (state) => state.assetUpload.saveGlobalFieldsData
  );

  const dispatch = useDispatch();

  let navigate = useNavigate();

  useEffect(() => {
    dispatch(fetchAssetSelectTypeList(authToken));
    dispatch(setBreadcrumbStatus(0));
    dispatch(setSelectedAssetType({}));
    //dispatch(saveGlobalFieldsData(authToken,[]));
    dispatch({
      type: types.SAVE_GLOBAL_FIELDS_DATA,
      payload: [],
    });
    dispatch(setPublishType(false));
    dispatch(setEditAsset(false));
  }, []);

  props.pageTypeHandler("Select Asset Type");

  //console.log("uploadedData",uploadedData);

  return (
    <>
      {isLoading && <Loading />}
      <Row className="custom-row">
        <Col className="col-lg-2 col-md-12 col-12">
          <h1 className="selectassest-title">
            Select <br />
            Asset Type
          </h1>
        </Col>
        <Col className="col-lg-10 col-md-12 col-12">
          <Row className="select-type-card custom-row">
            {assetSelectTypeList &&
              assetSelectTypeList.map((asset) => {
                return (
                  <Col className="col-lg-3 col-md-4 col-12 mb-5 col">
                    <Card
                      onClick={() => {
                        dispatch(setBreadcrumbStatus(1));
                        dispatch(setSelectedAssetType(asset));
                        setTimeout(() => {
                          navigate(`/new-asset/upload-asset`);
                        }, 1000);
                      }}
                    >
                      <div className="img-wrapper">
                        <Card.Img variant="top" src={asset.article_image} />
                      </div>
                      <Card.Body>
                        <Card.Title>
                          {asset.article_type}
                          <span>
                            <Icon.ChevronRight />
                          </span>
                        </Card.Title>
                        <Card.Text>{asset.description}</Card.Text>
                      </Card.Body>
                    </Card>
                  </Col>
                );
              })}
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default SelectAssetType;
