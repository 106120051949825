import Page from '../Page';

const Campaign = (props) => {

  props.pageTypeHandler("Campaign");

  return (
    <Page title="Campaign">
      <h1>Campaign</h1>
    </Page>
  );
};

export default Campaign;
