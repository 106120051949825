/* eslint-disable */
import { Row, Col, Button, Modal } from "react-bootstrap";
import DataTable from "react-data-table-component";
import React from "react";
import * as Icon from "react-bootstrap-icons";
import { useNavigate } from "react-router";

import './Contacts.css';

import { useState, useEffect, useCallback } from "react";

//Redux-imports
import { useDispatch, useSelector } from "react-redux";
import { deleteContact, fetchContactDetails, fetchContactsList, fetchProspectsList } from "../../../../../redux/actions/crmActions";
import Loading from "../../../../Loading";


const Contacts = (props) => {

  //   const { pagename } = props;

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const contactsList = useSelector((state) => state.crmLeads.contactsList);
  const totalContactsList = useSelector((state) => state.crmLeads.totalContactsList);
  const isLoading = useSelector((state) => state.crmLeads.loading);
  const isPageReset = useSelector((state) => state.crmLeads.resetCPPaging);

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedID, setSelectedID] = useState(null);

  const [show, setShow] = useState(false);
  const handleClose = () => {
    setShow(false);
  };
  const handleShow = () => setShow(true);

  // const handleDelete = useCallback(
  //   row => async () => {
  //     await axios.delete(`https://reqres.in/api/users/${row.id}`);
  //     const response = await axios.get(
  //       `https://reqres.in/api/users?page=${currentPage}&per_page=${perPage}`
  //     );

  //     setData(removeItem(response.data.data, row));
  //     setTotalRows(totalRows - 1);
  //   },
  //   [currentPage, perPage, totalRows]
  // );

  // const handleDelete = (contact) => {
  //   console.log("Delete-Contact", contact);
  // }

  // const removeItem = (array, item) => {
  //   const newArray = array.slice();
  //   newArray.splice(newArray.findIndex(a => a === item), 1);

  //   return newArray;
  // };

  // const fetchUsers = async (page, size = perPage) => {
  //   // setLoading(true);

  //   // const response = await axios.get(
  //   //   `https://reqres.in/api/users?page=${page}&per_page=${size}&delay=1`
  //   // );

  //   // setData(response.data.data);
  //   // setTotalRows(response.data.total);
  //   // setLoading(false);
  // };

  const columns = [
    {
      name: "Name",
      // selector: (row) => row.name,
      sortable: true,
      cell: (row) => (
        <div className="contacts-name">
          <div className='circle-contacts'>{row.name !== "" ? getChars(row.name) : ""}</div>
          {row.name !== "" ? row.name : ""}
        </div>
      )
    },
    {
      name: "Type",
      selector: (row) => row.type,
      sortable: true
    },
    {
      name: "Email",
      selector: (row) => props.pagename === "contacts" ? row.emailid : row.email_id,
      sortable: true
    },
    {
      name: "Phone Number",
      selector: (row) => row.contact_no,
      sortable: true,
    },
    {
      name: "Company",
      selector: (row) => row.company,
      sortable: true,
    },
    {
      name: "Quick Actions",
      button: true,
      cell: (row) => (
        <>
          <div className="action-btn"
            onClick={() => {
              dispatch(fetchContactDetails(props.authToken, row.contact_id));
              setTimeout(() => {
                navigate(`/crm/${props.pagename}/add-contact`);
              }, 2000);
            }}
          >
            <Icon.Pencil />
          </div>
          <div className="action-btn"
            onClick={() => {
              //handleDelete(row)
              setSelectedID(row.contact_id);
              handleShow();
            }}
          >
            <Icon.Trash />
          </div>
        </>
      )
    }
  ];

  const handlePageChange = page => {
    let currOffset;
    if (page > 1) {
      currOffset = (page * 10) - 10;
    } else {
      currOffset = 0;
    }
    //console.log("page",(page));
    //console.log("currOffset",currOffset);
    if (props.pagename === "contacts") {
      dispatch(fetchContactsList(props.authToken, null, currOffset));
    } else if (props.pagename === "prospects") {
      dispatch(fetchProspectsList(props.authToken, null, currOffset));
    }
    //fetchUsers(page);
    setCurrentPage(page);
  };

  // const handlePerRowsChange = async (newPerPage, page) => {
  //   fetchUsers(page, newPerPage);
  //   setPerPage(newPerPage);
  // };

  const getChars = (str) => {
    const firstLetters = str.split(' ').slice(0,2).map(word => word[0]).join('');
    return firstLetters;
  }

  useEffect(() => {
    if (isPageReset == 1) {
      setCurrentPage(1);
    }
  }, [isPageReset])

  useEffect(() => {
    if (props.pagename === "contacts") {
      dispatch(fetchContactsList(props.authToken));
      setCurrentPage(1);
    } else if (props.pagename === "prospects") {
      dispatch(fetchProspectsList(props.authToken));
      setCurrentPage(1);
    }
    props.setContacts([]);
  }, [props.pagename])

  console.log("contactsList", contactsList);
  //console.log("totalContactsList",totalContactsList);

  return (
    <>
      {isLoading === true ? <Loading /> : (
        <div className="container contacts-view">
          <Row>
            <Col className="contacts-data-table">
              <DataTable
                //title="Contact List"
                columns={columns}
                data={contactsList}
                defaultSortFieldID={1}
                progressPending={loading}
                pagination
                paginationServer
                paginationTotalRows={totalContactsList}
                paginationDefaultPage={currentPage}
                //onChangeRowsPerPage={handlePerRowsChange}
                onChangePage={handlePageChange}
                selectableRows
                onSelectedRowsChange={({ selectedRows }) => props.setContacts(selectedRows)}  /* console.log(selectedRows) */
              />
            </Col>
          </Row>
          <Modal show={show} onHide={handleClose} >
            <Modal.Header closeButton></Modal.Header>
            <Modal.Body style={{ "textAlign": "center" }}>
              <div className="alert-icon">
                <Icon.ExclamationTriangle />
              </div>
              Are you sure that you want to delete this {props.pagename}?
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant="info"
                onClick={() => {
                  handleClose();
                  dispatch(deleteContact(props.authToken, selectedID));
                  setTimeout(() => {
                    if (props.pagename === "contacts") {
                      dispatch(fetchContactsList(props.authToken));
                      setCurrentPage(1);
                    } else if (props.pagename === "prospects") {
                      dispatch(fetchProspectsList(props.authToken));
                      setCurrentPage(1);
                    }
                  }, 2000);
                }}
              >
                Yes
              </Button>
              <Button
                variant="info"
                onClick={() => {
                  handleClose();
                }}
              >
                No
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
      )};
    </>
  )
};

export default Contacts;
