/* eslint-disable */
import { useState, useEffect } from "react";
import Loading from "../../../Loading";
import { Row, Col, Form, Card, Button } from "react-bootstrap";
import * as Icon from "react-bootstrap-icons";
import FileInputComponent from "react-file-input-previews-base64";
import { useNavigate } from "react-router-dom";
import _ from "lodash";
import MagicStick from "../../../../assets/asset-upload/Vector.png"
import Toast from "../../../shared/Toast";
import $ from "jquery";

//Redux-imports
import { useDispatch, useSelector } from "react-redux";
import {
  fetchAssetSelectTypeList,
  setBreadcrumbStatus,
  setBaseUploadData,
  autoEmailMainData,
  setAutoemailStatus,
  setAutoEmailAccessPath,
  setCurrentAssetDetailNull,
  setTitleStatus,
} from "../../../../redux/actions/actions";

const AddingData = (props) => {
  const authToken = props.authToken;

  let navigate = useNavigate();

  const [uploadedData, setUploadedData] = useState({});
  const [gifFlag, setGifFlag] = useState(false);
  const [emailOption, setEmailOption] = useState("");
  const [videoUrlsInput, setVideoUrlsInput] = useState([{ video_url: "" }]);
  const [videoSize, setVideoSize] = useState(false);

  const isLoading = useSelector((state) => state.assetUpload.loading);

  const selectedAssetType = useSelector(
    (state) => state.assetUpload.selectedAssetType
  );

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchAssetSelectTypeList(authToken));
    dispatch(autoEmailMainData(null));
    dispatch(setCurrentAssetDetailNull(null));
    if (_.isEmpty(selectedAssetType)) {
      navigate("/new-asset");
    }
  }, [authToken, dispatch]);

  useEffect(() => {
    if (selectedAssetType.type === "Video" && uploadedData.length > 0) {
      if (uploadedData.length > 1) {
        Toast("Please upload single file only.", "E");
        setUploadedData({});
      } else if (!(uploadedData[0].name).includes(".mp4")) {
        Toast("Please upload .mp4 format file only.", "E");
        setUploadedData({});
      } else if (uploadedData[0].size > 15360) {
        setUploadedData({});
        Toast("Maximum 15MB file size accepted.", "E");
      }
    }
    // setGifFlag(false);
    // if ((selectedAssetType.type === "Social Post" || selectedAssetType.type === "Image") && uploadedData?.length > 0) {
    //   uploadedData.map((item,index) => {
    //     return item.type === "image/gif" ? gifValidation(index) : "";
    //   })
    // }
    //  let video = $("#uploadedVideo"); //JQuery selector
    //   console.log("video[0]",video[0]);
    //   console.log("video[0].videoWidth", video[0].videoWidth);
    //   console.log("video[0].videoHeight", video[0].videoHeight); 
    //console.log("uploadedData",uploadedData);
  }, [uploadedData]);

  props.pageTypeHandler("Global Fields");
  
  // const gifValidation = (num) => {
  //   Toast(`GIF format is not supported`, "E");
  //   setGifFlag(true);
  // }

  const videoUrlInputsHandler = (i, e) => {
    let newVideoUrlsInput = [...videoUrlsInput];
    newVideoUrlsInput[i][e.target.name] = e.target.value;
    setVideoUrlsInput(newVideoUrlsInput);
    //setUploadedData(newVideoUrlsInput);
  };

  const addVideoUrlFields = () => {
    setVideoUrlsInput([...videoUrlsInput, { video_url: "" }]);
    //setUploadedData([...videoUrlsInput, { video_url: "" }]);
  };

  const removeVideoUrlFields = (i) => {
    let newVideoUrlsInput = [...videoUrlsInput];
    newVideoUrlsInput.splice(i, 1);
    setVideoUrlsInput(newVideoUrlsInput);
    //setUploadedData(newVideoUrlsInput);
  };

  //console.log("videoUrlsInput", videoUrlsInput);

  const handleFile = (val) => {
    console.log("file", val);
    var reader = new FileReader();
    //var fileType = val[0].file.type;
    //console.log("type", fileType);
    reader.addEventListener("load", function () {
      var dataUrl = reader.result;
      var videoId = "videoMain";
      const videoEl = document.createElement('video');
      videoEl.id = videoId;
      videoEl.src = dataUrl;
      var videoTagRef = videoEl;
      videoTagRef.addEventListener('loadedmetadata', function (e) {
        // console.log("file height", videoTagRef.videoHeight);
        // console.log("file width", videoTagRef.videoWidth);
        // console.log("file duration", videoTagRef.duration);

        val.height = videoTagRef.videoHeight;
        val.width = videoTagRef.videoWidth;

        setUploadedData([val]);
      });

    }, false);

    if (val.file) {
      reader.readAsDataURL(val.file);
    }
  }
  
  const baseFormatEmailHandler = (option) => {
    let uploadBaseFormat;
    uploadBaseFormat = null
    dispatch(setTitleStatus(""));
    dispatch(setAutoEmailAccessPath(0));
    dispatch(setAutoemailStatus(option));
    dispatch(setBreadcrumbStatus(2));
    dispatch(setBaseUploadData(uploadBaseFormat));
    setTimeout(() => {
      navigate(`/new-asset/upload-email-asset`);
    }, 1000);
  }

  const baseFormatHandler = () => {
    // if (gifFlag === true) {
    //   Toast("GIF format is not supported", "E");
    //   return;
    // }
    let uploadBaseFormat;
    if (selectedAssetType.type === "Social Post" || selectedAssetType.type === "Image") {
      uploadBaseFormat = {
        data: [
          uploadedData.map((data) => {
            return {
              Base: {
                thumbImg: {},
                post_image: {
                  base64: data.base64,
                  file: {
                    name: data.name.replace(/\s/g, '_'),
                    size: data.size,
                    type: data.type,
                  },
                },
              },
              //Variance: [],
            };
          }),
        ],
      };
    } else if (selectedAssetType.type === "Emailer") {
      uploadBaseFormat = null
      dispatch(setAutoEmailAccessPath(0));
      dispatch(setAutoemailStatus(emailOption));
    } else if (
      selectedAssetType.type === "Brochure" ||
      selectedAssetType.type === "Infographics" ||
      selectedAssetType.type === "Infographic"
    ) {
      uploadBaseFormat = {
        data: [
          uploadedData.map((data) => {
            return {
              Base: {
                thumbImg: {},
                document: {
                  base64: data.base64,
                  file: {
                    name: data.name.replace(/\s/g, '_'),
                    size: data.size,
                    type: data.type,
                  },
                },
              },
            };
          }),
        ],
      };
    } else if (selectedAssetType.type === "Video") {
      if (videoUrlsInput[0].video_url !== "") {
        uploadBaseFormat = {
          data: [
            videoUrlsInput.map((data) => {
              //console.log("data", data);
              return {
                Base: {
                  thumbImg: {},
                  video_url: data.video_url,
                },
              };
            }),
          ],
        };
      } else {
        uploadBaseFormat = {
          data: [
            uploadedData.map((data) => {
              return {
                Base: {
                  thumbImg: {},
                  video: {
                    base64: data.base64,
                    file: {
                      name: data.name.replace(/\s/g, '_'),
                      size: data.size,
                      type: data.type,
                      height: data.height,
                      width: data.width
                    },
                  },
                },
              };
            }),
          ],
        };
      }
    } else if (selectedAssetType.type === "Banner") {
      uploadBaseFormat = {
        data: [
          uploadedData.map((data) => {
            return {
              Base: {
                thumbImg: {},
                banner_image: {
                  base64: data.base64,
                  file: {
                    name: data.name.replace(/\s/g, '_'),
                    size: data.size,
                    type: data.type,
                  },
                },
              },
            };
          }),
        ],
      };
    }
    dispatch(setBreadcrumbStatus(2));
    dispatch(setBaseUploadData(uploadBaseFormat));
    setTimeout(() => {
      navigate(`/new-asset/${selectedAssetType.type !== "Emailer" ? "global-asset-details": emailOption != 2 ? "upload-email-asset":"pick-template"}`);
    }, 1000);
    //console.log("uploadBaseFormat", uploadBaseFormat);
  };

  console.log("uploadedData", uploadedData);

  return (
    <div className="new-asset">
      {isLoading && <Loading />}
      <Row className="asset-type ">
        <Col className="col-lg-3  col-md-3 col-12 mb-lg-0 mb-4">
          <img src={selectedAssetType.article_image} alt="img" />
          <h1 className="mt-5">
            Add{selectedAssetType.type === "Emailer" ? "ing":""} <br />
            {(selectedAssetType.type === "Email") && (
              <>
                {selectedAssetType.type}
              </>
            )}
            {(selectedAssetType.type === "Social Post" || selectedAssetType.type === "Image") && (
              <>
                {selectedAssetType.type}
              </>
            )}
            {selectedAssetType.type === "Brochure" && (
              <>
                {selectedAssetType.type} <br /> & Leaflets
              </>
            )}
            {(selectedAssetType.type === "Infographics" || selectedAssetType.type === "Infographic") && (
              <>
              {selectedAssetType.type}
              </>
            )}
            {selectedAssetType.type === "Video" && (
              <>
                {selectedAssetType.type}
              </>
            )}
            {selectedAssetType.type === "Banner" &&
              <>
                {selectedAssetType.type}
              </>
            }
          </h1>
          <h6 className="selectassest-title">{selectedAssetType.description}</h6>
          <span
            className="change-type"
            onClick={() => {
              dispatch(setBreadcrumbStatus(0));
              setTimeout(() => {
                navigate("/new-asset");
              }, 1000);
            }}
          >
            Change asset type
          </span>
        </Col>
        {selectedAssetType.type === "Emailer" ?
          <Col className="col-md-9 cursor-pointer col-12 mb-lg-0 mb-4">
            <Row className="or d-flex card-holder-custom align-item-center justify-content-center">
            {/* <Col
              // style={{width:"32%"}}    
              className={`card-custom col-md-3 cursor-pointer col-12 mb-lg-0 mb-4 ${emailOption === "2" && "active-tab"}`}
              onClick={()=>{setEmailOption("2")}}
            >
              <div>
                <div className="internal-card">
                    <span>Pick a Template</span>
                    <span><img src={MagicStick} alt="magic-stick" /></span>
                </div>
              </div>
            </Col>
              or */}
            <Col
              // style={{width:"32%"}}    
              className={`card-custom col-md-3 cursor-pointer col-12 mb-lg-0 mb-4 ${emailOption === "1" && "active-tab"}`}
              onClick={() => {
                setEmailOption("1")
                baseFormatEmailHandler("1");
              }}
            >
              <div>
                <div className="internal-card">
                    <span>Upload HTML</span>
                    <span><img src={MagicStick} alt="magic-stick" /></span>
                </div>
              </div>
            </Col>
            or
            <Col
              // style={{width:"32%"}}    
              className={`card-custom col-md-3 cursor-pointer col-12 mb-lg-0 mb-4 ${emailOption === "0" && "active-tab"}`}
              onClick={() => {
                setEmailOption("0")
                baseFormatEmailHandler("0");
              }}
            >
              <div>
                <div className="internal-card">
                    <span>Text Email</span>
                    <span><img src={MagicStick} alt="magic-stick" /></span>
                </div>
              </div>
            </Col> 
            </Row>
          </Col>
        :
        <Col
          className={
            selectedAssetType.type === "Social Post" || selectedAssetType.type === "Image" || 
              selectedAssetType.type === "Banner"
              ? ""
              : selectedAssetType.type === "Video"
                ? "col-lg-4  col-md-4 col-12 mb-lg-0 mb-4"
                : ""
          }
        >
          <Card className="bulk-upload">
            <Card.Body>
              <Card.Title>
                {/* {selectedAssetType.article_type === "Video"
                  ? "Single File Upload"
                  : "Bulk Upload"}
                <br /> */}
                <Icon.Upload />
              </Card.Title>
              {(selectedAssetType.type === "Social Post" || selectedAssetType.type === "Image") && (
                <Card.Text>Upload creatives for WhatsApp, Facebook, Twitter and LinkedIn</Card.Text>
              )}
              {(selectedAssetType.type === "Banner") && (
                <Card.Text>Upload microsite banners related to different products and services</Card.Text>
              )}
              {(selectedAssetType.type === "Brochure") && (
                <Card.Text>Upload all product-related brochures in bulk</Card.Text>
              )}
              {(selectedAssetType.type === "Infographics" || selectedAssetType.type === "Infographic") && (
                <Card.Text>Upload content in PDF format </Card.Text>
              )}
              {selectedAssetType.type === "Video" && (
                <Card.Text>Upload a video in mp4 or YouTube link format<br/>
                <strong className="fst-italic" >Note: Please upload a single file only</strong></Card.Text>
              )}
              <div>
                {(selectedAssetType.type === "Social Post" || selectedAssetType.type === "Image" ||
                  selectedAssetType.type === "Banner") && (
                    <div className="files-upload-wrapper pdf">
                      <FileInputComponent
                        labelText=""
                        multiple={true}
                        callbackFunction={(file_arr) => {
                          setUploadedData(file_arr);
                        }}
                        // accept="image/*,.pdf,video/*"
                        accept="image/*"
                      />
                    </div>
                  )}
                {(selectedAssetType.type === "Brochure" ||
                  selectedAssetType.type === "Infographics" ||
                  selectedAssetType.type === "Infographic") && (
                    <div className="files-upload-wrapper pdf">
                      <FileInputComponent
                        labelText=""
                        multiple={true}
                        callbackFunction={(file_arr) => {
                          setUploadedData(file_arr);
                        }}
                        // accept="image/*,.pdf,video/*"
                        accept=".pdf"
                      />
                    </div>
                  )}
                {selectedAssetType.type === "Video" && (
                  <div className="files-upload-wrapper video">
                    <FileInputComponent
                      className="video-format"
                      labelText=""
                      multiple={false}
                      callbackFunction={(file_arr) => {
                        //setUploadedData(file_arr);
                        handleFile(file_arr);
                      }}
                      // accept="image/*,.pdf,video/*"
                      accept=".mp4"
                    />
                    {/* <video id="uploadedVideo">
                      <source 
                      src={_.isEmpty(uploadedData) ? "" : uploadedData[0].base64}
                      type="video/mp4" />
                    </video> */}
                  </div>
                )}
              </div>
            </Card.Body>
          </Card>
        </Col>
        }
        {/* {(selectedAssetType.article_type === "Social Post" ||
          selectedAssetType.article_type === "Banner") && (
          <>
            <Col className="col-1">
              <span>or</span>
            </Col>
            <Col className="col-4">
              <Card className="image-gallery">
                <Card.Body>
                  <Card.Text>Select Images</Card.Text>
                  <Row>
                    <Col>
                      <Form>
                        <Form.Control
                          size="sm"
                          type="text"
                          placeholder="Search library"
                          className="search-field"
                        />
                        <div className="search-icon">
                          <Icon.Search />
                        </div>
                      </Form>
                    </Col>
                    <Col>
                      <Form>
                        <Form.Control
                          size="sm"
                          type="text"
                          placeholder="Search Unsplash"
                          className="search-field"
                        />
                        <div className="search-icon">
                          <Icon.Search />
                        </div>
                      </Form>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col>
          </>
        )} */}
        {selectedAssetType.type === "Video" && (
          <>
            <Col className="col-lg-1  col-md-6 col-12 mb-lg-0 mb-4 text-center">
              <span className="text-center">or</span>
            </Col>
            <Col className="col-lg-4  col-md-6 col-12 mb-lg-0 mb-4">
              <Card className="video-gallery">
                <Card.Body>
                  <Card.Text>Enter Video URLs</Card.Text>
                  {videoUrlsInput.map((element, index) => (
                    <Row>
                      <Col>
                        <Form>
                          <Form.Control
                            size="sm"
                            type="text"
                            name="video_url"
                            className="video-url-field mb-3"
                            placeholder="Video URL"
                            value={element.video_url || ""}
                            onChange={(e) => videoUrlInputsHandler(index, e)}
                          />
                        </Form>
                        {index ? (
                          <span
                            className="remove-url-field"
                            onClick={() => removeVideoUrlFields(index)}
                          >
                            <Icon.XCircleFill />
                          </span>
                        ) : null}
                      </Col>
                    </Row>
                    // <div className="form-inline" key={index}>
                    //   <label>Name</label>
                    //   <input
                    //     type="text"
                    //     name="name"
                    //     value={element.name || ""}
                    //     onChange={(e) => handleChange(index, e)}
                    //   />
                    //   <label>Email</label>
                    //   <input
                    //     type="text"
                    //     name="email"
                    //     value={element.email || ""}
                    //     onChange={(e) => handleChange(index, e)}
                    //   />
                    //   {index ? (
                    //     <button
                    //       type="button"
                    //       className="button remove"
                    //       onClick={() => removeFormFields(index)}
                    //     >
                    //       Remove
                    //     </button>
                    //   ) : null}
                    // </div>
                  ))}
                  <Row>
                    <Col>
                      <Button
                        variant="info"
                        className="add-more-video"
                        onClick={addVideoUrlFields}
                      >
                        <span>
                          <Icon.Plus />
                        </span>
                        Add More
                      </Button>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col>
            {/* <Col className="col-1">
              <span>or</span>
            </Col>
            <Col className="col-3">
              <Card className="video-gallery">
                <Card.Body>
                  <Card.Text>Select Videos</Card.Text>
                  <Row>
                    <Col>
                      <Form>
                        <Form.Control
                          size="sm"
                          type="text"
                          placeholder="Search video library"
                          className="search-field"
                        />
                        <div className="search-icon">
                          <Icon.Search />
                        </div>
                      </Form>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col> */}
          </>
        )}
      </Row>
      {selectedAssetType.type !=="Emailer" && <Row className="next-btn custom-row g-0 m-lg-0 mb-4 mx-2">
        <Col className="my-4">
          <Button className="m-lg-0 mx-2 " variant="info" onClick={baseFormatHandler}
            disabled={((_.isEmpty(uploadedData) && (videoUrlsInput[0].video_url === "" && selectedAssetType.type !=="Emailer")) || emailOption == 2 ? true : false)}
          >
            Next
            <span>
              <Icon.ChevronRight />
            </span>
          </Button>
        </Col>
      </Row>}
    </div>
  );
};

export default AddingData;
