// import "./styles.css";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import * as Icon from "react-bootstrap-icons";
import SubTable from "./SubTable"
import vector from '../../assets/setup-icon/Vector.png';
import "bootstrap/dist/css/bootstrap.min.css";
import { useDispatch, useSelector } from "react-redux";
import { categoryListSequencing, activeCategoryData, deleteCategory, setCategoryAttribute, setContentAttribute, deleteContent, deleteBusinessNeed, setBatchAttribute, deleteBatch } from "../../redux/actions/setupActions";
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import { Button, Modal, Form} from 'react-bootstrap';

export default function DragTable(props) {
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const [users, setUsers] = useState(props.categoryData);
  const [deleteData, setDeleteData] = useState({});
  const [isActive, setIsActive] = useState(false);
  const [parentId, setParentId] = useState("");
  const category_all = useSelector((state) => state.assetLibrary.categoryList);
  const content_drop = useSelector((state) => state.setupScreen.content_drop.content_type_dropdown);
  const authToken = props.authToken;
  const type = 1;
  
  const [show, setShow] = useState(false);

  const handleClose = () => {
    setDeleteData({});
    setShow(false);
  }
  const editPageOpen = (userData) => {
    switch (props.subPageName) {
      case "category":
         edit_category(userData.cat_name, type, userData.id, null, "cat_table", userData.image,"", userData.description )
        break;
      case "contentList":
        edit_content(userData.article_type, userData.selected_content_id ,userData.id ,userData.image, userData.description, userData.internal)
        break;
      case "businessNeed":
        props.handleEditShow("inside" ,userData.type ,userData.id);
        break;
      case "levels":
        edit_batch(userData.badge_icon
          , userData.badge_name ,userData.date
          ,userData.id
          , userData.points
          )
        break;
      default:
        break;
    }
  }
  const handleShowPopup = (id, type) => {
    setShow(true);
    setDeleteData({ id: id, type: type })
  }
  const handleActiveChange = (e, id) => {
      setIsActive(!isActive);
      dispatch(activeCategoryData(authToken, id, e.target.checked !== true ? 2 : 1, type, props.page));
  }
  const handleDragEnd = (e) => {
    if (!e.destination) return;
    let tempData = Array.from(users);
    let [source_data] = tempData.splice(e.source.index, 1);
    tempData.splice(e.destination.index, 0, source_data);
    setUsers(tempData);
    if (props.subPageName !== "contentList") {
      dispatch(categoryListSequencing(authToken, e.draggableId , e.destination.index + 1, props.page));
    }
  };
  
  const toggleSubTable = (id) => {
    if (parentId !== id) {
      setParentId(id);
    } else {
      setParentId("");
    }
  };
  
  const deleteEvent = () => {
    switch (props.subPageName) {
      case "category":
        dispatch(deleteCategory(authToken, deleteData.id, deleteData.type,props.page));
        break;
      case "contentList":
        dispatch(deleteContent(authToken, deleteData.id, deleteData.type,props.page));
        break;
      case "businessNeed":
        dispatch(deleteBusinessNeed(authToken, deleteData.id));
        break;
      case "levels":
        dispatch(deleteBatch(authToken, deleteData.id));
        break;
      default:
        console.log("Table Error")
        break;
    }
    props.handleRemoveSearch();
    handleClose();
  };
  
  const edit_content = (name, pid, sid, image, desc, internal) => {
    let activeContentType = null;
    activeContentType = content_drop.filter((item) => {
      return item.id === pid
    })
    dispatch(setContentAttribute(name, activeContentType, pid, sid, image, desc, internal));
    navigate("/setup/content/contentList/editContent");
  }
  const edit_batch = (icon, name, date, id, point) => {
    // let activeContentType = null;
    // activeContentType = content_drop.filter((item) => {
    //   return item.id === pid
    // })
    dispatch(setBatchAttribute(icon, name, date, id, point));
    navigate("/setup/leaderBoard/levels/editBatches");
  }
  
  const edit_category = ( name, type, pid, sid, page, imageLoaded, s_code, description) => {
    let activeCategory = null;
    if ((type === 2 && page ==='sub_cat_table') || (type === 1 && page ==='cat_table')) {
      activeCategory = category_all.filter((item) => {
        return item.id === pid
      })
    }
    dispatch(setCategoryAttribute(name, type, activeCategory,pid, sid, imageLoaded,s_code,description));
    navigate("/setup/content/editCategory");
  }
  
  useEffect(() => {
    console.log('xyz',props.categoryData);
  }, []);


  
  return (
    <div className="App">
      <DragDropContext onDragEnd={handleDragEnd}>
        <div className="table borderd">
          <Droppable droppableId="droppable-1">
            {(provider) => (
              <div
                style={{ lineHeight: "35px" }}
                className="text-capitalize"
                ref={provider.innerRef}
                {...provider.droppableProps}
              >
                {users && users.map((user, index) => (
                  <>
                    <Draggable
                      key={user.id.toString()}
                      draggableId={user.id.toString()}
                      index={index}
                    >
                      {(provider) => (
                        <>
                          <div
                            className="row border-bottom"
                            {...provider.draggableProps}
                            ref={provider.innerRef}
                          >
                            <div
                              style={{display: (props.subPageName === "contentList" || props.subPageName === "businessNeed" || props.subPageName === "levels") && "none"}}
                              className="drag-button"
                              {...provider.dragHandleProps}
                            >
                              {" "}
                              <Icon.GripHorizontal color="#D9D9D9" />{" "}
                            </div>
                            <div className="table-row-count drag-button">
                              {props.page+index+1 + "."}
                            </div>
                            <div className="text-aligning table-name">
                              <span className="title-main" title={user.cat_name || user.article_type || user.type || user.badge_name}>{user.cat_name || user.article_type || user.type || user.badge_name}</span>
                            </div>
                            <div className={ props.subPageName === "category" ? "text-aligning":"adjust-width text-aligning"}>
                              <span className="bordering-date">{user && user.date && user.date.slice(0,10)}</span>
                            </div>
                            {/* <div className="edit-icon">
                              <Icon.ImageFill color="#64B6E7" />
                              <span className="px-1">view media</span>
                            </div> */}
                            <div className="column-styling">
                              <span className="edit-icon" onClick={()=>{editPageOpen(user)}}>
                                <Icon.PencilSquare className="mr-3" color="#64B6E7" />
                                Edit
                              </span>
                            </div>
                            <div className="column-styling">
                              <span className="edit-icon" onClick={()=>{handleShowPopup(user.id,type)}}>
                                <Icon.TrashFill color="rgba(33, 33, 33, 0.3)" />
                              </span>
                            </div>
                            {props.subPageName === "category" && <div className="toggle-switch">
                              <Form.Check 
                                className="d-inline"
                                type="switch"
                                id="custom-switch"
                                value={user.status}
                                defaultChecked={user.status}
                                onChange={(e) => {
                                  handleActiveChange(e,user.id);
                                }}
                              />
                            </div>}
                            {user.subcategory_list && user.subcategory_list.length > 0 && props.subPageName === "category" && <div className="column-styling" onClick={() => { toggleSubTable(user.id) }}>
                              {parentId !== user.id ? <img src={vector} alt="icon" />
                                : <img style={{transform: "rotate(180deg)"}} src={vector} alt="icon" />}
                            </div>}
                            
                            {user.subcategory_list && user.subcategory_list.length > 0 && props.subPageName === "category" && parentId !== "" && <SubTable authToken={authToken} page={props.page} subPageName={props.subPageName} subUser={user.subcategory_list} pid={parentId} handleShowPopup={handleShowPopup} edit_category={edit_category} />}
                            
                          </div>
                        </>
                      )}
                    </Draggable>
                  </>
                ))}
                {provider.placeholder}
              </div>
            )}
          </Droppable>
        </div>
      </DragDropContext>
      
      {/* delete poppup */}
      <Modal className="delete-popup" show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Delete</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to delete ?</Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={deleteEvent}>
            Delete
          </Button>
          <Button variant="secondary" onClick={handleClose}>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}
