import React, { useEffect, useState } from "react";
// import LeftMenu from "./LeftMenu";
// import RightScreen from "./RightScreen";
// import SingleScreen from "./SingleScreen";
import { Button, Col, Dropdown, Form } from "react-bootstrap";
import * as Icon from "react-bootstrap-icons";
import Page from "./Page";
import "./campaign.css";
import DirectList from "./DirectList";
import IndirectList from "./IndirectList";
import List from "./List";
import CampaignListFilters from "./CampaignListFilters";
import { useDispatch } from "react-redux";
import EmailCampaign from "./EmailCampaign";
import PickTemplate from "./PickTemplate";

import 'react-datepicker/dist/react-datepicker.css';
import ContactList from "./ContactList";
import { fetchCreateTemplateData } from "../../../redux/actions/actions";

const CampaignMain = (props) => {
  const dispatch = useDispatch();
  const [searchField, setSearchField] = useState("");
  const [sortByOption, setSortByOption] = useState("");
  const [selectedIds, setSelectedIds] = useState([]);
  const [sortValue, setSortValue] = useState({ title: "", value: "" });
  
  const handleSelectIds = (value) => {
    setSelectedIds(value);
  }
  
  const FullScreen = () => {
    if (props.subPageName === "direct") {
      return <DirectList authToken={props.authToken} searchField={searchField} pageName={props.pageName} subPageName={props.subPageName} innerTitle={props.innerTitle}/>
    }
    else if (props.subPageName === "indirect") {
      return <IndirectList authToken={props.authToken} searchField={searchField} pageName={props.pageName} subPageName={props.subPageName} innerTitle={props.innerTitle}/>
    }
    else if (props.subPageName === "list") {
      return <List authToken={props.authToken} sortValue={sortValue} searchField={searchField} pageName={props.pageName} subPageName={props.subPageName} innerTitle={props.innerTitle}/>
    }
    else if (props.subPageName === "contact_list") {
      return <ContactList authToken={props.authToken} handleSelectIds={handleSelectIds} sortValue={sortValue} searchField={searchField} pageName={props.pageName} subPageName={props.subPageName} innerTitle={props.innerTitle}/>
    }
    else if (props.subPageName === "run_template" || props.subPageName === "push_template") {
      return <PickTemplate authToken={props.authToken} pageName={props.pageName} subPageName={props.subPageName} innerTitle={props.innerTitle}/>
    }
    else if (props.subPageName === "run_campaign") {
      return <EmailCampaign authToken={props.authToken} pageName={props.pageName} subPageName={props.subPageName} topTitle={props.topTitle}/>
    }
    else if (props.subPageName === "push_campaign") {
      return <EmailCampaign authToken={props.authToken} pageName={props.pageName} subPageName={props.subPageName} topTitle={props.topTitle}/>
    }
    // else {
    //   return <SingleScreen authToken={props.authToken} pageName={props.pageName} subPageName={props.subPageName} innerTitle={props.innerTitle}/>;
    // }
  }
  useEffect(() => {
    props.pageTypeHandler("Campaign");
    dispatch(fetchCreateTemplateData(props.authToken));
  }, [])
  
  const searchHandler = (e, text, path) => {
    // if (path === "direct") {
    //   dispatch(fetchDirectListing(props.authToken, e.target.value))
    // } else {
    //   dispatch(fetchIndirectListing(props.authToken, text))
    // }
  }
  
  return (
    <div className="campaign">
      <CampaignListFilters
        authToken={props.authToken}
        pagename={props.subPageName}
        searchHandler={searchHandler}
        searchField={searchField}
        setSearchField={setSearchField}
        displayType={props.displayType}
        selectedIds={selectedIds}
      />
      <Page title="Campaign">
      <div className="row main-setup-container">

          <>
            {props.displayType == 1 && <>
            {props.subPageName === "list" && props.subPageName === "contact_list" && <div className="row campaign-row">
              <div className="col-8 px-0 tagline d-flex align-item-center">
                {props.subPageName !== "contact_list" ? "Contacts List":"Contacts"}
              </div>
              {props.subPageName !== "contact_list" && <div className="col-4 d-flex sort-by justify-content-end p-0">
                  <span className="title">Sort by</span>
                  <Form.Select
                      aria-label="select-sortby"
                      onChange={(e) => {
                        setSortByOption(e.target.value);
                        let sort = {
                          title: "",
                          value: "",
                        }
                        if (e.target.value == 1) {
                          sort.title = "date";
                          sort.value = "ASC";
                        } else if (e.target.value == 2) {
                          sort.title = "date";
                          sort.value = "DESC";
                        } else if (e.target.value == 3) {
                          sort.title = "title";
                          sort.value = "ASC";
                        } else {
                          sort.title = "title";
                          sort.value = "DESC";
                        }
                        setSortValue(sort);
                      }}
                      value={sortByOption}
                  >
                    <option value="null">Any</option>
                    <option value="1">Date (ASC)</option>
                    <option value="2">Date (DESC)</option>
                    <option value="3">Title (ASC)</option>
                    <option value="4">Title (DESC)</option>
                  </Form.Select>
              </div>}
            </div>}
            </>}
            <div className="col-12">
              <FullScreen/>
            </div>
          </>
      </div>
      </Page>
    </div>
  );
};

export default CampaignMain;
  