/* eslint-disable */
import ReactPaginate from "react-paginate";
import { useState, useEffect } from "react";

//Redux-imports
import { useDispatch, useSelector } from "react-redux";
import {
  globalFilterAssetLibraryContentList,
} from "../../redux/actions/actions";
import { fetchHashtagListing, fetchCategoryListing, fetchContentListing, fetchGlobalSearchListing, fetchProductTypeListing, fetchParentListing, fetchPartnerCategoryListing } from "../../redux/actions/setupActions"

import $ from "jquery";

const PaginationNew = (props) => {
  const dispatch = useDispatch();

  const [pageCount, setPageCount] = useState(0);
  const [pageStatus, setPageStatus] = useState(0);

  let currOffset = 0;

  const currQuickFilterId = useSelector(
    (state) => state.assetLibrary.quickFilterId
  );
  const currQuickFilterType = useSelector(
    (state) => state.assetLibrary.quickFilterType
  );
  const currFilterType = useSelector((state) => state.assetLibrary.filterType);
  const currFilterValue = useSelector(
    (state) => state.assetLibrary.filterValue
  );
  const currSortFilter = useSelector((state) => state.assetLibrary.sortFilter);
  const currFolderId = useSelector((state) => state.assetLibrary.folderId);
  const currFolderType = useSelector((state) => state.assetLibrary.folderType);
  const currFolderName = useSelector((state) => state.assetLibrary.folderName);
  
  const totalData = props.total_page;
  const globalSearchKey = useSelector((state) => state.assetLibrary.globalSearchKey);
  const qFilterKey = useSelector((state) => state.assetLibrary.qFilterKey);
  const cFilterKey = useSelector((state) => state.assetLibrary.cFilterKey);
  const pFilterKey = useSelector((state) => state.assetLibrary.pFilterKey);
  const sFilterKey = useSelector((state) => state.assetLibrary.sFilterKey);
  const svFilterKey = useSelector((state) => state.assetLibrary.svFilterKey);
  
  const search_input = useSelector((state) => state.setupScreen.search_input);
  const sort_value = useSelector((state) => state.setupScreen.sort_value);
  
  const resetPage = useSelector((state) => state.assetLibrary.resetPage);

  useEffect(() => {
    setTimeout(() => {
      $(".page-item").removeClass("active");
      $("li.page-item:nth-child(2)").addClass("active");
    }, 1000);
    setPageCount(Math.ceil(totalData / 20));
  }, []);
  
  useEffect(() => {
    let calculated_count = totalData ? (totalData / 20) : pageCount
    if (calculated_count === 1) {
      $("li.page-item:nth-child(2)").addClass("active");
    }
    setPageStatus(calculated_count);
    setPageCount(Math.ceil(totalData / 20));
  }, [totalData]);

  const handlePageClick = (event) => {
    if (event.selected > 0) {
      $("li.page-item:nth-child(2)").removeClass("active");
    }
    const newOffset = (event.selected * 20) % totalData;
    if (newOffset === 0) {
      currOffset = newOffset;
    } else {
      currOffset = newOffset;
    }
    switch(props.currPage) {
      case 'category':
        dispatch(fetchCategoryListing(props.authToken, currOffset, search_input));
        break;
      case 'contentList':
        dispatch(fetchContentListing(props.authToken, currOffset, search_input, sort_value));
        break;
      case 'hashtag':
        props.unselectData();
        dispatch(fetchHashtagListing(props.authToken, currOffset, search_input, sort_value));
        break;
      case 'globalSearch':
        props.unselectData();
        dispatch(fetchGlobalSearchListing(props.authToken, "successfull", currOffset, search_input));
        break;
      case 'globalSearchUn':
        props.unselectData();
        dispatch(fetchGlobalSearchListing(props.authToken, "unsuccessfull", currOffset, search_input));  
        break;
      case 'productType':
        dispatch(fetchProductTypeListing(props.authToken, currOffset, search_input, sort_value));
        break;
      case 'partner':
        dispatch(fetchParentListing(props.authToken, currOffset, search_input, sort_value));
        break;
      case 'partnerCategory':
        dispatch(fetchPartnerCategoryListing(props.authToken, currOffset, search_input, sort_value));
        break;
      default:
        dispatch(globalFilterAssetLibraryContentList(props.authToken, globalSearchKey, pFilterKey, qFilterKey, cFilterKey, sFilterKey, svFilterKey, currFolderId, currFolderType, currFolderName, currOffset));
    }
  };

  useEffect(() => {
    // console.log("PageReset",resetPage);
  }, [resetPage]);

  return (
    <>
      { pageStatus >= 1 && <div>
        <ReactPaginate
        nextLabel=">"
        onPageChange={handlePageClick}
        pageRangeDisplayed={3}
        marginPagesDisplayed={2}
        pageCount={pageCount}
        previousLabel="<"
        pageClassName="page-item"
        pageLinkClassName="page-link"
        previousClassName="page-item"
        previousLinkClassName="page-link"
        nextClassName="page-item"
        nextLinkClassName="page-link"
        breakLabel="..."
        breakClassName="page-item"
        breakLinkClassName="page-link"
        containerClassName="pagination"
        activeClassName="active"
        renderOnZeroPageCount={null}
        forcePage={resetPage}
      />
      </div>}
    </>
  );
};

export default PaginationNew;
