// reducers
import {
  TOGGLE_LOADING,
  FETCH_STAGES_LIST,
  FETCH_ALL_LEAD_DETAILS,
  FETCH_NEW_LEAD_DETAILS,
  FETCH_ENGAGED_LEAD_DETAILS,
  FETCH_DEAL_LEAD_DETAILS,
  FETCH_QUOTE_LEAD_DETAILS,
  FETCH_WON_LEAD_DETAILS,
  FETCH_DROPPED_LEAD_DETAILS,
  FETCH_PRODUCT_DROPDOWN,
  FETCH_SOURCE_DROPDOWN,
  FETCH_CITY_DROPDOWN,
  USER_ACTIVITY_DETAILS,
  LEAD_IMPORT_MAPPINGS,
  CRM_IMPORT_TYPES,
  MAPPED_MEMORY_DATA,
  DOWNLOAD_MAPPEDDATA_STATUS_FILE_PATH,
  CONTACT_MAPPED_MEMORY_DATA,
  CONTACT_DOWNLOAD_MAPPEDDATA_STATUS_FILE_PATH,
  STORE_EMAIL_TEMPLATES,
  STORE_TASK_LIST,
  FETCH_CONTACTS_LIST,
  FETCH_PROSPECTS_LIST,
  FETCH_CONTACT_DETAILS,
  FETCH_CONTACT_LIST,
  GET_SHARE_LINK,
  GET_EMAIL_SHORT_LINK,
  GET_FB_SHORT_LINK,
  GET_TW_SHORT_LINK,
  GET_LN_SHORT_LINK,
  GET_WH_SHORT_LINK,
  UNAUTHORIZED_CRM,
  CRM_IMPORT_DATA_INSERT_ID,
  ALL_LEAD_TYPE_DETAILS,
  CLEAR_ALL_LEAD_TYPE_DETAILS,
  LOAD_MORE_LEAD_DETAILS,
  CLEAR_STAGES_LIST,
  RESET_CONTACT_PROSPECT_PAGING
} from "../../constants/ActionTypes";

const initialState = {
  loading: false,
  stagesList: [],
  allLeads: [],
  newLeads: [],
  engagedLeads: [],
  dealLeads: [],
  quoteLeads: [],
  wonLeads: [],
  droppedLeads: [],
  leadStages: {},
  productDropdown: [],
  sourceDropdown: [],
  cityDropdown: [],
  filterDropdown: [],
  userActivityDetails: null,
  leadImportMappingKeys: null,
  crmImportTypes: [],
  mappedMemoryData: [],
  downloadMappedDataStatusFile: null,
  contactMappedMemoryData: [],
  contactDownloadMappedDataStatusFile: null,
  mailTemplates: null,
  tasksList: null,
  contactsList: [],
  totalContactsList: null,
  contactDetails: null,
  contactList: null,
  shareAssetDetails: null,
  emailShortLink: null,
  fbShortLink: null,
  twShortLink: null,
  lnShortLink: null,
  whShortLink: null,
  unAuthorisedCrm: null,
  crmImportedDataIndertedId: null,
  allLeadDetails: [],
  resetCPPaging: 0
};

const crmReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case TOGGLE_LOADING:
      return { ...state, loading: payload };
    case FETCH_STAGES_LIST:
      return { ...state, stagesList: payload.stages, filterDropdown: payload.settings };
    case CLEAR_STAGES_LIST:
      console.log("clear stages");
      return { ...state, stagesList: [] };
    case ALL_LEAD_TYPE_DETAILS:
      const index = state.allLeadDetails.findIndex(lead => lead.leadType == payload.leadType); //finding index of the item                                                                       action.payload); //finding index of the item
      //console.log("index", index);
      const newArray = [...state.allLeadDetails]; //making a new array
      //console.log("newArr", newArray);
      if (index !== -1) {
        newArray.splice(index, 1, payload)
      } else {
        newArray.push(payload);
      }//changing value in the new array
      return {
        ...state, //copying the orignal state
        allLeadDetails: newArray, //reassingning todos to new array
      }

    case LOAD_MORE_LEAD_DETAILS:
      const index1 = state.allLeadDetails.findIndex(lead => lead.leadType == payload.leadType); //finding index of the item                                                                       action.payload); //finding index of the item
      //console.log("index", index);
      let newArray1 = [...state.allLeadDetails]; //making a new array
      //console.log("newArr", newArray);
      if (index1 !== -1) {
        //newArray1.splice(index1, 1, payload)
        newArray1 = payload.data.length > 0 ?
          payload.data.map(item => {
            newArray1[index1].data.push(item);
            return newArray1;
          }) :
          ""
      }
      console.log("newArray1", newArray1);
      return { ...state, allLeadDetails: newArray1 }

    case CLEAR_ALL_LEAD_TYPE_DETAILS:
      console.log("clear redux state");
      return { ...state, allLeadDetails: [] }
    case FETCH_ALL_LEAD_DETAILS:
      return { ...state, allLeads: payload.activity_details };
    case FETCH_NEW_LEAD_DETAILS:
      return { ...state, newLeads: payload.activity_details, leadStages: Object.assign(state.leadStages, { 1: { lead_stage: "New", lead_type: "1", id: "1", items: payload.activity_details } }) };
    case FETCH_ENGAGED_LEAD_DETAILS:
      return { ...state, engagedLeads: payload.activity_details, leadStages: Object.assign(state.leadStages, { 2: { lead_stage: "Engaged", lead_type: "2", id: "2", items: payload.activity_details } }) };
    case FETCH_DEAL_LEAD_DETAILS:
      return { ...state, dealLeads: payload.activity_details, leadStages: Object.assign(state.leadStages, { 3: { lead_stage: "Deal", lead_type: "3", id: "3", items: payload.activity_details } }) };
    case FETCH_QUOTE_LEAD_DETAILS:
      return { ...state, quoteLeads: payload.activity_details, leadStages: Object.assign(state.leadStages, { 4: { lead_stage: "Quote", lead_type: "4", id: "4", items: payload.activity_details } }) };
    case FETCH_WON_LEAD_DETAILS:
      return { ...state, wonLeads: payload.activity_details, leadStages: Object.assign(state.leadStages, { 5: { lead_stage: "Won", lead_type: "5", id: "5", items: payload.activity_details } }) };
    case FETCH_DROPPED_LEAD_DETAILS:
      return { ...state, droppedLeads: payload.activity_details, leadStages: Object.assign(state.leadStages, { 6: { lead_stage: "Dropped", lead_type: "6", id: "6", items: payload.activity_details } }) };
    case FETCH_PRODUCT_DROPDOWN:
      return { ...state, productDropdown: payload };
    case FETCH_SOURCE_DROPDOWN:
      return { ...state, sourceDropdown: payload };
    case FETCH_CITY_DROPDOWN:
      return { ...state, cityDropdown: payload };
    case USER_ACTIVITY_DETAILS:
      return { ...state, userActivityDetails: payload };
    case LEAD_IMPORT_MAPPINGS:
      return { ...state, leadImportMappingKeys: payload }
    case CRM_IMPORT_TYPES:
      return { ...state, crmImportTypes: payload }
    case MAPPED_MEMORY_DATA:
      return { ...state, mappedMemoryData: payload }
    case DOWNLOAD_MAPPEDDATA_STATUS_FILE_PATH:
      return { ...state, downloadMappedDataStatusFile: payload }
    case STORE_EMAIL_TEMPLATES:
      return { ...state, mailTemplates: payload };
    case STORE_TASK_LIST:
      return { ...state, tasksList: payload };
    case FETCH_CONTACTS_LIST:
      return { ...state, contactsList: payload.data, totalContactsList: payload.total };
    case FETCH_PROSPECTS_LIST:
      return { ...state, contactsList: payload.data, totalContactsList: payload.total };
    case FETCH_CONTACT_DETAILS:
      return { ...state, contactDetails: payload };
    case CONTACT_MAPPED_MEMORY_DATA:
      return { ...state, contactMppedMemoryData: payload }
    case CONTACT_DOWNLOAD_MAPPEDDATA_STATUS_FILE_PATH:
      return { ...state, contactDownloadMappedDataStatusFile: payload }
    case FETCH_CONTACT_LIST:
      return { ...state, contactList: payload };
    case GET_SHARE_LINK:
      return { ...state, shareAssetDetails: payload.data };
    case GET_EMAIL_SHORT_LINK:
      return { ...state, emailShortLink: payload };
    case GET_FB_SHORT_LINK:
      return { ...state, fbShortLink: payload };
    case GET_TW_SHORT_LINK:
      return { ...state, twShortLink: payload };
    case GET_LN_SHORT_LINK:
      return { ...state, lnShortLink: payload };
    case GET_WH_SHORT_LINK:
      return { ...state, whShortLink: payload };
    case UNAUTHORIZED_CRM:
      return { ...state, unAuthorisedCrm: payload };
    case CRM_IMPORT_DATA_INSERT_ID:
      return { ...state, crmImportedDataIndertedId: payload };
    case RESET_CONTACT_PROSPECT_PAGING:
      return { ...state, resetCPPaging: payload };
    default:
      return state;
  }
};
export default crmReducer;
