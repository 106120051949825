import React from 'react'
import { Col, Button, OverlayTrigger, Popover } from "react-bootstrap";
import * as Icon from "react-bootstrap-icons";
import ReportTable from '../shared/ReportTable';
import { useEffect } from "react";
import Page from "../Page"
import infoIcon from "../../assets/info-fill.svg"
import { useDispatch, useSelector } from "react-redux";
import DragShimmer from './shimmer/DragShimmer';
import { fetchLeaderReport,exportLeadDetails } from "../../redux/actions/setupActions";

const SetupLeaderReport = (props) => {
    const dispatch = useDispatch();
    let isLoading = useSelector((state) => state.setupScreen.loading);
  let pageNum = useSelector((state) => state.setupScreen.currPage);
  const talic_active = useSelector((state) => state.assetLibrary.talic_active);
    const authToken = props.authToken;
    const leaderboard_report = useSelector((state) => state.setupScreen.leaderboard_report);
  
    props.pageTypeHandler("leadReport");
  
    const downloadExcel = (e) => {
        dispatch(exportLeadDetails(props.authToken));
    }
  
    useEffect(() => {
        dispatch(fetchLeaderReport(authToken));
    }, []);
  
    const popover = (
      <Popover id="popover-basic" style={{overflow:"hidden"}}>
        <Popover.Body>
          {leaderboard_report?.criteria?.length > 0 && leaderboard_report?.criteria?.map((criteria_item,criteria_index) => {
            return (
              <p className="tootltip-style"><b>{criteria_item.key}</b>: {criteria_item.value}</p>           
            )
          })}
          <p className="tootltip-style how-to-earn">
            <b>How to earn the points :</b><br />
            <Icon.Dot color='#000000' style={{fontSize:"18px"}}/>App download: 100 points <br/>
            <Icon.Dot color='#000000' style={{fontSize:"18px"}}/>Customer playing quiz: 25 points <br/>
            <Icon.Dot color='#000000' style={{fontSize:"18px"}}/>Every Lead Generated: 20 points <br/>
            <Icon.Dot color='#000000' style={{fontSize:"18px"}}/>Every referral: 20 points <br/>
            <Icon.Dot color='#000000' style={{fontSize:"18px"}}/>Every content shared: 5 points <br/>
            <Icon.Dot color='#000000' style={{fontSize:"18px"}}/>Active app usage: 3 points <br/>
            <Icon.Dot color='#000000' style={{fontSize:"18px"}}/>Uninstalling the app: -40% of earned points <br/>
          </p>  
        </Popover.Body>
      </Popover>
    );
    
  return (
      <Page title="Conversion CTA">
      <div className='leader-report-top-title'>
        <h3>{props.topTitle}
          <Button style={{ marginLeft: '60px', float:"right" }} onClick={downloadExcel}  className='common-setup-button' variant="primary">
            <Icon.FileEarmarkArrowUpFill className='export-btn-icon' />Export
          </Button>
        </h3>
      </div>
      <div className='scrollable-table'>
        <div className='scrollable-width'>          
          <div>
              <div className='leader-report'>
                <div className="top-heading-bar">
                <ul>
                    <li className='report-row-count report-similar-prop'>
                      <span>Rank</span>
                    </li>
                    <li className='level-styling report-similar-prop'>
                      <span>Level</span>
                      {talic_active === "tal" && <OverlayTrigger placement="bottom" overlay={popover}>
                        <img src={infoIcon} alt="info"/>
                      </OverlayTrigger>}
                    </li>
                    <li className='report-name report-similar-prop'>
                      <span>Name</span>
                    </li>
                    <li className='report-points report-similar-prop'>
                      <span>Points</span>
                    </li>
                    <li className='report-code'>
                      <span>{talic_active === "tal" ? "Talic":""} Code</span>
                    </li>
                    <li className='report-branch report-similar-prop'>
                      <span>Branch</span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
                
          <div>
            {isLoading && <DragShimmer subPageName={props.subPageName} tableData="report"/>}
            {isLoading === false && leaderboard_report?.data?.length > 0 &&
                <Col>
                  <ReportTable authToken={authToken} page={pageNum} subPageName={props.subPageName} selfRank={leaderboard_report.self_rank} categoryData={leaderboard_report.data} />
                </Col>
            }
            {leaderboard_report?.data?.length === 0 && (
              <div className="no-data asset">
                <span>
                  <Icon.Inbox />
                </span>
                <br />
                No Data
              </div>
            )}
          </div>
          
          {/* {total_data > 20 && <Pagination authToken={props.authToken} total_page={ total_data } page={pageNum} currPage={props.subPageName} />}           */}
        </div>
      </div>
      </Page>
    )
}

export default SetupLeaderReport;