// reducers
import {
  TOGGLE_LOADING,
  FETCH_DESIGNATION_LIST,
  FETCH_DESIGNATION_LIST_SUPPORT,
  FETCH_GLOBAL_SEARCH_LIST,
  FETCH_BUSINESS_NEED_LIST,
  FETCH_GLOBAL_SEARCH_LIST_U,
  FETCH_PRODUCT_TYPE_LIST,
  FETCH_PARENT_LIST,
  FETCH_PARENT_CATEGORY_LIST,
  FETCH_BUSINESS_FLAG,
  ROUTE_CHANGE_FLAG,
  CONTENT_LIST_DROPDOWN,
  PARTNER_ID_H,
  SET_DESC_ATTR,
  SET_PARENT_ID,
  FETCH_CONTENT_LIST,
  SET_SUB_ID,
  SET_IMAGE,
  SET_SELECTED_VALUE,
  SET_ATTR_TYPE,
  SET_NAME_ATTR,
  UNAUTHORIZED_SETUP,
  FETCH_CATEGORY_LIST,
  CURRENT_URL_PATH,
  FETCH_HASHTAG_LIST,
  SEARCH_INPUT,
  CURRENT_PAGE,
  SORT_VALUE,
  EDIT_PARTNER,
  ADD_PARTNER,
  SPECIAL_USER,
  SET_SCHEMA_CODE,
  BASE_TYPE_PARENT,
  LEADERBOARD_DROPDOWN,
  LEADERBOARD_LISTING,
  FETCH_LEVELS_LIST,
  BATCH_VALUES,
  FETCH_LEADERBOARD_FLAG,
  FETCH_LEADERBOARD_REPORT,
} from "../../constants/ActionTypes";

const initialState = {
  schema_code:"",
  special_user: false,
  selected_value: [],
  loading: false,
  navigatePage: false,
  currentUrl: "",
  partner_id: null,
  is_navigate: false,
  unAuthorisedSetup: null,
  business_need_list: null,
  parent_list: null,
  parent_category_list: null,
  designation_list: null,
  designation_list_support: null,
  product_type_list: null,
  category_list: null,
  hashtag_list: null,
  content_list: null,
  global_search_listing: null,
  global_search_u_listing: null,
  search_input: "",
  sort_value: "",
  name_attr: "",
  currPage: 0,
  parent_id: null,
  curr_image: "",
  content_drop: [],
  type_attr: null,
  sub_id: null,
  desc_attr: "",
  edit_partner:null,
  add_partner:null,
  base_type_parent:null,
  leaderboard_dropdown:null,
  leaderboard_listing:null,
  levels_listing:null,
  batch_values:null,
  leaderboard_flag: false,
  leaderboard_report: null,
};
const setupReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case TOGGLE_LOADING:
      return { ...state, loading: payload };
    case ROUTE_CHANGE_FLAG:
      return { ...state, is_navigate: payload };
    case FETCH_BUSINESS_FLAG:
      return { ...state, business_flag: payload === "1" ? true : false };
    case SPECIAL_USER:
      return { ...state, special_user: payload };
    case SET_SCHEMA_CODE:
      return { ...state, schema_code: payload };
    case PARTNER_ID_H: 
      return { ...state, partner_id: payload };
    case CURRENT_URL_PATH:
      return { ...state, currentUrl: payload };
    case FETCH_PARENT_LIST: 
      return { ...state, parent_list: payload };
    case FETCH_PARENT_CATEGORY_LIST:
      return { ...state, parent_category_list: payload };
    case FETCH_DESIGNATION_LIST: 
      return { ...state, designation_list: payload };
    case FETCH_DESIGNATION_LIST_SUPPORT: 
      return { ...state, designation_list_support: payload };
    case FETCH_CATEGORY_LIST:
      return { ...state, category_list: payload };
    case FETCH_PRODUCT_TYPE_LIST:
      return { ...state, product_type_list: payload };
    case FETCH_BUSINESS_NEED_LIST:
      return { ...state, business_need_list: payload };
    case FETCH_GLOBAL_SEARCH_LIST:
      return { ...state, global_search_listing: payload };
    case FETCH_GLOBAL_SEARCH_LIST_U:
      return { ...state, global_search_u_listing: payload };
    case FETCH_CONTENT_LIST:
      return { ...state, content_list: payload };
    case CONTENT_LIST_DROPDOWN:
      return { ...state, content_drop: payload };
    case FETCH_HASHTAG_LIST:
      return { ...state, hashtag_list: payload };
    case SEARCH_INPUT:
      return { ...state, search_input: payload };
    case CURRENT_PAGE:
      return { ...state, currPage: payload };
    case SORT_VALUE:
      return { ...state, sort_value: payload };
    case SET_NAME_ATTR:
      return { ...state, name_attr: payload };
    case SET_DESC_ATTR:
      return { ...state, desc_attr: payload };
    case SET_ATTR_TYPE:
      return { ...state, type_attr: payload };
    case SET_SELECTED_VALUE:
      return { ...state, selected_value: payload };
    case SET_IMAGE:
      return { ...state, curr_image: payload };
    case SET_PARENT_ID:
      return { ...state, parent_id: payload };
    case SET_SUB_ID:
      return { ...state, sub_id: payload };
    case UNAUTHORIZED_SETUP:
      return { ...state, unAuthorisedSetup: payload };
    case EDIT_PARTNER:
      return { ...state, edit_partner: payload };
    case ADD_PARTNER:
      return { ...state, add_partner: payload };
    case BASE_TYPE_PARENT:
      return { ...state, base_type_parent: payload };
    case LEADERBOARD_DROPDOWN:
      return { ...state, leaderboard_dropdown: payload };
    case LEADERBOARD_LISTING:
      return { ...state, leaderboard_listing: payload };
    case FETCH_LEVELS_LIST:
      return { ...state, levels_listing: payload };
    case BATCH_VALUES:
      return { ...state, batch_values: payload };
    case FETCH_LEADERBOARD_FLAG:
      return { ...state, leaderboard_flag: payload == 1 ? true : false };
    case FETCH_LEADERBOARD_REPORT:
      return { ...state, leaderboard_report: payload };
    default:
      return state;
  }
};
export default setupReducer;
