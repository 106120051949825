import React from 'react'
import { Button, InputGroup, Form, Modal } from 'react-bootstrap';
import * as Icon from "react-bootstrap-icons";
import SelectTable from './SelectTable';
import { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";

const SetupHashtagGroup = (props) => {
    let navigate = useNavigate();
    const add_edit_screen = () => {
      navigate("/setup/content/hashtagGroup/createGroup"); 
    }
    return (
      <div>
        <div>
          <div>
            <InputGroup className="mb-3 search-inside">
              <Form.Control
                placeholder="Search Hashtag Groups"
                aria-label="Username"
                aria-describedby="basic-addon1"
              />
              <InputGroup.Text id="basic-addon1">
                <Icon.Search/>
              </InputGroup.Text>
            </InputGroup>
            <div className='right-buttons'>
              <span>Sort By <span className='sort-text mx-1' >Date Added <Icon.CaretDownFill color="#64B6E7" /></span></span>
              <Button style={{marginLeft: '60px'}} onClick={add_edit_screen} className='common-setup-button' variant="primary">+ Hashtag Groups</Button>
            </div>
          </div>
          {/* <div>
            <SelectTable tableData="hashtagGroup" />
          </div> */}
        </div>
      </div>
    )
}

export default SetupHashtagGroup;