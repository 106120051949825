import Page from "../Page";
import React from "react";

const Dashboard = (props) => {
  props.pageTypeHandler("Dashboard");

  return (
    <Page title={props.title}>
      <h1>Dashboard</h1>
    </Page>
  );
};

export default Dashboard;
