/* eslint-disable */
import {
  Container,
  Row,
  Col,
  Card,
  Form,
  Badge,
  Button,
  ButtonGroup, Stack, OverlayTrigger, Tooltip
} from "react-bootstrap";
import * as Icon from "react-bootstrap-icons";
import _ from "lodash";
import React, { useState, useEffect, useCallback } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import PerfectScrollbar from "react-perfect-scrollbar";
import "react-perfect-scrollbar/dist/css/styles.css";
import LeadTabs from "../crm-list/leads/tab-components/LeadTabs";
import {
  FloatingMenu,
  MainButton,
  ChildButton,
} from "react-floating-button-menu";
import Offcanvas from 'react-bootstrap/Offcanvas';
import { useNavigate } from "react-router-dom";

import "./CRMList.css";

//Redux-imports
import { useDispatch, useSelector } from "react-redux";
import {
  fetchAllLeadTypeDetails,
  fetchAllLeadDetails,
  fetchDealLeadDetails,
  fetchDroppedLeadDetails,
  fetchEngagedLeadDetails,
  fetchLeadStagesList,
  fetchNewLeadDetails,
  fetchQuoteLeadDetails,
  fetchWonLeadDetails,
  loadMoreLeadDetails,
  updateLeadsStage
} from "../../../../redux/actions/crmActions";
import { Link } from "react-router-dom";
import AddNote from "./leads/AddNote";
import AddTask from './leads/AddTask';
import AddMail from "./leads/AddMail";
import DropdownItem from "react-bootstrap/esm/DropdownItem";
import RefImg from "../../../../assets/referal.png";


const CRMList = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  //const authToken = props.authToken;

  const { setLeadListFromActions, authToken, leadStages, setLeadStages, columns, setColumns,
    stagesList, updateLeadStage } = props

  //const stagesList = useSelector((state) => state.crmLeads.stagesList);

  const allLeads = useSelector((state) => state.crmLeads.allLeads);
  const newLeads = useSelector((state) => state.crmLeads.newLeads);
  const engagedLeads = useSelector((state) => state.crmLeads.engagedLeads);
  const dealLeads = useSelector((state) => state.crmLeads.dealLeads);
  const quoteLeads = useSelector((state) => state.crmLeads.quoteLeads);
  const wonLeads = useSelector((state) => state.crmLeads.wonLeads);
  const droppedLeads = useSelector((state) => state.crmLeads.droppedLeads);
  //const allLeadDetails = useSelector((state) => state.crmLeads.allLeadDetails);


  let loadMoreCounter = null;
  let leadTypeTracker = null;
  let contact = {};

  const [isOpen, setIsOpen] = useState(false);
  const [floatBtn, setFloatBtn] = useState(false);
  const [leadChecked, setLeadChecked] = useState(false);
  const [showOffcanvas, setShowOffcanvas] = useState(false);
  const [showItem, setShowItem] = useState(null);
  const [action, setAction] = useState("");
  const [offcanvasClose, setOffcanvasClose] = useState(true);
  // const [leadStages, setLeadStages] = useState({});
  // const [columns, setColumns] = useState({});

  /* const arrayToObject1 = (arr, key) => {
    return arr.reduce((obj, item) => {
      if (allLeadDetails.length > 0) {
        allLeadDetails.map(leadDetails => {
          if (leadDetails.leadType == item.lead_type) {
            item['items'] = leadDetails.data;
          }
        });
      } else {
        item['items'] = [];
      }

      obj[item[key]] = item;
      //console.log("obj[item[key]]", obj[item[key]]);
      return obj;
    }, {})
  }

  useEffect(() => {
    // console.log("leadStages", leadStages);
    //console.log("stagesList", stagesList);
    // console.log("arrayToObject1", arrayToObject1(stagesList, 'id'));
    setLeadStages({});
    setColumns({});
    let stages = arrayToObject1(stagesList, 'order');
    //console.log("stages", stages);
    setLeadStages(stages);
    setColumns(stages);
  }, [stagesList, allLeadDetails]); 

  useEffect(() => {
    if (stagesList) {
      stagesList.map(item => {
        dispatch(fetchAllLeadTypeDetails(authToken, +item.lead_type, +item.total))
      });
    }
  }, [stagesList]); */

  //  console.log("leads", leadStages);


  const onDragEnd = (result, columns, setColumns) => {
    //console.log("result", result);
    if (!result.destination) return;
    const { source, destination } = result;

    if (source.droppableId !== destination.droppableId) {
      const sourceColumn = columns[source.droppableId];
      const destColumn = columns[destination.droppableId];
      // console.log("sourceColumn", columns[source.droppableId]);
      // console.log("destColumn", destination.droppableId);
      const sourceItems = [...sourceColumn.items];
      const destItems = [...destColumn.items];
      const [removed] = sourceItems.splice(source.index, 1);
      destItems.splice(destination.index, 0, removed);
      setColumns({
        ...columns,
        [source.droppableId]: {
          ...sourceColumn,
          items: sourceItems,
        },
        [destination.droppableId]: {
          ...destColumn,
          items: destItems,
        },
      });
    } else {
      const column = columns[source.droppableId];
      const copiedItems = [...column.items];
      const [removed] = copiedItems.splice(source.index, 1);
      copiedItems.splice(destination.index, 0, removed);
      setColumns({
        ...columns,
        [source.droppableId]: {
          ...column,
          items: copiedItems,
        },
      });
    }


    var myData = stagesList.map((item) => {
      return item;
    });
    let destId = myData.filter(item => {
      if (item.order == result.destination.droppableId) {
        return item;
      }
    });
    let srcId = myData.filter(item => {
      if (item.lead_type == result.source.droppableId) {
        return item;
      }
    });
    // console.log("dest ", destId);
    // console.log("src ", srcId);
    const finalResult = {
      source: srcId[0].lead_type,
      destination: destId[0].lead_stage, //dropData[0].lead_stage
      contact_id: result.draggableId,
    };
    //console.log("finalResult", finalResult);
    updateLeadStage(finalResult.destination, finalResult.contact_id);
  };


  const loadMoreHandler = (type, limit, total) => {
    /*   if (leadTypeTracker === type) {
        loadMoreCounter = loadMoreCounter + 3;
        // console.log("leadTypeTracker", leadTypeTracker);
        // console.log("loadMoreCounter", loadMoreCounter);
        dispatch(
          loadMoreLeadDetails(authToken, leadTypeTracker, loadMoreCounter)
        );
      } else {
        leadTypeTracker = type;
        loadMoreCounter = limit + 3;
        // console.log("leadTypeTracker", leadTypeTracker);
        // console.log("loadMoreCounter", loadMoreCounter);
        dispatch(
          loadMoreLeadDetails(authToken, leadTypeTracker, loadMoreCounter)
        );
      } */
    //let limit1 = limit > 3 ? limit-3 : limit;
    let startLimit = total - limit;
    //console.log("loadMore type", type, "total", total, "loadMore limit", limit, "startLimit", startLimit);
    dispatch(loadMoreLeadDetails(authToken, type, startLimit > 3 ? startLimit - 3 : 0, startLimit < 3 ? startLimit : 3));
  };

  const scoreCountHandler = (count) => {
    if (count < 25) {
      return "";
    } else if (count >= 25 && count < 50) {
      return " 🔥";
    } else if (count >= 50 && count < 75) {
      return " 🔥🔥";
    } else {
      return " 🔥🔥🔥";
    }
  };


  const handleOffcanvasClose = () => {
    setOffcanvasClose(false);
    setShowOffcanvas(false);
    setLeadListFromActions();
  }


  const getChars = (str) => {
    const firstLetters = str.split(' ').slice(0, 2).map(word => word[0]).join('').toUpperCase();
    return firstLetters;
  }


  const doActionLead = (item, val) => {
    setShowItem(null);
    setAction(val);
    contact["contact_ids"] = [];
    contact["contact_ids"].push(item);
    setShowItem(contact);

    if (val === 'note') {
      setAction(val);
    } else if (val === 'task') {
      setAction(val);
    }
    setShowOffcanvas(true);
    setIsOpen(null);
  }

  const handleClose = () => setShowOffcanvas(false);

  /*  useEffect(() => {
     dispatch(fetchLeadStagesList(authToken));
 
     // dispatch(fetchAllLeadDetails(authToken));
     // dispatch(fetchNewLeadDetails(authToken));
     // dispatch(fetchEngagedLeadDetails(authToken));
     // dispatch(fetchDealLeadDetails(authToken));
     // dispatch(fetchQuoteLeadDetails(authToken));
     // dispatch(fetchWonLeadDetails(authToken));
     // dispatch(fetchDroppedLeadDetails(authToken));
     //setColumns(leadStages);
   }, []); */

  // useEffect(() => {
  //   if (props.checked === 0) {
  //     setLeadChecked(false);
  //   }
  // }, [props.checked]);

  //console.log("column", columns);

  const renderNoteTooltip = (props) => {

    document.getElementById('note').tooltip();
  };

  const renderMailTooltip = (props) => {
    document.getElementById('mail').tooltip();
  };

  const renderTaskTooltip = (props) => {
    document.getElementById('task').tooltip();
  };

  return (
    <PerfectScrollbar>
      <Container className="crm-list-container ">
        <Row className="stages-row">

          {!_.isEmpty(columns) && (

            <DragDropContext
              onDragEnd={(result) => onDragEnd(result, columns, setColumns)}
            >
              {Object.entries(columns).map(([columnId, column], index) => {
                return (
                  <Col className="col-3 stages" key={columnId} id={columnId}>
                    <div className="stage-title">{column?.lead_stage}&nbsp;&nbsp;({column?.total})</div>
                    <PerfectScrollbar>
                      <div className="stage-body">
                        <Droppable
                          droppableId={column?.order.toString()}
                          key={columnId}
                          isDropDisabled={column?.lead_stage == 'All' ? true : false}
                          isDragging={column?.lead_stage == 'All' ? false : true}
                          isDropEnabled={false} //column?.lead_stage == 'All' ? false : true
                        >
                          {(provided, snapshot) => {
                            return (
                              <div
                                {...provided.droppableProps}
                                ref={provided.innerRef}
                                style={{
                                  background: snapshot.isDraggingOver
                                    ? "#F9F1E7"
                                    : "#F8F8F8",
                                  width: 330,
                                  minHeight: 100,
                                }}
                              >
                                {column?.items && column.items.map((item, index) => {
                                  return (
                                    <Draggable
                                      key={item.contact_id}
                                      draggableId={item?.contact_id.toString()}
                                      index={index}
                                      isDragDisabled={column?.lead_stage == 'All' ? true : false}
                                      isDragging={column?.lead_stage == 'All' ? false : true}
                                    >
                                      {(provided, snapshot) => {
                                        return (
                                          <div
                                            //className=""
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                          >
                                            <Card
                                              onMouseEnter={() => {
                                                if (column?.lead_stage !== 'All') {
                                                  if (
                                                    item.contact_id == floatBtn
                                                  ) {
                                                    setFloatBtn(null);
                                                  } else {
                                                    setFloatBtn(item.contact_id);
                                                  }
                                                }
                                              }
                                              }
                                              onMouseLeave={() => {
                                                if (column?.lead_stage !== 'All') {
                                                  if (
                                                    item.contact_id == floatBtn
                                                  ) {
                                                    setFloatBtn(null);
                                                  } /* else {
                                                    setFloatBtn(item.contact_id);
                                                  } */
                                                }
                                              }
                                              }
                                              className={
                                                _.includes(
                                                  props.leadList.contact_ids,
                                                  item.contact_id
                                                )
                                                  ? "selected-card"
                                                  : ""
                                              }
                                            >
                                              <Card.Body>
                                                <div className="score-box">
                                                  Score :{" "}
                                                  {item.lead_score +
                                                    `${scoreCountHandler(
                                                      item.lead_score
                                                    )}`}
                                                </div>
                                                {item.lead_type == "sip" ?
                                                  <div className="score-box sip-box">
                                                    SIP CONTINUITY
                                                  </div>
                                                  : ""}
                                                <Card.Title className="show-pointer">
                                                  <Form.Check
                                                    type="checkbox"
                                                    className="check-box"
                                                    //checked={props.check}
                                                    defaultChecked={false}
                                                    checked={
                                                      _.includes(
                                                        props.leadList.contact_ids,
                                                        item
                                                      ) ? true : false}
                                                    onChange={(e) => {
                                                      // setLeadChecked(
                                                      //   !leadChecked
                                                      // );
                                                      props.handleCheck(
                                                        e,
                                                        item
                                                      );
                                                    }}
                                                  />
                                                  <div className="title" onClick={(e) => doActionLead(item, 'single')}>
                                                    {item.user_name}
                                                  </div>
                                                  {props.companyName && (
                                                    <React.Fragment onClick={(e) => doActionLead(item, 'single')}>
                                                      {item.company && (
                                                        <>
                                                          <div className="vr-divider">
                                                            <hr />
                                                          </div>
                                                          <div className="sub-title">
                                                            {item.company}
                                                          </div>
                                                        </>
                                                      )}
                                                    </React.Fragment>
                                                  )}
                                                </Card.Title>
                                                <hr />
                                                <Card.Text className="show-pointer" onClick={(e) => doActionLead(item, 'single')}>
                                                  {props.lastEngagement && (
                                                    <>
                                                      {item.refr_name !== "" && (
                                                        <img style={{width:"20px"}} src={RefImg} alt="" />
                                                      )}
                                                      {item.activity}
                                                      <div className="date">
                                                        - {item.activity_date}
                                                      </div>
                                                    </>
                                                  )}
                                                </Card.Text>
                                                <div className="footer">
                                                  {props.tasks && (
                                                    <>
                                                      <div className="to-do">
                                                        To do: {(item.task_category === "Task" || item.task_category === "Call Log") ? item.task_title : ""}
                                                      </div>
                                                      <Badge
                                                        className={(item.task_status === "Completed" || item.task_category === "Call Log") ? 'due text-primary' : 'due text-danger'}
                                                        bg="light"
                                                      >
                                                        {item.task_category === "Task" ? item.task_status : ""}
                                                      </Badge>
                                                    </>
                                                  )}
                                                </div>
                                                {((floatBtn === item.contact_id && column?.lead_stage !== 'All')
                                                  ? true
                                                  : false) && (
                                                    <div className="floating-btn">
                                                      <FloatingMenu
                                                        slideSpeed={500}
                                                        direction="left"
                                                        spacing={8}
                                                        isOpen={
                                                          isOpen ===
                                                            item.contact_id
                                                            ? true
                                                            : false
                                                        }
                                                      >
                                                        <MainButton
                                                          iconResting={
                                                            <Icon.Plus
                                                              style={{
                                                                fontSize: 20,
                                                              }}
                                                            />
                                                          }
                                                          iconActive={
                                                            <Icon.X
                                                              style={{
                                                                fontSize: 15,
                                                              }}
                                                            />
                                                          }
                                                          backgroundcolor="black"
                                                          onClick={() => {
                                                            if (
                                                              item.contact_id ==
                                                              isOpen
                                                            ) {
                                                              setIsOpen(null);
                                                            } else {
                                                              setIsOpen(
                                                                item.contact_id
                                                              );
                                                            }
                                                          }}
                                                          size={32}
                                                        />
                                                        {/* <OverlayTrigger
                                                          placement="right"
                                                          delay={{ show: 250, hide: 400 }}
                                                          overlay={renderNoteTooltip}
                                                        > */}
                                                        <ChildButton
                                                          id="note"
                                                          title="Add Note"
                                                          icon={
                                                            <Icon.Sticky
                                                              style={{
                                                                fontSize: 15,
                                                              }}
                                                            />
                                                          }
                                                          backgroundcolor="white"
                                                          size={32}
                                                          onClick={(e) => doActionLead(item, 'note')}
                                                          onMouseEnter={renderNoteTooltip}
                                                        />
                                                        {/* </OverlayTrigger> */}
                                                        <ChildButton
                                                          id="mail"
                                                          title="Send Mail"
                                                          icon={
                                                            <Icon.Envelope
                                                              style={{
                                                                fontSize: 15,
                                                              }}
                                                            />
                                                          }
                                                          backgroundcolor="white"
                                                          size={32}
                                                          onClick={(e) => doActionLead(item, 'mail')}
                                                          onMouseEnter={renderMailTooltip}
                                                        />
                                                        {/*  <ChildButton
                                                          icon={
                                                            <Icon.Telephone
                                                              style={{
                                                                fontSize: 15,
                                                              }}
                                                            />
                                                          }
                                                          backgroundcolor="white"
                                                          size={32}
                                                        /> */}
                                                        <ChildButton
                                                          id="task"
                                                          title="Add Task"
                                                          icon={
                                                            <Icon.ClipboardCheck
                                                              style={{
                                                                fontSize: 15,
                                                              }}
                                                              nativecolor="black"
                                                            />
                                                          }
                                                          backgroundcolor="white"
                                                          onClick={(e) => doActionLead(item, 'task')}
                                                          size={32}
                                                          onMouseEnter={renderTaskTooltip}
                                                        />
                                                        {/*  <ChildButton
                                                          icon={
                                                            <Icon.People
                                                              style={{
                                                                fontSize: 15,
                                                              }}
                                                              nativecolor="black"
                                                            />
                                                          }
                                                          backgroundcolor="white"
                                                          size={32}
                                                        /> */}
                                                      </FloatingMenu>
                                                    </div>
                                                  )}
                                              </Card.Body>
                                            </Card>
                                          </div>
                                        );
                                      }}
                                    </Draggable>
                                  );
                                })}
                                {provided.placeholder}
                              </div>
                            );
                          }}
                        </Droppable>
                        {column?.items?.length > 0 ? (
                          <div
                            className="load-more"
                            onClick={() => {
                              loadMoreHandler(
                                parseInt(column.lead_type),
                                column.items.length,
                                column.total
                              );
                              //console.log("load more limit", column.items.length, "column.lead_type", +column.lead_type);
                            }
                            }
                          >
                            {column.items.length > 2 && column.items.length < +column.total ? 'Load more' : ""}
                          </div>
                        ) : (
                          <div className="no-data">
                            <span>
                              <Icon.Inbox />
                            </span>
                            <br />
                            No Data
                          </div>
                        )}
                      </div>
                    </PerfectScrollbar>
                  </Col>
                );
              })}
            </DragDropContext>
          )}
        </Row>

        <Offcanvas show={showOffcanvas} onHide={handleClose} placement="end" className="offcanvas-section">
          <Offcanvas.Header> {/* closeButton */}
            {(action === 'note' || action === 'task' || action === 'mail') && showItem && (<div className="container-body">
              <Stack direction="horizontal" gap={2} className="ml-5">
                {action === 'note' && (
                  <>
                    <Icon.Sticky
                      style={{
                        fontSize: 15,
                        color: '#45BCD2',
                      }}
                    />
                    <div className="fs-5 fw-bold text-color mb-2 p-2">Adding Note To</div>
                  </>
                )}
                {action === 'task' && (
                  <>
                    <Icon.ClipboardCheck
                      style={{
                        fontSize: 15,
                        color: '#45BCD2',
                      }}
                    />
                    <div className="fs-5 fw-bold text-color mb-2 p-2">Adding Task To</div>
                  </>
                )}
                {action === 'mail' && (
                  <>
                    <Icon.Envelope
                      style={{
                        fontSize: 15,
                        color: '#45BCD2',
                      }}
                    />
                    <div className="fs-5 fw-bold text-color mb-2 p-2">Sending Mail To</div>
                  </>
                )}
              </Stack>
              <Row>
                {showItem.contact_ids.length <= 3 && (
                  <Stack direction="horizontal" gap={1}>
                    {showItem.contact_ids.map((item, index) => {
                      return (
                        <div key={index}>
                          <div className='circle1'>{getChars(item.user_name)}</div>
                          <div className='fs-6 fw-normal wordBreak'>{item.user_name}</div>
                        </div>
                      )
                    }
                    )}
                  </Stack>
                )}
              </Row>
            </div>
            )}

            {action === 'single' && showItem && (
              <Stack direction="horizontal" gap={3} className='lead-tab-header d-flex justify-content-center'>
                {showItem.contact_ids.map((item, index) => {
                  return (
                    <Stack direction="horizontal" gap={3} key={index}>
                      <div className='circle'>{getChars(item.user_name)}</div>
                      <div className="mb-1">
                        <div className='fs-4 fw-bold'>{item.user_name ? item.user_name : ""}</div>
                        <div className='fs-6 fw-normal'>{item.email_id ? item.email_id : ""}</div>
                      </div>
                    </Stack>
                  )
                }
                )}
                <div className='text-color mb-4 show-pointer mt-3' onClick={() => navigate(`/crm/leads/${showItem.contact_ids[0]?.contact_id}`)}>Expand</div>
              </Stack>
            )}
          </Offcanvas.Header>
          <Offcanvas.Body >
            {action === 'single' && (
              <LeadTabs contact={showItem} authToken={props.authToken} from="bulk" />
            )}
            <div className="p-4">
              {action === 'note' && (
                <AddNote contactid={showItem} authToken={props.authToken} from="bulk" setDefault={() => handleOffcanvasClose()} />
              )}
              {action === 'task' && (
                <AddTask contactId={showItem} authToken={props.authToken} from="bulk" setDefault={() => handleOffcanvasClose()} />
              )}
              {action === 'mail' && (
                <AddMail contactId={showItem} authToken={props.authToken} from="bulk" setDefault={() => handleOffcanvasClose()} />
              )}
            </div>
          </Offcanvas.Body>
        </Offcanvas>

      </Container>
    </PerfectScrollbar>
  );
};

export default CRMList;
