/* eslint-disable */
import { Form, Button, Card } from "react-bootstrap";
import Bookmark from "../../../Bookmark";

import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

//Redux-imports
import { useDispatch, useSelector } from "react-redux";
import { getShareLink } from "../../../../redux/actions/crmActions";

const ShareAssetCard = (props) => {
  //console.log("props.content", props.content);
  const dispatch = useDispatch();

  let navigate = useNavigate();

  useEffect(() => {
    dispatch(
      getShareLink(props.authToken, {
        action: props.content[0].type,
        content_id: props.content[0].content_id,
      })
    );
  }, []);

// console.log("props.content", props.content);

  return (
    <>
      {props.content &&
        props.content.map((content, index) => {
          return (
            <Card className="asset-card" key={index}>
              <Form>
                <Card.Img variant="top" src={content.thumb_image} />

                <Card.Body>
                  <div className="card-body-wrapper">
                    <div className="card-tags">
                      <div>{content.content_type}</div>
                      {content.contenttype_status && (
                        <div>{content.contenttype_status}</div>
                      )}
                    </div>
                    <Card.Title>
                      <div
                        className="solution_types"
                        title={
                          content.solution_types.length > 1 &&
                          content.solution_types.map((type) => {
                            return " " + type.it_type;
                          })
                        }
                      >
                        {content.solution_types.length > 1
                          ? content.solution_types.map((type) => {
                              return <>{type.it_type + ", "}</>;
                            })
                          : content.solution_types.map((type) => {
                              return <>{type.it_type}</>;
                            })}
                      </div>
                      <Bookmark
                        authToken={props.authToken}
                        bookmarkStatus={content.bookmark}
                        id={content.content_id}
                        type={content.type}
                        share={true}
                      />
                    </Card.Title>

                    <a href={() => false} title={content.content_title}>
                      <Card.Text>{content.content_title}</Card.Text>
                    </a>
                    {content.activity_date && (
                      <Card.Title>
                        Published
                        <span>{content.activity_date}</span>
                      </Card.Title>
                    )}
                  </div>
                  <div className="hr-divider">
                    <hr />
                  </div>
                  {(props.userType === "P" || props.userType === "S") ? (
                    <div className="card-footer-action">
                      {content.status &&
                        (content.status !== "Published" ? (
                          <Button size="sm" variant="outline-info">
                            Publish
                          </Button>
                        ) : (
                          <Button size="sm" variant="secondary" disabled>
                            {content.status}
                          </Button>
                        ))}

                      {content.partner_category &&
                        content.partner_category.map((category) => {
                          const partnerName = category.charAt(0);
                          return (
                            <>
                              <div className="circle" title={category}>
                                {partnerName}
                              </div>
                            </>
                          );
                        })}

                      {content.partner_category.length > 1 && (
                        <div
                          className="count"
                          title={
                            content.partner_category &&
                            content.partner_category.map(
                              (category) => " " + category
                            )
                          }
                        >
                          {content.partner_category.length - 2 > 0
                            ? `+${content.partner_category.length - 2}`
                            : ""}
                        </div>
                      )}
                    </div>
                  ) : (
                    <div className="card-footer-action">
                      <div className="share-btn">Sharing</div>
                    </div>
                  )}
                </Card.Body>
              </Form>
            </Card>
          );
        })}
    </>
  );
};

export default ShareAssetCard;
