// import Page from "../../Page";
import * as Icon from "react-bootstrap-icons";
import React, { useEffect } from "react";
import { Table, Form, Row, Col, Button, Modal } from "react-bootstrap";
import Multiselect from "multiselect-react-dropdown";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  addLeaderBoard,
  deleteLeaderBoard,
  getLeaderboard,
} from "../../redux/actions/setupActions";
import Toast from "../shared/Toast";
import Loading from "../Loading";

const SetupLeaderBoard = (props) => {
  const dispatch = useDispatch();

  const [leadValues, setLeadValues] = useState([
    {
      activity_type: "",
      activity_type_name: "",
      condition_type: "",
      condition_dropdown: [],
      condition_type_name: "",
      score: "",
    },
  ]);
  const [show, setShow] = useState(false);
  const [deleteData, setDeleteData] = useState(null);
  const leadListing = useSelector(
    (state) => state?.setupScreen.leaderboard_listing
  );
  const leadDropdown = useSelector(
    (state) => state?.setupScreen.leaderboard_dropdown
  );
  
  const loading = useSelector((state) => state?.setupScreen.loading);

  const handleClose = () => {
    setDeleteData(null);
    setShow(false);
  };
  const handleShowPopup = (id) => {
    setShow(true);
    setDeleteData(id);
  };
  const deleteEvent = () => {
    // console.log("delete event", deleteData);
    dispatch(deleteLeaderBoard(props.authToken, deleteData));
    handleClose();
  };

  useEffect(() => {
    dispatch(getLeaderboard(props.authToken, "activity_condition_dropdown"));
    dispatch(getLeaderboard(props.authToken, "list"));
  }, []);

  

  useEffect(() => {
    setLeadValues(
      leadListing?.length > 0
        ? leadListing
        : [
            {
              activity_type: "",
              activity_type_name: "",
              condition_type: "",
              condition_dropdown: [],
              condition_type_name: "",
              score: "",
            },
          ]
    );
  }, [leadListing]);

  // console.log(leadListing, "dropdown");

  const handleActionSelect =  (selectedItem, index) => {
  
    let obj = {
      target: {
        name: "",
        value: "",
        id: "",
        condition_dropdown: [],
      },
    };
    obj.target.name = "Action";
    obj.target.value = [{ activity: selectedItem[0].activity }];
    obj.target.id = selectedItem[0].id;

    const filterItem = Object.keys(leadDropdown?.condition_dropdown
      ).indexOf(selectedItem[0].activity); 
    const filterValue = Object.values(leadDropdown?.condition_dropdown)[filterItem][0]; 
    obj.target.condition_dropdown = filterValue;

    onchangeAddInput(obj, index);

  };
  const handleConditionSelect = (selectedItem, index) => {
    let obj = {
      target: {
        name: "",
        value: "",
        id: "",
      },
    };
    obj.target.name = "Condition";
    obj.target.value = [{ condition: selectedItem[0].condition }];
    obj.target.id = selectedItem[0].id;
    onchangeAddInput(obj, index);
  };
  const handleAdd = (e) => {
    setLeadValues([
      ...leadValues,
      {
        activity_type: "",
        activity_type_name: "",
        condition_type: "",
        condition_dropdown: [],
        condition_type_name: "",
        score: null,
      },
    ]);
  };


  const onchangeAddInput = (event, index) => {
    const values = [...leadValues];
    if (event.target.name === "Action") {
      values[index].activity_type_name = event.target.value;
      values[index].activity_type = event.target.id;
      values[index].condition_dropdown = event.target.condition_dropdown;
      values[index].condition_type_name = [];
      values[index].condition_type = "";
    } else if (event.target.name === "Condition") {
      values[index].condition_type_name = event.target.value;
      values[index].condition_type = event.target.id;
    } else if (event.target.name === "Score") {
      values[index].score = parseInt(event.target.value);
    }
    setLeadValues(values);
  };

  const submitHandler = async () => {
    await dispatch(addLeaderBoard(props.authToken, leadValues));
  };

  // console.log(leadValues, "finalleadValues");
  return (
    <div className="lead-row">
      {loading && <Loading />}

      <Row>
        <Col>
          <div className="top-heading-bar" style={{ paddingLeft: "20px" }}>
            <ul>
              <li style={{ width: "28%" }}>
                <span>Activity Type</span>
              </li>
              <li style={{ width: "29%" }}>
                <span>Condition</span>
              </li>
              <li style={{ width: "24%" }}>
                <span>Score</span>
              </li>
              <li className="ms-6" style={{ width: "5%" }}>
                <div className="add-leads " onClick={handleAdd}>
                  <Icon.Plus color="#ffffff" size={20} />
                </div>
              </li>
            </ul>
          </div>
        </Col>
      </Row>
      {leadValues.map((item, index) => {
        return (
          <Table>
            <tbody>
              <tr>
                <td
                  className="lead-channel"
                  // style={{ width: "25%", textAlign: "center" }}
                >
                  <Multiselect
                    className="drop-down my-1"
                    options={leadDropdown?.activity_dropdown}
                    selectedValues={item?.activity_type_name}
                    // onSelect={onSelectCategory}
                    // onRemove={onRemoveCategory}
                    onSelect={(selectedItem, selectedList) => {
                      if (selectedItem.length > 0) {
                        handleActionSelect(selectedItem, index);
                      }
                    }}
                    singleSelect
                    displayValue="activity"
                    placeholder="Select Channel"
                  />
                </td>
                <td
                  className="lead-condition"
                  // style={{ width: "25%", textAlign: "center" }}
                >
                  <Multiselect
                    className="drop-down my-1"
                    options={
                      item?.condition_dropdown ? item?.condition_dropdown : []
                    }
                    onSelect={(selectedItem, selectedList) => {
                      if (selectedItem.length > 0) {
                        handleConditionSelect(selectedItem, index);
                      }
                    }}
                    selectedValues={item?.condition_type_name}
                    // disable={
                    //   item?.condition_dropdown &&
                    //   item?.condition_dropdown.length > 0
                    //     ? false
                    //     : true
                    // }
                    // onSelect={onSelectCategory}
                    // onRemove={onRemoveCategory}
                    singleSelect
                    displayValue="condition"
                    placeholder="Select Condition"
                  />
                </td>
                <td className="lead-score-points">
                  <Form.Control
                    className="numeric-score-input my-1"
                    type="number"
                    placeholder="Score"
                    value={item?.score}
                    onChange={(e) => {
                      onchangeAddInput(e, index);
                    }}
                    name="Score"
                  />
                </td>
                <td className="delete-icon">
                  <div className="card-footer-action list-card d-flex">
                    <span
                      className="edit-icon my-auto"
                      onClick={() => handleShowPopup(item.id)}
                    >
                      <Icon.TrashFill color="#64B6E7" />
                    </span>
                  </div>
                </td>
              </tr>
            </tbody>
          </Table>
        );
      })}
      <Button
        variant="primary"
        className="save-button"
        size="sm"
        style={{ width: "25%" }}
        onClick={submitHandler}
      >
        Submit
      </Button>
      <Modal className="delete-popup" show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Delete</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to delete ?</Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={deleteEvent}>
            Delete
          </Button>
          <Button variant="secondary" onClick={handleClose}>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default SetupLeaderBoard;
