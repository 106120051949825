/* eslint-disable */
import React, { useEffect, useState } from "react";
import { Stack, Form, Col, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { appUserActivity, fetchTaskList, taskManager } from "../../../../../../redux/actions/crmActions";
import "./Leadtabs.css";
import { useParams } from 'react-router';
import moment from "moment";
import * as Icon from "react-bootstrap-icons";
import DeleteModal from "../../../../../shared/DeleteModal";
import { useNavigate } from "react-router-dom";

const Tasks = (props) => {

    const { id } = useParams();
    const { authToken, contactId } = props;

    const [deleteDialog, setDeleteDialog] = useState(false);
    const [item, setItem] = useState(null);

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const taskList = useSelector((state) => state.crmLeads.tasksList);

    useEffect(() => {
        let idd = id !== undefined ? id : contactId.contact_ids[0].contact_id;
        //dispatch(fetchTaskList(authToken, idd));
        return () => {
            // console.log("activity cleanup", props);      
        };
    }, []);


    const getDate = (date) => {
        if (date !== "" && date !== null && date !== "0000-00-00 00:00:00") {
            let d = moment(date).format('DD');
            return d;
        }
    }

    const getMonth = (date) => {
        if (date !== "" && date !== null && date !== "0000-00-00 00:00:00") {
            let m = moment(date).format('MMM');
            return m.toUpperCase();
        }
    }

    const doAction = (item, val) => {
        setItem(item);
        if (val === 'edit') {
            let idd = id ? id : contactId.contact_ids[0].contact_id;
            navigate(`/crm/leads/${idd}/task/${'edit' + '-' + item.id}`);
        } else if (val === 'delete') {
            setDeleteDialog(true);
        }
    }

    const deleteConfirmedTask = () => {
        let idd = id ? id : contactId.contact_ids[0].contact_id;
        let body = { "action": "delete", "id": item.id };
        dispatch(taskManager(authToken, body, 'deleteTask', idd));
        setDeleteDialog(false);
        setItem(null);
    }

    return (
        <div className="offcanvas-body-background">
            {taskList && taskList.length > 0 ? (
                <div className="activites">
                    {taskList.map((item, i) => {
                        if (item.task_category == "Task") {
                            return (
                                <>
                                    <Row className="m-2 p-4" key={i}>
                                        <Col sm lg={2} className="p-1">
                                            <div className="fs-6 fw-bold">{getDate(item.creation_date)} <br /> {getMonth(item.creation_date)}</div>
                                        </Col>
                                        <Col sm lg={10} style={{ backgroundColor: '#fff' }}>
                                            <Row className="p-2 lh-base">
                                                <Col sm md={10}>
                                                    <div className="act-title"><Icon.ClipboardCheck />&nbsp;<strong>Task assigned to</strong>&nbsp;&nbsp;{item.first_name}&nbsp;{item.last_name}</div>
                                                    <div className="mt-2 act-notes">{item.notes}</div>

                                                </Col>
                                                <Col sm md={2}>
                                                    <Row>
                                                        <Icon.Trash className="text-color" onClick={(e) => doAction(item, 'delete')} />
                                                    </Row>
                                                    <Row>
                                                        <Icon.Pencil className="text-color mt-3" onClick={(e) => doAction(item, 'edit')} />
                                                    </Row>
                                                </Col>
                                            </Row>
                                            <Row className="p-2 lh-base mt-2">
                                                <Col sm md={10}>
                                                    <div className="act-subhead">DUE</div>
                                                    {item.due_date !== '0000-00-00 00:00:00' ? <div className="fw-bolder act-datetime mt-1">{moment(item.due_date).format("hh:mm A DD MMM yyyy")}</div> : " "}
                                                </Col>
                                                <Col sm md={2}>
                                                    <div className="act-subhead">STATUS</div>
                                                    {item.task_status && (
                                                        <div className={item.task_status == 'due' ? 'fw-bolder text-danger' : 'fw-bolder text-color'}>{item.task_status}
                                                        </div>)}
                                                </Col>
                                            </Row>
                                            {item.comments.length > 0 && (
                                                <>
                                                    {item.comments.map((item1, i) => {
                                                        return (
                                                            <Row className="comment-section d-flex justify-content-center p-2" key={i}>
                                                                <Col sm lg={1}>
                                                                    <Icon.ChatSquare style={{ color: '#F6971A' }} />
                                                                </Col>
                                                                <Col sm lg={3}>
                                                                    <div className="act-title ms-1 me-1">{moment(item1.date).format('YYYY-MM-DD hh:mm A')}</div>
                                                                </Col>
                                                                <Col sm lg={8}>
                                                                    <div className="act-notes">{item1.comment}</div>
                                                                </Col>
                                                            </Row>
                                                        )
                                                    })}
                                                </>
                                            )}
                                        </Col>
                                    </Row>
                                </>
                            )
                        }
                    })
                    }
                </div>
            )
                :
                <div className="no-data mt-5">
                    <span>
                        <Icon.Inbox />
                    </span>
                    <br />
                    No Data
                </div>
            }

            <DeleteModal show={deleteDialog} hide={() => setDeleteDialog(false)} deleteItem={deleteConfirmedTask} />

        </div>
    )
}

export default Tasks;