// import "./styles.css";
// import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import * as Icon from "react-bootstrap-icons";
// import SubTable from "./SubTable"
// import vector from '../../assets/setup-icon/Vector.png';
import "bootstrap/dist/css/bootstrap.min.css";
import { useDispatch, useSelector } from "react-redux";
import { deleteCategory,setProductAttribute , deleteProductTypeData } from "../../redux/actions/setupActions";
import { useNavigate } from "react-router-dom";
import { Button, Modal} from 'react-bootstrap';
import { useState,useEffect } from "react";

export default function Table(props) {
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const [users, setUsers] = useState(props.categoryData);
  // const [parentId, setParentId] = useState("");
  const authToken = props.authToken;
  
  const [show, setShow] = useState(false);
  const [deleteData, setDeleteData] = useState(null);
  const handleClose = () => {
    setDeleteData(null);
    setShow(false);
  }
  const handleShowPopup = (id) => {
    setShow(true);
    setDeleteData(id);
  }
  
  // const toggleSubTable = (id) => {
  //   if (parentId !== id) {
  //     setParentId(id);
  //   } else {
  //     setParentId("");
  //   }
  // };
  
  const deleteEvent = () => {
    dispatch(deleteProductTypeData(authToken, deleteData, props.page));
    handleClose();
  };
  
  const edit_product_type = (name, sid, desc) => {
    dispatch(setProductAttribute(name, sid, desc));
    navigate("/setup/content/editProductType");
  }
  
  useEffect(() => {
    console.log('xyz',props.categoryData);
  }, []);
  
  return (
    <div className="App">
        <div className="table borderd">
              <div style={{ lineHeight: "35px" }} className="text-capitalize">
                    {users && users.map((user, index) => {
                            return (
                                <>
                                    <div
                                        className="row border-bottom">
                                        <div className="table-row-count drag-button">
                                            {props.page + index + 1 + "."}
                                        </div>
                                        <div className="text-aligning table-name">
                                            <span title={user.title}>{(user.title && user.title.slice(0, 20))}
                                            {((user.title && user.title.length > 20)) && <span>...</span>}</span>
                                        </div>
                                        <div className="text-aligning" style={{ width:"60%" }}>
                                            <span className="bordering-date">{user && user.date && user.date.slice(0, 10)}</span>
                                        </div>
                                        {/* <div className="edit-icon">
                                            <Icon.ImageFill color="#64B6E7" />
                                            <span className="px-1">view media</span>
                                        </div> */}
                                        <div className="column-styling">
                                            <span className="edit-icon" onClick={() => {
                                                if (props.subPageName === "productType") {
                                                    edit_product_type(user.title, user.id, user.description)
                                                } else {
                                                    console.log("Add Condition")
                                                }
                                            }}>
                                                <Icon.PencilSquare className="mr-3" color="#64B6E7" />
                                                Edit
                                            </span>
                                        </div>
                                        <div className="column-styling">
                                            <span className="edit-icon" onClick={() => { handleShowPopup(user.id) }}>
                                                <Icon.TrashFill color="rgba(33, 33, 33, 0.3)" />
                                            </span>
                                        </div>
                                    </div>
                                </>
                            )
                        }
                    )}
              </div>
        </div>
        <Modal className="delete-popup"  show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Delete</Modal.Title>
          </Modal.Header>
          <Modal.Body>Are you sure you want to delete ?</Modal.Body>
          <Modal.Footer>
            <Button variant="primary" onClick={deleteEvent}>
              Delete
            </Button>
            <Button variant="secondary" onClick={handleClose}>
              Cancel
            </Button>
          </Modal.Footer>
        </Modal>
    </div>
  );
}
