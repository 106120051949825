/* eslint-disable */
import { useRef, useState, useEffect, useContext } from "react";
import AuthContext from "../../../context/AuthProvider";
import axios from "axios";
import Page from "../../Page";
import { Container, Row, Col, Form, Button, Nav, Alert } from "react-bootstrap";
import * as Icon from "react-bootstrap-icons";
import logo from "../../../assets/logo.png";
import "./Login.css";
import { useNavigate } from "react-router-dom";

const LoginToken = (props) => {
  const authCtx = useContext(AuthContext);

  let navigate = useNavigate();

  const TOKEN_LOGIN_URL = authCtx.tokenLoginUrl;
  const [errMsg, setErrMsg] = useState("");

  const [show, setShow] = useState(false);
  const [authToken, setAuthToken] = useState("");

  //let tempUrl = `${window.location.href}`;

  const tokenLoginHandler = async (e) => {
    try {
      const response = await axios.post(TOKEN_LOGIN_URL, {
        token: localStorage.getItem("tokenLogin"),
      });
      if (response?.data?.statusCode === "200") {
        // console.log("response", response);
        authCtx.onLogin(response?.data);
        navigate("/AssetLibrary");
        // setTimeout(() => {
        //   }, 1000);
      } else if (response?.data?.statusCode === "403") {
        setShow(true);
        setErrMsg("Invalid Token");
      }
    } catch (err) {
      if (!err?.response) {
        setShow(true);
        setErrMsg("Invalid Token");
      }
    }
  };

  const otherLoginHandler = () => {
    localStorage.clear();
    window.location.reload();
  };

  useEffect(() => {
    setAuthToken(authCtx.auth);
    tokenLoginHandler();
  }, []);

  return (
    <>
      <Page title={props.title || "SalesPanda"}>
        <Row>
          <Col className="col-4" style={{ margin: "auto" }}>
            <Row style={{ textAlign: "center" }}>
              <Col>
                <br />
                <br />
                <img src={logo} alt="SalesPanda Logo" />
                <br />
                <br />
              </Col>
            </Row>
            <Row style={{ textAlign: "center" }}>
              <Col>
                <br />
                <h3 className="title">Login</h3>
                <br />
              </Col>
            </Row>
            <Container className="login">
              <Row>
                <Col>
                  {show && (
                    <Alert
                      variant="danger"
                      onClose={() => setShow(false)}
                      dismissible
                    >
                      <Alert.Heading>
                        <Icon.ExclamationTriangle />
                      </Alert.Heading>
                      <p>{errMsg}</p>
                    </Alert>
                  )}
                  {/* {show && ( */}
                    <Button
                      variant="info"
                      type="button"
                      onClick={otherLoginHandler}
                    >
                      Login with other options..!
                    </Button>
                  {/* )} */}
                </Col>
              </Row>
            </Container>
          </Col>
        </Row>
      </Page>
    </>
  );
};

export default LoginToken;
