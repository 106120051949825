import { useState, useEffect } from "react";
import Pagination from 'react-responsive-pagination';

//Redux-imports
import { useDispatch, useSelector } from "react-redux";
// import {
//   fetchHashtagListing,
//   fetchCategoryListing,
//   fetchContentListing,
//   fetchGlobalSearchListing,
//   fetchProductTypeListing,
//   fetchParentListing,
//   fetchPartnerCategoryListing
// } from "../../redux/actions/setupActions";
import { fetchDirectListing, fetchIndirectListing, fetchListDataListing, fetchListIndividualDataListing, fetchPickTemplateListing } from "../../../redux/actions/campaignAction";
import moment from "moment";

const PaginationNew = (props) => {
  const dispatch = useDispatch();
  const [currentPage, setCurrentPage] = useState(0);
  const totalData = Math.ceil(props.total_page / 20);

  const search_input = useSelector((state) => state.campaign.search_input);
  const show_enteries = useSelector((state) => state.campaign.show_enteries);
  let list_id = useSelector((state) => state.campaign.list_id);
  // const pageNum = useSelector((state) => state.campaign.currPage);
  // const sort_value = useSelector((state) => state.setupScreen.sort_value);
  const totalContactData = Math.ceil(props.total_page / show_enteries);
  const resetPage = useSelector((state) => state.assetLibrary.resetPage);

  function handlePageChange(page) {
      setCurrentPage(page);
      let currOffset = page;
      let from_time;
      let to_time;
      switch(props.currPage) {
      case 'direct':
        from_time = moment(props.startDate).format('DD-MM-YYYY')
        to_time = moment(props.endDate).format('DD-MM-YYYY')
        dispatch(fetchDirectListing(props.authToken, search_input, currOffset, props.timeFilter, from_time, to_time));
        break;
      case 'indirect':
        from_time = moment(props.startDate).format('DD-MM-YYYY')
        to_time = moment(props.endDate).format('DD-MM-YYYY')
        dispatch(fetchIndirectListing(props.authToken, search_input, currOffset, props.timeFilter, from_time, to_time));
          break;
      case 'list':
        dispatch(fetchListDataListing(props.authToken, search_input, currOffset, "", ""));
          break;
      case 'contact_list':
          dispatch(fetchListIndividualDataListing(props.authToken, list_id, search_input, currOffset, show_enteries));
          break;
      case 'list_modal':
          if (props.path === "list") {
            dispatch(fetchListDataListing(props.authToken, search_input, currOffset, "", ""));
          } else {
            dispatch(fetchListIndividualDataListing(props.authToken, list_id, search_input, currOffset, show_enteries));
          }    
          break;
      case 'run_template':
          dispatch(fetchPickTemplateListing(props.authToken, props.searchField, props.tabData, currOffset, props.currPage))
        break;  
      case 'push_template':
          dispatch(fetchPickTemplateListing(props.authToken, props.searchField, props.tabData, currOffset, props.currPage))
        break;  
      default:
        break;
    }
  }

    useEffect(() => {
      setCurrentPage(props.page);
    }, [props.page]);
    
  useEffect(() => {
    console.log("PageReset", resetPage);
  }, [resetPage]);

  return (
    <div className="w-50 full-width mt-3">
        <Pagination
            className="pagination justify-content-start"  
            total={props.currPage === "contact_list" || props.path === "contact" ? totalContactData : totalData}
            current={currentPage}
            onPageChange={(page) => handlePageChange(page)}
        />
    </div>
  );
};

export default PaginationNew;
