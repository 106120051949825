/* eslint-disable */
import React, { useRef } from 'react';
import { Row, Col, Breadcrumb, Form, Button, Image, Modal } from "react-bootstrap";
import * as Icon from "react-bootstrap-icons";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import * as XLSX from 'xlsx';
import { useSelector, useDispatch } from 'react-redux';
import { LeadImportMappingData, LeadImportDoc, fetchCrmImportTypes, exportImportedDataStatus } from '../../../../../redux/actions/crmActions';
import Toast from '../../../../shared/Toast';
import './Addleads.css';
import imgImport from '../../../../../assets/crm-import.png';
import Utils from '../../../../shared/utils';
import * as types from "../../../../../constants/ActionTypes";
import Loading from "../../../../Loading";

const Imports = (props) => {

    const { authToken } = props;

    const [data, setData] = useState([]);
    const [dbval, setDbval] = useState([]);
    const [arr, setArr] = useState([]);
    const [file, setFile] = useState(null);
    const [modal, setModal] = useState(false);
    const [downloadType, setDownloadType] = useState(0);
    const [breadcrumbStatus, setBreadcrumbStatus] = useState(0);


    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(3);
    const [upperPageBound, setUpperPageBound] = useState(3);
    const [lowerPageBound, setLowerPageBound] = useState(0);
    const [isPrevBtnActive, setIsPrevBtnActive] = useState('disabled');
    const [isNextBtnActive, setIsNextBtnActive] = useState('');
    const [pageBound, setPageBound] = useState(5);

    const [insertedId, setInsertedId] = useState(null);

    let inputFile /* = useRef() */;
    const ref = useRef(null);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const dbValues = useSelector((state) => state.crmLeads.leadImportMappingKeys);
    const memoryData = useSelector((state) => state.crmLeads.mappedMemoryData);
    const filePath = useSelector((state) => state.crmLeads.downloadMappedDataStatusFile);
    const importTypes = useSelector((state) => state.crmLeads.crmImportTypes);
    const crmImportedDataIndertedId = useSelector((state) => state.crmLeads.crmImportedDataIndertedId);
    const isLoading = useSelector((state) => state.crmLeads.loading);

    props.pageTypeHandler("CRM Imports");

    useEffect(() => {
        let domainName =  window.location.hostname;
        dispatch(fetchCrmImportTypes(props.authToken, domainName));
        dispatch({ type: types.CRM_IMPORT_DATA_INSERT_ID, payload: null });
    }, []);

    useEffect(() => {
        if (importTypes.length == 1 && importTypes[0].name == "General") {
            setDownloadType(importTypes[0].id);
        }else{
            setDownloadType(0);
        }
    }, [importTypes]);

    useEffect(() => {
        setInsertedId(crmImportedDataIndertedId);
        if (crmImportedDataIndertedId !== null) {
            setBreadcrumbStatus(2);
        }
    }, [crmImportedDataIndertedId]);


    useEffect(() => {
        let dbKeys = [];
        // console.log("duuuu", dbValues);

        if (dbValues !== undefined && dbValues !== null) {
            for (let i = 0; i < Object.keys(dbValues).length; i++) {
                dbKeys.push({ name: Object.keys(dbValues)[i], value: Object.values(dbValues)[i] });
            }
        }
        setDbval(dbKeys);
        //console.log("::", dbKeys,/* dbValues, Object.keys(dbValues).length */);
    }, [dbValues]);



    const downloadTemplate = () => {
        if (downloadType == 1) {
            let x = {};
            x["Firstname"] = null;
            x["Lastname"] = null;
            x["Email"] = null;
            x["Phone"] = null;
            x["City"] = null;
            x["advisor_no"] = null;
            x["advisor_email"] = null;
            x["lead stage"] = null;
            x["company"] = null;
            Utils([x], 'import_csv_test', 'csv');
        } else if (downloadType == 2) {
            let x = {};
            x["FOLIONO"] = null;
            x["INVESTORNAME"] = null;
            x["REGISTRATIONDATE"] = null;
            x["SCHEMENAME"] = null;
            x["AMOUNT"] = null;
            x["FREQUENCY"] = null;
            x["RM_EMAIL_ID"] = null;
            x["AGENT"] = null;
            Utils([x], 'SIP Termination', 'csv');
        } else if (downloadType == 0) {
            Toast("Please select import type", "E");
        }

        setModal(false);
    }

    // handle file upload
    const handleFileUpload = (e) => {
        //console.log("sdads", inputFile);
        e.preventDefault();
        setData([]);
        setArr([]);
        setFile(null);
        var files = e.target.files, f = files[0];
        setFile(f);
        //inputFile.current.value = f;

        console.log("filee", f.name);
        var reader = new FileReader();
        reader.onload = function (e) {
            var data = e.target.result;
            let readedData = XLSX.read(data, { type: 'binary' });
            const wsname = readedData.SheetNames[0];
            const ws = readedData.Sheets[wsname];

            /* Convert array to json*/
            const dataParse = XLSX.utils.sheet_to_json(ws, {
                defval: "",
                blankrows: true,
                header: "A",
                raw: true,
                rawNumbers: true,
            });
            // console.log("dataa", dataParse[0], "length===", Object.keys(dataParse[0]).length);
            let d = [];
            let arr1 = [];
            for (let j = 0; j < Object.keys(dataParse[0]).length; j++) {
                //console.log("dsf", Object.values(dataParse[0])[j]);
                d.push({ name: Object.values(dataParse[0])[j] });
                arr1.push({ id: j, value: "" });
            }

            console.log("file d", d);
            console.log("file arr1", arr1);
            setArr(arr1);
            setData(d);
            //setFileUploaded(dataParse);
        };
        reader.readAsBinaryString(f)
    }


    const handleBreadCrumbStatus = (e) => {
        if (e == 1) {
            if (downloadType == 0) {
                Toast("Select Type of Import", "E");
            } else if (file == null) {
                Toast("Upload file", "E");
            } else {  /* if (file !== null && downloadType !== 0) */
                dispatch(LeadImportMappingData(props.authToken, { import_type: downloadType }));
                setBreadcrumbStatus(e);
            }
        }
    }

    const updateArray = (value, index) => {
        console.log("dssd", value, index);
        let newArr = [...arr];
        newArr[index].value = value;
        setArr(newArr);
    }

    const handleSubmit = () => {
        const lookup = arr.reduce((a, e) => {
            a[e.value] = ++a[e.value] || 0;
            return a;
        }, {});
        console.log("lookup", lookup);
        const duplicates = arr.filter(e => {
            if (e.value !== "") {
                return lookup[e.value];
            }
        });
        console.log("duplicates", duplicates);

        if (duplicates.length > 0) {
            Toast("Duplications In  Mappings, check again", "E");
        } else {
            let array1 = arr.filter(e => {
                if (e.value !== "") {
                    return e;
                }
            });
            console.log("arr", arr);
            console.log("arr1", array1);
            // console.log("array1 lenght", array1.length, "dbval length", dbval.length);

            if (array1.length !== dbval.length) {
                Toast("Map all the db fields to the file headers", "E");
            } else {
                let mapped_data_memory = []
                let formData = new FormData();
                formData.append('imported_file', file);
                formData.append("imported_type", downloadType);

                arr.map((a, i) => {
                    if (a.value !== '') {
                        formData.append("mapped_data[" + i + "]", a.value);
                    } /* else {
                    formData.append("mapped_data[" + i + "]", " ");
                } */
                    if (downloadType == 1) {
                        if (a.value === 'firstname') {
                            mapped_data_memory.push({ name: "firstname", index: i });
                        } else if (a.value === 'lastname') {
                            mapped_data_memory.push({ name: "lastname", index: i });
                        } else if (a.value === 'email') {
                            mapped_data_memory.push({ name: "email", index: i });
                        } else if (a.value === 'contact_no') {
                            mapped_data_memory.push({ name: "contact_no", index: i });
                        }
                    } else if (downloadType == 2) {
                        if (a.value === 'investorname') {
                            mapped_data_memory.push({ name: "investorname", index: i });
                        } else if (a.value === 'scheme_name') {
                            mapped_data_memory.push({ name: "scheme_name", index: i });
                        } else if (a.value === 'rm_email_id') {
                            mapped_data_memory.push({ name: "rm_email_id", index: i });
                        }
                    }
                });

                dispatch(LeadImportDoc(authToken, formData, mapped_data_memory, downloadType));
                /*  setTimeout(() => {
                     console.log("crmImportedDataIndertedId", insertedId);
                     if (insertedId !== null) {
                         setBreadcrumbStatus(2);
                     }
                 }, [3000]); */
            }
        }
    }

    console.log("insertedId", insertedId);

    const downloadMappedData = () => {
        let index1, index2, index3, index4 = "";
        /* 1 {
                    file_path: file,
                    index4: emailIdx,
                    index3: phoneIdx,
                    index1: firstnameIdx,
                    index2: lastnameIdx,
                    imported_type:importType
                },
               2 {
                    file_path: file,
                    index4: RM_EMAIL_ID,
                    index3: SCHEMENAME,
                    index1: insertedId,//prev res id
                    index2: investorName,
                    imported_type:importType
                } */
        if (downloadType == 1 && memoryData !== []) {
            memoryData.map(a => {
                if (a.name === 'firstname') {
                    index1 = a.index;
                } else if (a.name === 'lastname') {
                    index2 = a.index;
                } else if (a.name === 'email') {
                    index4 = a.index;
                } else if (a.name === 'contact_no') {
                    index3 = a.index;
                }
            });
        } else if (downloadType == 2 && memoryData !== []) {
            if (crmImportedDataIndertedId !== null || crmImportedDataIndertedId !== 0) {
                index1 = crmImportedDataIndertedId;
            }
            memoryData.map(a => {
                if (a.name === 'investorname') {
                    index2 = a.index;
                } else if (a.name === 'scheme_name') {
                    index3 = a.index;
                } else if (a.name === 'rm_email_id') {
                    index4 = a.index;
                }
            })
        }
        dispatch(exportImportedDataStatus(authToken, filePath, index1, index2, index3, index4, downloadType));
    }


    const doneImport = () => {
        dispatch({ type: types.MAPPED_MEMORY_DATA, payload: [] });
        dispatch({ type: types.DOWNLOAD_MAPPEDDATA_STATUS_FILE_PATH, payload: null });
        dispatch({ type: types.CRM_IMPORT_DATA_INSERT_ID, payload: null })
        navigate('/crm');
    }



    return (
        <>
            {isLoading === true ? <Loading /> : (
                <>
                    <div className="sub-header">

                        <Row>
                            <Col className="header-breadcrumb">
                                <Breadcrumb>
                                    <Breadcrumb.Item
                                        linkAs={Link}
                                        linkProps={{ to: "/crm" }}
                                        eventKey={"Leads"}
                                    >
                                        <div onClick={() => { dispatch({ type: types.CRM_IMPORT_DATA_INSERT_ID, payload: null }) }}>Leads</div>
                                    </Breadcrumb.Item>
                                    <span>
                                        <Icon.ChevronRight />
                                    </span>
                                    <>
                                        <Breadcrumb.Item active>Import</Breadcrumb.Item>
                                    </>
                                </Breadcrumb>
                            </Col>
                        </Row>
                    </div>


                    <Row className="sub-breadcrumb mb-4">
                        <Col>
                            <ul>
                                <li
                                    className={breadcrumbStatus === 0 ? "link active" : "link"}
                                /* onClick={() => {
                                    handleBreadCrumbStatus(0);
                                }} */
                                >
                                    1. Select File
                                </li>
                                <li className={breadcrumbStatus === 0 ? "link active" : "link"}>
                                    --------------------------------------------------------------------------
                                </li>
                                <li
                                    className={breadcrumbStatus === 1 ? "link active" : "link"}
                                /* onClick={() => {
                                    handleBreadCrumbStatus(1);
                                }} */
                                >
                                    2. Map Fields
                                </li>
                                <li className={breadcrumbStatus === 1 ? "link active" : "link"}>
                                    --------------------------------------------------------------------------
                                </li>
                                <li
                                    className={breadcrumbStatus === 2 ? "link active" : "link"}
                                /* onClick={() => {
                                    handleBreadCrumbStatus(2);
                                }} */
                                >
                                    3. Check
                                </li>
                            </ul>
                        </Col>
                    </Row>

                    {(breadcrumbStatus === 0 || breadcrumbStatus === 1) && (
                        <div className="breadcrumb-page">
                            <Row>
                                <Col sm md={4} className='d-flex align-items-start justify-content-center p-4'>
                                    <div>
                                        <Image className='import-image' src={imgImport} alt="image" />
                                        <div className='import-text'>Import</div>
                                        {/* <div className='import-sub-text'>Maecenas nulla interdum volutpat tortor. Nunc purus.</div> */}
                                        <a variant="light" className='import-download-text mt-2' onClick={() => setModal(true)}>Download Import format</a>
                                    </div>
                                </Col>

                                <Modal
                                    size="md"
                                    aria-labelledby="contained-modal-title-vcenter"
                                    centered
                                    show={modal}
                                    onHide={() => setModal(false)}
                                >
                                    <Form onSubmit={() => downloadTemplate()}>
                                        <Modal.Header closeButton>
                                            <Modal.Title id="contained-modal-title-vcenter">
                                                <div className='fs-5 fw-bold'>Select type of file you want to download</div>
                                            </Modal.Title>
                                        </Modal.Header>
                                        <Modal.Body>

                                            <Form.Select
                                                id="downloadFileType"
                                                className="mapping-select"
                                                as="select"
                                                required
                                                onChange={(e) => {
                                                    setDownloadType(e.target.value);
                                                }}
                                            >
                                                {importTypes?.length > 1 && (<option key={1} value="">Type of Import</option>)}
                                                {importTypes.map((item, index) => {
                                                    return (
                                                        <>
                                                            <option key={index + 2} value={item.id} >{item.name}</option>
                                                        </>
                                                    )
                                                })}
                                            </Form.Select>
                                        </Modal.Body>
                                        <Modal.Footer>
                                            <Button type="submit" className="submit-btn" >Download</Button>
                                        </Modal.Footer>
                                    </Form>
                                </Modal>

                                {breadcrumbStatus === 0 && (
                                    <Col sm md={8}>
                                        {/* <Form onSubmit={() => handleBreadCrumbStatus(1)}> */}
                                        <div className='my-div'>
                                            <div className='p-3'>
                                                <div className='import-text'>Import</div>
                                                <Form.Select
                                                    id="importType"
                                                    required
                                                    className="mapping-select"
                                                    as="select"
                                                    value={downloadType}
                                                    placeholder='Type of import'
                                                    onChange={(e) => {
                                                        // updateArray(e.target.value);
                                                        setDownloadType(e.target.value)
                                                    }}
                                                >
                                                    {importTypes?.length > 1 && (<option key={1} value="">Type of Import</option>)}
                                                    {importTypes.map((item1, index) => {
                                                        return (
                                                            <>
                                                                <option key={index + 2} value={item1.id} >{item1.name}</option>
                                                            </>
                                                        )
                                                    })}
                                                </Form.Select>
                                                <div className='select-file mt-2 d-flex align-items-center justify-content-center'>
                                                    <div>
                                                        <Form.Control required name="fileUpload" style={{ display: 'none' }} type="file" ref={refParam => inputFile = refParam} accept=".csv,.xlsx,.xls" onChange={(e) => handleFileUpload(e)} />
                                                        <div className='fs-5  d-flex justify-content-center' onClick={() => inputFile.click()}>
                                                            <span>Select File </span><Icon.ArrowBarUp className='m-2' />
                                                        </div>
                                                        {file !== null && (<div className='fst-normal d-flex justify-content-center'>{file.name}</div>)}
                                                        <div className='fst-normal d-flex justify-content-center'>Supported formats(.csv)</div>
                                                        <div>
                                                            <a variant="light" className='import-download-text d-flex justify-content-center' onClick={() => downloadTemplate()}>Download Import format</a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <Button type="button" className="my-button submit-btn" onClick={() => handleBreadCrumbStatus(1)}>
                                                Next&nbsp;&nbsp;&nbsp;&nbsp;
                                                <span>
                                                    <Icon.ChevronRight />
                                                </span>
                                            </Button>
                                        </div>
                                        {/*  </Form> */}
                                    </Col>
                                )}

                                {breadcrumbStatus === 1 && (
                                    <Col sm md={8}>
                                        <div className='my-div'>
                                            <div className='p-3'>
                                                <div className='import-text'>Map Fields</div>

                                                <Row>
                                                    <Col sm lg={4}>Name</Col>
                                                    <Col sm lg={4}></Col>
                                                    <Col sm lg={4}>Map To</Col>
                                                </Row>

                                                <hr />

                                                <div className='breadcrumb2'>
                                                    {data.map((item, i) => {
                                                        return (
                                                            <>
                                                                <Row key={i}>
                                                                    <Col sm lg={4}>
                                                                        <span className='fst-normal fw-bolder'>{item.name}</span>
                                                                    </Col>
                                                                    <Col sm lg={4}>
                                                                        <Icon.ArrowRight style={{ width: '180px', color: '#45BCD2' }} />
                                                                    </Col>
                                                                    <Col sm lg={4}>
                                                                        <Form.Select
                                                                            // key={i}
                                                                            id={"mapping" + arr[i].id}
                                                                            // className="mapping-select"
                                                                            as="select"
                                                                            value={arr[i].value}
                                                                            onChange={(e) => {
                                                                                updateArray(e.target.value, i);
                                                                            }}
                                                                        >
                                                                            <option key={1} value="">Select</option>
                                                                            {dbval.map((item1, index) => {
                                                                                return (
                                                                                    <>
                                                                                        <option key={index + 2} value={item1.name} >{item1.value}</option>
                                                                                    </>
                                                                                )
                                                                            })}
                                                                        </Form.Select>
                                                                    </Col>
                                                                </Row>
                                                                <hr />
                                                            </>
                                                        )
                                                    })}
                                                </div>

                                            </div>

                                            <Button variant="info" type="button" className="mt-5 my-button submit-btn" onClick={() => handleSubmit()}>
                                                Import&nbsp;&nbsp;&nbsp;&nbsp;
                                                <span>
                                                    <Icon.ChevronRight />
                                                </span>
                                            </Button>
                                        </div>
                                    </Col>
                                )}

                            </Row>
                        </div>
                    )}

                    {breadcrumbStatus === 2 && (
                        <div className='breadcrumb-page'>
                            <div className='my-div'>
                                <Row className='p-3'>
                                    <Col>
                                        <div className="import-text">Imported Data</div>
                                    </Col>
                                    <Col>
                                        {/*  <span className='d-flex justify-content-end'>
                                    <Button className='submit-btn m-1'>Push Notification</Button>
                                    <Button className='submit-btn m-1'>Email</Button>
                                </span> */}
                                    </Col>
                                </Row>

                                <div className='mt-2 p-5 '>
                                    <div className='d-flex align-items-center justify-content-center'>
                                        <Button className='submit-btn' onClick={() => downloadMappedData()}>Download MappedData</Button>
                                    </div>
                                    <div className='d-flex align-items-center justify-content-center mt-1'>
                                        <div className='fs-normal fw-normal fst-italic'>To know the status of your imported data, download the file </div>
                                    </div>

                                </div>


                                <Button variant="info" type="submit" className="mt-5 my-button" onClick={() => doneImport()}>
                                    Done&nbsp;&nbsp;&nbsp;&nbsp;
                                    <span>
                                        <Icon.ChevronRight />
                                    </span>
                                </Button>
                            </div>
                        </div>
                    )}

                </>
            )}
        </>
    )
}

export default Imports;