// reducers
import {
  TOGGLE_LOADING,
  LIST_LOADING,
  FILTER_LOADING,
  SEARCH_ASSET_LIBRARY_CONTENT_LIST,
  GLOBAL_FILTER_ASSET_LIBRARY_CONTENT_LIST,
  SET_GLOBAL_SEARCH_KEY,
  SET_GLOBAL_QFILTER_KEY,
  SET_GLOBAL_CFILTER_KEY,
  SET_GLOBAL_PFILTER_KEY,
  SET_GLOBAL_SFILTER_KEY,
  SET_GLOBAL_SOFILTER_KEY,
  FETCH_ASSET_LIBRARY_CONTENT_LIST,
  FETCH_ASSET_LIBRARY_FOLDER_LIST,
  FETCH_ASSET_LIBRARY_CATEGORY_LIST,
  FETCH_ASSET_LIBRARY_PRODUCT_LIST,
  FETCH_ASSET_LIBRARY_HASHTAG_LIST,
  FETCH_ASSET_LIBRARY_QUICK_FILTERS,
  SET_ASSET_LIBRARY_QUICK_FILTER,
  QUICK_FILTERS,
  TALIC_ACTIVE,
  FILTER,
  SORT_FILTER,
  SET_ASSET_LIBRARY_CATEGORY_FILTER,
  SET_ASSET_LIBRARY_STATUS_FILTER,
  SET_ASSET_LIBRARY_SORTBY_FILTER,
  SET_ASSET_BOOKMARK,
  UPDATE_MAIN_CONTENT,
  SET_ASSET_LIBRARY_CONTENT_LIST_PAGINATION,
  CREATE_ASSET_FOLDER,
  FETCH_ASSET_FOLDER_DROPDOWN_LIST,
  ADD_CONTENT_TO_FOLDER,
  SET_PREVIOUS_URL,
  FETCH_FOLDER_CONTENT_LIST,
  FOLDER,
  EDIT_FOLDER_NAME,
  PUBLISH_ASSET_CONTENT,
  DELETE_ASSET_CONTENT,
  DELETE_ASSET_BULK_CONTENT,
  DUPLICATE_ASSET_CONTENT,
  EDIT_ASSET_CONTENT,
  SET_ASSET,
  FETCH_ASSET_CONTENT_DETAILS,
  SEARCH_SUGGESTION_LIST,
  REMOVE_SUGGESTION_LIST,
  UNAUTHORIZED_ASSET_LIBRARY,
  FETCH_ASSET_LIBRARY_SUBCATEGORY_LIST,
  FETCH_ASSET_LIBRARY_LEAD_GENERATION_LIST,
  SET_FOLDER_DETAILS_DATA,
  END_LIMIT_MIROSITE
} from "../../constants/ActionTypes";

const initialState = {
  loading: false,
  list_loading: false,
  filter_loading: false,
  contentList: [],
  folderList: [],
  categoryList: [],
  subcategoryList: [],
  leadGenerationList: [],
  hashtagList: [],
  quickFilters: [],
  backToPreviousUrl:"",
  quickFilterId: null,
  quickFilterType: null,
  filterType: null,
  filterValue: null,
  talic_active: "",
  sortFilter: null,
  microsite_flag: 0,
  totalData: null,
  bookmark: null,
  createFolder: null,
  addFolder: null,
  editFolder: null,
  folderDropdown: [],
  folderContentList: [],
  end_limit: 20,
  folderId: null,
  folderType: null,
  folderName: null,
  globalSearchKey: null,
  qFilterKey: null,
  cFilterKey: null,
  pFilterKey:null,
  sFilterKey: null,
  svFilterKey: null,
  main_content_link:"",
  soFilterKey: null,
  publishContent: null,
  deleteContent: null,
  deleteBulkContent: null,
  duplicateContent: null,
  editContent: null,
  currentAsset: null,
  productListData: null,
  currentAssetDetails: [],
  suggestionList: [],
  removeSuggList: [],
  notificationMsg: null,
  unauthorizedAssetLibrary: null,
  resetPage: null,
  folderDetails: null
};

const assetReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case TOGGLE_LOADING:
      return { ...state, loading: payload };
    case LIST_LOADING:
      return { ...state, list_loading: payload };
    case FILTER_LOADING:
      return { ...state, filter_loading: payload };
    case SEARCH_SUGGESTION_LIST:
      return { ...state, suggestionList: payload.suggestionlist };
    case SET_PREVIOUS_URL:
      return { ...state, backToPreviousUrl: payload };
    case REMOVE_SUGGESTION_LIST:
      return { ...state, notificationMsg: payload };
    case SET_GLOBAL_SEARCH_KEY:
      return { ...state, globalSearchKey: payload };
    case SET_GLOBAL_QFILTER_KEY:
      return { ...state, qFilterKey: payload };
    case SET_GLOBAL_CFILTER_KEY:
      return { ...state, cFilterKey: payload };
    case SET_GLOBAL_PFILTER_KEY:
      return { ...state, pFilterKey: payload };
    case SET_GLOBAL_SFILTER_KEY:
      return { ...state, sFilterKey: payload };
    case END_LIMIT_MIROSITE:
      return { ...state, end_limit: payload };
    case SET_GLOBAL_SOFILTER_KEY:
      return { ...state, svFilterKey: payload };
    case SEARCH_ASSET_LIBRARY_CONTENT_LIST:
      return { ...state, contentList: payload.listing_data, totalData: payload.total_data, resetPage: payload.total_data };
    case GLOBAL_FILTER_ASSET_LIBRARY_CONTENT_LIST:
      return { ...state, contentList: payload.listing_data, microsite_flag: payload.microsite_flag ,folderList: payload.folders, totalData: payload.total_data, resetPage: payload.total_data };
    case FETCH_ASSET_LIBRARY_CONTENT_LIST:
      return { ...state, contentList: payload.listing_data, folderList: payload.folders, totalData: payload.total_data, resetPage: payload.total_data };
    case FETCH_ASSET_LIBRARY_FOLDER_LIST:
      return { ...state, folderList: payload };
    case FETCH_ASSET_LIBRARY_CATEGORY_LIST:
      return { ...state, categoryList: payload };
    case FETCH_ASSET_LIBRARY_PRODUCT_LIST:
      return { ...state, productListData: payload };
    case FETCH_ASSET_LIBRARY_HASHTAG_LIST:
      return { ...state, hashtagList: payload };
    case FETCH_ASSET_LIBRARY_QUICK_FILTERS:
      return { ...state, quickFilters: payload };
    case SET_ASSET_LIBRARY_QUICK_FILTER:
      return { ...state, contentList: payload.listing_data, totalData: payload.total_data, resetPage: payload.total_data };
    case QUICK_FILTERS:
      return { ...state, quickFilterId: payload.id, quickFilterType: payload.value };
    case FILTER:
      return { ...state, filterType: payload.filterType, filterValue: payload.filterValue };
    case TALIC_ACTIVE:
      return { ...state, talic_active: payload }
    case SORT_FILTER:
      return { ...state, sortFilter: payload };
    case SET_ASSET_LIBRARY_CATEGORY_FILTER:
      return { ...state, contentList: payload.listing_data, totalData: payload.total_data, resetPage: payload.total_data };
    case SET_ASSET_LIBRARY_STATUS_FILTER:
      return { ...state, contentList: payload.listing_data, totalData: payload.total_data, resetPage: payload.total_data };
    case SET_ASSET_LIBRARY_SORTBY_FILTER:
      return { ...state, contentList: payload.listing_data, totalData: payload.total_data, resetPage: payload.total_data };
    case SET_ASSET_BOOKMARK:
      return { ...state, bookmark: payload };
    case UPDATE_MAIN_CONTENT:
      return { ...state, main_content_link: payload };
    case SET_ASSET_LIBRARY_CONTENT_LIST_PAGINATION:
      return { ...state, contentList: payload.listing_data, totalData: payload.total_data, resetPage: payload.total_data };
    case CREATE_ASSET_FOLDER:
      return { ...state, createFolder: payload };
    case FETCH_ASSET_FOLDER_DROPDOWN_LIST:
      return { ...state, folderDropdown: payload };
    case ADD_CONTENT_TO_FOLDER:
      return { ...state, addFolder: payload };
    case FOLDER:
      return { ...state, folderId: payload.id, folderType: payload.value, folderName: payload.name };
    case FETCH_FOLDER_CONTENT_LIST:
      return { ...state, contentList: payload.listing_data, totalData: payload.total_data, resetPage: payload.total_data };
    case EDIT_FOLDER_NAME:
      return { ...state, editFolder: payload };
    case PUBLISH_ASSET_CONTENT:
      return { ...state, publishContent: payload };
    case DELETE_ASSET_CONTENT:
      return { ...state, deleteContent: payload };
    case DELETE_ASSET_BULK_CONTENT:
      return { ...state, deleteBulkContent: payload };
    case DUPLICATE_ASSET_CONTENT:
      return { ...state, duplicateContent: payload };
    case EDIT_ASSET_CONTENT:
      return { ...state, editContent: payload };
    case SET_ASSET:
      return { ...state, currentAsset: payload };
    case FETCH_ASSET_CONTENT_DETAILS:
      return { ...state, currentAssetDetails: payload };
    case UNAUTHORIZED_ASSET_LIBRARY:
      return { ...state, unauthorizedAssetLibrary: payload };
    case FETCH_ASSET_LIBRARY_SUBCATEGORY_LIST:
      return { ...state, subcategoryList: payload };
    case FETCH_ASSET_LIBRARY_LEAD_GENERATION_LIST:
      return { ...state, leadGenerationList: payload };
    case SET_FOLDER_DETAILS_DATA:
      return { ...state, folderDetails: payload };
    default:
      return state;
  }
};
export default assetReducer;
