import React from "react";
import LeftMenu from "./LeftMenu";
import RightScreen from "./RightScreen";
import SingleScreen from "./SingleScreen";
import { Button } from "react-bootstrap";
import * as Icon from "react-bootstrap-icons";
import Page from "../Page";
import "./setup.css";
import EditPartner from "./EditPartner";
import AddPartner from "./AddPartner";
import SetupBatches from "./SetupBatches";

const SetupMain = (props) => {
  const NextScreen = () => {
    if (props.subPageName === "editPartner") {
      return <EditPartner authToken={props.authToken} pageName={props.pageName} subPageName={props.subPageName} innerTitle={props.innerTitle}/>
    }else if(props.subPageName === "addPartner"){
      return <AddPartner authToken={props.authToken} pageName={props.pageName} subPageName={props.subPageName} innerTitle={props.innerTitle}/>
    }else if(props.subPageName === "addBatches"){
      return <SetupBatches authToken={props.authToken} pageName={props.pageName} subPageName={props.subPageName} innerTitle={props.innerTitle}/>
    }else if(props.subPageName === "editBatches"){
      return <SetupBatches authToken={props.authToken} pageName={props.pageName} subPageName={props.subPageName} innerTitle={props.innerTitle}/>
    }else {
      return <SingleScreen authToken={props.authToken} pageName={props.pageName} subPageName={props.subPageName} innerTitle={props.innerTitle}/>;
    }
  }
  
  props.pageTypeHandler("Setup");
  return (
    <Page title="Settings">
    <div className="row main-setup-container">
        {props.topTitle && <h3>{props.topTitle}</h3>}
      {props.displayType === "1" ? (
        <>
          <div className="col-2 p-0">
            <LeftMenu pageName={props.pageName} subPageName={props.subPageName} authToken={ props.authToken }/>
          </div>
          <div className="col-10">
            <RightScreen pageName={props.pageName} subPageName={props.subPageName} authToken={ props.authToken } />
          </div>
        </>
      ) : (
          <NextScreen/>
      )}
      </div>
      </Page>
  );
};

export default SetupMain;
