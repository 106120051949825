import React from 'react';
import SetupCategory from './SetupCategory';
import SetupImageLibrary from './SetupImageLibrary';
import SetupContentList from './SetupContentList';
import SetupHashtag from './SetupHashtag';
import SetupHashtagGroup from './SetupHashtagGroup';
import SetupGlobalSearch from './SetupGlobalSearch';
import SetupProduct from './SetupProductType';
import SetupBussinessNeed from './setupBussinessNeed';
import CreateGroup from './CreateGroup';
import EditGroup from './EditGroup';
import SetupPartner from './SetupPartner';
import SetupPartnerCategory from './SetupPartnerCategory';
import  SetupLeaderBoard  from './SetupLeaderBoard';
import { SetupLevels } from './SetupLevels';
import SetupLeaderReport from './SetupLeaderReport';
// import { useSelector } from "react-redux";
// import { useState, useRef, useEffect } from "react";

const RightScreen = (props) => {
     const ShowScreen = () => {
          let title = props.subPageName
          switch (title) {
               // content screens
               case 'category':
                    return <SetupCategory subPageName={ props.subPageName} authToken={ props.authToken }/>
               case 'imageLibrary':
                    return <SetupImageLibrary subPageName={ props.subPageName} authToken={ props.authToken }/>
               case 'contentList':
                    return <SetupContentList subPageName={ props.subPageName} authToken={ props.authToken }/>
               case 'hashtag':
                    return <SetupHashtag subPageName={props.subPageName} authToken={ props.authToken }/>
               case 'hashtagGroup':
                    return <SetupHashtagGroup subPageName={ props.subPageName} authToken={ props.authToken }/>
               case 'globalSearch':
                    return <SetupGlobalSearch subPageName={ props.subPageName} authToken={ props.authToken }/>
               case 'productType':
                    return <SetupProduct subPageName={props.subPageName} authToken={props.authToken} />
               case 'businessNeed':
                    return <SetupBussinessNeed subPageName={ props.subPageName} authToken={ props.authToken }/>
               case 'createGroup':
                    return <CreateGroup subPageName={ props.subPageName} authToken={ props.authToken }/>
               case 'editGroup':
                    return <EditGroup subPageName={props.subPageName} authToken={props.authToken} />
               // userManagement Screen
               case 'partner':
                    return <SetupPartner subPageName={ props.subPageName} authToken={ props.authToken }/>
               case 'partnerCategory':
                    return <SetupPartnerCategory subPageName={ props.subPageName} authToken={ props.authToken }/>
               case 'leaderBoard':
                    return <SetupLeaderBoard subPageName={ props.subPageName} authToken={ props.authToken }/>
               case 'levels':
                    return <SetupLevels subPageName={props.subPageName} authToken={props.authToken} />
               case 'report':
                    return <SetupLeaderReport subPageName={ props.subPageName} authToken={ props.authToken }/>
               default:
                    break;
          }
     }
    return (
        <div className='right-screen'>
              {props.subPageName && <ShowScreen />}
        </div>
    )
}

export default RightScreen;