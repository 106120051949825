/* eslint-disable */
import ReactPaginate from "react-paginate";
import { useState, useEffect } from "react";

//Redux-imports
import { useDispatch, useSelector } from "react-redux";
import {
  setAssetLibrarySortByFilter,
  setAssetLibraryFilter,
  setAssetLibraryContentListPagination,
  setAssetLibraryQuickFilter,
  fetchFolderContentList,
  searchAssetLibraryContentList,
  globalFilterAssetLibraryContentList,
} from "../redux/actions/actions";

import $ from "jquery";

const Pagination = (props) => {
  const dispatch = useDispatch();

  const [pageCount, setPageCount] = useState(0);
  //const [endOffset, setEndOffset] = useState(itemsPerPage);

  let currOffset = 0;

  const currQuickFilterId = useSelector(
    (state) => state.assetLibrary.quickFilterId
  );
  const currQuickFilterType = useSelector(
    (state) => state.assetLibrary.quickFilterType
  );
  const currFilterType = useSelector((state) => state.assetLibrary.filterType);
  const currFilterValue = useSelector(
    (state) => state.assetLibrary.filterValue
  );
  const currSortFilter = useSelector((state) => state.assetLibrary.sortFilter);
  const currFolderId = useSelector((state) => state.assetLibrary.folderId);
  const currFolderType = useSelector((state) => state.assetLibrary.folderType);
  const currFolderName = useSelector((state) => state.assetLibrary.folderName);
  const totalData = useSelector((state) => state.assetLibrary.totalData);
  const globalSearchKey = useSelector((state) => state.assetLibrary.globalSearchKey);
  const qFilterKey = useSelector((state) => state.assetLibrary.qFilterKey);
  const cFilterKey = useSelector((state) => state.assetLibrary.cFilterKey);
  const pFilterKey = useSelector((state) => state.assetLibrary.pFilterKey);
  const sFilterKey = useSelector((state) => state.assetLibrary.sFilterKey);
  const svFilterKey = useSelector((state) => state.assetLibrary.svFilterKey);
  
  const resetPage = useSelector((state) => state.assetLibrary.resetPage);
  // console.log("currFolderId",currFolderId);
  // console.log("currFolderType",currFolderType);

  useEffect(() => {
    setTimeout(() => {
      $(".page-item").removeClass("active");
      $("li.page-item:nth-child(2)").addClass("active");
    }, 3000);
    setPageCount(Math.ceil(totalData / 20));
  }, [totalData]);

  const handlePageClick = (event) => {
    props.pageCounter(event.selected);
    if (event.selected > 0) {
      $("li.page-item:nth-child(2)").removeClass("active");
    }
    const newOffset = (event.selected * 20) % totalData;
    if (newOffset === 0) {
      currOffset = newOffset;
      //setItemOffset(newOffset);
      //setEndOffset(20);
    } else {
      //setEndOffset(20);
      //setItemOffset(newOffset + 1);
      currOffset = newOffset;
    }
    // if (currQuickFilterId) {
    //   dispatch(
    //     setAssetLibraryQuickFilter(
    //       props.authToken,
    //       currQuickFilterId,
    //       currQuickFilterType,
    //       currOffset
    //     )
    //   );
    // } else if (currFilterType) {
    //   dispatch(
    //     setAssetLibraryFilter(
    //       props.authToken,
    //       currFilterType,
    //       currFilterValue,
    //       currOffset
    //     )
    //   );
    // } else if (currSortFilter) {
    //   dispatch(
    //     setAssetLibrarySortByFilter(props.authToken, currSortFilter, currOffset)
    //   );
    // } else if (currFolderId) {
    //   dispatch(
    //     fetchFolderContentList(props.authToken, currFolderId, currFolderType, currFolderName, currOffset)
    //   );
    // } else if (globalSearchKey) {
    //   dispatch(
    //     dispatch(searchAssetLibraryContentList(props.authToken, globalSearchKey, currOffset))
    //   );
    // } else {
    //   dispatch(
    //     setAssetLibraryContentListPagination(props.authToken, currOffset)
    //   );
    // }
    dispatch(globalFilterAssetLibraryContentList(props.authToken, globalSearchKey, pFilterKey, qFilterKey, cFilterKey, sFilterKey, svFilterKey, currFolderId, currFolderType, currFolderName, currOffset));
  };

  useEffect(() => {
    console.log("PageReset",resetPage);
  }, [resetPage]);

  return (
    <>
      <ReactPaginate
        nextLabel=">"
        onPageChange={handlePageClick}
        pageRangeDisplayed={3}
        marginPagesDisplayed={2}
        pageCount={pageCount}
        previousLabel="<"
        pageClassName="page-item"
        pageLinkClassName="page-link"
        previousClassName="page-item"
        previousLinkClassName="page-link"
        nextClassName="page-item"
        nextLinkClassName="page-link"
        breakLabel="..."
        breakClassName="page-item"
        breakLinkClassName="page-link"
        containerClassName="pagination"
        activeClassName="active"
        renderOnZeroPageCount={null}
        forcePage={resetPage}
      />
    </>
  );
};

export default Pagination;
