import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Cookies from 'js-cookie';
import Loading from "../../Loading";
import { useDispatch } from "react-redux";
import { backToPreviousRoute, fetchCompanyLogo, fetchResetPasswordDetails } from "../../../redux/actions/actions";
// ..

const ResetPassword = (props) => {
    const dispatch = useDispatch();
    let navigate = useNavigate();
    props.pageTypeHandler("ResetPassword");
    useEffect(() => {
        if (window.location.href.indexOf("sp-reset-password?") > -1) {
            let resetPasswordUrl = window.location.href.split('?').pop();
            let setUrl = window.location.href;
            setUrl = setUrl.split('/')[0] + "/" + setUrl.split('/')[1] + "/" + setUrl.split('/')[2] + "/";
            //let newUrl = "http://localhost/sp-bfsi/framework/api/sp-reset-password?" + resetPasswordUrl;
            let newUrl = `${setUrl}framework/api/sp-reset-password?` + resetPasswordUrl;
            // console.log("setUrl",setUrl);
            // console.log("newUrl",newUrl);
            dispatch(fetchResetPasswordDetails(newUrl));
            localStorage.setItem("resetPassword", newUrl);
            setTimeout(() => {
                navigate("/")
            }, 2000);
        };
        dispatch(backToPreviousRoute(""));
    });
    return (
        <>
         <Loading />
        </>
    )
};

export default ResetPassword;
